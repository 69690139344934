<template>
  <div>
    <div class="d-none d-lg-block">
      <div class="" >
        <section class="">
          <div style="margin-top: -4.5%" class="container p-3">
            <div class="row">
              <div class="col-md-3">
                <cardFIlter
                  :fungsiFilterCategory="fungsiFilterCategory"
                  :fungsi="fungsiFilter"
                  :dataEtalaseCount="dataEtalaseCount"
                  :dataEtalase="dataEtalase"
                  :dataFilter="dataFilter"
                  :dataSort="dataSort"
                  :dataFilterCategory="dataFilterCategory"
                  :resetOptions="resetOptions"
                />
                <div></div>
              </div>

              <div  class="col-md-9 body-home">
                <nav class="navbar navbar-expand-lg mb-2 pl-0 pt-0">
                  <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav">
                      <li
                        @click="halamanProduct('hal_utama')"
                        id="hal_utama"
                        class="hal_utama br-color-button-jaja mr-2 nav-item active bg-warning border border-warning font-weight-bold"
                        style="
                          border-bottom-left-radius: 0rem !important;
                          border-bottom-right-radius: 0rem !important;
                          padding: 0px 30px;
                        "
                      >
                        <a
                          id="a-hal_utama"
                          class="btn nav-link text-white font-weight-bold"
                        >
                          <i class="fas fa-archive h5 mr-2"></i> Produk</a
                        >
                      </li>
                      <li
                        @click="halamanProduct('hal_all_produk')"
                        id="hal_all_produk"
                        class="hal_all_produk border border-warning br-color-button-jaja text-white bg-white nav-item"
                        style="
                          border-bottom-left-radius: 0rem !important;
                          border-bottom-right-radius: 0rem !important;
                          padding: 0px 30px;
                        "
                      >
                        <a
                          id="a-hal_all_produk"
                          class="btn nav-link text-warning font-weight-bold"
                          ><i class="fas fa-home h5 mr-2"></i> Toko</a
                        >
                      </li>
                    </ul>
                  </div>
                </nav>
                <div :class="setHidden.hal_utama ? `` : `d-none`">
                  <div class="placeholder" style="display: none">
                    <div class="ph-item" style="margin: 0">
                      <div class="ph-col-12">
                        <div class="ph-picture" style="height: 500px"></div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="pl-3">
                      Pencarian produk untuk
                      <span style="color: red">{{
                        this.$route.params.id
                      }}</span>
                      sebanyak
                      <span style="color: red">{{ this.recordsLength }}</span>
                      item
                    </div>
                  </div>
                  <br />
                  <cardProduk :data="dataSemuaProduk" :isFinish="isFinish" />
                  <br />
                  <pagination
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                    :records="recordsLength"
                    v-model="page"
                    :per-page="perPage"
                    @paginate="getSearch"
                  >
                  </pagination>
                </div>
                <div>
                  <div :class="setHidden.hal_all_produk ? `` : `d-none`">
                    <div class="placeholder" style="display: none">
                      <div class="ph-item" style="margin: 0">
                        <div class="ph-col-12">
                          <div class="ph-picture" style="height: 500px"></div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="pl-3">
                        Pencarian Toko untuk
                        <b>
                          <span style="color: red">{{
                            this.$route.params.id
                          }}</span></b
                        >
                        sebanyak
                        <span style="color: red">{{ this.recordsToko }}</span>
                        item
                      </div>
                    </div>
                    <br />
                    <cardToko :data="dataSemuaToko" :isFinish="isFinish" />
                    <br />
                    <pagination
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                      :records="recordsToko"
                      v-model="page"
                      :per-page="perPage"
                      @paginate="getSearch"
                    >
                    </pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="d-lg-none">
      <div class="">
        <section class="">
          <div style="margin-top: -42%" class="container-m p-3">
            <div class="row">
              <div class="col-md-3">
                <cardFIlter
                  :fungsiFilterCategory="fungsiFilterCategory"
                  :fungsi="fungsiFilter"
                  :dataEtalaseCount="dataEtalaseCount"
                  :dataEtalase="dataEtalase"
                  :dataFilter="dataFilter"
                  :dataSort="dataSort"
                  :dataFilterCategory="dataFilterCategory"
                  :resetOptions="resetOptions"
                />
                <div></div>
              </div>

              <div class="col-md-9 body-home">
                <nav class="navbar navbar-expand-lg mb-2 pl-0 pt-0">
                  <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav">
                      <li
                        @click="halamanProduct('hal_utama')"
                        id="hal_utama"
                        class="hal_utama br-color-button-jaja mr-2 nav-item active bg-warning border border-warning font-weight-bold"
                        style="
                          border-bottom-left-radius: 0rem !important;
                          border-bottom-right-radius: 0rem !important;
                          padding: 0px 30px;
                        "
                      >
                        <a
                          id="a-hal_utama"
                          class="btn nav-link text-white font-weight-bold"
                        >
                          <i class="fas fa-archive h5 mr-2"></i> Produk</a
                        >
                      </li>
                      <li
                        @click="halamanProduct('hal_all_produk')"
                        id="hal_all_produk"
                        class="hal_all_produk border border-warning br-color-button-jaja text-white bg-white nav-item"
                        style="
                          border-bottom-left-radius: 0rem !important;
                          border-bottom-right-radius: 0rem !important;
                          padding: 0px 30px;
                        "
                      >
                        <a
                          id="a-hal_all_produk"
                          class="btn nav-link text-warning font-weight-bold"
                          ><i class="fas fa-home h5 mr-2"></i> Toko</a
                        >
                      </li>
                    </ul>
                  </div>
                </nav>
                <div :class="setHidden.hal_utama ? `` : `d-none`">
                  <div class="placeholder" style="display: none">
                    <div class="ph-item" style="margin: 0">
                      <div class="ph-col-12">
                        <div class="ph-picture" style="height: 500px"></div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="pl-3">
                      Pencarian produk untuk
                      <span style="color: red">{{
                        this.$route.params.id
                      }}</span>
                      sebanyak
                      <span style="color: red">{{ this.recordsLength }}</span>
                      item
                    </div>
                  </div>
                  <br />
                  <cardProduk :data="dataSemuaProduk" :isFinish="isFinish" />
                  <br />
                  <pagination
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                    :records="recordsLength"
                    v-model="page"
                    :per-page="perPage"
                    @paginate="getSearch"
                  >
                  </pagination>
                </div>
                <div>
                  <div :class="setHidden.hal_all_produk ? `` : `d-none`">
                    <div class="placeholder" style="display: none">
                      <div class="ph-item" style="margin: 0">
                        <div class="ph-col-12">
                          <div class="ph-picture" style="height: 500px"></div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="pl-3">
                        Pencarian Toko untuk
                        <b>
                          <span style="color: red">{{
                            this.$route.params.id
                          }}</span></b
                        >
                        sebanyak
                        <span style="color: red">{{ this.recordsToko }}</span>
                        item
                      </div>
                    </div>
                    <br />
                    <cardToko :data="dataSemuaToko" :isFinish="isFinish" />
                    <br />
                    <pagination
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                      :records="recordsToko"
                      v-model="page"
                      :per-page="perPage"
                      @paginate="getSearch"
                    >
                    </pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="mt-5">
          <br />
          <NavBar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

import { mapGetters } from "vuex";
import firebase from "firebase/app";
import Flickity from "vue-flickity";
import cardPlaceholder from "../../components/card/cardplaceholder";
import cardSlider from "../../components/card/cardslider";
import cardProduk from "../../components/card/cardproduct";
import cardToko from "../../components/card/cardtoko";
import cardFIlter from "../../components/filtercategory/filtercategory";
import NavBar from "../../components/master/navbarbuttom.vue";
export default {
  data() {
    return {
      dataSemuaProduk: {},
      dataSemuaToko: {},
      dataKategory: {},
      dataFilter: [],
      dataFilterLokasi: [],
      dataSort: [],
      dataPage: {},
      setHidden: {
        hal_utama: true,
        hal_all_produk: false,
      },
      reqData: {
        page: 1,
        limit: 20,
        keyword: "",
        filter_price: "",
        filter_category: "",
        filter_condition: "",
        filter_preorder: "",
        filter_brand: "",
        sort: "",
        filter_etalase: "",
        filter_location: "",
      },
      page: 1,
      perPage: 12,
      dataEtalase: {},
      dataEtalaseCount: 0,
      dataFilterCategory: [],
      recordsLength: 0,
      recordsToko: 0,
      pageSizes: [12, 24, 32],
      isFinish: false,
      isFinishToko: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  // created() {
  //   this.getSearch();
  // },
  components: {
    cardProduk,
    cardFIlter,
    cardToko,
    NavBar,
  },
  computed: {
    ...mapGetters({ isLoggedIn: "isLoggedIn" }),
    ...mapGetters({ globalToken: "globalTokenGetters" }),
  },
  beforeMount() {
    this.getSearch();
    this.getSearchToko();
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
    resetOptions: function () {
      this.reqData.filter_price = "";
      this.reqData.filter_category = "";
      this.reqData.filter_condition = "";
      this.reqData.filter_preorder = "";
      this.reqData.filter_brand = "";
      this.reqData.sort = "";
      this.reqData.filter_etalase = "";
      this.reqData.filter_location = "";

      var category_list = document.getElementsByClassName("categoryProduct");
      for (var i_cat = 0; i_cat < category_list.length; i_cat++) {
        category_list[i_cat].selected = false;
      }

      var etalase_list = document.getElementsByClassName("etalaseProduct");
      for (var i_eta = 0; i_eta < etalase_list.length; i_eta++) {
        etalase_list[i_eta].checked = false;
      }

      var condition_list = document.getElementsByClassName("conditionProduct");
      for (var i_con = 0; i_con < condition_list.length; i_con++) {
        condition_list[i_con].checked = false;
      }

      var preorder_list = document.getElementsByClassName("preorderProduct");
      for (var i_pre = 0; i_pre < preorder_list.length; i_pre++) {
        preorder_list[i_pre].checked = false;
      }

      var sort_list = document.getElementsByClassName("sortProduct");
      for (var i_sort = 0; i_sort < sort_list.length; i_sort++) {
        sort_list[i_sort].checked = false;
      }

      this.getSearch();
    },
    fungsiFilter(slug) {
      const sortOrNot = slug.split(":")[0];
      if (sortOrNot == "filterProduk") {
        const filterName = slug.split(":")[1];
        const valueFilter = slug.split(":")[2];
        if (filterName == "condition") {
          this.reqData.filter_condition = valueFilter;
          this.getSearch();
        } else {
          this.reqData.filter_preorder = valueFilter;
          this.getSearch();
        }
      } else if (sortOrNot == "sortProduk") {
        this.reqData.sort = slug.split(":")[1];
        this.getSearch();
      } else {
        this.reqData.filter_etalase = slug.split(":")[2];
        this.getSearch();
      }
    },
    fungsiFilterCategory(slug) {
      this.reqData.filter_category = event.target.value;
      this.getSearch();
      this.getSearchToko();
    },
    getSearch: function () {
      this.reqData.limit = 10;
      const token = this.globalToken;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", token ? token : "");
      myHeaders.append("Cookie", "ci_session=p20okeu9kbgvs9amf7e93ptqjkmcg48r");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}product/searchAll/${this.$route.params.id}?page=${this.page}&limit=${this.perPage}&keyword=${this.reqData.keyword}&filter_price=${this.reqData.filter_price}&filter_category=${this.reqData.filter_category}&filter_condition=${this.reqData.filter_condition}&filter_preorder=${this.reqData.filter_preorder}&filter_brand=${this.reqData.filter_brand}&sort=${this.reqData.sort}&filter_etalase=${this.reqData.filter_etalase}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          // console.log(res)
          if (res.status.code === 200) {
            window.scrollTo(0, 0);
            const arrReqData = Object.values(this.reqData);
            const data = arrReqData.filter((item, index) => {
              return item != "";
            });
            if (data.length == 2) {
              data[0] == 1
                ? (this.dataHalUtama = res.data.items)
                : (this.dataHalUtama = {});
            }
            this.dataSemuaProduk = res.data.items;
            this.dataSemuaToko = res.data.toko;

            this.dataFilter = res.data.filters;
            this.dataSort = res.data.sorts;
            this.dataPage = res.data.totalPage;
            this.recordsLength = res.data.totalData;
            this.recordsToko = res.data.toko.length;
          }
          this.isFinish = true;
        })
        .catch((error) => console.log("error", error));
    },
    halamanProduct(slug) {
      if (slug == "hal_all_produk") {
        $(`#hal_all_produk`).addClass("bg-warning border border-warning");
        $(`#hal_all_produk a`).addClass("text-white font-weight-bold");
        $(`#hal_all_produk`).removeClass("bg-white");
        $(`#hal_all_produk a`).removeClass("text-warning");

        $(`#hal_utama`).addClass("bg-white border border-warning");
        $(`#hal_utama a`).addClass("text-warning font-weight-bold");
        $(`#hal_utama`).removeClass("bg-warning");
        $(`#hal_utama a`).removeClass("text-white");
      } else {
        $(`#hal_utama`).addClass("bg-warning border border-warning");
        $(`#hal_utama a`).addClass("text-white font-weight-bold");
        $(`#hal_utama`).removeClass("bg-white");
        $(`#hal_utama a`).removeClass("text-warning");

        $(`#hal_all_produk`).addClass("bg-white border border-warning");
        $(`#hal_all_produk a`).addClass("text-warning font-weight-bold");
        $(`#hal_all_produk`).removeClass("bg-warning");
        $(`#hal_all_produk a`).removeClass("text-white");
      }

      if (slug == "hal_utama") {
        this.setHidden.hal_utama = true;
        this.setHidden.hal_all_produk = false;

        if (slug == "hal_utama") {
          slug = "hal_utama";
        } else {
          slug = "hal_all_produk";
        }
      } else {
        this.setHidden.hal_utama = false;
        this.setHidden.hal_all_produk = true;
        if (slug == "hal_all_produk") {
          slug = "hal_all_produk";
        } else {
          slug = "hal_utama";
        }
      }
    },
    getKategoriSelect: function (page) {
      this.reqData.limit = 10;
      const token = this.globalToken;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", token ? token : "");
      myHeaders.append("Cookie", "ci_session=p20okeu9kbgvs9amf7e93ptqjkmcg48r");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}product/searchAll/${this.$route.params.id}?page=${this.page}&limit=${this.perPage}&keyword=&filter_price=&filter_category=&filter_condition=&filter_preorder=&filter_brand=&sort=&filter_etalase=`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.dataFilterCategory = res.data.filters[0].items;
          }
          this.isFinish = true;
        })
        .catch((error) => console.log("error", error));
    },
    handlePageSizeChange(event) {
      this.perPage = event.target.value;
      this.page = 1;
      this.getSearch(this.page);
    },
    filterProduk(slug) {
      // console.log(slug, event.target.value, Object.keys(this.reqData))
      const stringS = "filter_";
      const dataConcate = stringS.concat(slug);
      if (dataConcate == "filter_category") {
        this.reqData.filter_category = event.target.value;
      } else if (dataConcate == "filter_condition") {
        this.reqData.filter_condition = event.target.value;
      } else if (dataConcate == "filter_preorder") {
        this.reqData.filter_preorder = event.target.value;
      }
      setTimeout(() => this.getSearch(), 200);
    },
    sortProduk() {
      this.reqData.sort = event.target.value;
      setTimeout(() => this.getSearch(), 200);
    },
    setPage(page) {
      this.reqData.page = page;
      setTimeout(() => this.getSearch(), 200);
    },

    setAltImg(event) {
      event.target.src =
        "https://seller.jaja.id/asset/images/img-thumbnail.png";
    },
  },
};
</script>

<style scope>
.header-filter {
  border-radius: 15px 15px 0 0;
}

.kotak-filter {
  color: #65b0c9;
  font-size: 25px;
}

.kotak-tombol-reset a {
  background: rgb(224, 116, 116);
  color: white;
}

.ul-list-filter {
  list-style-type: none;
}

.kotak-kondisi {
  position: relative;
  top: -12px;
}

.kotak-stock {
  position: relative;
  top: -8.5px;
}

.short-product {
  padding: 15px 0 15px 0;
  position: relative;
  top: -28px;
  border-radius: 0 0 15px 15px;
}
.short-product div {
  margin-left: 17.5%;
}

.text-label {
  color: rgb(117, 116, 116);
}

.label-order {
  font-size: 16px;
}

.label-order-ready {
  font-size: 14px;
}

.bg-filter {
  background: #ffd68d;
}
.card-product {
  border-radius: 20px;
}

.ImgSizeProduct {
  height: 150px;
}
.ImgSizeProduct img {
  border-radius: 20px 20px 0 0;
  /* border:1px solid rgb(224, 224, 224); */
}
.text-product {
  color: #65b0c9;
  font-weight: bold;
  text-align: left;
}
.text-price {
  color: #fdb739;
  font-weight: bold;
  text-align: left;
  font-size: 20px;
}
.kotak-location {
  position: relative;
  display: flex;
  top: -10px;
}
.kotak-location small {
  position: absolute;
  left: 15px;
}
.kotak-location img {
  width: 14px;
  height: 14px;
  position: absolute;
}

/* PAGINATION CUSTOM BY MALA */

.page-link {
  width: 40px;
  height: 40px;
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-right: 5px;
  margin-left: 5px;
  color: #65b0c9;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 50%;
  font-weight: bold;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
  border-radius: 50%;
  font-weight: bold;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #65b0c9;
  border-color: #65b0c9;
  font-weight: bold;
}
.container-m {
  padding: 10px;
}
</style>