<template>
  <div>
    <div v-if="!isLoggedIn" class="nav-bar">
      <router-link
        :class="{ 'nav-item': true, active: $route.path === '/' }"
        :to="'/'"
      >
        <i class="fas fa-rocket"></i>
        <span>Explore</span>
      </router-link>
      <router-link
        :class="{ 'nav-item': true, active: $route.path === '/user/register' }"
        :to="'/user/register'"
      >
        <i class="fas fa-receipt"></i>
        <span>Daftar</span>
      </router-link>
      <router-link
        :class="{ 'nav-item': true, active: $route.path === '/user/login' }"
        :to="'/user/login'"
      >
        <i class="fas fa-user"></i>
        <span>Login</span>
      </router-link>
    </div>
    <div v-else class="nav-bar">
      <router-link
        :class="{ 'nav-item': true, active: $route.path === '/' }"
        :to="'/'"
      >
        <i class="fas fa-rocket"></i>
        <span>Explore</span>
      </router-link>

      <router-link
        :class="{ 'nav-item': true, active: $route.path === '/chat' }"
        :to="'/chat'"
      >
        <i class="fas fa-comments"></i> <span>Chat</span></router-link
      >

      <router-link
        :class="{ 'nav-item': true, active: $route.path === '/listorder' }"
        :to="'/listorder'"
      >
        <i class="fas fa-receipt"></i>
        <span>Pesanan</span>
      </router-link>

      <router-link
        :class="{ 'nav-item': true, active: $route.path === '/dashboard' }"
        :to="'/dashboard'"
      >
        <i class="fas fa-user"></i>
        <span>Akun</span>
      </router-link>
    </div>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import vClickOutside from "v-click-outside";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      showSearchDropdown: false,
      showMenu: false,
      chatNotifications: 0,
      notificationNotifications: 0,
      cartNotifications: 0,
      isScrolled: false,
      // isLoggedIn: false,
      // showOrHiddenLogout: false,
      srcData: "",
      typingTimer: "",
      doneTypingInterval: 700,
      dataCombine: [],
      hiddenCardPencarian: false,
      dataToko: {},
      dataCategory: {},
      dataProduct: {},
      dataDitemukan: {},
      masterKategori: {},
      masterInfo: {},
      cekMouseKategori: false,
      cekMouseJaja: false,
      cekMouseEBH: false,
      cekMouseMasdis: false,
      showOrHiddenLogout: false,
      childCategori: {
        length: 0,
        data: "",
      },
      indexParentActive: 0,

      vcoConfig: {
        handler: this.handler,
        middleware: this.middleware,
        events: ["dblclick", "click"],
        // Note: The default value is true, but in case you want to activate / deactivate
        //       this directive dynamically use this attribute.
        isActive: true,
        // Note: The default value is true. See "Detecting Iframe Clicks" section
        //       to understand why this behaviour is behind a flag.
        detectIFrame: true,
      },
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({ isLoggedIn: "isLoggedIn" }),
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    // ...mapGetters({ totalKeranjang: "infoGetters" }),
    ...mapGetters({ user: "user" }),
    ...mapGetters({
      dataUrl: "urlGetters",
    }),
  },
  created() {
    this.srcData = this.srcData === "" ? this.$route.params.id : "";
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeMount() {
    this.getMasteKategori();
    // this.escapeFunction()
  },
  mounted() {
    this.getNotif();
    this.getInfo();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    toggleSearchDropdown() {
      this.showSearchDropdown = !this.showSearchDropdown;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    handleScroll() {
      // Mengubah nilai isScrolled berdasarkan perilaku scroll
      if (window.scrollY > 0) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },

    onClickOutside(event) {},
    handler(event) {
      this.isOpen = false;
    },
    middleware(event) {
      return event.target.className.split(" ")[0] !== "triggerMiddleware";
    },
    escapeFunction() {
      // this.hiddenCardPencarian=true
      // alert('dsadsa')
      // let that = this;
      // document.addEventListener('keyup', function (evt) {
      //     if (evt.keyCode === 27) {
      //       console.log(this.hiddenCardPencarian)
      //       this.hiddenCardPencarian=true
      //     }
      // });
    },
    onEnter(e) {
      this.$router.push("/product-search/" + e);
    },
    pencarianEnter() {
      this.$router.push("/product-search/" + this.srcData);
      // document.getElementsByClassName('ul-pencarian')[0].style.display = 'none'
    },
    pencarianData() {
      this.isOpen = true;
      this.srcData = event.target.value;
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => this.doneTyping(this.srcData), 500);
    },
    doneTyping(string) {
      let myHeaders = new Headers();
      myHeaders.append("Cookie", "ci_session=vah7ivbaoqeus4qfh89d7c8o2q55216c");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}product/search?limit=5&keyword=${string}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          this.dataCombine = [];
          this.hiddenCardPencarian = false;
          const res = JSON.parse(result);
          this.dataToko = res.data.store;
          this.dataCategory = res.data.category;
          this.dataProduct = res.data.product;
          this.dataDitemukan = true;
          this.dataCombine.push(res.data.product);
          this.dataCombine.push(res.data.store);
          this.dataCombine.push(res.data.category);

          console.log("dataCategory", res.data.category);
          console.log("dataProduct", res.data.product);
        });
    },
    getInfo() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/info`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.cartNotifications = res.data.totalProductInCart || 0;
            this.notificationNotifications = res.data.totalNotifUnread || 0;
            // Set data lain jika perlu
          }
        })
        .catch((error) => console.log("error", error));
    },
    getNotif() {
      const database = firebase.database();
      const uid = this.user.uid;

      database.ref(`/friend/${uid}`).on("value", (snapshot) => {
        let countChat = 0;
        snapshot.forEach((snap) => {
          const item = snap.val();
          if (item && item.id !== uid && item.id !== "null" && item.amount) {
            countChat += item.amount;
          }
        });
        this.chatNotifications = countChat;
      });
    },
    getMasteKategori() {
      let myHeaders = new Headers();
      myHeaders.append("Cookie", "ci_session=mgqcv1a854uuigbeae07b3hue1hcdgo8");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}master/category`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.masterKategori = res.data;
          }
        })
        .catch((error) => console.log("error", error));
    },
    mouseMasuk() {
      this.cekMouseKategori = true;
    },
    mouseKeluar() {
      this.cekMouseKategori = false;
    },
    mouseMasukJaja() {
      this.cekMouseJaja = true;
    },
    mouseKeluarJaja() {
      this.cekMouseJaja = false;
    },
    mouseMasukEBH() {
      this.cekMouseEBH = true;
    },
    mouseKeluarEBH() {
      this.cekMouseEBH = false;
    },
    mouseMasukMasdis() {
      this.cekMouseMasdis = true;
    },
    mouseKeluarMasdis() {
      this.cekMouseMasdis = false;
    },
    mouselistover(index) {
      this.indexParentActive = index;
      this.childCategori.length = this.masterKategori[index].children.length;
      this.childCategori.data = this.masterKategori[index].children;
    },
    gotoDetailProdukNavbar(slug) {
      this.$router.push("/kategori/" + slug);
      this.cekMouseKategori = false;
    },
    mouselistchildover(index) {
      var element = document.getElementById("childActive" + index);
      element.classList.add("active");
      this.cekMouseKategori = true;
    },
    mouselistchildleave(index) {
      var element = document.getElementById("childActive" + index);
      element.classList.remove("active");
    },
    setPage(index, slug, name) {
      if (index == 0) {
        this.isOpen = false;
        this.srcData = name;
        this.$router.push(`/home/DetailProduk/${slug}`);
      } else if (index == 1) {
        this.isOpen = false;
        this.srcData = name;
        this.$router.push(`/home/DetailToko/${slug}`);
      } else {
        this.isOpen = false;
        this.srcData = name;
        this.$router.push(`/kategori/${slug}`);
      }
      this.dataCombine = [];
    },
    logout() {
      this.$swal
        .fire({
          icon: "info",
          title: "Apakah anda akan keluar?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Keluar",
          denyButtonText: `Batalkan`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.runLogout();
          }
          // else if (result.isDenied) {
          //   this.$swal.fire("Cancel", "Anda tidak Logout", "warning");
          // }
        });
      // localStorage.clear();
      // window.location.href = "${this.domainURL}"
    },
    runLogout() {
      // this.$swal.fire('sukses', 'anda berhasil logout', 'success')
      localStorage.clear();
      setTimeout(() => this.runRedirect(), 500);
    },
    runRedirect() {
      let url = new URL(this.dataUrl.url);
      window.location.href = url.origin;
    },
    showDropDownLogout() {
      this.showOrHiddenLogout
        ? (this.showOrHiddenLogout = false)
        : (this.showOrHiddenLogout = true);
    },
  },
};
</script>

  <style scoped>
.nav-bar {
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #b0bec5;
  font-size: 14px;
}

.nav-item.active {
  color: #ffcc00;
}

.nav-item i {
  font-size: 20px;
  margin-bottom: 5px;
}
</style>
  