<template>
  <div style="margin-top: -2%" class="container p-3">
    <div class="hero-image">
      <img
        class=""
        src="https://nimda.jaja.id/asset/front/images/file/2216a3d750383cd7136825750bd573fa.jpg"
        alt=""
      />
    </div>
    <div class="row p-3">
      <div class="col-md-2 mt-4 mb-4">
        <div class="card bg-primary">
          <div class="d-flex justify-content-center bd-highlight">
            <div class="p-2 bd-highlight">
              <img
                class="img-fluid"
                style="filter: invert(0.9)"
                src="https://nimda.jaja.id/asset/gift/makeover.png"
                alt="Card image cap"
              />
            </div>
          </div>
          <div class="d-flex justify-content-center bd-highlight">
            <small class="card-title text-white">PAKET 100 RIBU</small>
          </div>
        </div>
      </div>

      <div class="col-md-2 mt-4 mb-4">
        <div class="card bg-primary">
          <div class="d-flex justify-content-center bd-highlight">
            <div class="p-2 bd-highlight">
              <img
                class="img-fluid"
                style="filter: invert(0.9)"
                src="https://nimda.jaja.id/asset/gift/shoes.png"
                alt="Card image cap"
              />
            </div>
          </div>
          <div class="d-flex justify-content-center bd-highlight">
            <small class="card-title text-white">PAKET 100 RIBU</small>
          </div>
        </div>
      </div>

      <div class="col-md-2 mt-4 mb-4">
        <div class="card bg-primary">
          <div class="d-flex justify-content-center bd-highlight">
            <div class="p-2 bd-highlight">
              <img
                class="img-fluid"
                style="filter: invert(0.9)"
                src="https://nimda.jaja.id/asset/gift/summer.png"
                alt="Card image cap"
              />
            </div>
          </div>
          <div class="d-flex justify-content-center bd-highlight">
            <small class="card-title text-white">PAKET 100 RIBU</small>
          </div>
        </div>
      </div>

      <div class="col-md-2 mt-4 mb-4">
        <div class="card bg-primary">
          <div class="d-flex justify-content-center bd-highlight">
            <div class="p-2 bd-highlight">
              <img
                class="img-fluid"
                style="filter: invert(0.9)"
                src="https://nimda.jaja.id/asset/gift/trousers.png"
                alt="Card image cap"
              />
            </div>
          </div>
          <div class="d-flex justify-content-center bd-highlight">
            <small class="card-title text-white">PAKET 100 RIBU</small>
          </div>
        </div>
      </div>

      <div class="col-md-2 mt-4 mb-4">
        <div class="card bg-primary">
          <div class="d-flex justify-content-center bd-highlight">
            <div class="p-2 bd-highlight">
              <img
                class="img-fluid"
                style="filter: invert(0.9)"
                src="https://nimda.jaja.id/asset/gift/tshirt.png"
                alt="Card image cap"
              />
            </div>
          </div>
          <div class="d-flex justify-content-center bd-highlight">
            <small class="card-title text-white">PAKET 100 RIBU</small>
          </div>
        </div>
      </div>

      <div class="col-md-2 mt-4 mb-4">
        <div class="card bg-primary">
          <div class="d-flex justify-content-center bd-highlight">
            <div class="p-2 bd-highlight">
              <img
                class="img-fluid"
                style="filter: invert(0.9)"
                src="https://nimda.jaja.id/asset/gift/watch.png"
                alt="Card image cap"
              />
            </div>
          </div>
          <div class="d-flex justify-content-center bd-highlight">
            <small class="card-title text-white">PAKET 100 RIBU</small>
          </div>
        </div>
      </div>
    </div>
    <cardProduct :data="resDataGift.data.items" />
  </div>
</template>

<script>
import cardProduct from "../../components/card/cardproduct";

export default {
  data() {
    return {
      dataKategory: {},
      paramReq: {
        category: "",
        page: 1,
        limit: "10",
        keyword: "",
        filter_price: "",
        filter_location: "",
        filter_condition: "",
        filter_preorder: "",
        filter_brand: "",
        sort: "",
        is_gift: 1,
        gift: "",
      },
      resDataGift: {},
    };
  },
  components: {
    cardProduct,
  },
  computed: {
    // getKategori()
  },
  created() {
    this.getKategori();
  },
  methods: {
    getKategori() {
      let queryParams = Object.keys(this.paramReq).map((item) => {
        return `${item}=${this.paramReq[item] || ""}`;
      });

      const url = `${this.domainURL}product/store/jaja-gift?${queryParams.join(
        "&"
      )}`;

      let myHeaders = new Headers();
      myHeaders.append("Cookie", "ci_session=a1pjdvtf2qb2vhuk123pl8jonhogqjrp");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          this.resDataGift = res;
        });
    },
  },
};
</script>
<style scoped>
.body-gift {
  margin-top: 1%;
}
.hero-image img {
  /* width:80%; */
  height: 50vh;
}
</style>