<template>
  <div>
    <div class="d-none d-lg-block">
      <div>
        <div class="row" v-if="Object.keys(data).length > 0">
          <div
            v-for="(item, index) in data"
            :key="index"
            class="col-md-3 col-6 mt-4 carousel-cell is-selected"
          >
            <router-link :to="'/home/DetailProduk/' + item.slug">
              <div
                class="shadow-custom"
                style="
                  display: flex;
                  flex-direction: column;
                  border-radius: 12px;
                  overflow: hidden;
                  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                "
              >
                <img
                  :src="
                    String(item.image).includes('/https:')
                      ? item.image.slice(45, item.image.length)
                      : item.image
                  "
                  style="
                    width: 100%;
                    height: 232px;
                    object-fit: cover;
                    border-radius: 12px 12px 0 0;
                  "
                />
                <div :class="item.isDiscount ? `btn-preordor` : `d-none`">
                  <span
                    class="bgdiskons font-weight-bold"
                    style="position: absolute; right: 0; top: -232px"
                  >
                    {{ item.discount }} %
                  </span>
                </div>

                <div
                  :class="item.isPreorder == 'Y' ? `btn-preordor` : `d-none`"
                >
                  <span
                    style="position: absolute; right: 0"
                    class="bgpreorder font-weight-bold"
                  >
                    Pre Order
                  </span>
                </div>

                <div
                  :class="item.freeOngkir == 'Y' ? `btn-preordor` : `d-none`"
                >
                  <span
                    style="position: absolute; left: 0; top: -232px"
                    class="bgfreeongkir font-weight-bold"
                  >
                    Free Ongkir
                  </span>
                </div>
                <div
                  style="
                    padding: 10px;
                    background-color: #fff;
                    border-radius: 0 0 12px 12px;
                  "
                >
                  <p
                    class="mt-4 text-left"
                    style="
                      color: #1a1a1a;
                      font-family: Poppins;
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 20px;
                      letter-spacing: -0.14px;
                      margin-bottom: 10px;
                      white-space: nowrap; /* Menambahkan ini untuk memastikan satu baris */
                    "
                  >
                    {{
                      item.name.length > 25
                        ? item.name.slice(0, 25).concat("...")
                        : item.name
                    }}
                  </p>

                  <p
                    class="text-left"
                    style="
                      color: #000;
                      font-family: Poppins;
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 22px;
                      letter-spacing: -0.16px;
                      margin-bottom: 10px;
                    "
                  >
                    {{ item.isDiscount ? item.priceDiscount : item.price }}
                    <strike :class="item.isDiscount ? `` : `d-none`">
                      <small>{{ item.price }}</small>
                    </strike>
                  </p>
                  <p
                    class="text-left"
                    style="
                      color: #ca8c1e;
                      font-family: Poppins;
                      font-size: 10px;
                      font-weight: 500;
                      line-height: 16px;
                      letter-spacing: -0.1px;
                    "
                  >
                    <i
                      class="fas fa-map-marker-alt"
                      style="margin-right: 4px; color: red"
                    ></i>
                    {{ item.location }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="mt-4 row" v-else-if="isFinish">
          <div class="col-md-12 col-12 carousel-cell is-selected">
            <div
              class="CheckShowCheck card-product p-3 mb-3 shadow-custom"
              style="background: white !important"
            >
              <div class="">
                <div></div>
                <img
                  slot="image"
                  src="https://nimda.jaja.id/asset/icon-jaja/notfound.png"
                  class="img-fluid"
                  style="
                    max-height: 400px;
                    max-width: 100%;
                    width: auto;
                    border-radius: 10px;
                  "
                  alt="not found"
                />

                <div class="text-dark">
                  <div class="keterangan ResetKet_JajaID">
                    <h4
                      class="text-product one-line fsxx text-center"
                      style="margin-top: -20px"
                    >
                      Produk belum tersedia.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div v-for="index in 12" :key="index" class="" style="padding: 10px">
            <content-placeholders :rounded="true">
              <content-placeholders-img style="width: 200px; height: 200px" />
              <content-placeholders-heading />
            </content-placeholders>
          </div>
        </div>
      </div>
    </div>
    <div class="d-lg-none">
      <div>
        <div class="row" v-if="Object.keys(data).length > 0">
          <div
            v-for="(item, index) in data"
            :key="index"
            class="col-md-3 col-6 mt-4 carousel-cell is-selected"
          >
            <router-link :to="'/home/DetailProduk/' + item.slug">
              <div
                class="shadow-custom"
                style="
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  border-radius: 12px;
                  overflow: hidden;
                  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                "
              >
                <img
                  :src="
                    String(item.image).includes('/https:')
                      ? item.image.slice(45, item.image.length)
                      : item.image
                  "
                  style="
                    width: 100%;
                    height: 232px;
                    object-fit: cover;
                    border-radius: 12px 12px 0 0;
                  "
                />
                <div :class="item.isDiscount ? `btn-wishlist` : `d-none`">
                  <span
                    class="bgdiskons font-weight-bold"
                    style="position: absolute; right: 0; top: -232px"
                  >
                    {{ item.discount }} %
                  </span>
                </div>

                <div
                  :class="item.isPreorder == 'Y' ? `btn-wishlist2` : `d-none`"
                >
                  <span
                    style="position: absolute; right: 0"
                    class="bgpreorder font-weight-bold"
                  >
                    Pre Order
                  </span>
                </div>

                <div
                  :class="item.freeOngkir == 'Y' ? `btn-wishlist3` : `d-none`"
                >
                  <span
                    style="position: absolute; left: 0; top: -232px"
                    class="bgfreeongkir font-weight-bold"
                  >
                    Free Ongkir
                  </span>
                </div>
                <div
                  style="
                    padding: 10px;
                    background-color: #fff;
                    border-radius: 0 0 12px 12px;
                  "
                >
                  <p
                    class="text-left"
                    style="
                      color: #1a1a1a;
                      font-family: Poppins;
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 20px;
                      letter-spacing: -0.14px;
                      margin-bottom: 10px;
                      white-space: nowrap; /* Menambahkan ini untuk memastikan satu baris */
                    "
                  >
                    {{
                      item.name.length > 25
                        ? item.name.slice(0, 25).concat("...")
                        : item.name
                    }}
                  </p>

                  <p
                    class="text-left"
                    style="
                      color: #000;
                      font-family: Poppins;
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 22px;
                      letter-spacing: -0.16px;
                      margin-bottom: 10px;
                    "
                  >
                    {{ item.isDiscount ? item.priceDiscount : item.price }}
                    <strike :class="item.isDiscount ? `` : `d-none`">
                      <small>{{ item.price }}</small>
                    </strike>
                  </p>
                  <p
                    class="text-left"
                    style="
                      color: #ca8c1e;
                      font-family: Poppins;
                      font-size: 10px;
                      font-weight: 500;
                      line-height: 16px;
                      letter-spacing: -0.1px;
                    "
                  >
                    <i
                      class="fas fa-map-marker-alt"
                      style="margin-right: 4px; color: red"
                    ></i>
                    {{ item.location }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="mt-4 row" v-else-if="isFinish">
          <div class="col-md-12 col-12 carousel-cell is-selected">
            <div
              class="CheckShowCheck card-product p-3 mb-3 shadow-custom"
              style="background: white !important"
            >
              <div class="">
                <div></div>
                <img
                  slot="image"
                  src="https://nimda.jaja.id/asset/icon-jaja/notfound.png"
                  class="img-fluid"
                  style="
                    max-height: 400px;
                    max-width: 100%;
                    width: auto;
                    border-radius: 10px;
                  "
                  alt="not found"
                />

                <div class="text-dark">
                  <div class="keterangan ResetKet_JajaID">
                    <h4
                      class="text-product one-line fsxx text-center"
                      style="margin-top: -20px"
                    >
                      Produk belum tersedia.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div v-for="index in 12" :key="index" class="" style="padding: 10px">
            <content-placeholders :rounded="true">
              <content-placeholders-img style="width: 200px; height: 200px" />
              <content-placeholders-heading />
            </content-placeholders>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      // type: Array,
      required: true,
    },
    isFinish: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      srcLoad: "http://via.placeholder.com/350x150",
      isLoaded: false,
    };
  },
  watch: {
    isLoaded: function (newValue, oldValue) {
      // console.log("New value is: " + newValue);
      // console.log("Old value is: " + oldValue);
    },
  },
  methods: {
    onImgLoad() {
      this.isLoaded = true;
    },
  },
};
</script>

<style scope>
.body-home {
  /* jaja */
  /* background: #eff6f8 */
  /* ebh */
  background: #f2f2f2;
}
.header-filter {
  border-radius: 15px 15px 0 0;
}
.kotak-filter {
  color: #65b0c9;
  font-size: 25px;
}
.kotak-tombol-reset a {
  background: rgb(224, 116, 116);
  color: white;
}
.ul-list-filter {
  list-style-type: none;
}
.kotak-kondisi {
  position: relative;
  top: -12px;
}
.kotak-lokasi {
  position: relative;
  top: -12px;
}
.kotak-kategori {
  position: relative;
  top: -12px;
}
.kotak-etalase {
  position: relative;
  top: -12px;
}
.kotak-stock {
  position: relative;
  top: -8.5px;
}
.short-product {
  padding: 15px 0 15px 0;
  position: relative;
  top: -28px;
  border-radius: 0 0 15px 15px;
}
.short-product div {
  margin-left: 17.5%;
}
.text-label {
  color: rgb(117, 116, 116);
}
.label-order {
  font-size: 16px;
}
.label-order-ready {
  font-size: 14px;
}
.bg-filter {
  background: #ffd68d;
}
.card-product {
  border-radius: 10px;
  background: white !important;
}
.ImgSizeProduct {
  height: 150px;
}
.ImgSizeProduct img {
  border-radius: 20px 20px 0 0;
  width: 165px;
  height: 150px;
  object-fit: cover;
}
.text-product {
  /* color: #65b0c9; */
  color: #244786 !important;
  font-weight: bold;
  text-align: left;
  max-height: 25px;
  height: 100%;
}
.text-price {
  color: #fdb739;
  font-weight: bold;
  text-align: left;
  font-size: 20px;
}
.kotak-location {
  position: relative;
  display: flex;
  top: -10px;
}
.kotak-location small {
  position: absolute;
  left: 15px;
}
.kotak-location img {
  width: 14px;
  height: 14px;
  position: absolute;
}

a:hover {
  text-decoration: none;
}

.card-product .btn-wishlist {
  position: absolute;
  right: 15px;
  top: 0;
}
.bgdiskons {
  color: #fff;
  background-color: #65b0c9;
  padding: 3px 3px 8px 3px;
  font-size: 15px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  /* font-weight: bold; */
}

.card-product .btn-wishlist2 {
  position: absolute;
  right: 15px;
  top: 130px;
}
.bgpreorder {
  color: #fff;
  background-color: #da8922bd;
  padding: 3px 3px 8px 3px;
  font-size: 12px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  /* font-weight: bold; */
}

.card-product .btn-wishlist3 {
  position: absolute;
  left: 15px;
  top: 130px;
}
.bgfreeongkir {
  color: #fff;
  background-color: #fb7181cc;
  padding: 3px 3px 8px 3px;
  font-size: 12px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  /* font-weight: bold; */
}
.btn-preordor {
  position: relative;
}
</style>