import { setHeaderToken } from "../../utils/auth";
import { removeHeaderToken } from "../../utils/auth";

import axios from 'axios'

export default {
    state: {
        user: null,
        isLoggedIn: false,
        // urlData: {}
    },
    mutations: {
        set_user(state, data) {
            state.user = data
            state.isLoggedIn = true
        },
        reset_user(state) {
            state.user = null
            state.isLoggedIn = false
        },
    },
    getters: {
        isLoggedIn(state) {
            return state.isLoggedIn
        },
        user(state) {
            return state.user
        },
    },
    actions: {
        login({ dispatch, commit }, data) {
            return new Promise((resolve, reject) => {
                axios.post('user/login', data)
                    .then(response => {
                        console.log("🚀 ~ file: auth.js ~ line 34 ~ returnnewPromise ~ response", response.data)
                        if (response.data.status.code === 200) {
                            const token = response.data.data
                            setHeaderToken(token)
                            localStorage.setItem('token', String(token))
                            dispatch('get_user')
                            resolve(response)
                        } else {
                            alert(response.data.status.message)
                        }
                    })
                    .catch(err => {
                        commit('reset_user')
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        async get_user({ commit }) {
            if (!localStorage.getItem('token')) {
                return false;
            }
            try {
                let response = await axios.get('user/profile')
                commit('set_user', response.data.data)
                return response.data.data
            } catch (error) {
                commit('reset_user')
                removeHeaderToken()
                // localStorage.removeItem('token')
                return error
            }
        },
        // async getUrlDinamic({ commit }) {
        //     let url_string = window.location.href;
        //     let url = new URL(url_string);
        //     commit('setUrl', url)
        // }
    }
}

