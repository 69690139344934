<template>
  <div>
    <div class="container style-text" v-if="isFinish">
      <h4 style="text-align: left; margin-top: 20px; padding-left: 15px">
        <b>Kirim ke beberapa alamat</b>
      </h4>

      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div
              class="col-md-12"
              v-for="(item, index) in dataCartMulti.items"
              :key="index"
            >
              <div>
                <div class="container mt-4 style-text">
                  <div
                    class="card shadow-custom"
                    style="border-radius: 10px !important"
                  >
                    <div
                      class="card-header"
                      style="border-radius: 10px 10px 0 0 !important"
                    >
                      <span
                        class="mb-0 float-left fsx"
                        style="color: white; font-weight: bold"
                      >
                        <router-link
                          :to="'/home/DetailToko/' + item.store.slug"
                          class="text-white"
                        >
                          {{ item.store.name }}
                        </router-link>
                      </span>
                    </div>
                    <div class="card-body">
                      <div class="my-2">
                        <div
                          class="list-group-item p-0"
                          style="border: 0px !important"
                        >
                          <div class="table-responsive">
                            <table
                              class="table Table_JajaID"
                              style="
                                margin-top: -2%;
                                margin-bottom: 0 !important;
                              "
                            >
                              <tbody
                                class="HeaderCartBody_JajaID"
                                v-for="(i, j) in item.products"
                                :key="j"
                              >
                                <tr class="desktop-only">
                                  <td
                                    class="text-dark"
                                    style="width: 15%; font-weight: bold"
                                  >
                                    #
                                  </td>
                                  <td
                                    class="text-left text-dark"
                                    style="width: 70%; font-weight: bold"
                                  >
                                    Judul
                                  </td>
                                  <td
                                    class="text-center text-dark"
                                    style="width: 15%; font-weight: bold"
                                  >
                                    Total
                                  </td>
                                </tr>
                                <tr class="desktop-only">
                                  <td style="width: 15%">
                                    <img
                                      :src="i.image"
                                      class="img-responsive"
                                      style="
                                        display: inline-block;
                                        float: left;
                                        object-fit: contain;
                                      "
                                      width="60px"
                                      height="60px"
                                      alt=""
                                    />
                                  </td>
                                  <td
                                    style="
                                      width: 70%;
                                      color: #64b0c9;
                                      font-weight: bold;
                                    "
                                  >
                                    <router-link
                                      :to="'/home/DetailProduk/' + i.slug"
                                      class="text-primary-jaja"
                                    >
                                      {{ i.name }}
                                    </router-link>
                                  </td>
                                  <td class="text-center" style="width: 70%">
                                    {{ i.qty }}
                                  </td>
                                </tr>
                                <tr
                                  v-for="(k, l) in i.address"
                                  :key="l"
                                  class="desktop-only"
                                >
                                  <td style="width: 15%; padding-left: 4.5%">
                                    <img
                                      src="https://nimda.jaja.id/asset/icon-jaja/location.png"
                                      style="width: 20px"
                                      alt=""
                                    />
                                  </td>
                                  <td style="width: 60%">{{ k.address }}</td>
                                  <td class="text-center">
                                    <div
                                      @click="deleteAddress(k.cartId)"
                                      class="
                                        p-3
                                        tombol-hapus-address
                                        bd-highlight
                                      "
                                    >
                                      <button class="btn btn-danger btn-sm">
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <tr class="desktop-only">
                                  <td colspan="3">
                                    <div
                                      class="
                                        d-flex
                                        justify-content-end
                                        tombol-multidrop
                                        bd-highlight
                                        mb-1
                                      "
                                      @click="showModalAlamat(index + ':' + j)"
                                    >
                                      <div class="bd-highlight">
                                        <a
                                          class="
                                            btn btn-sm
                                            font-weight-bold
                                            text-dark
                                            btn-add-send
                                          "
                                          style="
                                            color: white !important;
                                            background: #fdb738 !important;
                                            border-color: #fdb738 !important;
                                          "
                                          >Tambah Pengiriman Baru</a
                                        >
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-12">
              <div class="container mt-4 style-text">
                <div
                  class="card shadow-custom"
                  style="border-radius: 10px !important"
                >
                  <div
                    class="card-header"
                    style="border-radius: 10px 10px 0 0 !important"
                  >
                    <span
                      class="mb-0 float-left fsx"
                      style="color: white; font-weight: bold"
                      >Ringkasan Belanja</span
                    >
                  </div>
                  <div class="card-body">
                    <div class="rincianbayar">
                      <div class="list-group">
                        <div
                          class="list-group-item d-flex justify-content-between"
                          style="border: 0px !important"
                        >
                          <div class="fsx font-weight-bold">Total Item</div>
                          <div>
                            <span
                              class="fsx"
                              style="color: #64b0c9; font-weight: bold"
                              >({{ dataCartMulti.totalData }} Barang)</span
                            >
                          </div>
                        </div>

                        <div
                          class="
                            list-group-item
                            d-flex
                            justify-content-between
                            fsx
                          "
                          style="border: 0px !important"
                        >
                          <div class="fsx font-weight-bold">Total Harga</div>
                          <div>
                            <span
                              id="TotalPembayaranUnfix"
                              class="fsx"
                              style="color: #64b0c9; font-weight: bold"
                              >{{ dataCartMulti.totalCartCurrencyFormat }}</span
                            >
                          </div>
                        </div>

                        <div>
                          <button
                            @click="gotoCheckOut_multiDrop()"
                            class="btn btn-block btn-primary"
                            style="
                              background: #fdb738 !important;
                              border-color: #fdb738 !important;
                              font-weight: bold;
                            "
                          >
                            Beli
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="modalAddress"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title font-weight-bold" id="exampleModalLabel">
                Tambah Alamat Kirim
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body list-alamat">
              <ul class="list-group">
                <div
                  v-for="(item, index) in dataListAddress"
                  :key="index"
                  class="mb-2"
                >
                  <div
                    class="p-2"
                    style="
                      border-radius: #64b0c9 !important;
                      border: 2px solid #64b0c9;
                      border-radius: 10px;
                    "
                  >
                    <label
                      class="d-block text-dark font-weight-bold"
                      style="color: #64b0c9 !important"
                      >{{ item.nama_penerima }}</label
                    >
                    <label
                      class="text-dark font-weight-bold"
                      style="color: #64b0c9 !important"
                      >{{ item.no_telepon }}</label
                    >
                    <li
                      for="0"
                      class="list-group-item"
                      style="border: 0 !important; padding-left: 0px !important"
                    >
                      <input
                        id="0"
                        @change="setMultiDrop(index)"
                        class=""
                        type="radio"
                        value="true"
                        name="alamat"
                      />
                      <span> {{ item.alamat_lengkap }}</span>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary font-weight-bold"
                data-dismiss="modal"
                style="background: #fdb738; border-color: #fdb738"
              >
                Close
              </button>
              <button
                type="button"
                @click="InsertAlamatMultiDrop"
                class="btn btn-primary font-weight-bold"
                style="background: #64b0c9; border-color: #64b0c9"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container style-text" v-if="!isFinish">
      <h4 style="text-align: left; margin-top: 20px; padding-left: 15px">
        <b>
          <content-placeholders :rounded="true">
            <content-placeholders-text
              :lines="1"
              style="width: 300px; height: 20px"
            />
          </content-placeholders>
        </b>
      </h4>

      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-12">
              <div>
                <div class="container mt-4 style-text">
                  <div
                    class="card shadow-custom"
                    style="border-radius: 10px !important"
                  >
                    <div
                      class="card-header"
                      style="border-radius: 10px 10px 0 0 !important"
                    >
                      <span
                        class="mb-0 float-left fsx"
                        style="color: white; font-weight: bold"
                      >
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 200px; height: 20px"
                          />
                        </content-placeholders>
                      </span>
                    </div>
                    <div class="card-body">
                      <div class="my-2">
                        <div
                          class="list-group-item p-0"
                          style="border: 0px !important"
                        >
                          <div class="table-responsive">
                            <table
                              class="table Table_JajaID"
                              style="
                                margin-top: -2%;
                                margin-bottom: 0 !important;
                              "
                            >
                              <tbody class="HeaderCartBody_JajaID">
                                <tr class="desktop-only">
                                  <td
                                    class="text-dark"
                                    style="width: 15%; font-weight: bold"
                                  >
                                    <content-placeholders :rounded="true">
                                      <content-placeholders-text
                                        :lines="1"
                                        style="width: 50px; height: 20px"
                                      />
                                    </content-placeholders>
                                  </td>
                                  <td
                                    class="text-left text-dark"
                                    style="width: 70%; font-weight: bold"
                                  >
                                    <content-placeholders :rounded="true">
                                      <content-placeholders-text
                                        :lines="1"
                                        style="width: 80px; height: 20px"
                                      />
                                    </content-placeholders>
                                  </td>
                                  <td
                                    class="text-center text-dark"
                                    style="width: 15%; font-weight: bold"
                                  >
                                    <content-placeholders :rounded="true">
                                      <content-placeholders-text
                                        :lines="1"
                                        style="width: 80px; height: 20px"
                                      />
                                    </content-placeholders>
                                  </td>
                                </tr>
                                <tr class="desktop-only">
                                  <td style="width: 15%">
                                    <content-placeholders :rounded="true">
                                      <content-placeholders-img
                                        class="img-responsive"
                                        style="
                                          display: inline-block;
                                          float: left;
                                          object-fit: contain;
                                        "
                                        width="60px"
                                        height="60px"
                                      />
                                    </content-placeholders>
                                  </td>
                                  <td
                                    style="
                                      width: 70%;
                                      color: #64b0c9;
                                      font-weight: bold;
                                    "
                                  >
                                    <content-placeholders :rounded="true">
                                      <content-placeholders-text
                                        :lines="1"
                                        style="width: 200px; height: 20px"
                                      />
                                    </content-placeholders>
                                  </td>
                                  <td class="text-center" style="width: 70%">
                                    <content-placeholders :rounded="true">
                                      <content-placeholders-text
                                        :lines="1"
                                        style="width: 100px; height: 20px"
                                      />
                                    </content-placeholders>
                                  </td>
                                </tr>
                                <tr class="desktop-only">
                                  <td style="width: 15%; padding-left: 4.5%">
                                    <content-placeholders :rounded="true">
                                      <content-placeholders-img
                                        style="width: 20; height: 20px"
                                      />
                                    </content-placeholders>
                                  </td>
                                  <td style="width: 60%">
                                    <content-placeholders :rounded="true">
                                      <content-placeholders-heading
                                        style="width: 300px"
                                      />
                                    </content-placeholders>
                                  </td>
                                  <td class="text-center">
                                    <div
                                      class="
                                        p-3
                                        tombol-hapus-address
                                        bd-highlight
                                      "
                                    >
                                      <content-placeholders :rounded="true">
                                        <content-placeholders-img
                                          style="width: 40px; height: 40px"
                                        />
                                      </content-placeholders>
                                    </div>
                                  </td>
                                </tr>
                                <tr class="desktop-only">
                                  <td colspan="3">
                                    <div
                                      class="
                                        d-flex
                                        justify-content-end
                                        tombol-multidrop
                                        bd-highlight
                                        mb-1
                                      "
                                    >
                                      <div class="bd-highlight">
                                        <content-placeholders :rounded="true">
                                          <content-placeholders-img
                                            style="width: 200px; height: 30px"
                                          />
                                        </content-placeholders>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-12">
              <div class="container mt-4 style-text">
                <div
                  class="card shadow-custom"
                  style="border-radius: 10px !important"
                >
                  <div
                    class="card-header"
                    style="border-radius: 10px 10px 0 0 !important"
                  >
                    <span
                      class="mb-0 float-left fsx"
                      style="color: white; font-weight: bold"
                    >
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="1"
                          style="width: 200px; height: 20px"
                        />
                      </content-placeholders>
                    </span>
                  </div>
                  <div class="card-body">
                    <div class="rincianbayar">
                      <div class="list-group">
                        <div
                          class="list-group-item d-flex justify-content-between"
                          style="border: 0px !important"
                        >
                          <div class="fsx font-weight-bold">
                            <content-placeholders :rounded="true">
                              <content-placeholders-text
                                :lines="1"
                                style="width: 150px; height: 20px"
                              />
                            </content-placeholders>
                          </div>
                          <div>
                            <span
                              class="fsx"
                              style="color: #64b0c9; font-weight: bold"
                            >
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 100px; height: 20px"
                                />
                              </content-placeholders>
                            </span>
                          </div>
                        </div>

                        <div
                          class="list-group-item d-flex justify-content-between"
                          style="border: 0px !important"
                        >
                          <div class="fsx font-weight-bold">
                            <content-placeholders :rounded="true">
                              <content-placeholders-text
                                :lines="1"
                                style="width: 140px; height: 20px"
                              />
                            </content-placeholders>
                          </div>
                          <div>
                            <span
                              class="fsx"
                              style="color: #64b0c9; font-weight: bold"
                            >
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 110px; height: 20px"
                                />
                              </content-placeholders>
                            </span>
                          </div>
                        </div>

                        <div>
                          <content-placeholders
                            :rounded="true"
                            :centered="true"
                          >
                            <content-placeholders-img
                              style="width: 80%; height: 20px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="modalAddress"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title font-weight-bold" id="exampleModalLabel">
                Tambah Alamat Kirim
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body list-alamat">
              <ul class="list-group">
                <div
                  v-for="(item, index) in dataListAddress"
                  :key="index"
                  class="mb-2"
                >
                  <div
                    class="p-2"
                    style="
                      border-radius: #64b0c9 !important;
                      border: 2px solid #64b0c9;
                      border-radius: 10px;
                    "
                  >
                    <label
                      class="d-block text-dark font-weight-bold"
                      style="color: #64b0c9 !important"
                      >{{ item.nama_penerima }}</label
                    >
                    <label
                      class="text-dark font-weight-bold"
                      style="color: #64b0c9 !important"
                      >{{ item.no_telepon }}</label
                    >
                    <li
                      for="0"
                      class="list-group-item"
                      style="border: 0 !important; padding-left: 0px !important"
                    >
                      <input
                        id="0"
                        @change="setMultiDrop(index)"
                        class=""
                        type="radio"
                        value="true"
                        name="alamat"
                      />
                      <span> {{ item.alamat_lengkap }}</span>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary font-weight-bold"
                data-dismiss="modal"
                style="background: #fdb738; border-color: #fdb738"
              >
                Close
              </button>
              <button
                type="button"
                @click="InsertAlamatMultiDrop"
                class="btn btn-primary font-weight-bold"
                style="background: #64b0c9; border-color: #64b0c9"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<style scope>
.style-text {
  text-align: left;
}
.btn-add-send {
  width: 100%;
  background: yellow;
}
</style>
<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dataCartMulti: {},
      dataListAddress: {},
      cardId: "",
      idAlamat: "",
      isFinish: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  created() {
    this.getCartMultiple();
  },
  methods: {
    getCartMultiple() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}cart/cart_mulitple`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          this.isFinish = true;
          if (res.status.code === 200) {
            this.dataCartMulti = res.data;
          }
        })
        .catch((error) => console.log("error", error));
    },
    showModalAlamat(params) {
      const indexToko = params.split(":")[0];
      const indexProduct = params.split(":")[1];
      const id_toko = this.dataCartMulti.items[indexToko].store.id;
      const id_product =
        this.dataCartMulti.items[indexToko].products[indexProduct].productId;
      const cartId =
        this.dataCartMulti.items[indexToko].products[indexProduct].cartId;
      this.cardId = cartId;
      this.getDataCartById(cartId);
      // console.log(indexToko, indexProduct, id_toko, id_product, cartId);
    },
    getDataCartById(cartId) {
      // console.log(cartId);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Cookie", "ci_session=algvu7hs9k2g29olljh7sgmfgrump6dk");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}cart/getDataCartById/${cartId}`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            // console.log(this.user);
            this.dataListAddress = this.user.location;
            $("#modalAddress").modal("show");
          }
        })
        .catch((error) => console.log("error", error));
    },
    setMultiDrop(index) {
      this.idAlamat = this.dataListAddress[index].id;
    },
    InsertAlamatMultiDrop() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem("token"));
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id_cart: this.cardId,
        id_alamat_multi_drop: this.idAlamat,
      });

      // console.log(raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}cart/insert_cart_multidrop`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            $("#modalAddress").modal("hide");
            this.$swal
              .fire("Sukses", "Berhasil Menambahkan Data", "success")
              .then(() => {
                this.getCartMultiple();
              });
          }
        })
        .catch((error) => console.log("error", error));

      // console.log(this.idAlamat, this.cardId);
    },
    deleteAddress(id) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem("token"));

      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}cart/${id}`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.$swal.fire("Sukses", "Berhasil Menghapus Data", "success");
            this.getCartMultiple();
          } else {
            this.$swal.fire("Gagal", "Gagal Menghapus Data", "warning");
          }
        })
        .catch((error) => console.log("error", error));
    },
    gotoCheckOut_multiDrop() {
      this.$router.push(`/payment_multiple`);
    },
  },
};
</script>

<style lang="scss" scoped>
.close {
  font-size: 2rem;
  color: #fff;
  text-shadow: 0 1px 0 #fff;
}

.modal-header {
  background: #64b0c9 !important;
  color: white;
  font-weight: bold;
  border-radius: 1rem 1rem 0 0;
}

.modal-content {
  border-radius: 1rem;
}
</style>