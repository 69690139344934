<template>
  <div>
    <div class="d-none d-lg-block">
      <div style="margin-top: -2%" class="container p-3">
        <div class="row">
          <div class="col-md-3">
            <sideBar />
          </div>
          <div class="col-md-9" v-if="isFinish">
            <div class="card-product br-card-jaja shadow-custom">
              <div class="card-header br-card-header-jaja">
                <div class="d-flex justify-content-between bd-highlight">
                  <div class="pt-2 bd-highlight mr-auto">
                    <h5 class="d-flex font-weight-bold text-dark">Dashboard</h5>
                  </div>
                  <div class="bd-highlight">
                    <button class="btn btn-success font-weight-bold">
                      Terverifikasi
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body br-card-footer-jaja">
                <div class="d-flex justify-content-center bd-highlight mb-3">
                  <div class="p-2 bd-highlight">
                    <div
                      @click="setStatusOrder('notPaid')"
                      class="card-status-order btn"
                    >
                      <div class="d-flex flex-column py-3">
                        <div class="">
                          <img
                            alt=""
                            width="45"
                            class="lazy text-center"
                            src="../../assets/dashboard/4.png"
                            style=""
                          />
                        </div>
                        <div class="">
                          <small class="text-primary-jaja font-weight-bold"
                            >Belum Bayar</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-2 bd-highlight">
                    <div
                      @click="setStatusOrder('waitConfirm')"
                      class="card-status-order btn"
                    >
                      <div class="d-flex flex-column py-3">
                        <div class="">
                          <img
                            alt=""
                            width="44"
                            class="lazy text-center"
                            src="../../assets/dashboard/3.png"
                            style=""
                          />
                        </div>
                        <div class="">
                          <small class="text-primary-jaja font-weight-bold"
                            >Diproses</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-2 bd-highlight">
                    <div
                      @click="setStatusOrder('sent')"
                      class="card-status-order btn"
                    >
                      <div class="d-flex flex-column py-3">
                        <div class="">
                          <img
                            alt=""
                            width="56"
                            class="lazy text-center"
                            src="../../assets/dashboard/1.png"
                            style=""
                          />
                        </div>
                        <div class="">
                          <small class="text-primary-jaja font-weight-bold"
                            >Dikirim</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-2 bd-highlight">
                    <div
                      @click="setStatusOrder('done')"
                      class="card-status-order btn"
                    >
                      <div class="d-flex flex-column py-3">
                        <div class="">
                          <img
                            alt=""
                            width="32"
                            class="lazy text-center"
                            src="../../assets/dashboard/2.png"
                            style=""
                          />
                        </div>
                        <div class="">
                          <small class="text-primary-jaja font-weight-bold"
                            >Selesai</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="py-3">
                  <div class="row">
                    <div class="col-md-5 p-3">
                      <div class="border-soft p-2">
                        <div class="d-flex justify-content-start bd-highlight">
                          <div class="p-2 bd-highlight mr-auto">
                            <b>Akun</b>
                          </div>
                          <div class="p-2 bd-highlight">
                            <p class="mb-0">
                              <span class="float-right">
                                <router-link :to="'/profil'">
                                  <a class="btn bg-jaja btn-sm text-white">
                                    Edit akun &nbsp;
                                    <i class="fas fa-edit text-white"></i>
                                  </a>
                                </router-link>
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-start bd-highlight">
                          <div class="pl-2 bd-highlight">
                            {{ userComp.name }}
                          </div>
                        </div>
                        <div class="d-flex justify-content-start bd-highlight">
                          <div class="pl-2 bd-highlight">
                            {{ userComp.email }}
                          </div>
                        </div>
                        <div class="d-flex justify-content-start bd-highlight">
                          <div class="pl-2 bd-highlight">
                            Telp.
                            {{
                              userComp.phoneNumber ? userComp.phoneNumber : `-`
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-7 p-3">
                      <div class="border-soft p-2">
                        <div class="d-flex justify-content-start bd-highlight">
                          <div class="p-2 bd-highlight mr-auto">
                            <b>Alamat Utama</b>
                          </div>
                          <div class="p-2 bd-highlight">
                            <p class="mb-0">
                              <span class="float-right">
                                <router-link :to="'/address'">
                                  <a class="btn bg-jaja btn-sm text-white"
                                    >Ubah alamat &nbsp; <i class="fas fa-edit text-white"></i
                                  ></a>
                                </router-link>
                              </span>
                            </p>
                          </div>
                        </div>

                        <div class="d-flex justify-content-start bd-highlight">
                          <div class="pl-2 bd-highlight">
                            {{
                              userComp.location[0]
                                ? userComp.location[0].nama_penerima
                                : `-`
                            }}
                          </div>
                        </div>
                        <div class="d-flex justify-content-start bd-highlight">
                          <div class="pl-2 bd-highlight text-left">
                            {{
                              userComp.location[0]
                                ? userComp.location[0].alamat_lengkap
                                : ``
                            }}
                            <!-- {{userComp.location[0].alamat_lengkap}} -->
                          </div>
                        </div>
                        <div class="d-flex justify-content-start bd-highlight">
                          <div class="pl-2 bd-highlight">
                            {{
                              userComp.location[0]
                                ? userComp.location[0].no_telepon
                                : ``
                            }}
                            <!-- Telp. {{userComp.location[0].no_telepon}}  -->
                          </div>
                        </div>
                        <!-- <div class="d-flex justify-content-start bd-highlight">
                                            <div class="pl-2 bd-highlight">
                                                <small class="text-muted">Diperbaharui 	<i>
                                                    06 Januari 2022 09:58:21 </i>
                                                </small>
                                            </div>
                                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-9" v-if="!isFinish">
            <div class="card-product br-card-jaja shadow-custom">
              <div class="card-header br-card-header-jaja">
                <div class="d-flex justify-content-between bd-highlight">
                  <div class="pt-2 bd-highlight mr-auto">
                    <h5 class="d-flex font-weight-bold text-white">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="1"
                          style="width: 200px; height: 20px"
                        />
                      </content-placeholders>
                    </h5>
                  </div>
                  <div class="bd-highlight">
                    <content-placeholders :rounded="true">
                      <content-placeholders-img
                        style="width: 200px; height: 30px"
                      />
                    </content-placeholders>
                  </div>
                </div>
              </div>
              <div class="card-body br-card-footer-jaja">
                <div class="d-flex justify-content-center bd-highlight mb-3">
                  <div class="p-2 bd-highlight">
                    <div class="card-status-order btn">
                      <div class="d-flex flex-column py-3">
                        <div class="mb-1">
                          <content-placeholders
                            :rounded="true"
                            :centered="true"
                          >
                            <content-placeholders-img
                              style="width: 30px; height: 30px"
                              class="lazy text-center"
                            />
                          </content-placeholders>
                        </div>
                        <div class="">
                          <small class="text-primary-jaja font-weight-bold">
                            <content-placeholders
                              :rounded="true"
                              :centered="true"
                            >
                              <content-placeholders-text
                                :lines="1"
                                style="width: 100%; height: 20px"
                              />
                            </content-placeholders>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-2 bd-highlight">
                    <div class="card-status-order btn">
                      <div class="d-flex flex-column py-3">
                        <div class="mb-1">
                          <content-placeholders
                            :rounded="true"
                            :centered="true"
                          >
                            <content-placeholders-img
                              style="width: 30px; height: 30px"
                              class="lazy text-center"
                            />
                          </content-placeholders>
                        </div>
                        <div class="">
                          <small class="text-primary-jaja font-weight-bold">
                            <content-placeholders
                              :rounded="true"
                              :centered="true"
                            >
                              <content-placeholders-text
                                :lines="1"
                                style="width: 100%; height: 20px"
                              />
                            </content-placeholders>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-2 bd-highlight">
                    <div class="card-status-order btn">
                      <div class="d-flex flex-column py-3">
                        <div class="mb-1">
                          <content-placeholders
                            :rounded="true"
                            :centered="true"
                          >
                            <content-placeholders-img
                              style="width: 30px; height: 30px"
                              class="lazy text-center"
                            />
                          </content-placeholders>
                        </div>
                        <div class="">
                          <small class="text-primary-jaja font-weight-bold">
                            <content-placeholders
                              :rounded="true"
                              :centered="true"
                            >
                              <content-placeholders-text
                                :lines="1"
                                style="width: 100%; height: 20px"
                              />
                            </content-placeholders>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-2 bd-highlight">
                    <div class="card-status-order btn">
                      <div class="d-flex flex-column py-3">
                        <div class="mb-1">
                          <content-placeholders
                            :rounded="true"
                            :centered="true"
                          >
                            <content-placeholders-img
                              style="width: 30px; height: 30px"
                              class="lazy text-center"
                            />
                          </content-placeholders>
                        </div>
                        <div class="">
                          <small class="text-primary-jaja font-weight-bold">
                            <content-placeholders
                              :rounded="true"
                              :centered="true"
                            >
                              <content-placeholders-text
                                :lines="1"
                                style="width: 100%; height: 20px"
                              />
                            </content-placeholders>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="py-3">
                  <div class="row">
                    <div class="col-md-5 p-3">
                      <div class="border-soft p-2">
                        <div class="d-flex justify-content-start bd-highlight">
                          <div class="p-2 bd-highlight mr-auto">
                            <b>
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 100px; height: 20px"
                                />
                              </content-placeholders>
                            </b>
                          </div>
                          <div class="p-2 bd-highlight">
                            <p class="mb-0">
                              <span class="float-right">
                                <content-placeholders :rounded="true">
                                  <content-placeholders-img
                                    style="width: 30px; height: 30px"
                                  />
                                </content-placeholders>
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-start bd-highlight">
                          <div class="pl-2 bd-highlight">
                            <content-placeholders :rounded="true">
                              <content-placeholders-heading
                                style="width: 200px"
                              />
                            </content-placeholders>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-7 p-3">
                      <div class="border-soft p-2">
                        <div class="d-flex justify-content-start bd-highlight">
                          <div class="p-2 bd-highlight mr-auto">
                            <b>
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 100px; height: 20px"
                                />
                              </content-placeholders>
                            </b>
                          </div>
                          <div class="p-2 bd-highlight">
                            <p class="mb-0">
                              <span class="float-right">
                                <content-placeholders :rounded="true">
                                  <content-placeholders-img
                                    style="width: 30px; height: 30px"
                                  />
                                </content-placeholders>
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-start bd-highlight">
                          <div class="pl-2 bd-highlight">
                            <content-placeholders :rounded="true">
                              <content-placeholders-heading
                                style="width: 300px"
                              />
                            </content-placeholders>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-lg-none">
      <div style="margin-top: -42%" class="p-3">
        <div>
          <div class="col-md-3">
            <sideBar />
          </div>
        </div>
        <div class="mt-5">
          <br />
          <NavBar />
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
<script>
import { mapGetters } from "vuex";
import sideBar from "../../components/sidebar/sideBar";
import mapGoggle from "../../components/mapcomponent/mapcomp.vue";
import NavBar from "../../components/master/navbarbuttom.vue";
export default {
  data() {
    return {
      wishList: {},
      userComp: {},
      isFinish: false,
    };
  },
  state: {
    status: "",
  },
  mutations: {
    set_status(state, data) {
      state.status = data;
    },
  },
  components: {
    sideBar,
    NavBar,
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  mounted() {
    this.getDataWisLish();
    window.scrollTo(0, 0);
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      if (this.user.uid) {
        this.userComp = this.user;
        this.isFinish = true;
      } else {
        this.isFinish = false;
      }
    },
    getDataWisLish() {},
    setStatusOrder(status) {
      this.$router.push("/listorder?status=" + status);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-status-order {
  width: 150px;
  /* height:50px; */
  border: 1px solid #d6d6d6;
  border-radius: 10px;
}

.border-soft {
  border: 1px solid #d6d6d6;
  border-radius: 10px;
}

.bg-jaja {
  background: rgb(100, 175, 200);
}
</style>