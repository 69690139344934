<template>
  <div style="margin-top: -2%" class="container p-3">
    <div class="row">
      <div class="col-md-3">
        <sideBar />
      </div>
      <div class="col-md-9">
        <div class="card-product shadow-custom br-card-jaja">
          <div class="card-header br-card-header-jaja">
            <div class="d-flex justify-content-start bd-highlight text-left">
              <div class="bd-highlight mr-auto">
                <h5 class="text-dark font-weight-bold d-block">
                  Ganti Password
                </h5>
                <small class="text-daek"
                  >Kamu yakin ingin ganti password?</small
                >
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- <div class="d-flex justify-content-between bd-highlight mb-3">
                            <div class="bd-highlight">
                                <h5>Yakin Ubah password</h5>
                            </div>
                            <div class="bd-highlight">
                                <span @click="liatPassword" class="btn btn-warning btn-sm">Lihat Password</span>
                            </div>
                        </div> -->

            <div class="row">
              <div class="col-md-12 text-left">
                <form @submit.prevent="updatePassword">
                  <div class="form-group">
                    <label for="" class="font-weight-bold"
                      >Password saat ini*</label
                    >
                    <input
                      :type="setTypeInput"
                      class="form-control inputan"
                      v-model="rChangePass.old_password"
                      placeholder="Password saat ini"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="" class="font-weight-bold"
                      >Password baru*</label
                    >
                    <input
                      :type="setTypeInput"
                      class="form-control inputan"
                      v-model="rChangePass.new_password"
                      placeholder="Ganti Password"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="" class="font-weight-bold"
                      >Konfirmasi password*</label
                    >
                    <input
                      :type="setTypeInput"
                      class="form-control inputan"
                      v-model="rChangePass.confirm_new_password"
                      placeholder="Konfirmasi ganti password"
                      required
                    />
                  </div>
                  <div class="d-flex justify-content-between bd-highlight mb-2">
                    <div class="bd-highlight">
                      <div class="form-check">
                        <input
                          @click="seePass"
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          <small>Lihat Password</small>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn bg-jaja text-white font-weight-bold"
                    >
                      Simpan
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
<script>
import { mapGetters } from "vuex";
import sideBar from "../../components/sidebar/sideBar";
import mapGoggle from "../../components/mapcomponent/mapcomp.vue";
export default {
  data() {
    return {
      rChangePass: {
        email: "",
        old_password: "",
        new_password: "",
        confirm_new_password: "",
      },
      setTypeInput: "password",
    };
  },
  components: {
    sideBar,
    mapGoggle,
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    updatePassword() {
      this.rChangePass.email = this.user.email;
      setTimeout(() => this.runUpdatePass(), 500);
    },
    runUpdatePass() {
      this.$swal.fire({
        html: '<img src="https://jsonx.jaja.id/asset/home/logo/Loading_2.gif" alt="">', // add html attribute if you want or remove
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          this.$swal.showLoading();
        },
      });
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=aphlebj5dh2h75r2tmn9v5s1j6kghv9j");

      var raw = JSON.stringify(this.rChangePass);

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/change_password/new`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.$swal
              .fire("Sukses", res.status.message, "success")
              .then(() => {
                location.reload();
              });
          } else {
            this.$swal.fire("Warning", res.status.message, "warning");
          }
        })
        .catch((error) => console.log("error", error));
    },
    seePass() {
      event.target.checked
        ? (this.setTypeInput = `text`)
        : (this.setTypeInput = `password`);
    },
  },
};
</script>
<style lang="scss" scoped>
i.fa-angle-left {
  font-size: 20px;
}
.introInput {
  width: 100%;
}

.inputan {
  border-radius: 10px;
  border: 1px solid black;
}
.bg-jaja {
  background: rgb(100, 175, 200);
}
</style>