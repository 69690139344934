<template>
  <div>
    <div class="d-none d-lg-block">
      <div>
        <div style="margin-top: -2%" class="container p-3" v-if="isFinish">
          <div class="row">
            <div class="col-md-3">
              <sideBar />
            </div>
            <div class="col-md-9">
              <div class="card-product shadow-custom br-card-jaja">
                <div class="card-header br-card-header-jaja">
                  <div class="d-flex justify-content-between bd-highlight">
                    <div class="pt-2 bd-highlight mr-auto">
                      <h5 class="d-flex font-weight-bold text-dark">
                        Tambah Alamat
                      </h5>
                    </div>
                    <div class="pt-1 bd-highlight">
                      <h6 class="d-flex font-weight-bold">
                        <router-link to="/address">
                          <button
                            type="button"
                            class="btn btn-warning btn-sm text-white font-weight-bold"
                          >
                            <i class="fas fa-angle-left ver-middle"></i
                            >&nbsp;<span class="ver-middle">Kembali</span>
                          </button>
                        </router-link>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="nama" class="float-left"
                          >Nama Penerima *</label
                        >
                        <input
                          @keyup="isiData('receiverName')"
                          type="text"
                          class="form-control"
                          placeholder="Isi Nama Penerima Anda"
                        />
                      </div>
                      <div class="form-group">
                        <label for="no_telepon" class="float-left"
                          >Nomor Telepon *</label
                        >
                        <input
                          @keyup="isiData('phoneNumber')"
                          type="text"
                          class="form-control"
                          placeholder="Isi Nomer Telp Anda"
                        />
                      </div>
                      <div class="form-group">
                        <label for="province" class="float-left"
                          >Provinsi *</label
                        >
                        <select
                          class="form-control"
                          id="exampleFormControlSelect1"
                          @change="getProvId()"
                        >
                          <option
                            v-for="(item, index) in provinsi"
                            :key="index"
                            :value="item.province_id"
                          >
                            {{ item.province }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="kota" class="float-left">Kota *</label>
                        <select
                          class="form-control"
                          id="exampleFormControlSelect1"
                          @change="getCityId()"
                        >
                          <option
                            v-for="(item, index) in kota"
                            :key="index"
                            :value="item.city_id"
                          >
                            {{ item.city_name }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="kecamatan" class="float-left"
                          >Kecamatan *</label
                        >
                        <select
                          class="form-control"
                          id="exampleFormControlSelect1"
                          @change="getKecamatanId()"
                        >
                          <option
                            v-for="(item, index) in kecamatan"
                            :key="index"
                            :value="item.kecamatan_id + ':' + item.kecamatan_kd"
                          >
                            {{ item.kecamatan }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="kelurahan" class="float-left"
                          >Kelurahan *</label
                        >
                        <select
                          class="form-control"
                          id="exampleFormControlSelect1"
                          @change="getKelurahanId()"
                        >
                          <option
                            v-for="(item, index) in kelurahan"
                            :key="index"
                            :value="item.kelurahan_id"
                          >
                            {{ item.kelurahan_desa }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="kodepos" class="float-left"
                          >Kode Pos *</label
                        >
                        <input
                          @keyup="isiData('postalCode')"
                          type="text"
                          class="form-control"
                          placeholder="isi Kode Pos Anda"
                        />
                      </div>
                      <div class="form-group">
                        <label for="jenisalamat" class="float-left"
                          >Jenis Alamat *</label
                        >
                        <div
                          class="d-flex justify-content-between bd-highlight mb-3"
                        >
                          <div class="p-2 bd-highlight">
                            <div class="form-check">
                              <input
                                @change="pilihLabel"
                                class="form-check-input"
                                type="radio"
                                name="gender"
                                id="flexRadioDefault1"
                                value="kantor"
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault1"
                              >
                                Alamat Kantor
                              </label>
                            </div>
                          </div>
                          <div class="p-2 bd-highlight">
                            <div class="form-check">
                              <input
                                @change="pilihLabel"
                                class="form-check-input"
                                type="radio"
                                name="gender"
                                id="flexRadioDefault2"
                                value="rumah"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault2"
                              >
                                Alamat Rumah
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="alamatlengkap" class="float-left"
                          >Alamat Lengkap *</label
                        >
                        <input
                          @keyup="isiData('address')"
                          type="text"
                          class="form-control"
                          placeholder="isi Alamat Rumah"
                        />
                      </div>
                      <div class="form-group">
                        <label for="alamatgmaps" class="float-left"
                          >Isi Alamat Google Maps *</label
                        >
                        <input type="hidden" class="latit" />
                        <input type="hidden" class="longi" />
                        <mapGoggle
                          :latlong="latlong"
                          :reqUpdateAddr="rAddAdrs"
                        />
                      </div>
                    </div>
                    <div class="col-md-4"></div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    @click="submitAddress"
                    type="button"
                    class="btn bg-primary-jaja text-white font-weight-bold"
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container p-3" v-if="!isFinish">
          <div class="row">
            <div class="col-md-3">
              <sideBar />
            </div>
            <div class="col-md-9">
              <div class="card-product shadow-custom br-card-jaja">
                <div class="card-header br-card-header-jaja">
                  <div class="d-flex justify-content-between bd-highlight">
                    <div class="pt-2 bd-highlight mr-auto">
                      <h5 class="d-flex font-weight-bold text-white">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 200px"
                          />
                        </content-placeholders>
                      </h5>
                    </div>
                    <div class="pt-1 bd-highlight">
                      <h6 class="d-flex font-weight-bold">
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 150px; height: 30px"
                          />
                        </content-placeholders>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 200px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 180px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 190px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 200px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 190px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 160px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 180px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 150px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 150px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 200px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 200px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 200px"
                          />
                        </content-placeholders>
                      </div>
                    </div>
                    <div class="col-md-4"></div>
                  </div>
                </div>
                <div class="modal-footer">
                  <content-placeholders :rounded="true">
                    <content-placeholders-img
                      style="width: 150px; height: 40px"
                    />
                  </content-placeholders>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-lg-none" >
      <div>
        <div style="margin-top: -177px" class="container-m p-3" v-if="isFinish">
          <div class="row">
            <div class="col-md-12">
              <div class="card-product shadow-custom br-card-jaja">
                <div class="card-header br-card-header-jaja">
                  <div class="d-flex justify-content-between bd-highlight">
                    <div class="pt-2 bd-highlight mr-auto">
                      <h5 class="d-flex font-weight-bold text-dark">
                        Tambah Alamat
                      </h5>
                    </div>
                    <div class="pt-1 bd-highlight">
                      <h6 class="d-flex font-weight-bold">
                        <router-link to="/address">
                          <button
                            type="button"
                            class="btn btn-warning btn-sm text-white font-weight-bold"
                          >
                            <i class="fas fa-angle-left ver-middle"></i
                            >&nbsp;<span class="ver-middle">Kembali</span>
                          </button>
                        </router-link>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="nama" class="float-left"
                          >Nama Penerima *</label
                        >
                        <input
                          @keyup="isiData('receiverName')"
                          type="text"
                          class="form-control"
                          placeholder="Isi Nama Penerima Anda"
                        />
                      </div>
                      <div class="form-group">
                        <label for="no_telepon" class="float-left"
                          >Nomor Telepon *</label
                        >
                        <input
                          @keyup="isiData('phoneNumber')"
                          type="text"
                          class="form-control"
                          placeholder="Isi Nomer Telp Anda"
                        />
                      </div>
                      <div class="form-group">
                        <label for="province" class="float-left"
                          >Provinsi *</label
                        >
                        <select
                          class="form-control"
                          id="exampleFormControlSelect1"
                          @change="getProvId()"
                        >
                          <option
                            v-for="(item, index) in provinsi"
                            :key="index"
                            :value="item.province_id"
                          >
                            {{ item.province }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="kota" class="float-left">Kota *</label>
                        <select
                          class="form-control"
                          id="exampleFormControlSelect1"
                          @change="getCityId()"
                        >
                          <option
                            v-for="(item, index) in kota"
                            :key="index"
                            :value="item.city_id"
                          >
                            {{ item.city_name }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="kecamatan" class="float-left"
                          >Kecamatan *</label
                        >
                        <select
                          class="form-control"
                          id="exampleFormControlSelect1"
                          @change="getKecamatanId()"
                        >
                          <option
                            v-for="(item, index) in kecamatan"
                            :key="index"
                            :value="item.kecamatan_id + ':' + item.kecamatan_kd"
                          >
                            {{ item.kecamatan }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="kelurahan" class="float-left"
                          >Kelurahan *</label
                        >
                        <select
                          class="form-control"
                          id="exampleFormControlSelect1"
                          @change="getKelurahanId()"
                        >
                          <option
                            v-for="(item, index) in kelurahan"
                            :key="index"
                            :value="item.kelurahan_id"
                          >
                            {{ item.kelurahan_desa }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="kodepos" class="float-left"
                          >Kode Pos *</label
                        >
                        <input
                          @keyup="isiData('postalCode')"
                          type="text"
                          class="form-control"
                          placeholder="isi Kode Pos Anda"
                        />
                      </div>
                      <div class="form-group">
                        <label for="jenisalamat" class="float-left"
                          >Jenis Alamat *</label
                        >
                        <div
                          class="d-flex justify-content-between bd-highlight mb-3"
                        >
                          <div class="p-2 bd-highlight">
                            <div class="form-check">
                              <input
                                @change="pilihLabel"
                                class="form-check-input"
                                type="radio"
                                name="gender"
                                id="flexRadioDefault1"
                                value="kantor"
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault1"
                              >
                                Alamat Kantor
                              </label>
                            </div>
                          </div>
                          <div class="p-2 bd-highlight">
                            <div class="form-check">
                              <input
                                @change="pilihLabel"
                                class="form-check-input"
                                type="radio"
                                name="gender"
                                id="flexRadioDefault2"
                                value="rumah"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault2"
                              >
                                Alamat Rumah
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="alamatlengkap" class="float-left"
                          >Alamat Lengkap *</label
                        >
                        <input
                          @keyup="isiData('address')"
                          type="text"
                          class="form-control"
                          placeholder="isi Alamat Rumah"
                        />
                      </div>
                      <div class="form-group">
                        <label for="alamatgmaps" class="float-left"
                          >Isi Alamat Google Maps *</label
                        >
                        <input type="hidden" class="latit" />
                        <input type="hidden" class="longi" />
                        <mapGoggle
                          :latlong="latlong"
                          :reqUpdateAddr="rAddAdrs"
                        />
                      </div>
                    </div>
                    <div class="col-md-4"></div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    @click="submitAddress"
                    type="button"
                    class="btn bg-primary-jaja text-white font-weight-bold"
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container p-3" v-if="!isFinish">
          <div class="row">
            <div class="col-md-3">
              <sideBar />
            </div>
            <div class="col-md-9">
              <div class="card-product shadow-custom br-card-jaja">
                <div class="card-header br-card-header-jaja">
                  <div class="d-flex justify-content-between bd-highlight">
                    <div class="pt-2 bd-highlight mr-auto">
                      <h5 class="d-flex font-weight-bold text-white">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 200px"
                          />
                        </content-placeholders>
                      </h5>
                    </div>
                    <div class="pt-1 bd-highlight">
                      <h6 class="d-flex font-weight-bold">
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 150px; height: 30px"
                          />
                        </content-placeholders>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 200px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 180px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 190px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 200px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 190px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 160px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 180px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 150px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 150px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 200px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 35px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="form-group">
                        <label for="nama" class="float-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 200px"
                            />
                          </content-placeholders>
                        </label>
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            style="width: 100%; height: 200px"
                          />
                        </content-placeholders>
                      </div>
                    </div>
                    <div class="col-md-4"></div>
                  </div>
                </div>
                <div class="modal-footer">
                  <content-placeholders :rounded="true">
                    <content-placeholders-img
                      style="width: 150px; height: 40px"
                    />
                  </content-placeholders>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <br />
          <NavBar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import sideBar from "../../components/sidebar/sideBar";
import mapGoggle from "../../components/mapcomponent/mapcomp.vue";
import NavBar from "../../components/master/navbarbuttom.vue";
export default {
  data() {
    return {
      rAddAdrs: {
        label: "rumah",
        receiverName: "",
        phoneNumber: "",
        address: "",
        addressGoogle: "",
        latitude: "",
        longitude: "",
        provinceId: "",
        cityId: "",
        districtId: "",
        subDistrictId: "",
        postalCode: "",
      },
      provinsi: {},
      kota: {},
      kecamatan: {},
      kelurahan: {},
      latlong: {
        lat: 45.508,
        lng: -73.587,
      },
      isFinish: false,
    };
  },
  components: {
    sideBar,
    mapGoggle,
    NavBar,
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  mounted() {
    this.getProvinci();
    window.scrollTo(0, 0);
  },
  methods: {
    getProvinci() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      // fetch("https://jaja.id/backend/master/province", requestOptions)
      fetch(`${this.domainURL}master/province`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          this.isFinish = true;
          if (res.status === 200) {
            const data = res.provinsi.map((item) => {
              return item;
            });
            const newObj = {
              province: "Silahkan Pilih Provinsi",
              province_id: "",
              province_kd: "",
            };
            data.unshift(newObj);
            this.provinsi = data;
          }
        })
        .catch((error) => console.log("error", error));
    },
    getProvId() {
      this.rAddAdrs.provinceId = event.target.value;
      this.getCity(event.target.value);
    },
    getCity(id) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(`${this.domainURL}master/city?province=${id}`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status === 200) {
            const data = res.city.map((item) => {
              return item;
            });

            const newObj = {
              city_id: "",
              city_kd: "",
              city_name: "Silahkan Pilih Kota",
              postal_code: "",
              province: "",
              province_id: "",
              province_kd: "",
            };

            data.unshift(newObj);
            // this.provinsi = data;
            this.kota = data;
          }
        })
        .catch((error) => console.log("error", error));
    },
    getCityId() {
      this.rAddAdrs.cityId = event.target.value;
      this.getKecamatan(event.target.value);
    },
    getKecamatan(id) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(`${this.domainURL}master/kecamatan?city=${id}`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status === 200) {
            const data = res.kecamatan.map((item) => {
              return item;
            });

            const newObj = {
              city: "",
              city_id: "",
              city_kd: "0",
              kecamatan: "Silahkan Pilih Kecamatan",
              kecamatan_id: "",
              kecamatan_kd: "",
              province: "",
              province_id: "",
              province_kd: "",
            };

            data.unshift(newObj);
            this.kecamatan = data;
          }
        })
        .catch((error) => console.log("error", error));
    },
    getKecamatanId() {
      const id = event.target.value.split(":")[1];
      this.rAddAdrs.districtId = event.target.value.split(":")[0];
      this.getKeluarahan(id);
    },
    getKeluarahan(id) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(`${this.domainURL}master/kelurahan?kd_kec=${id}`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status === 200) {
            const data = res.kelurahan.map((item) => {
              return item;
            });

            const newObj = {
              kabupaten_kota: "",
              kd_kab_kota: "",
              kd_kec: "",
              kd_kelurahan_desa: "",
              kd_prop: "",
              kecamatan: "",
              kelurahan_desa: "Silahkan Pilih Kelurahan",
              kelurahan_id: "",
              propinsi: "",
            };
            // console.log(data, newObj)
            data.unshift(newObj);
            this.kelurahan = data;
          }
        })
        .catch((error) => console.log("error", error));
    },
    getKelurahanId() {
      this.rAddAdrs.subDistrictId = event.target.value;
      // console.log(this.rAddAdrs)
    },
    isiData(slug) {
      if (slug === "receiverName") {
        this.rAddAdrs.receiverName = event.target.value;
      } else if (slug === "phoneNumber") {
        this.rAddAdrs.phoneNumber = event.target.value;
      } else if (slug === "postalCode") {
        this.rAddAdrs.postalCode = event.target.value;
      } else if (slug === "address") {
        this.rAddAdrs.address = event.target.value;
      }
    },
    pilihLabel() {
      this.rAddAdrs.label = event.target.value;
      // console.log(this.rAddAdrs.label)
      // console.log(event.target.value)
    },
    submitAddress() {
      // console.log(this.rAddAdrs)
      const lat = document.getElementsByClassName("latit");
      const lang = document.getElementsByClassName("longi");
      const alamat = document.getElementsByClassName("introInput");
      this.rAddAdrs.latitude = lat[0].value;
      this.rAddAdrs.longitude = lang[0].value;
      this.rAddAdrs.addressGoogle = alamat[0].value;
      setTimeout(() => this.cekIsiValue(), 500);

      //  console.log('this.rAddAdrs.latitude', lat[0].value);
      //  console.log('this.rAddAdrs.longitude', lang[0].value);
      //  console.log('this.rAddAdrs.addressGoogle', this.rAddAdrs.addressGoogle);
    },
    cekIsiValue() {
      const params = this.rAddAdrs;
      const arrParams = Object.values(params);
      const data = arrParams.filter((item) => {
        return item != "";
      });
      // console.log(data.length,arrParams.length)
      if (arrParams.length > data.length) {
        if (params.address === "") {
          this.$swal.fire("Alamat kosong", "Mohon isi alamat", "warning");
        } else if (
          params.addressGoogle === "" ||
          params.longitude === "" ||
          params.latitude === ""
        ) {
          this.$swal.fire(
            "Alamat Google Map Tidak Valid Atau Kosong",
            "Pastikan titik sesuai dengan rumah anda agar pengantaran pesanan sampai dengan cepat",
            "warning"
          );
        } else if (params.receiverName === "") {
          this.$swal.fire(
            "Nama Penerima Kosong",
            "Mohon isi nama penerima",
            "warning"
          );
        } else if (params.phoneNumber === "") {
          this.$swal.fire(
            "Nomer Telepon Kosong",
            "Mohon isi nomor telepon",
            "warning"
          );
        } else if (params.provinceId === "") {
          this.$swal.fire(
            `Provinsi Belum Dipilih`,
            `Mohon isi data provinsi`,
            "warning"
          );
        } else if (params.cityId === "") {
          this.$swal.fire(
            `Kota Belum Dipilih`,
            `Mohon isi data kota`,
            "warning"
          );
        } else if (params.districtId === "") {
          this.$swal.fire(
            `Kecamatan Belum Dipilih`,
            `Mohon isi data kecamatan`,
            "warning"
          );
        } else if (params.subDistrictId === "") {
          this.$swal.fire(
            `Kelurahan Belum Dipilih`,
            `Mohon isi data kelurahan`,
            "warning"
          );
        } else if (params.postalCode === "") {
          this.$swal.fire(
            `Kode Pos Belum Dipilih`,
            `Mohon isi kode pos`,
            "warning"
          );
        }
      } else {
        this.runAddAddress();
      }
    },
    runAddAddress() {
      this.$swal.fire({
        html: '<img src="https://jsonx.jaja.id/asset/home/logo/Loading_2.gif" alt="">', // add html attribute if you want or remove
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          this.$swal.showLoading();
        },
      });

      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(this.rAddAdrs);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/address`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          // console.log(res.status.code)
          if (res.status.code === 200) {
            this.$swal.fire(`Sukses`, "Berhasil Menambahkan Data", "success");
            this.$store.dispatch("get_user").then(() => {
              this.$router.push("/address");
            });
          } else {
            this.$swal.fire(`Gagal`, "Gagal Menambahkan Data", "warning");
          }
        })
        .catch((error) => console.log("error", error));
    },
  },
};
</script>
<style>
i.fa-angle-left {
  font-size: 20px;
}
.introInput {
  width: 100%;
}
.container-m {
  padding: 10px;
}
</style>