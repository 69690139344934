<template>
  <div class="card-login">
    <div class="kotak-login">
      <div class="d-flex justify-content-between bd-highlight">
        <div class="bd-highlight">
          <h5 class="font-weight-bold">Verification</h5>
        </div>
        <div class="bd-highlight"></div>
        <div class="bd-highlight">
          <router-link to="/">
            <img
              width="70"
              class="logo-jaja"
              src="https://jsonx.jaja.id/asset/home/logo/jaja-logo.png"
              alt=""
            />
          </router-link>
        </div>
      </div>
      <div class="d-flex justify-content-between bd-highlight mb-2">
        <div class="bd-highlight">
          <!-- <h5>{{this.$route.params.email}}</h5> -->
          <small
            >Tolong masukkan 6 digit PIN yang sudah dikirimkan ke email
            kamu</small
          >
        </div>
      </div>

      <form @submit.prevent="sendPINForgotPassword">
        <div class="form-group">
          <input
            type="text"
            v-model="pin"
            name="pin"
            class="form-control"
            required=""
            placeholder="PIN"
          />
        </div>

        <button
          type="submit"
          class="btn bg-primary-jaja text-white font-weight-bold btn-block"
        >
          Dapatkan Password
        </button>
      </form>

      <div class="mt-4 kotak-bawah">
        <div class="d-flex justify-content-between bd-highlight mt-4 p-2">
          <div class="p-2 bd-highlight">
            <small>Sudah memiliki akun?</small>
          </div>
          <div class="p-2 bd-highlight">
            <button
              @click="goToLogin"
              class="tombol-daftar p-1 text-dark font-weight-bold"
            >
              Masuk
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pin: "",
      setTypeInput: "password",
    };
  },
  computed: {},
  components: {},
  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      "https://apis.google.com/js/client:platform.js?onload=renderButton"
    );
    document.head.appendChild(externalScript);
    window.scrollTo(0, 0);
  },
  methods: {
    sendPINForgotPassword() {
      this.$swal.fire({
        html: '<img src="https://jsonx.jaja.id/asset/home/logo/Loading_2.gif" alt="">', // add html attribute if you want or remove
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          this.$swal.showLoading();
        },
      });

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: this.$route.params.email,
        code: this.pin,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}user/verification/forgot_password`,
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            // $("#modalUpdate").modal('hide')
            this.$swal
              .fire("Sukses", res.status.message, "success")
              .then(() => {
                // this.$router.push('/user/lupa-password/new-password')
                this.$router.push({
                  name: "LupaPasswordNewPassword",
                  params: { email: this.$route.params.email, code: this.pin },
                });
              });
          } else if (res.status.code === 409) {
            // $("#modalUpdate").modal('hide')
            this.$swal.fire("Info", res.status.message, "info").then(() => {});
          } else {
            // $("#modalUpdate").modal('hide')
            this.$swal
              .fire("Warning", res.status.message, "warning")
              .then(() => {});
          }
        })
        .catch((error) => console.log("error", error));
    },
    goToLogin() {
      this.$router.push("/user/login");
    },
    seePass() {
      event.target.checked
        ? (this.setTypeInput = `text`)
        : (this.setTypeInput = `password`);
      // console.log(this.setTypeInput);
    },
  },
};
</script>

<style lang="scss" scoped>
.kotak-login {
  width: 30vw;
  background-color: white;
  border-radius: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  margin-left: 105%;
}
.kotak-bawah {
  position: relative;
  width: 30vw;
  margin-left: -20px;
  background-color: #f0f0f0;
  border-radius: 0 0 20px 20px;
}

.opacity-button {
  opacity: 0.5;
}
.tombol-fb {
  width: 50vh;
}

.card-login {
}
.tombol-daftar {
  background-color: rgb(239 194 122);
  border-radius: 10px;
  font-size: 12px;
  width: 5vw;
  border: 0px !important;
}
</style>