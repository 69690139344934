<template>
  <div>
    <div class="d-none d-lg-block">
      <div>
        <div
          class="d-flex flex-column bd-highlight custom-background-profile py-3 card-header-sidbar mb-2"
        >
          <div class="bd-highlight mb-2" v-if="isFinish">
            <!-- <img
              class="rounded-circle square-80 imguseravatar bg-biru-jaja"
              :src="
                userComp.image
                  ? userComp.image
                  : require('../../assets/dashboard/user-not-fond.png')
              "
              alt="avatar"
            /> -->
            <img
                style="width: 62px; height: 62px"
                src="../../assets/dashboard/user-not-fond.png"
              />
          </div>
          <div class="bd-highlight mb-2" v-else>
            <content-placeholders :rounded="true" :centered="true">
              <content-placeholders-img
                class="rounded-circle square-80 imguseravatar"
                style="width: 80px; height: 80px"
              />
            </content-placeholders>
          </div>
          <div class="bd-highlight" v-if="isFinish">
            <span class="font-weight-bold h5 text-white">{{
              userComp.name
            }}</span>
          </div>
          <div class="bd-highlight" v-else>
            <span class="font-weight-bold h5 text-white"> Name </span>
          </div>
          <div class="bd-highlight">
            <img
              style="width: 24px; height: 24px; flex-shrink: 0"
              src="../../assets/dashboard/koin.png"
            />

            <span class="nilaiKoin font-weight-bold text-white" v-if="isFinish">
              &nbsp;{{ userComp.coinFormat }}
            </span>
            <span class="nilaiKoin font-weight-bold text-white" v-else>
              Coin
            </span>
          </div>
          <div class="bd-highlight">
            <a href="https://jsonx.jaja.id/mulai-jualan"
              ><small class="font-weight-bold text-white"
                >Jajakan Produkmu</small
              ></a
            >
          </div>
        </div>

        <div
          class="card-footer-body-sidebar py-3 px-2"
          style="background-color: white"
        >
          <router-link :to="'/dashboard'">
            <div class="bg-white p-2 text-left mb-2 list-sidebar">
              <span class="ml-2 font-weight-bold text-dark">
                <img class="icon-dash" src="../../assets/dashboard/dash.png" />
                &nbsp;Dashboard</span
              >
            </div>
          </router-link>
          <router-link :to="'/profil'">
            <div class="bg-white p-2 text-left mb-2 list-sidebar">
              <span class="ml-2 font-weight-bold text-dark">
                <img
                  style="width: 28px; height: 28px; gap: 8px; flex-shrink: 0"
                  src="../../assets/navbar-asset/user.png"
                />

                &nbsp; &nbsp;Profil</span
              >
            </div>
          </router-link>

          <router-link :to="'/address'">
            <div class="bg-white p-2 text-left mb-2 list-sidebar">
              <span class="ml-2 font-weight-bold text-dark">
                <img class="icon-dash" src="../../assets/dashboard/6.png" />
                &nbsp; Alamat</span
              >
            </div>
          </router-link>

          <router-link :to="'/gantipassword'">
            <div class="bg-white p-2 text-left mb-2 list-sidebar">
              <span class="ml-2 font-weight-bold text-dark">
                &nbsp;
                <img
                  style="width: 20px; height: 22px; gap: 8px; flex-shrink: 0"
                  src="../../assets/dashboard/forget.png"
                />
                &nbsp; Ganti Password</span
              >
            </div>
          </router-link>

          <router-link :to="'/pemberitahuan'">
            <div class="bg-white p-2 text-left mb-2 list-sidebar">
              <span class="ml-2 font-weight-bold text-dark">
                &nbsp;
                <img
                  style="width: 22px; height: 22px; gap: 8px; flex-shrink: 0"
                  src="../../assets/navbar-asset/notif.png"
                />
                &nbsp; Pemberitahuan</span
              >
            </div>
          </router-link>

          <router-link :to="'/rekening'">
            <div class="bg-white p-2 text-left mb-2 list-sidebar">
              <span class="ml-2 font-weight-bold text-dark">
                <img class="icon-dash" src="../../assets/dashboard/9.png" />
                &nbsp;Rekening Anda</span
              >
            </div>
          </router-link>

          <!-- <router-link :to="'/danakembali'">
				<div class="bg-white p-2 text-left mb-2 list-sidebar">
					<span class="ml-2 font-weight-bold text-dark">Pengembalian Dana</span>
				</div>
			</router-link> -->

          <router-link :to="'/koin'">
            <div class="bg-white p-2 text-left mb-2 list-sidebar">
              <span class="ml-2 font-weight-bold text-dark">
                <img class="icon-dash" src="../../assets/dashboard/8.png" />
                &nbsp;Koin</span
              >
            </div>
          </router-link>

          <router-link :to="'/favorite'">
            <div class="bg-white p-2 text-left mb-2 list-sidebar">
              <span class="ml-2 font-weight-bold text-dark">
                <img class="icon-dash" src="../../assets/dashboard/7.png" />
                &nbsp; Favorite</span
              >
            </div>
          </router-link>

          <router-link :to="'/lastseen'">
            <div class="bg-white p-2 text-left list-sidebar">
              <span class="ml-2 font-weight-bold text-dark">
                <img
                  class="icon-dash"
                  src="../../assets/dashboard/10.png"
                />&nbsp; Terakhir dilihat</span
              >
            </div>
          </router-link>

          <div @click="logout" class="bg-white p-2 text-left list-sidebar">
            <span class="ml-2 font-weight-bold text-dark">
              <img
                @click="logout"
                class="icon-dash"
                src="../../assets/dashboard/12.png"
              />&nbsp; Keluar Akun</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="d-lg-none">
      <div>
        <div
          class="d-flex flex-column bd-highlight py-3 card-header-sidbar mb-2"
        >
          <div class="bd-highlight mb-2" v-if="isFinish">
            <img
              class="rounded-circle square-80 imguseravatar bg-biru-jaja"
              :src="
                userComp.image
                  ? userComp.image
                  : `https://nimda.jaja.id/asset/icon-jaja/user%20jaja.png`
              "
              alt="avatar"
            />
          </div>
          <div class="bd-highlight mb-2" v-else>
            <content-placeholders :rounded="true" :centered="true">
              <content-placeholders-img
                class="rounded-circle square-80 imguseravatar"
                style="width: 80px; height: 80px"
              />
            </content-placeholders>
          </div>
          <div class="bd-highlight" v-if="isFinish">
            <span class="font-weight-bold h5">{{ userComp.name }}</span>
          </div>
          <div class="bd-highlight" v-else>
            <span class="font-weight-bold h5"> Name </span>
          </div>
          <div class="bd-highlight">
            <img
              src="https://jsonx.jaja.id/asset/front/images/koin.png"
              alt="coin-gif"
              width="32"
            />
            <span class="nilaiKoin font-weight-bold" v-if="isFinish">
              {{ userComp.coinFormat }}
            </span>
            <span class="nilaiKoin font-weight-bold" v-else> Coin </span>
          </div>
          <div class="bd-highlight">
            <a href="https://jsonx.jaja.id/mulai-jualan"
              ><small class="font-weight-bold text-dark"
                >Jajakan Produkmu</small
              ></a
            >
          </div>
        </div>

        <div
          class="d-flex justify-content-between bd-highlight card-header-body-sidebar bg-white pt-2"
        >
          <div>
            <div @click="setStatusOrder('notPaid')" class="card-pesanan">
              <img
                style="width: 29.538px; height: 24px"
                src="../../assets/dashboard/4.png"
              />
              <p class="text-pesanan">Belum bayar</p>
            </div>
          </div>
          <div>
            <div @click="setStatusOrder('waitConfirm')" class="card-pesanan">
              <img
                style="width: 29px; height: 24px"
                src="../../assets/dashboard/3.png"
              />
              <p class="text-pesanan">Diproses</p>
            </div>
          </div>
          <div>
            <div @click="setStatusOrder('sent')" class="card-pesanan">
              <img
                style="width: 36px; height: 20.571px"
                src="../../assets/dashboard/1.png"
              />
              <p class="text-pesanan">Dikirim</p>
            </div>
          </div>
          <div>
            <div @click="setStatusOrder('done')" class="card-pesanan">
              <img
                style="width: 24px; height: 24px"
                src="../../assets/dashboard/2.png"
              />
              <p class="text-pesanan">Selesai</p>
            </div>
          </div>
        </div>

        <div class="bg-white card-footer-body-sidebar py-3 px-2">
          <router-link :to="'/dashboard'">
            <div class="bg-white p-2 text-left mb-2 list-sidebar">
              <span class="ml-2 font-weight-bold text-dark font-weight-bold">
                <img class="icon-dash" src="../../assets/dashboard/5.png" />
                &nbsp; Dashboard</span
              >
            </div>
          </router-link>
          <router-link :to="'/profil'">
            <div class="bg-white mt-3 p-2 text-left mb-2 list-sidebar">
              <span class="ml-2 font-weight-bold text-dark">
                <img class="icon-dash" src="../../assets/dashboard/11.png" />
                &nbsp;Profil</span
              >
            </div>
          </router-link>

          <router-link :to="'/address'">
            <div class="bg-white mt-3 p-2 text-left mb-2 list-sidebar">
              <span class="ml-2 font-weight-bold text-dark">
                <img class="icon-dash" src="../../assets/dashboard/6.png" />
                &nbsp; Alamat</span
              >
            </div>
          </router-link>

          <!-- <router-link :to="'/gantipassword'">
            <div class="bg-white p-2 text-left mb-2 list-sidebar">
              <span class="ml-2 font-weight-bold text-dark">
                <img class="icon-dash" src="../../assets/dashboard/8.png" />
                &nbsp; Ganti Password</span
              >
            </div>
          </router-link> -->

          <router-link :to="'/rekening'">
            <div class="bg-white mt-3 p-2 text-left mb-2 list-sidebar">
              <span class="ml-2 font-weight-bold text-dark">
                <img class="icon-dash" src="../../assets/dashboard/9.png" />
                &nbsp;Rekening Anda</span
              >
            </div>
          </router-link>

          <!-- <router-link :to="'/danakembali'">
				<div class="bg-white p-2 text-left mb-2 list-sidebar">
					<span class="ml-2 font-weight-bold text-dark">Pengembalian Dana</span>
				</div>
			</router-link> -->

          <router-link :to="'/koin'">
            <div class="bg-white mt-3 p-2 text-left mb-2 list-sidebar">
              <span class="ml-2 font-weight-bold text-dark">
                <img class="icon-dash" src="../../assets/dashboard/8.png" />
                &nbsp;Koin</span
              >
            </div>
          </router-link>

          <router-link :to="'/favorite'">
            <div class="bg-white mt-3 p-2 text-left mb-2 list-sidebar">
              <span class="ml-2 font-weight-bold text-dark">
                <img class="icon-dash" src="../../assets/dashboard/7.png" />
                &nbsp; Favorite</span
              >
            </div>
          </router-link>

          <router-link :to="'/lastseen'">
            <div class="bg-white mt-3 p-2 text-left list-sidebar">
              <span class="ml-2 font-weight-bold text-dark">
                <img
                  class="icon-dash"
                  src="../../assets/dashboard/10.png"
                />&nbsp;Terakhir dilihat</span
              >
            </div>
          </router-link>

          <div @click="logout" class="bg-white mt-3 p-2 text-left list-sidebar">
            <span class="ml-2 font-weight-bold text-dark">
              <img
                @click="logout"
                class="icon-dash"
                src="../../assets/dashboard/12.png"
              />&nbsp;Keluar Akun</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      userComp: {},
      isFinish: false,
    };
  },
  components: {},
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  created() {
    this.getUser();
  },
  methods: {
    setStatusOrder(status) {
      this.$router.push("/listorder?status=" + status);
    },
    logout() {
      this.$swal
        .fire({
          icon: "info",
          title: "Apakah anda akan keluar?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Keluar",
          denyButtonText: `Batalkan`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.runLogout();
          }
          // else if (result.isDenied) {
          //   this.$swal.fire("Cancel", "Anda tidak Logout", "warning");
          // }
        });
      // localStorage.clear();
      // window.location.href = "${this.domainURL}"
    },
    runLogout() {
      // this.$swal.fire('sukses', 'anda berhasil logout', 'success')
      localStorage.clear();
      this.$router.push("/user/login");
    },
    getUser() {
      if (this.user.uid) {
        this.userComp = this.user;
        this.isFinish = true;
      } else {
        this.isFinish = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.imguseravatar {
  width: 60px;
  height: 60px;
}

.bg-orange-soft-jaja {
  background: #fedeac !important;
}

.card-header-sidbar {
  border-radius: 10px;
}

.bg-biru-jaja {
  background: rgb(100, 175, 200);
}

.rm-bullet {
  list-style-type: none;
  width: 290px;
}

.kotak-list {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  width: 230px;
  // position: relative;
  left: -9px;
  color: rgb(153, 150, 150);
}

.parent-list-account {
  position: relative;
  top: -10px;
}
.list-sidebar {
  border-radius: 10px;
}
.list-sidebar span {
  color: rgb(153, 150, 150);
}

.card-header-body-sidebar {
  border-radius: 10px 10px 0 0;
}

.card-footer-body-sidebar {
  border-radius: 0 0 10px 10px;
}
.card-pesanan {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}
.text-pesanan {
  color: var(--Primary-40, #65b0c9);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 160% */
  letter-spacing: -0.1px;
}
.icon-dash {
  width: 32px;
  height: 32px;
  gap: 8px;
  flex-shrink: 0;
}
.custom-background-profile {
  background-image: url("../../assets/bg/bg-profile.png");
  background-size: cover;
  background-position: center;
}
</style>