import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/home/index'
import Login from '../pages/auth/login'
import LupaPassword from '../pages/auth/lupa-password'
import LupaPasswordVerification from '../pages/auth/lupa-password-verification'
import LupaPasswordNewPassword from '../pages/auth/lupa-password-newpassword'
import DetailProduk from '../pages/home/DetaliProduk'
import Cart from '../pages/cart/cart'
import Payment from '../pages/payment/payment.vue'
import Order from '../pages/order/detailOrder.vue'
import Komplain from '../pages/order/detailKomplain.vue'
import DetailToko from '../pages/toko/toko.vue'
import Kategori from '../pages/kategori/kategori.vue'
import Search from '../pages/product-search/product-search.vue'
import ListOrder from '../pages/listorder/listorder.vue'
import Profil from '../pages/profil/profil.vue'
import Address from '../pages/address/address.vue'
import Tambahalamat from '../pages/address/tambahalamat.vue'
import Editalamat from '../pages/address/editalamat.vue'
import Gantipassword from '../pages/gantipassword/gantipassword.vue'
import Rekening from '../pages/rekening/rekening.vue'
import Tambahrekening from '../pages/rekening/tambahrekening.vue'
import Koin from '../pages/koin/koin.vue'
import Favorite from '../pages/favorite/favorite.vue'
import Dashboard from '../pages/dashboard/dashboard.vue'
import Lastseen from '../pages/lastseen/lastseen.vue'
import Chat from '../pages/chat/chat.vue'
import Gift from '../pages/gift/gift.vue'
import AddSendAddr from '../pages/addsendaddress/addsendaddress'
import Payment_multiple from '../pages/payment_multiple/payment_multi'
import Register from '../pages/auth/register'
import RegisterVerification from '../pages/auth/register-verification'
import RegisterUlangVerification from '../pages/auth/verfikasi-ulang'
import Danakembali from '../pages/danakembali/danakembali'
import Pemberitahuan from '../pages/pemberitahuan/pemberitahuan'
import tasya from '../pages/tasya/tasya'
import Tracking from '../pages/tracking/tracking'
import Checkout from '../pages/checkout/checkout.vue'
import seller from '../components/seller/landingPage.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/user/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/user/lupa-password',
    name: 'LupaPassword',
    component: LupaPassword
  },
  {
    path: '/auth/lupa-password/verification',
    name: 'LupaPasswordVerification',
    component: LupaPasswordVerification
  },
  {
    path: '/auth/lupa-password/new-password',
    name: 'LupaPasswordNewPassword',
    component: LupaPasswordNewPassword
  },
  {
    path: '/home/DetailProduk/:id',
    name: 'detailProduct',
    component: DetailProduk
  },
  {
    path: '/home/DetailToko/:id',
    name: 'detailToko',
    component: DetailToko
  },
  {
    path: '/cart/cart',
    name: 'cart',
    component: Cart
  },
  {
    path: '/payment',
    name: 'payment',
    component: Payment
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout
  },
  {
    path: '/order/:id',
    name: 'order',
    component: Order
  },
  {
    path: '/order/detail-komplain/:id',
    name: 'detail-komplain',
    component: Komplain
  },
  {
    path: '/kategori/:id',
    name: 'kategori',
    component: Kategori
  },
  {
    path: '/product-search/:id',
    name: 'product-search',
    component: Search
  },
  {
    path: '/listorder',
    name: 'listorder',
    component: ListOrder
  },
  {
    path: '/profil',
    name: 'profil',
    component: Profil
  },
  {
    path: '/address',
    name: 'address',
    component: Address
  },
  {
    path: '/tambahalamat',
    name: 'tambahalamat',
    component: Tambahalamat
  },
  {
    path: '/editalamat/:id',
    name: 'editalamat',
    component: Editalamat
  },
  {
    path: '/gantipassword',
    name: 'gantipassword',
    component: Gantipassword
  },
  {
    path: '/rekening',
    name: 'rekening',
    component: Rekening
  },
  {
    path: '/tambahrekening',
    name: 'tambahrekening',
    component: Tambahrekening
  },
  {
    path: '/koin',
    name: 'koin',
    component: Koin
  },
  {
    path: '/favorite',
    name: 'favorite',
    component: Favorite
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/lastseen',
    name: 'lastseen',
    component: Lastseen
  },
  {
    path: '/chat',
    name: 'chat',
    component: Chat
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../pages/about/index.vue')
  },
  {
    path: '/gift',
    name: 'gift',
    component: Gift
  },
  {
    path: '/AddSendAddr',
    name: 'AddSendAddr',
    component: AddSendAddr
  },
  {
    path: '/payment_multiple',
    name: 'payment_multiple',
    component: Payment_multiple
  },
  {
    path: '/user/register',
    name: 'register',
    component: Register
  },
  {
    path: '/auth/register/verification',
    name: 'RegisterVerification',
    component: RegisterVerification
  },
  {
    path: '/auth/register/verfikasi-ulang',
    name: 'RegisterUlangVerification',
    component: RegisterUlangVerification
  },
  {
    path: '/danakembali',
    name: 'danakembali',
    component: Danakembali
  },
  {
    path: '/pemberitahuan',
    name: 'pemberitahuan',
    component: Pemberitahuan
  },
  {
    path: '/tasya',
    name: 'tasya',
    component: tasya
  },
  {
    path: '/tracking/:id',
    name: 'tracking',
    component: Tracking
  },
  {
    path: '/seller/info',
    name: 'seller',
    component: seller
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



export default router
