<template>
  <div>
    <div>
      <GmapAutocomplete
        class="introInput form-control"
        @place_changed="setPlace"
      />
    </div>
    <br />
    <GmapMap :center="center" :zoom="12" style="width: 100%; height: 300px">
      <GmapMarker
        v-for="(m, index) in markers"
        :key="index"
        :position="center"
        :clickable="true"
        :draggable="true"
        @click="center = m.position"
        @dragend="showLocation"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  props: {
    latlong: {
      type: Object,
    },
    reqUpdateAddr: {
      type: Object,
    },
  },
  name: "GoogleMap",
  data() {
    return {
      center: {
        // lat: 45.508,
        // lng: -73.587
        lat: this.latlong.lat,
        lng: this.latlong.lng,
      },
      currentPlace: null,
      locatorLat: "",
      locatorLng: "",
      markers: [],
      places: [],
    };
  },
  mounted() {
    this.geolocate();
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place;
      this.addMarker();
    },
    addMarker() {
      // console.log('masuk')
      if (this.currentPlace) {
        const marker = {
          lat: Number(this.currentPlace.geometry.location.lat()),
          lng: Number(this.currentPlace.geometry.location.lng()),
        };
        // console.log(this.currentPlace)
        this.reqUpdateAddr.addressGoogle = this.currentPlace.formatted_address;
        this.reqUpdateAddr.latitude = this.currentPlace.geometry.location.lat();
        this.reqUpdateAddr.longitude =
          this.currentPlace.geometry.location.lng();
        document.getElementsByClassName("latit")[0].value =
          this.currentPlace.geometry.location.lat();
        document.getElementsByClassName("longi")[0].value =
          this.currentPlace.geometry.location.lng();
        this.markers.push({
          position: marker,
        });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    geocodedAddress(lat, lon) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&sensor=false&key=AIzaSyB4C8a6rkM6BKu1W0owWvStPzGHoc4ZBXI`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data.results[0].geometry.location)
          document.getElementsByClassName("latit")[0].value =
            data.results[0].geometry.location.lat;
          document.getElementsByClassName("longi")[0].value =
            data.results[0].geometry.location.lng;
          // let address = data.results[0].formatted_address
          // document.getElementsByClassName('introInput')[0].value = address
        })
        .catch((error) => console.log("error", error));
    },
    showLocation(evt) {
      this.locatorLat = evt.latLng.lat();
      this.locatorLng = evt.latLng.lng();
      this.center.lat = evt.latLng.lat();
      this.center.lng = evt.latLng.lng();
      this.geocodedAddress(this.locatorLat, this.locatorLng);
    },
  },
};
</script>