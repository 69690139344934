<template>
  <div>
    <div v-if="isLoadingDelete" class="overlay">
      <img
        src="https://jsonx.jaja.id/asset/home/logo/Loading_2.gif"
        alt="Loading"
        class="loading-gif"
      />
    </div>
    <div class="d-none d-lg-block">
      <section style="margin-top: -2.5%">
        <div v-if="recordsLength > 0">
          <div class="container">
            <div
              class="card-product shadow-custom p-3"
              style="margin-left: -1px; margin-right: 16px"
            >
              <div>
                <h4
                  style="
                    margin-left: 10px;
                    text-align: left;
                    margin-top: 10px;
                    color: #000;
                    font-family: Poppins;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 26px; /* 130% */
                    letter-spacing: 0.2px;
                  "
                >
                  <router-link style="color: black" :to="'/'">
                    <i class="fas fa-arrow-left"></i> </router-link
                  >&nbsp; &nbsp; Keranjang Belanja
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div
                  v-for="(item, index) in dataCart.items"
                  :key="index"
                  class="card-product shadow-custom mb-4 mt-4"
                >
                  <div class="card-header br-card-header-jaja text-left">
                    <div class="custom-control custom-checkbox d-inline">
                      <input
                        @change="checkListProduct(item.store.id + ':store')"
                        type="checkbox"
                        class="custom-control-input"
                        :id="'ceklisTokoDekstop' + index"
                        :checked="item.isSelected"
                      />
                      <label
                        class="custom-control-label"
                        :for="'ceklisTokoDekstop' + index"
                      >
                        <img
                          src="../../assets/icon-s/shop.png"
                          alt="icon"
                          class="mr-2 mb-1"
                          style="
                            width: 20px;
                            height: 16px;
                            margin-left: 30px;
                            color: black;
                          "
                        /><b>
                          <router-link
                            :to="'/home/DetailToko/' + item.store.slug"
                            style="color: black"
                            >{{ item.store.name }}</router-link
                          >
                        </b>
                      </label>
                    </div>
                  </div>

                  <table
                    class="table tabel-striped Table_JajaID mb-0 desktop-only ml-1 mr-1"
                  >
                    <tbody class="HeaderCartBody_JajaID" id="show-all-cart">
                      <tr class="Barang_JajaID">
                        <td
                          class="text-center MiddleCenter"
                          style="width: 5%"
                        ></td>
                        <td class="MiddleCenter" style="width: 30%">
                          <div class="media">
                            <div
                              class="media-body"
                              style="
                                margin-top: auto;
                                margin-bottom: auto;
                                text-align: left;
                              "
                            >
                              <b style="color: #65b0c9">Produk</b>
                            </div>
                          </div>
                        </td>
                        <!-- <td class="MiddleCenter">
                          <b style="color: #65b0c9"> &emsp; Variant</b>
                        </td> -->
                        <td class="MiddleCenter">
                          <b style="color: #65b0c9"> &emsp; Harga</b>
                        </td>
                        <td
                          class="MiddleCenter"
                          style="
                            width: 20%;
                            -webkit-touch-callout: none;
                            -webkit-user-select: none;
                            -khtml-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                          "
                        >
                          <div>
                            <b style="color: #65b0c9" class="text-center"
                              >Kuantitas</b
                            >
                          </div>
                        </td>
                        <td class="text-center my-product-total MiddleCenter">
                          <input
                            type="hidden"
                            id="getHarga-5303"
                            value="36000"
                            data-pilih="T"
                          /><span id="harga-5303"
                            ><b style="color: #65b0c9">
                              &emsp; Sub Total</b
                            ></span
                          >
                        </td>
                        <td class="MiddleCenter" style="width: 10%"></td>
                      </tr>
                      <tr
                        v-for="(a, b) in item.products"
                        :key="b"
                        class="Barang_JajaID"
                        style="height: 100%"
                      >
                        <td class="text-center MiddleCenter" style="width: 5%">
                          <div class="custom-control custom-checkbox">
                            <input
                              @change="checkListProduct(a.cartId + ':cart')"
                              type="checkbox"
                              class="custom-control-input check-product"
                              :id="'cartProduk' + a.cartId"
                              :checked="a.isSelected"
                            />
                            <label
                              class="custom-control-label"
                              :for="'cartProduk' + a.cartId"
                            ></label>
                          </div>
                        </td>
                        <td class="MiddleCenter" style="width: 30%">
                          <div class="media">
                            <div class="img-cart-item mr-2">
                              <img
                                :src="a.image"
                                height="60px"
                                width="60px"
                                style="object-fit: contain"
                                alt=""
                              />
                            </div>
                            <div
                              class="media-body"
                              style="
                                margin-top: auto;
                                margin-bottom: auto;
                                text-align: left;
                              "
                            >
                              <router-link
                                :to="'/home/DetailProduk/' + a.slug"
                                style="color: #64b0c9"
                              >
                                <b class="text-dark">{{ a.name }}</b>
                              </router-link>
                              <!-- <p>
                                <b style="color: #64b0c9">
                                  &emsp; {{ a.variant }}</b
                                >
                              </p> -->
                            </div>
                          </div>
                        </td>
                        <!-- <td class="MiddleCenter" v-if="a.variant != null">
                          <br />
                          <b style="color: #64b0c9"> &emsp; {{ a.variant }}</b>
                        </td>
                        <td class="MiddleCenter" v-else>
                          <br />
                          <b class="text-dark"> &emsp; -</b>
                        </td> -->
                        <td class="MiddleCenter">
                          <b class="text-dark">
                            &emsp;
                            <strike :class="a.isDiscount ? `` : `d-none`">
                              <small>{{ a.priceCurrencyFormat }}</small>
                            </strike>
                            &nbsp;
                            <span
                              :class="
                                a.isDiscount ? `badge badge-danger` : `d-none`
                              "
                            >
                              {{ a.discount }} %</span
                            >
                            <br />
                            <span class="text-dark font-weight-bold mr-1">{{
                              a.isDiscount
                                ? a.priceDiscountCurrencyFormat
                                : a.priceCurrencyFormat
                            }}</span>
                          </b>
                        </td>
                        <td
                          class="MiddleCenter"
                          style="
                            width: 20%;
                            -webkit-touch-callout: none;
                            -webkit-user-select: none;
                            -khtml-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                          "
                        >
                          <center>
                            <div
                              class="mt-3 d-flex justify-content-center bd-highlight"
                            >
                              <div class="p-2 bd-highlight">
                                <span
                                  class="minus btn"
                                  @click="
                                    addQty(
                                      a.productId +
                                        ':' +
                                        a.variantId +
                                        ':' +
                                        a.qty +
                                        ':less'
                                    )
                                  "
                                  style="
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 8px;
                                    color: black;
                                    border-radius: 2px;
                                    background: var(--Secondary-30, #fdb739);
                                    height: 25px;
                                    width: 25px;
                                  "
                                  >-</span
                                >
                              </div>
                              <div class="p-2 bd-highlight">
                                <input
                                  type="number pl-1"
                                  min="1"
                                  class="count"
                                  :value="a.qty"
                                  readonly
                                  style="
                                    color: #212529;
                                    background-color: #f8f9fa;
                                    font-size: 16px;
                                    font-weight: 600;
                                    border: none;
                                    text-align: center;
                                    width: 32px;
                                  "
                                />
                              </div>
                              <div class="p-2 bd-highlight">
                                <span
                                  class="plus btn"
                                  @click="
                                    addQty(
                                      a.productId +
                                        ':' +
                                        a.variantId +
                                        ':' +
                                        a.qty +
                                        ':add'
                                    )
                                  "
                                  style="
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 8px;
                                    color: black;
                                    border-radius: 2px;
                                    background: var(--Secondary-30, #fdb739);
                                    height: 25px;
                                    width: 25px;
                                  "
                                  >+</span
                                >
                              </div>
                            </div>
                          </center>
                        </td>
                        <td class="baru text-center MiddleCenter">
                          <input
                            type="hidden"
                            id="getHarga-5303"
                            value="36000"
                            data-pilih="T"
                          /><span id="harga-5303">
                            <!-- <br /> -->
                            <b class="baru text-dark">
                              &emsp; {{ a.subTotalCurrencyFormat }}</b
                            ></span
                          >
                        </td>
                        <td class="MiddleCenter" style="width: 10%">
                          <a
                            @click="hapusCart(a.cartId)"
                            class="btn mt-3"
                            style="border-radius: 5px"
                          >
                            <i style="color: red" class="fas fa-trash-alt"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="container">
                  <pagination
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                    :records="recordsLength"
                    v-model="page"
                    :per-page="perPage"
                    @paginate="getDataCart"
                  >
                  </pagination>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mt-4 container">
                  <div class="card-product shadow-custom p-3">
                    <h5
                      style="
                        color: var(--Primary-40, #65b0c9);
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 22px; /* 137.5% */
                        letter-spacing: -0.16px;
                      "
                      class="text-left"
                    >
                      Total Harga
                    </h5>
                    <div class="mt-2 d-flex justify-content-between">
                      <div
                        style="
                          color: var(--Neutral-60, #1a1a1a);
                          font-family: Poppins;
                          font-size: 20px;
                          font-style: normal;
                          font-weight: 600;
                          line-height: 26px; /* 130% */
                          letter-spacing: 0.2px;
                        "
                      >
                        {{ dataCart.totalCartCurrencyFormat }}
                      </div>
                      <div></div>
                      <div
                        style="
                          color: #000;

                          font-family: Poppins;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: 20px; /* 142.857% */
                          letter-spacing: -0.14px;
                        "
                      >
                        {{ dataCart.totalProductSelected }} barang
                      </div>
                    </div>
                    <div class="mt-3">
                      <button
                        @click="cekIsHasAddress(dataCart.isHasAddress)"
                        style="
                          color: #fff;
                          display: flex;
                          padding: 12px 8px;
                          justify-content: center;
                          align-items: center;
                          gap: 8px;
                          align-self: stretch;
                          border-radius: 4px;
                          background: var(--Primary-40, #65b0c9);
                          width: 100%;
                          border: none;
                        "
                      >
                        Bayar Pesanan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container mt-4" v-else-if="isFinish">
          <div class="row">
            <div class="col-md-12 col-12 carousel-cell is-selected">
              <div
                class="CheckShowCheck card-product p-3 mb-3 shadow-custom"
                style="background: white !important"
              >
                <div class="">
                  <div></div>
                  <img
                    slot="image"
                    src="https://nimda.jaja.id/asset/icon-jaja/notfound.png"
                    class="img-fluid"
                    style="
                      max-height: 400px;
                      max-width: 100%;
                      width: auto;
                      border-radius: 10px;
                    "
                    alt=""
                  />

                  <div class="text-dark">
                    <div class="keterangan ResetKet_JajaID">
                      <h4
                        class="text-product one-line fsxx text-center"
                        style="margin-top: -20px"
                      >
                        Looks like your cart is empty.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container mt-4" v-else>
          <div>
            <!-- Overlay -->
            <div v-if="isLoading" class="overlay">
              <img
                src="https://jsonx.jaja.id/asset/home/logo/Loading_2.gif"
                alt="Loading"
                class="loading-gif"
              />
            </div>

            <!-- Konten Anda -->
            <div class="container mt-4" v-else>
              <div class="row" v-for="index in 4" :key="index">
                <div class="col-2">
                  <content-placeholders :rounded="true">
                    <content-placeholders-img
                      style="width: 160px; height: 50px; margin-bottom: 20px"
                    />
                  </content-placeholders>
                </div>
                <div class="col-10">
                  <content-placeholders :rounded="true">
                    <content-placeholders-heading style="margin-bottom: 20px" />
                  </content-placeholders>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="d-lg-none" style="margin-top: -180px; background-color: white">
      <div class="shopping-cart-mobile">
        <div class="mb-3 container-m d-flex justify-content-start">
          <div>
            <span
              @click="belanjaLagi()"
              class="text-left"
              style="
                color: #000;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 19px; /* 135.714% */
                letter-spacing: 0.28px;
              "
            >
              <i class="fas fa-arrow-left"></i>&nbsp; &nbsp; Keranjang
              Belanja</span
            >
          </div>
        </div>

        <div
          class="container-m"
          v-for="(store, storeIndex) in dataCart.items"
          :key="storeIndex"
        >
          <div class="text-left">
            <input type="checkbox" />
            <span
              class="ml-2"
              style="
                color: #000;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px; /* 133.333% */
                letter-spacing: 0.12px;
              "
            >
              <img
                src="../../assets/icon-s/shop.png"
                alt="Store Icon"
                style="width: 22px; height: 16px; margin-right: 4px"
              />&nbsp;{{ store.store.name }}</span
            >
          </div>
          <hr />
          <div
            class="mt-3 item-mobile"
            v-for="(product, productIndex) in store.products"
            :key="productIndex"
          >
            <input
              type="checkbox"
              v-model="product.isSelected"
              @change="checkListProduct(product.cartId + ':cart')"
              :id="'cartProduk' + product.cartId"
            />
            <img
              :src="product.image"
              :alt="product.name"
              class="item-image-mobile"
            />
            <div class="ml-1 item-details-mobile">
              <h4 class="text-produk">{{ product.name }}</h4>
              <b class="original-price-mobile">
                <strike :class="product.isDiscount ? `` : `d-none`">
                  <small> {{ product.priceCurrencyFormat }} </small>
                </strike>
              </b>

              <p class="mt-2 price-mobile">
                {{
                  product.isDiscount
                    ? product.priceDiscountCurrencyFormat
                    : product.priceCurrencyFormat
                }}
                &nbsp; &nbsp;
                <a
                  style="color: #fa1919; cursor: pointer"
                  @click="hapusCart(a.cartId)"
                >
                  <i class="fas fa-trash-alt"></i
                ></a>
              </p>
            </div>
            <div class="ml-2 quantity-controls-mobile">
              <span class="btn-mobile" @click="updateQuantity(product, 'less')"
                >-</span
              >
              <input
                type="number"
                min="1"
                class="count-mobile"
                v-model="product.qty"
                readonly
              />
              <span class="btn-mobile" @click="updateQuantity(product, 'add')"
                >+</span
              >
            </div>
          </div>
        </div>
        <div
          style="
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 1000;
            padding: 12px 10px 8px 10px;
            gap: 8px;
            border-radius: 16px 16px 0px 0px;
            background: var(--Neutral-10, #fff);
            box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.04);
          "
        >
          <div class="container-m d-flex justify-content-between">
            <div>
              <p
                class="text-left"
                style="
                  color: var(--Neutral-50, #757575);
                  font-family: Poppins;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 16px; /* 160% */
                  letter-spacing: -0.1px;
                "
              >
                Total
              </p>
              <p
                class="text-left"
                style="
                  color: var(--Neutral-60, #1a1a1a);
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 19px;
                  letter-spacing: 0.28px;
                "
              >
                {{ dataCart.totalCartCurrencyFormat }}
              </p>
            </div>
            <div></div>
            <div>
              <button
                @click="cekIsHasAddress(dataCart.isHasAddress)"
                class="mt-3 checkout-button-mobile"
              >
                <span>Bayar Pesanan</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dataCart: {},
      page: 1,
      perPage: 5,
      recordsLength: 0,
      pageSizes: [12, 24, 32],
      isFinish: false,
      isLoading: true,
      isLoadingDelete: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.isLoading = false;
    }, 3000); // 3 detik
  },
  props: {
    a: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    truncatedName() {
      return (name) => {
        const maxLength = 20; // Batasan panjang teks
        return name.length > maxLength
          ? name.substring(0, maxLength) + "..."
          : name;
      };
    },
  },
  created() {
    this.getDataCart();
  },
  methods: {
    getDataCart: function (page) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}cart?page=${this.page}&limit=${this.perPage}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            window.scrollTo(0, 0);
            this.dataCart = res.data;
            this.recordsLength = res.data.totalData;
          }
          this.isFinish = true;
        })
        .catch((error) => console.log("error", error));
    },
    handlePageSizeChange(event) {
      this.perPage = event.target.value;
      this.page = 1;
      this.getDataCart(this.page);
    },
    addQty(params) {
      const arr = params.split(":");
      const productId = arr[0];
      const variantId = arr[1];
      const qty = arr[2];
      const action = arr[3];

      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        productId: productId,
        variantId: variantId,
        qty: qty,
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}cart/qty?action=${action}`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.getDataCart();
            this.$swal
              // .fire(
              //   "Berhasil",
              //   `Cart berhasil di ${action == "add" ? `tambahkan` : `dikurangi`}`,
              //   "success"
              // )
              .then(() => {
                this.getDataCart();
              });
          } else {
            this.$swal.fire("Warning", res.status.message, "warning");
          }
        })
        .catch((error) => console.log("error", error));
    },
    hapusCart(id) {
      this.$swal
        .fire({
          title: "Warning",
          text: "Apakah anda yakin akan menghapus data?",
          icon: "warning",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Hapus",
          denyButtonText: `Batalkan`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.runDeleteCart(id);
          }
        });
    },

    runDeleteCart(id) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);

      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}cart/${id}`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            // Tampilkan overlay loading
            this.isLoading = true;
            this.$swal
              .fire({
                title: "Sukses",
                text: "Berhasil menghapus data",
                icon: "success",
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: true,
              })
              .then(() => {
                // Setelah popup swal ditutup, panggil metode untuk memperbarui data keranjang
                // dan sembunyikan overlay setelah 2 detik
                this.getDataCart();
                this.$router.go(0);
                setTimeout(() => {
                  this.isLoading = false;
                }, 2000);
              });
          } else {
            this.$swal.fire("Gagal", `${res.status.message}`, "warning");
          }
        })
        .catch((error) => console.log("error", error));
    },

    checkListProduct(data) {
      const isi = data.split(":");
      const params =
        isi[1] == "cart"
          ? {
              cartId: isi[0],
              storeId: "",
            }
          : {
              cartId: "",
              storeId: isi[0],
            };

      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(params);

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}cart/selected`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.getDataCart();
          }
        })
        .catch((error) => console.log("error", error));
    },
    cekIsHasAddress(isHasAddress) {
      if (!isHasAddress) {
        this.$swal
          .fire({
            title: "Alamat Kosong",
            text: "Silahkan isi alamat terlebih dahulu",
            icon: "warning",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Isi Alamat",
            denyButtonText: "Batalkan",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$router.push("/tambahalamat");
            }
          });
        return;
      }

      // Cek apakah produk berasal dari toko yang berbeda
      const selectedStores = new Set();
      this.dataCart.items.forEach((item) => {
        if (item.isSelected) {
          selectedStores.add(item.store.id);
        }
      });

      if (selectedStores.size > 1) {
        this.$swal.fire(
          "Produk dari Toko Berbeda",
          `Anda tidak bisa melanjutkan pembayaran jika produk berasal dari toko yang berbeda.`,
          "warning"
        );
      } else {
        this.pindahHalaman();
      }
    },
    // cekIsHasAddress(isHasAddress) {
    //   isHasAddress
    //     ? this.cekChooseProduct()
    //     : this.$swal
    //         .fire({
    //           title: "Alamat Kosong",
    //           text: "Silahkan isi alamat terlebih dahulu",
    //           icon: "warning",
    //           showDenyButton: true,
    //           showCancelButton: false,
    //           confirmButtonText: "Isi Alamat",
    //           denyButtonText: `Batalkan`,
    //         })
    //         .then((result) => {
    //           if (result.isConfirmed) {
    //             this.$router.push("/tambahalamat");
    //           }
    //         });
    // },
    cekChooseProduct() {
      this.dataCart.totalProductSelected
        ? this.pindahHalaman()
        : this.$swal.fire(
            "Produk Belum Dipilih",
            `Silahkan pilih produk terlebih dahulu`,
            "warning"
          );
    },
    pindahHalaman() {
      // this.$router.push("/payment");
      this.$router.push("/checkout");
    },
    belanjaLagi() {
      this.$router.push("/");
    },
    ...mapActions({
      getShipping: "getShipping",
    }),
  },
};
</script>
<style>
.count {
  width: 50%;
  height: 100%;
}

/* css mobile */
.container-m {
  padding: 10px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-gif {
  width: 300px; /* Atur ukuran gif loading sesuai kebutuhan */
  height: auto;
}
.shopping-cart-mobile {
  padding: 20px;
}
.store-mobile {
  margin-bottom: 16px;
}
.store-mobile h3 {
  margin-bottom: 8px;
}
.item-mobile {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px;
  /* border: 1px solid #e0e0e0; */
  border-radius: 8px;
}
.item-mobile input[type="checkbox"] {
  margin-right: 16px;
}
.item-image-mobile {
  width: 60px;
  height: 60px;
  margin-right: 8px;
}
.item-details-mobile {
  flex-grow: 1;
  text-align: left;
}
.item-details-mobile h4 {
  margin: 0 0 4px 0;
  font-size: 14px;
}
.original-price-mobile {
  text-decoration: line-through;
  color: #fa1919;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.08px;
}
.price-mobile {
  color: var(--Neutral-60, #1a1a1a);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 160% */
  letter-spacing: -0.1px;
}
.quantity-controls-mobile {
  display: flex;
  padding: 3px 5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--Primary-20, #c1efff);
  background: #fff;
}
.btn-mobile {
  border-radius: 2px;
  background: var(--Primary-20, #c1efff);
  display: flex;
  width: 12px;
  height: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
}
.count-mobile {
  width: 20px;
  text-align: center;
  background-color: #f8f9fa;
  border: none;
  border-radius: 10px;
  margin: 0 5px;
  font-size: 10px;
}
.total-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ccc;
}
.checkout-button-mobile {
  width: 128px;
  padding: 12px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--Primary-40, #65b0c9);
  border: none;
  color: white;
  font-size: 11px;
}
.text-produk {
  color: #000;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 160% */
  letter-spacing: -0.1px;
}
.harga-diskonn {
  color: #fa1919;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 175% */
  letter-spacing: 0.08px;
}
.harga-normal {
  color: var(--Neutral-60, #1a1a1a);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 160% */
  letter-spacing: -0.1px;
}
.baru {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
</style>