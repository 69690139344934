<template>
  <div>
    <!-- POPUP -->
    <!-- <div class="popup" v-if="showPopup" @click="closePopupOutside">
      <button class="close" @click="closePopup" style="color: white">
        <i class="fas fa-times"></i>
      </button>
      <img
        :src="require('@/assets/popup2.png')"
        alt="Popup Image"
        @click="openNewTab('https://auto.jaja.id/#/')"
      />
    </div> -->

    <Banner />
    <ProdukTerlaris />
  </div>
</template>

<!-- ... (script and style sections remain the same) -->

<script>
import ProdukTerlaris from "../../components/homecomponents/ProdukTerlaris";
import Banner from "../../components/homecomponents/Banner";
export default {
  data() {
    return {
      showPopup: true,
    };
  },
  components: {
    ProdukTerlaris,
    Banner,
  },
  computed: {
    homeGetters() {
      return this.$store.getters.homeGetters;
    },
  },
  mounted() {
    setTimeout(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, 500);

    // Menambahkan event listener untuk menutup popup saat mengklik di luar area gambar
    document.addEventListener("click", this.handleOutsideClick);
  },
  methods: {
    openNewTab(url) {
      window.open(url, "_blank");
    },
    closePopup() {
      this.showPopup = false;
    },
    closePopupOutside(event) {
      // Jangan tutup popup jika mengklik pada gambar
      if (event.target.tagName !== "IMG") {
        this.closePopup();
      }
    },
    handleOutsideClick(event) {
      // Tutup popup jika mengklik di luar area popup
      if (!this.$el.contains(event.target)) {
        this.closePopup();
      }
    },
  },
  beforeDestroy() {
    // Hapus event listener sebelum komponen dihancurkan
    document.removeEventListener("click", this.handleOutsideClick);
  },
};
</script>

<style>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 50px;
  color: white;
}

.popup img {
  max-width: 100%;
  max-height: 80%;
}
</style>
