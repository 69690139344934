<template>
  <div>
    <div class="d-none d-lg-block">
      <div class="container p-3" style="margin-top: -2%">
        <div class="row">
          <div class="col-md-3">
            <sideBar />
          </div>
          <div class="col-md-9" v-if="isFinish">
            <div class="card-product br-card-jaja shadow-custom">
              <div class="card-header br-card-header-jaja">
                <div class="d-flex justify-content-between bd-highlight">
                  <div class="pt-2 bd-highlight mr-auto">
                    <h5 class="d-flex font-weight-bold text-dark">Profil</h5>
                    <small class="text-dark">Lengkapi profil kamu</small>
                  </div>
                  <div class="bd-highlight">
                    <button class="btn btn-success font-weight-bold">
                      Terverifikasi
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body br-card-footer-jaja">
                <div class="">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group row">
                        <label
                          for="staticEmail"
                          class="col-sm-2 col-form-label text-left"
                          >Nama *</label
                        >
                        <div class="col-sm-6">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Masukan nama depan anda"
                            v-model="reqUpdateProfil.firstname"
                          />
                        </div>
                        <div class="col-sm-4">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Masukan nama belakang anda"
                            v-model="reqUpdateProfil.lastname"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                          >Nomer HP *</label
                        >
                        <div class="col-sm-10">
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Masukan nomer telepon anda"
                            v-model="reqUpdateProfil.phoneNumber"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                          >Email *</label
                        >
                        <div class="col-sm-10">
                          <input
                            type="email"
                            class="form-control"
                            placeholder="Email"
                            v-model="reqUpdateProfil.email"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                          >Jenis Kelamin *</label
                        >
                        <div
                          class="d-flex justify-content-between bd-highlight ml-2 mr-4"
                        >
                          <div class="p-2 bd-highlight">
                            <div class="form-check">
                              <input
                                @change="chooseGender"
                                class="form-check-input"
                                type="radio"
                                name="gender"
                                id="flexRadioDefault1"
                                :checked="userComp.gender === 'pria'"
                                value="pria"
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault1"
                              >
                                Pria
                              </label>
                            </div>
                          </div>
                          <div class="p-2 bd-highlight">
                            <div class="form-check">
                              <input
                                @change="chooseGender"
                                class="form-check-input"
                                type="radio"
                                name="gender"
                                id="flexRadioDefault2"
                                :checked="userComp.gender === 'wanita'"
                                value="wanita"
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault2"
                              >
                                Wanita
                              </label>
                            </div>
                          </div>
                          <input type="hidden" v-model="userComp.gender" />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                          >Tanggal Lahir *</label
                        >
                        <div class="col-sm-10">
                          <date-picker
                            :lang="lang"
                            v-model="reqUpdateProfil.birthDate"
                            valueType="format"
                            format="DD-MM-YYYY"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="form-group row mb-0">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                        ></label>
                        <div class="col-sm-10 text-left">
                          <button
                            @click="updateProfil"
                            class="btn bg-jaja text-white font-weight-bold"
                          >
                            Simpan
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-9" v-if="!isFinish">
            <div class="card-product br-card-jaja shadow-custom">
              <div class="card-header br-card-header-jaja">
                <div class="d-flex justify-content-between bd-highlight">
                  <div class="pt-2 bd-highlight mr-auto">
                    <h5 class="d-flex font-weight-bold text-white">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="1"
                          style="width: 200px"
                        />
                      </content-placeholders>
                    </h5>
                  </div>
                  <div class="bd-highlight">
                    <content-placeholders :rounded="true">
                      <content-placeholders-img
                        style="width: 200px; height: 30px"
                      />
                    </content-placeholders>
                  </div>
                </div>
              </div>
              <div class="card-body br-card-footer-jaja">
                <div class="">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group row">
                        <label
                          for="staticEmail"
                          class="col-sm-2 col-form-label text-left"
                        >
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 100px"
                            />
                          </content-placeholders>
                        </label>
                        <div class="col-sm-6">
                          <content-placeholders :rounded="true">
                            <content-placeholders-img
                              style="width: 100%; height: 30px"
                            />
                          </content-placeholders>
                        </div>
                        <div class="col-sm-4">
                          <content-placeholders :rounded="true">
                            <content-placeholders-img
                              style="width: 100%; height: 30px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                        >
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 120px"
                            />
                          </content-placeholders>
                        </label>
                        <div class="col-sm-10">
                          <content-placeholders :rounded="true">
                            <content-placeholders-img
                              style="width: 100%; height: 30px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                        >
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 100px"
                            />
                          </content-placeholders>
                        </label>
                        <div class="col-sm-10">
                          <content-placeholders :rounded="true">
                            <content-placeholders-img
                              style="width: 100%; height: 30px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                        >
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 120px"
                            />
                          </content-placeholders>
                        </label>
                        <div class="col-sm-10">
                          <content-placeholders :rounded="true">
                            <content-placeholders-img
                              style="width: 100%; height: 30px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                        >
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 110px"
                            />
                          </content-placeholders>
                        </label>
                        <div class="col-sm-10">
                          <content-placeholders :rounded="true">
                            <content-placeholders-img
                              style="width: 100%; height: 30px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                      <div class="form-group row mb-0">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                        ></label>
                        <div class="col-sm-10 text-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-img
                              style="width: 20%; height: 30px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-lg-none" >
      <div class="container-m p-3" style="margin-top: -177px">
        <div class="row">
          <div class="col-md-12" v-if="isFinish">
            <div class="mt-5 card-product br-card-jaja shadow-custom">
              <div class="card-header br-card-header-jaja">
                <div class="d-flex justify-content-between bd-highlight">
                  <div class="pt-2 bd-highlight mr-auto">
                    <h5 class="d-flex font-weight-bold text-dark">Profil</h5>
                    <small class="text-dark">Lengkapi profil kamu</small>
                  </div>
                  <div class="bd-highlight">
                    <button class="btn btn-success font-weight-bold">
                      Terverifikasi
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body br-card-footer-jaja">
                <div class="">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group row">
                        <label
                          for="staticEmail"
                          class="col-sm-2 col-form-label text-left"
                          >Nama Depan*</label
                        >
                        <div class="col-sm-12">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Masukan nama depan anda"
                            v-model="reqUpdateProfil.firstname"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-2 col-form-label text-left"
                          >Nomer Belakang *</label
                        >
                        <div class="col-sm-10">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Masukan nama belakang anda"
                            v-model="reqUpdateProfil.lastname"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                          >Nomer HP *</label
                        >
                        <div class="col-sm-10">
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Masukan nomer telepon anda"
                            v-model="reqUpdateProfil.phoneNumber"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                          >Email *</label
                        >
                        <div class="col-sm-10">
                          <input
                            type="email"
                            class="form-control"
                            placeholder="Email"
                            v-model="reqUpdateProfil.email"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                          >Jenis Kelamin *</label
                        >
                        <div
                          class="d-flex justify-content-between bd-highlight ml-2 mr-4"
                        >
                          <div class="p-2 bd-highlight">
                            <div class="form-check">
                              <input
                                @change="chooseGender"
                                class="form-check-input"
                                type="radio"
                                name="gender"
                                id="flexRadioDefault1"
                                :checked="userComp.gender === 'pria'"
                                value="pria"
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault1"
                              >
                                Pria
                              </label>
                            </div>
                          </div>
                          <div class="p-2 bd-highlight">
                            <div class="form-check">
                              <input
                                @change="chooseGender"
                                class="form-check-input"
                                type="radio"
                                name="gender"
                                id="flexRadioDefault2"
                                :checked="userComp.gender === 'wanita'"
                                value="wanita"
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault2"
                              >
                                Wanita
                              </label>
                            </div>
                          </div>
                          <input type="hidden" v-model="userComp.gender" />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                          >Tanggal Lahir *</label
                        >
                        <div class="col-sm-10">
                          <date-picker
                            :lang="lang"
                            v-model="reqUpdateProfil.birthDate"
                            valueType="format"
                            format="DD-MM-YYYY"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="form-group row mb-0">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                        ></label>
                        <div class="col-sm-10 text-left">
                          <button
                            @click="updateProfil"
                            class="btn bg-jaja text-white font-weight-bold"
                          >
                            Simpan
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-9" v-if="!isFinish">
            <div class="card-product br-card-jaja shadow-custom">
              <div class="card-header br-card-header-jaja">
                <div class="d-flex justify-content-between bd-highlight">
                  <div class="pt-2 bd-highlight mr-auto">
                    <h5 class="d-flex font-weight-bold text-white">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="1"
                          style="width: 200px"
                        />
                      </content-placeholders>
                    </h5>
                  </div>
                  <div class="bd-highlight">
                    <content-placeholders :rounded="true">
                      <content-placeholders-img
                        style="width: 200px; height: 30px"
                      />
                    </content-placeholders>
                  </div>
                </div>
              </div>
              <div class="card-body br-card-footer-jaja">
                <div class="">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group row">
                        <label
                          for="staticEmail"
                          class="col-sm-2 col-form-label text-left"
                        >
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 100px"
                            />
                          </content-placeholders>
                        </label>
                        <div class="col-sm-6">
                          <content-placeholders :rounded="true">
                            <content-placeholders-img
                              style="width: 100%; height: 30px"
                            />
                          </content-placeholders>
                        </div>
                        <div class="col-sm-4">
                          <content-placeholders :rounded="true">
                            <content-placeholders-img
                              style="width: 100%; height: 30px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                        >
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 120px"
                            />
                          </content-placeholders>
                        </label>
                        <div class="col-sm-10">
                          <content-placeholders :rounded="true">
                            <content-placeholders-img
                              style="width: 100%; height: 30px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                        >
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 100px"
                            />
                          </content-placeholders>
                        </label>
                        <div class="col-sm-10">
                          <content-placeholders :rounded="true">
                            <content-placeholders-img
                              style="width: 100%; height: 30px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                        >
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 120px"
                            />
                          </content-placeholders>
                        </label>
                        <div class="col-sm-10">
                          <content-placeholders :rounded="true">
                            <content-placeholders-img
                              style="width: 100%; height: 30px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                        >
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 110px"
                            />
                          </content-placeholders>
                        </label>
                        <div class="col-sm-10">
                          <content-placeholders :rounded="true">
                            <content-placeholders-img
                              style="width: 100%; height: 30px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                      <div class="form-group row mb-0">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label text-left"
                        ></label>
                        <div class="col-sm-10 text-left">
                          <content-placeholders :rounded="true">
                            <content-placeholders-img
                              style="width: 20%; height: 30px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <br />
        <NavBar />
      </div>
    </div>
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
<script type="text/javascript" src="https://app.midtrans.com/snap/snap.js" data-client-key="Mid-client-S9f6KNmuVrQre0GQ"></script>
<script>
import { mapGetters } from "vuex";

import VueBase64FileUpload from "vue-base64-file-upload";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
// import 'vue2-datepicker/locale/zh-cn';
import sideBar from "../../components/sidebar/sideBar";
import NavBar from "../../components/master/navbarbuttom.vue";
export default {
  data() {
    return {
      customImageMaxSize: 3,
      tanggal: null,
      reqUpdateProfil: {
        firstname: "",
        lastname: "",
        fullname: "",
        phoneNumber: null,
        email: "",
        gender: "",
        birthDate: "",
        photo: "",
      },
      dataProfil: {},
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      isFinish: false,
    };
  },
  components: {
    sideBar,
    DatePicker,
    VueBase64FileUpload,
    NavBar,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  created() {
    this.submitProfil(), this.getUser();
  },
  methods: {
    getUser() {
      if (this.user.uid) {
        this.userComp = this.user;
        this.isFinish = true;
      } else {
        this.isFinish = false;
      }
    },
    modalUpdate() {
      $("#modalUpdate").modal("show");
    },
    submitProfil() {
      let myStr = this.user.name;
      this.reqUpdateProfil.firstname = myStr.split(" ")[0];
      this.reqUpdateProfil.lastname = myStr.substring(myStr.indexOf(" "));

      this.reqUpdateProfil.phoneNumber = this.user.phoneNumber;
      this.reqUpdateProfil.email = this.user.email;
      this.reqUpdateProfil.gender = this.user.gender;
      this.reqUpdateProfil.birthDate = this.user.birthDate;
    },
    chooseGender() {
      this.user.gender = event.target.value;
      // console.log(event.target.value)
    },
    updateProfil() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=t3aj7ofie0camk7k1v2d6s8llbr7q3kb");
      this.reqUpdateProfil.fullname =
        this.reqUpdateProfil.firstname + " " + this.reqUpdateProfil.lastname;
      // let raw = JSON.stringify(this.reqUpdateProfil);
      var raw = JSON.stringify({
        name: this.reqUpdateProfil.fullname,
        phoneNumber: this.reqUpdateProfil.phoneNumber,
        email: this.user.email,
        gender: this.reqUpdateProfil.gender,
        birthDate: this.reqUpdateProfil.birthDate,
      });

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/profile`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          this.$swal.fire("Sukses", res.status.message, "success");
          this.$store.dispatch("get_user");

          // if(res.status.code === 200) {

          //     this.$swal.fire({
          //         title: 'Apakah anda Akan Logout ?',
          //         showDenyButton: true,
          //         showCancelButton: false,
          //         confirmButtonText: 'Logout',
          //         denyButtonText: `Batalkan`,
          //         }).then((result) => {
          //         /* Read more about isConfirmed, isDenied below */
          //         if (result.isConfirmed) {
          //         this.$swal.fire('Sukses', res.status.message, 'success')
          //         this.$store.dispatch('get_user')
          //         } else if (result.isDenied) {
          //             this.$swal.fire('Cancel', 'Anda tidak Logout', 'warning')
          //         }
          //     })
          // }
        })
        .catch((error) => console.log("error", error));
    },
    onFile(file) {
      console.log(file); // file object
    },

    onLoad(dataUri) {
      console.log(dataUri); // data-uri string
    },

    onSizeExceeded(size) {
      alert(
        `Image ${size}Mb size exceeds limits of ${this.customImageMaxSize}Mb!`
      );
    },
    inputProfil() {
      event.preventDefault();
      alert("ok");
    },
  },
};
</script>
<style lang="scss" scoped>
.iconPayment {
  width: 50%;
  margin-right: 5%;
}
.mx-datepicker {
  width: 100%;
}
.card-header-profil {
  background: rgb(100, 175, 200);
  border-radius: 10px 10px 0 0;
}

.kotak-profil-1 {
  margin-right: 15%;
}
.bg-jaja {
  background: rgb(100, 175, 200);
}
.container-m {
  padding: 10px;
}
</style>