<template>
  <div>
    <div>
      <div>
        <template v-if="resResultAllOrder.isActiveStatus === 'notPaid'">
          <div v-if="resResultAllOrder.data.totalData">
            <div
              v-for="(item, index) in resResultAllOrder.data.items"
              :key="index"
              class="card-body tab-content p-3 container"
              style="margin-top: -20px !important"
            >
              <router-link :to="'/order/' + item.orderId">
                <div class="tab-pane show active">
                  <div class="row bodyHtml container">
                    <div class="card-product shadow-custom mt-3 col-md-12">
                      <div class="d-flex justify-content-between bd-highlight">
                        <div class="p-2 bd-highlight">
                          <small class="text-primary-jaja">{{
                            item.createdAt
                          }}</small>
                          <small class="ml-2 text-danger">
                            <i class="fas fa-clock"></i> {{ item.expiredAt }}
                          </small>
                        </div>
                        <div class="p-2 bd-highlight">
                          <span
                            class="text-primary-jaja text-bold float-right sml"
                            >BELUM DIBAYAR</span
                          >
                        </div>
                      </div>
                      <div v-for="(a, b) in item.items" :key="b">
                        <div class="d-flex justify-content-start bd-highlight">
                          <div class="p-2 bd-highlight">
                            <i class="fas fa-store text-secondary-jaja"></i>
                            &nbsp;
                            <b class="nml text-primary-jaja">
                              {{ a.store.name }}</b
                            >
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-8">
                            <div
                              class="d-flex justify-content-start bd-highlight"
                              style="margin-left: 30px"
                            >
                              <div class="p-2 bd-highlight">
                                <img
                                  :src="a.products[0].image"
                                  class="img-thumbnail img-fluid"
                                  style="
                                    width: 100px;
                                    max-width: 100%;
                                    height: auto;
                                    max-height: 100%;
                                  "
                                  alt=""
                                />
                              </div>
                              <div class="p-2 bd-highlight">
                                <div class="text-left">
                                  <a class="text-dark">
                                    <span class="mb-0"
                                      ><b> {{ a.products[0].name }} </b></span
                                    >
                                  </a>
                                  <br />
                                  <span class="text-dark">
                                    {{ a.products[0].qty }} X
                                  </span>
                                  <span class="text-primary-jaja"
                                    ><b>{{
                                      a.products[0].isDiscount
                                        ? a.products[0]
                                            .priceDiscountCurrencyFormat
                                        : a.products[0].priceCurrencyFormat
                                    }}</b></span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div
                              class="d-flex justify-content-end bd-highlight"
                            >
                              <div class="p-2 bd-highlight mt-4">
                                <span class="text-primary-jaja"
                                  ><b>{{
                                    a.products[0].subTotalCurrencyFormat
                                  }}</b></span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                      <div
                        class="d-flex justify-content-between bd-highlight mb-3"
                      >
                        <div class="p-2 bd-highlight">
                          <button
                            class="stardust-white cslight btn btn-warning-custom"
                          >
                            <b>Bayar Sekarang</b>
                          </button>
                        </div>
                        <div class="p-2 bd-highlight">
                          <span class="text-dark">Total :&nbsp;</span>
                          &nbsp;
                          <b class="text-primary-jaja">{{
                            item.totalPriceCurrencyFormat
                          }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div v-else>
            <div
              class="card-body tab-content p-3 container"
              style="margin-top: -20px !important"
            >
              <div class="tab-pane show active">
                <div class="row bodyHtml container">
                  <div class="card-product shadow-custom mt-3 col-md-12">
                    <div class="row">
                      <div class="col-md-12 col-12 carousel-cell is-selected">
                        <div>
                          <div class="pb-3">
                            <div></div>
                            <img
                              slot="image"
                              src="https://nimda.jaja.id/asset/icon-jaja/notfound.png"
                              class="img-fluid"
                              style="
                                max-height: 400px;
                                max-width: 100%;
                                width: auto;
                                border-radius: 10px;
                              "
                              alt=""
                            />

                            <div class="text-dark">
                              <div class="keterangan ResetKet_JajaID">
                                <h4
                                  class="text-product one-line fsxx text-center"
                                  style="margin-top: -20px"
                                >
                                  Looks like the data you are looking for is
                                  empty.
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template
          v-else-if="resResultAllOrder.isActiveStatus === 'waitConfirm'"
        >
          <div v-if="resResultAllOrder.data.totalData">
            <div
              v-for="(item, index) in resResultAllOrder.data.items"
              :key="index"
              class="card-body tab-content p-3 container"
              style="margin-top: -20px !important"
            >
              <router-link :to="'/order/' + item.invoice">
                <div class="tab-pane show active">
                  <div class="row bodyHtml container">
                    <div class="card-product shadow-custom mt-3 col-md-12">
                      <div class="d-flex justify-content-between bd-highlight">
                        <div class="p-2 bd-highlight">
                          <small class="text-primary-jaja">{{
                            item.createdAt
                          }}</small>
                          <small class="ml-2 text-danger">
                            <i class="fas fa-clock"></i>
                            {{ item.expiredAt }}
                          </small>
                        </div>
                        <div class="p-2 bd-highlight">
                          <span
                            class="text-primary-jaja text-bold float-right sml"
                            >MENUNGGU KONFIRMASI</span
                          >
                        </div>
                      </div>
                      <div class="d-flex justify-content-start bd-highlight">
                        <div class="p-2 bd-highlight">
                          <i class="fas fa-store text-secondary-jaja"></i>
                          <b class="nml text-primary-jaja">
                            {{ item.store.name }}</b
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div
                            class="d-flex justify-content-start bd-highlight"
                            style="margin-left: 30px"
                          >
                            <div class="p-2 bd-highlight">
                              <img
                                :src="item.products[0].image"
                                class="img-thumbnail img-fluid"
                                style="
                                  width: 100px;
                                  max-width: 100%;
                                  height: auto;
                                  max-height: 100%;
                                "
                                alt=""
                              />
                            </div>
                            <div class="p-2 bd-highlight">
                              <div class="text-left">
                                <a class="text-dark">
                                  <span class="mb-0"
                                    ><b>{{ item.products[0].name }}</b>
                                  </span>
                                </a>
                                <br />
                                <span class="text-dark">
                                  {{ item.products[0].qty }} X
                                </span>
                                <span class="text-primary-jaja"
                                  ><b>
                                    {{
                                      item.products[0].isDiscount
                                        ? item.products[0]
                                            .priceDiscountCurrencyFormat
                                        : item.products[0].priceCurrencyFormat
                                    }}</b
                                  ></span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="d-flex justify-content-end bd-highlight">
                            <div class="p-2 bd-highlight mt-4">
                              <span class="text-primary-jaja"
                                ><b>{{
                                  item.products[0].subTotalCurrencyFormat
                                }}</b></span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div
                        class="d-flex justify-content-between bd-highlight mb-3"
                      >
                        <div class="p-2 bd-highlight">
                          <button
                            class="stardust-white cslight btn btn-warning-custom"
                          >
                            <b>Menunggu Konfirmasi</b>
                          </button>
                        </div>
                        <div class="p-2 bd-highlight">
                          <span class="text-dark">Total :&nbsp;</span>
                          <b class="text-primary-jaja">{{
                            item.totalPriceCurrencyFormat
                          }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div v-else>
            <div
              class="card-body tab-content p-3 container"
              style="margin-top: -20px !important"
            >
              <div class="tab-pane show active">
                <div class="row bodyHtml container">
                  <div class="card-product shadow-custom mt-3 col-md-12">
                    <div class="row">
                      <div class="col-md-12 col-12 carousel-cell is-selected">
                        <div>
                          <div class="pb-3">
                            <div></div>
                            <img
                              slot="image"
                              src="https://nimda.jaja.id/asset/icon-jaja/notfound.png"
                              class="img-fluid"
                              style="
                                max-height: 400px;
                                max-width: 100%;
                                width: auto;
                                border-radius: 10px;
                              "
                              alt=""
                            />

                            <div class="text-dark">
                              <div class="keterangan ResetKet_JajaID">
                                <h4
                                  class="text-product one-line fsxx text-center"
                                  style="margin-top: -20px"
                                >
                                  Looks like the data you are looking for is
                                  empty.
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="resResultAllOrder.isActiveStatus === 'prepared'">
          <div v-if="resResultAllOrder.data.totalData">
            <div
              v-for="(item, index) in resResultAllOrder.data.items"
              :key="index"
              class="card-body tab-content p-3 container"
              style="margin-top: -20px !important"
            >
              <router-link :to="'/order/' + item.invoice">
                <div class="tab-pane show active">
                  <div class="row bodyHtml container">
                    <div class="card-product shadow-custom mt-3 col-md-12">
                      <div class="d-flex justify-content-between bd-highlight">
                        <div class="p-2 bd-highlight">
                          <small class="text-primary-jaja">{{
                            item.createdAt
                          }}</small>
                          <small class="ml-2 text-danger">
                            <i class="fas fa-clock"></i>
                            {{ item.expiredAt }}
                          </small>
                        </div>
                        <div class="p-2 bd-highlight">
                          <span
                            class="text-primary-jaja text-bold float-right sml"
                            >DISIAPKAN</span
                          >
                        </div>
                      </div>
                      <div class="d-flex justify-content-start bd-highlight">
                        <div class="p-2 bd-highlight">
                          <i class="fas fa-store text-secondary-jaja"></i>
                          <b class="nml text-primary-jaja">
                            {{ item.store.name }}</b
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div
                            class="d-flex justify-content-start bd-highlight"
                            style="margin-left: 30px"
                          >
                            <div class="p-2 bd-highlight">
                              <img
                                :src="item.products[0].image"
                                class="img-thumbnail img-fluid"
                                style="
                                  width: 100px;
                                  max-width: 100%;
                                  height: auto;
                                  max-height: 100%;
                                "
                                alt=""
                              />
                            </div>
                            <div class="p-2 bd-highlight">
                              <div class="text-left">
                                <a class="text-dark">
                                  <span class="mb-0"
                                    ><b>{{ item.products[0].name }}</b>
                                  </span>
                                </a>
                                <br />
                                <span class="text-dark">
                                  {{ item.products[0].qty }} X
                                </span>
                                <span class="text-primary-jaja"
                                  ><b>
                                    {{
                                      item.products[0].isDiscount
                                        ? item.products[0]
                                            .priceDiscountCurrencyFormat
                                        : item.products[0].priceCurrencyFormat
                                    }}</b
                                  ></span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="d-flex justify-content-end bd-highlight">
                            <div class="p-2 bd-highlight mt-4">
                              <span class="text-primary-jaja"
                                ><b>{{
                                  item.products[0].subTotalCurrencyFormat
                                }}</b></span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div
                        class="d-flex justify-content-between bd-highlight mb-3"
                      >
                        <div class="p-2 bd-highlight">
                          <button
                            class="stardust-white cslight btn btn-warning-custom"
                          >
                            <b>Disiapkan</b>
                          </button>
                        </div>
                        <div class="p-2 bd-highlight">
                          <span class="text-dark">Total :&nbsp;</span>
                          <b class="text-primary-jaja">{{
                            item.totalPriceCurrencyFormat
                          }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div v-else>
            <div
              class="card-body tab-content p-3 container"
              style="margin-top: -20px !important"
            >
              <div class="tab-pane show active">
                <div class="row bodyHtml container">
                  <div class="card-product shadow-custom mt-3 col-md-12">
                    <div class="row">
                      <div class="col-md-12 col-12 carousel-cell is-selected">
                        <div>
                          <div class="pb-3">
                            <div></div>
                            <img
                              slot="image"
                              src="https://nimda.jaja.id/asset/icon-jaja/notfound.png"
                              class="img-fluid"
                              style="
                                max-height: 400px;
                                max-width: 100%;
                                width: auto;
                                border-radius: 10px;
                              "
                              alt=""
                            />

                            <div class="text-dark">
                              <div class="keterangan ResetKet_JajaID">
                                <h4
                                  class="text-product one-line fsxx text-center"
                                  style="margin-top: -20px"
                                >
                                  Looks like the data you are looking for is
                                  empty.
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="resResultAllOrder.isActiveStatus === 'sent'">
          <div v-if="resResultAllOrder.data.totalData">
            <div
              v-for="(item, index) in resResultAllOrder.data.items"
              :key="index"
              class="card-body tab-content p-3 container"
              style="margin-top: -20px !important"
            >
              <router-link :to="'/order/' + item.invoice">
                <div class="tab-pane show active">
                  <div class="row bodyHtml container">
                    <div class="card-product shadow-custom mt-3 col-md-12">
                      <div class="d-flex justify-content-between bd-highlight">
                        <div class="p-2 bd-highlight">
                          <small class="text-primary-jaja">{{
                            item.createdAt
                          }}</small>
                          <small class="ml-2 text-danger">
                            <i class="fas fa-clock"></i>
                            {{ item.expiredAt }}
                          </small>
                        </div>
                        <div class="p-2 bd-highlight">
                          <span
                            class="text-primary-jaja text-bold float-right sml"
                            >DIKIRIM</span
                          >
                        </div>
                      </div>
                      <div class="d-flex justify-content-start bd-highlight">
                        <div class="p-2 bd-highlight">
                          <i class="fas fa-store text-secondary-jaja"></i>
                          <b class="nml text-primary-jaja">
                            {{ item.store.name }}</b
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div
                            class="d-flex justify-content-start bd-highlight"
                            style="margin-left: 30px"
                          >
                            <div class="p-2 bd-highlight">
                              <img
                                :src="item.products[0].image"
                                class="img-thumbnail img-fluid"
                                style="
                                  width: 100px;
                                  max-width: 100%;
                                  height: auto;
                                  max-height: 100%;
                                "
                                alt=""
                              />
                            </div>
                            <div class="p-2 bd-highlight">
                              <div class="text-left">
                                <a class="text-dark">
                                  <span class="mb-0"
                                    ><b>{{ item.products[0].name }}</b>
                                  </span>
                                </a>
                                <br />
                                <span class="text-dark">
                                  {{ item.products[0].qty }} X
                                </span>
                                <span class="text-primary-jaja"
                                  ><b>
                                    {{
                                      item.products[0].isDiscount
                                        ? item.products[0]
                                            .priceDiscountCurrencyFormat
                                        : item.products[0].priceCurrencyFormat
                                    }}</b
                                  ></span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="d-flex justify-content-end bd-highlight">
                            <div class="p-2 bd-highlight mt-4">
                              <span class="text-primary-jaja"
                                ><b>{{
                                  item.products[0].subTotalCurrencyFormat
                                }}</b></span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div
                        class="d-flex justify-content-between bd-highlight mb-3"
                      >
                        <div class="p-2 bd-highlight">
                          <button
                            class="stardust-white cslight btn btn-warning-custom"
                          >
                            <b>Terima Pesanan</b>
                          </button>
                        </div>
                        <div class="p-2 bd-highlight">
                          <span class="text-dark">Total :&nbsp;</span>
                          <b class="text-primary-jaja">{{
                            item.totalPriceCurrencyFormat
                          }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div v-else>
            <div
              class="card-body tab-content p-3 container"
              style="margin-top: -20px !important"
            >
              <div class="tab-pane show active">
                <div class="row bodyHtml container">
                  <div class="card-product shadow-custom mt-3 col-md-12">
                    <div class="row">
                      <div class="col-md-12 col-12 carousel-cell is-selected">
                        <div>
                          <div class="pb-3">
                            <div></div>
                            <img
                              slot="image"
                              src="https://nimda.jaja.id/asset/icon-jaja/notfound.png"
                              class="img-fluid"
                              style="
                                max-height: 400px;
                                max-width: 100%;
                                width: auto;
                                border-radius: 10px;
                              "
                              alt=""
                            />

                            <div class="text-dark">
                              <div class="keterangan ResetKet_JajaID">
                                <h4
                                  class="text-product one-line fsxx text-center"
                                  style="margin-top: -20px"
                                >
                                  Looks like the data you are looking for is
                                  empty.
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="resResultAllOrder.isActiveStatus === 'done'">
          <div v-if="resResultAllOrder.data.totalData">
            <div
              v-for="(item, index) in resResultAllOrder.data.items"
              :key="index"
              class="card-body tab-content p-3 container"
              style="margin-top: -20px !important"
            >
              <router-link :to="'/order/' + item.invoice">
                <div class="tab-pane show active">
                  <div class="row bodyHtml container">
                    <div class="card-product shadow-custom mt-3 col-md-12">
                      <div class="d-flex justify-content-between bd-highlight">
                        <div class="p-2 bd-highlight">
                          <small class="text-primary-jaja">{{
                            item.createdAt
                          }}</small>
                          <small class="ml-2 text-danger">
                            <i class="fas fa-clock"></i>
                            {{ item.expiredAt }}
                          </small>
                        </div>
                        <div class="p-2 bd-highlight">
                          <span
                            class="text-primary-jaja text-bold float-right sml"
                            >SELESAI</span
                          >
                        </div>
                      </div>
                      <div class="d-flex justify-content-start bd-highlight">
                        <div class="p-2 bd-highlight">
                          <i class="fas fa-store text-secondary-jaja"></i>
                          <b class="nml text-primary-jaja">
                            {{ item.store.name }}</b
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div
                            class="d-flex justify-content-start bd-highlight"
                            style="margin-left: 30px"
                          >
                            <div class="p-2 bd-highlight">
                              <img
                                :src="item.products[0].image"
                                class="img-thumbnail img-fluid"
                                style="
                                  width: 100px;
                                  max-width: 100%;
                                  height: auto;
                                  max-height: 100%;
                                "
                                alt=""
                              />
                            </div>
                            <div class="p-2 bd-highlight">
                              <div class="text-left">
                                <a class="text-dark">
                                  <span class="mb-0"
                                    ><b>{{ item.products[0].name }}</b>
                                  </span>
                                </a>
                                <br />
                                <span class="text-dark">
                                  {{ item.products[0].qty }} X
                                </span>
                                <span class="text-primary-jaja"
                                  ><b>
                                    {{
                                      item.products[0].isDiscount
                                        ? item.products[0]
                                            .priceDiscountCurrencyFormat
                                        : item.products[0].priceCurrencyFormat
                                    }}</b
                                  ></span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="d-flex justify-content-end bd-highlight">
                            <div class="p-2 bd-highlight mt-4">
                              <span class="text-primary-jaja"
                                ><b>{{
                                  item.products[0].subTotalCurrencyFormat
                                }}</b></span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div
                        class="d-flex justify-content-between bd-highlight mb-3"
                      >
                        <div class="p-2 bd-highlight" v-if="item.isRating">
                          <button
                            class="stardust-white cslight btn btn-warning-custom"
                          >
                            <b>Beri Penilaian</b>
                          </button>
                        </div>
                        <div class="p-2 bd-highlight" v-if="!item.isRating">
                          <button
                            class="stardust-white cslight btn btn-warning-custom"
                          >
                            <b>Lihat Detail</b>
                          </button>
                        </div>
                        <div class="p-2 bd-highlight">
                          <span class="text-dark">Total :&nbsp;</span>
                          <b class="text-primary-jaja">{{
                            item.totalPriceCurrencyFormat
                          }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div v-else>
            <div
              class="card-body tab-content p-3 container"
              style="margin-top: -20px !important"
            >
              <div class="tab-pane show active">
                <div class="row bodyHtml container">
                  <div class="card-product shadow-custom mt-3 col-md-12">
                    <div class="row">
                      <div class="col-md-12 col-12 carousel-cell is-selected">
                        <div>
                          <div class="pb-3">
                            <div></div>
                            <img
                              slot="image"
                              src="https://nimda.jaja.id/asset/icon-jaja/notfound.png"
                              class="img-fluid"
                              style="
                                max-height: 400px;
                                max-width: 100%;
                                width: auto;
                                border-radius: 10px;
                              "
                              alt=""
                            />

                            <div class="text-dark">
                              <div class="keterangan ResetKet_JajaID">
                                <h4
                                  class="text-product one-line fsxx text-center"
                                  style="margin-top: -20px"
                                >
                                  Looks like the data you are looking for is
                                  empty.
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="resResultAllOrder.isActiveStatus === 'canceled'">
          <div v-if="resResultAllOrder.data.totalData">
            <div
              v-for="(item, index) in resResultAllOrder.data.items"
              :key="index"
              class="card-body tab-content p-3 container"
              style="margin-top: -20px !important"
            >
              <router-link :to="'/order/' + item.invoice">
                <div class="tab-pane show active">
                  <div class="row bodyHtml container">
                    <div class="card-product shadow-custom mt-3 col-md-12">
                      <div class="d-flex justify-content-between bd-highlight">
                        <div class="p-2 bd-highlight">
                          <small class="text-primary-jaja">{{
                            item.createdAt
                          }}</small>
                          <small class="ml-2 text-danger">
                            <i class="fas fa-clock"></i>
                            {{ item.expiredAt }}
                          </small>
                        </div>
                        <div class="p-2 bd-highlight">
                          <span
                            class="text-primary-jaja text-bold float-right sml text-uppercase"
                            >{{ item.cancelBy }}</span
                          >
                        </div>
                      </div>
                      <div class="d-flex justify-content-start bd-highlight">
                        <div class="p-2 bd-highlight">
                          <i class="fas fa-store text-secondary-jaja"></i>
                          <b class="nml text-primary-jaja">
                            {{ item.store.name }}</b
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div
                            class="d-flex justify-content-start bd-highlight"
                            style="margin-left: 30px"
                          >
                            <div class="p-2 bd-highlight">
                              <img
                                :src="item.products[0].image"
                                class="img-thumbnail img-fluid"
                                style="
                                  width: 100px;
                                  max-width: 100%;
                                  height: auto;
                                  max-height: 100%;
                                "
                                alt=""
                              />
                            </div>
                            <div class="p-2 bd-highlight">
                              <div class="text-left">
                                <a class="text-dark">
                                  <span class="mb-0"
                                    ><b>{{ item.products[0].name }}</b>
                                  </span>
                                </a>
                                <br />
                                <span class="text-dark">
                                  {{ item.products[0].qty }} X
                                </span>
                                <span class="text-primary-jaja"
                                  ><b>
                                    {{
                                      item.products[0].isDiscount
                                        ? item.products[0]
                                            .priceDiscountCurrencyFormat
                                        : item.products[0].priceCurrencyFormat
                                    }}</b
                                  ></span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="d-flex justify-content-end bd-highlight">
                            <div class="p-2 bd-highlight mt-4">
                              <span class="text-primary-jaja"
                                ><b>{{
                                  item.products[0].subTotalCurrencyFormat
                                }}</b></span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div
                        class="d-flex justify-content-between bd-highlight mb-3"
                      >
                        <div class="p-2 bd-highlight">
                          <button
                            class="stardust-white cslight btn btn-warning-custom"
                          >
                            <b>Expired</b>
                          </button>
                        </div>
                        <div class="p-2 bd-highlight">
                          <span class="text-dark">Total :&nbsp;</span>
                          <b class="text-primary-jaja">{{
                            item.totalPriceCurrencyFormat
                          }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div v-else>
            <div
              class="card-body tab-content p-3 container"
              style="margin-top: -20px !important"
            >
              <div class="tab-pane show active">
                <div class="row bodyHtml container">
                  <div class="card-product shadow-custom mt-3 col-md-12">
                    <div class="row">
                      <div class="col-md-12 col-12 carousel-cell is-selected">
                        <div>
                          <div class="pb-3">
                            <div></div>
                            <img
                              slot="image"
                              src="https://nimda.jaja.id/asset/icon-jaja/notfound.png"
                              class="img-fluid"
                              style="
                                max-height: 400px;
                                max-width: 100%;
                                width: auto;
                                border-radius: 10px;
                              "
                              alt=""
                            />

                            <div class="text-dark">
                              <div class="keterangan ResetKet_JajaID">
                                <h4
                                  class="text-product one-line fsxx text-center"
                                  style="margin-top: -20px"
                                >
                                  Looks like the data you are looking for is
                                  empty.
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="Object.keys(resResultAllOrder.data.items).length > 0">
            <div
              v-for="(item, index) in resResultAllOrder.data.items"
              :key="index"
              class="card-body tab-content p-3 container"
              style="margin-top: -20px !important"
            >
              <router-link :to="'/order/detail-komplain/' + item.invoice">
                <div class="tab-pane show active">
                  <div class="row bodyHtml container">
                    <div class="card-product shadow-custom mt-3 col-md-12">
                      <div class="d-flex justify-content-between bd-highlight">
                        <div class="p-2 bd-highlight">
                          <small class="text-primary-jaja">{{
                            item.createdAt
                          }}</small>
                          <small class="ml-2 text-danger">
                            <i class="fas fa-clock"></i>
                            {{ item.expiredAt }}
                          </small>
                        </div>
                        <div class="p-2 bd-highlight">
                          <span
                            class="text-primary-jaja text-bold float-right sml"
                            >PENGEMBALIAN</span
                          >
                        </div>
                      </div>
                      <div class="d-flex justify-content-start bd-highlight">
                        <div class="p-2 bd-highlight">
                          <i class="fas fa-store text-secondary-jaja"></i>
                          <b class="nml text-primary-jaja">
                            {{ item.store.name }}</b
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div
                            class="d-flex justify-content-start bd-highlight"
                            style="margin-left: 30px"
                          >
                            <div class="p-2 bd-highlight">
                              <img
                                :src="item.products[0].image"
                                class="img-thumbnail img-fluid"
                                style="
                                  width: 100px;
                                  max-width: 100%;
                                  height: auto;
                                  max-height: 100%;
                                "
                                alt=""
                              />
                            </div>
                            <div class="p-2 bd-highlight">
                              <div class="text-left">
                                <a class="text-dark">
                                  <span class="mb-0"
                                    ><b>{{ item.products[0].name }}</b>
                                  </span>
                                </a>
                                <br />
                                <span class="text-dark">
                                  {{ item.products[0].qty }} X
                                </span>
                                <span class="text-primary-jaja"
                                  ><b>
                                    {{
                                      item.products[0].isDiscount
                                        ? item.products[0]
                                            .priceDiscountCurrencyFormat
                                        : item.products[0].priceCurrencyFormat
                                    }}</b
                                  ></span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="d-flex justify-content-end bd-highlight">
                            <div class="p-2 bd-highlight">
                              <span class="text-primary-jaja"
                                ><b>{{
                                  item.products[0].subTotalCurrencyFormat
                                }}</b></span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div
                        class="d-flex justify-content-between bd-highlight mb-3"
                      >
                        <div class="p-2 bd-highlight">
                          <button
                            class="stardust-white cslight btn btn-warning-custom"
                          >
                            <b>Lihat Detail</b>
                          </button>
                        </div>
                        <div class="p-2 bd-highlight">
                          <span class="text-dark">Total :&nbsp;</span>
                          <b class="text-primary-jaja">{{
                            item.totalPriceCurrencyFormat
                          }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div v-else>
            <div
              class="card-body tab-content p-3 container"
              style="margin-top: -20px !important"
            >
              <div class="tab-pane show active">
                <div class="row bodyHtml container">
                  <div class="card-product shadow-custom mt-3 col-md-12">
                    <div class="row">
                      <div class="col-md-12 col-12 carousel-cell is-selected">
                        <div>
                          <div class="pb-3">
                            <div></div>
                            <img
                              slot="image"
                              src="https://nimda.jaja.id/asset/icon-jaja/notfound.png"
                              class="img-fluid"
                              style="
                                max-height: 400px;
                                max-width: 100%;
                                width: auto;
                                border-radius: 10px;
                              "
                              alt=""
                            />

                            <div class="text-dark">
                              <div class="keterangan ResetKet_JajaID">
                                <h4
                                  class="text-product one-line fsxx text-center"
                                  style="margin-top: -20px"
                                >
                                  Looks like the data you are looking for is
                                  empty.
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-else>
                <div class="card-body tab-content p-3 container" style="margin-top: -20px !important">
                    <div class="tab-pane show active">
                        <div class="row bodyHtml container">
                            <div class="card-product shadow-custom mt-3 col-md-12">
                                <div class="row">
                                    <div class="col-md-12 col-12 carousel-cell is-selected">
                                        <div>
                                            <div class="pb-3 pt-3">
                                                <content-placeholders :rounded="true">
                                                <content-placeholders-img style="width: 200px;"/>
                                                <content-placeholders-heading />
                                                </content-placeholders>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    resResultAllOrder: {
      type: Object,
    },
    resFilter: {
      type: Object,
    },
    isFinish: {
      type: Boolean,
      required: false,
    },
    recordsLength: {
      type: Number,
    },
    page: {
      type: Number,
    },
    perPage: {
      type: Number,
    },
    getListOrder: {
      type: Function,
      required: false,
    },
  },
  computed: {
    dataFilter() {
      return this.resResultAllOrder.data.filters;
    },
  },
};
</script>
<style scoped>
.container-m {
  padding: 15px;
}
</style>