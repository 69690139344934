import axios from 'axios'

export default {
    state: {
        shipping: {},
    },
    getters: {
        shippingGetter(state) {
            return state.shipping
        },
    },
    actions: {
        async getShipping({ commit }) {
            const res = await axios.get('checkout/shipping')
            localStorage.setItem('dataShipping', res.data.data)
            commit('setShipping', res.data.data)
        },
    },
    mutations: {
        setShipping(state, data) {
            state.shipping = data
        },
    },
}