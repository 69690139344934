<template>
  <div class="card-login">
    <div class="kotak-login">
      <div class="d-flex justify-content-between bd-highlight">
        <div class="bd-highlight">
          <h5 class="font-weight-bold">New Password</h5>
        </div>
        <div class="bd-highlight"></div>
        <div class="bd-highlight">
          <router-link to="/">
            <img
              width="70"
              class="logo-jaja"
              src="https://jsonx.jaja.id/asset/home/logo/jaja-logo.png"
              alt=""
            />
          </router-link>
        </div>
      </div>
      <div class="d-flex justify-content-between bd-highlight mb-2">
        <div class="bd-highlight">
          <small>Masukkan password baru</small>
        </div>
      </div>

      <form @submit.prevent="sendNewPass">
        <div class="form-group">
          <input
            :type="setTypeInput"
            v-model="pass"
            name="password"
            id="passwordLogin"
            class="form-control"
            required=""
            placeholder="Password"
          />
        </div>
        <div class="form-group">
          <input
            :type="setTypeInput"
            v-model="confirm_pass"
            name="confirmpassword"
            id="confirmpassword"
            class="form-control"
            required=""
            placeholder="Confirm Password"
          />
        </div>

        <div class="d-flex justify-content-between bd-highlight mb-2">
          <div class="bd-highlight">
            <div class="form-check">
              <input
                @click="seePass"
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                <small>Lihat Password</small>
              </label>
            </div>
          </div>
        </div>

        <button
          type="submit"
          class="btn bg-primary-jaja text-white font-weight-bold btn-block"
        >
          Dapatkan Password
        </button>
      </form>

      <div class="mt-4 kotak-bawah">
        <div class="d-flex justify-content-between bd-highlight mt-4 p-2">
          <div class="p-2 bd-highlight">
            <small>Sudah memiliki akun?</small>
          </div>
          <div class="p-2 bd-highlight">
            <button
              @click="goToLogin"
              class="tombol-daftar p-1 text-dark font-weight-bold"
            >
              Masuk
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pass: "",
      confirm_pass: "",
      setTypeInput: "password",
    };
  },
  computed: {},
  components: {},
  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      "https://apis.google.com/js/client:platform.js?onload=renderButton"
    );
    document.head.appendChild(externalScript);
    window.scrollTo(0, 0);
  },
  methods: {
    sendNewPass() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=v7fluv5q2mcvpte0hu330c9nhna0q75v");

      var raw = JSON.stringify({
        email: this.$route.params.email,
        code: this.$route.params.code,
        new_password: this.pass,
        confirm_new_password: this.confirm_pass,
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/change_password/forgot`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          this.$swal.fire({
            html: '<img src="https://jsonx.jaja.id/asset/home/logo/Loading_2.gif" alt="">', // add html attribute if you want or remove
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              this.$swal.showLoading();
            },
          });
          if (res.status.code === 200) {
            // $("#modalUpdate").modal('hide')
            this.$swal
              .fire("Sukses", "Berhasil Mengubah Data", "success")
              .then(() => {
                this.$router.push("/user/login");
              });
          } else if (res.status.code === 409) {
            // $("#modalUpdate").modal('hide')
            this.$swal.fire("Info", res.status.message, "info").then(() => {});
          } else {
            // $("#modalUpdate").modal('hide')
            this.$swal
              .fire("Warning", res.status.message, "warning")
              .then(() => {});
          }
        })
        .catch((error) => console.log("error", error));
    },
    goToLogin() {
      this.$router.push("/user/login");
    },
    seePass() {
      event.target.checked
        ? (this.setTypeInput = `text`)
        : (this.setTypeInput = `password`);
      // console.log(this.setTypeInput);
    },
  },
};
</script>

<style lang="scss" scoped>
.kotak-login {
  width: 30vw;
  background-color: white;
  border-radius: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  margin-left: 105%;
}
.kotak-bawah {
  position: relative;
  width: 30vw;
  margin-left: -20px;
  background-color: #f0f0f0;
  border-radius: 0 0 20px 20px;
}

.opacity-button {
  opacity: 0.5;
}
.tombol-fb {
  width: 50vh;
}

.card-login {
}
.tombol-daftar {
  background-color: rgb(239 194 122);
  border-radius: 10px;
  font-size: 12px;
  width: 5vw;
  border: 0px !important;
}
</style>