<template>
  <div>
    <div class="d-none d-lg-block">
      <div style="margin-top: -2%" class="container p-3">
        <div class="row">
          <div class="col-md-3">
            <sideBar />
          </div>
          <div class="col-md-9">
            <div class="card-product shadow-custom br-card-jaja">
              <div class="card-header br-card-header-jaja">
                <div class="d-flex justify-content-between bd-highlight">
                  <div class="bd-highlight">
                    <h5 class="d-flex font-weight-bold text-dark">Rekening</h5>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="col-center row">
                  <div :class="limitRek > 1 ? `d-none` : ``">
                    <router-link to="/tambahrekening">
                      <div class="add-credit-card m-2" id="show1">
                        <div
                          class="child-add-credit-card text-dark font-weight-bold"
                        >
                          <p class="mt-3">Tambah Rekening</p>
                          <i class="fas fa-plus"></i>
                        </div>
                      </div>
                    </router-link>
                  </div>

                  <div
                    v-for="(item, index) in dataRek"
                    :key="index"
                    class="list-card"
                  >
                    <div
                      class="credit-card m-2"
                      @click="showInfo(index)"
                      style="cursor: pointer"
                    >
                      <div class="flip">
                        <div class="front">
                          <div>
                            <div class="investor text-bold">
                              {{
                                item.bankName.length > 30
                                  ? item.bankName.slice(0, 30).concat("...")
                                  : item.bankName
                              }}
                            </div>
                            <div>
                              <div class="float-left">
                                <div class="chip">
                                  <div class="chip-line"></div>
                                  <div class="chip-line"></div>
                                  <div class="chip-line"></div>
                                  <div class="chip-line"></div>
                                  <div class="chip-main"></div>
                                </div>
                              </div>

                              <div
                                class="float-right"
                                style="
                                  position: absolute;
                                  top: 70px;
                                  right: 25px;
                                "
                              >
                                <span
                                  :class="
                                    item.verified
                                      ? 'badge badge-soft-success text-white bg-success'
                                      : 'badge badge-soft-warning text-white bg-danger'
                                  "
                                  >{{
                                    item.verified
                                      ? "Terverifikasi"
                                      : "Blm Terverifikasi"
                                  }}</span
                                >
                              </div>
                              <div class="clearfix"></div>
                            </div>
                            <div class="card-number">
                              <div class="section">{{ item.account }}</div>
                            </div>
                          </div>
                          <div class="card-holder">
                            <div onclick="show_modal()">
                              <div class="float-left">
                                {{ item.accountName }}
                                <input
                                  type="hidden"
                                  class="form-control"
                                  name="id_data"
                                  id="id_data"
                                  value="235"
                                />
                              </div>
                            </div>
                            <div class="float-right">
                              <span
                                :class="
                                  item.isPrimary
                                    ? 'badge badge-success'
                                    : 'd-none'
                                "
                              >
                                <div onclick="changePrimary(265)">
                                  {{ item.isPrimary ? "UTAMA" : "" }}
                                </div>
                              </span>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal" id="detailRek">
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title font-weight-bold">
                  Detail Rekening Bank
                </h5>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col text-left">
                    <span class="text-primary-jaja font-weight-bold"
                      >Atas Nama</span
                    >
                    <p style="color: #3d3d3d" id="name">
                      {{ itemRek.accountName }}
                    </p>
                  </div>
                  <div class="col text-left">
                    <span class="text-primary-jaja font-weight-bold">Bank</span>
                    <p style="color: #3d3d3d" id="bank_name">
                      {{ itemRek.bankName }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-left">
                    <span class="text-primary-jaja font-weight-bold"
                      >No. Rekening</span
                    >
                    <p style="color: #3d3d3d" id="account">
                      {{ itemRek.account }}
                    </p>
                  </div>
                  <div class="col text-left">
                    <span class="text-primary-jaja font-weight-bold"
                      >Kantor Cabang</span
                    >
                    <p style="color: #3d3d3d" id="branch_office">
                      {{ itemRek.branchOffice }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-left">
                    <span class="text-primary-jaja font-weight-bold"
                      >Kota/Kabupaten</span
                    >
                    <p style="color: #3d3d3d" id="city">
                      {{ itemRek.city }}
                    </p>
                  </div>
                  <div class="col text-left" v-if="itemRek.verified">
                    <span class="text-primary-jaja font-weight-bold"
                      >Status</span
                    >
                    <p style="color: #28a745; font-weight: bold" id="verified">
                      Terverifikasi
                    </p>
                  </div>
                  <div class="col text-left" v-else>
                    <span class="text-primary-jaja font-weight-bold"
                      >Status</span
                    >
                    <p style="color: #dc3545; font-weight: bold" id="verified">
                      Belum Terverifikasi :
                      <a
                        v-if="!itemRek.verified"
                        @click="kirimUlangVerif(itemRek.id)"
                        class="text-primary font-weight-bold"
                        style="cursor: pointer"
                      >
                        Kirim Ulang Verifikasi
                      </a>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-left">
                    <span class="text-primary-jaja font-weight-bold"
                      >Rekening Utama</span
                    >
                    <b-form-checkbox
                      switch
                      size="lg"
                      class="mt-1"
                      @change="changeRekUtama(itemRek.id)"
                      :checked="itemRek.isPrimary"
                      :disabled="itemRek.isPrimary"
                    >
                      <small v-if="itemRek.isPrimary"
                        >Telah Menjadi Rekening Utama</small
                      >
                      <small v-if="!itemRek.isPrimary"
                        >Jadikan Rekening Utama</small
                      >
                    </b-form-checkbox>
                  </div>
                  <div class="col text-left">
                    <span class="text-primary-jaja font-weight-bold"
                      >Hapus Rekening</span
                    >
                    <p style="color: #3d3d3d">
                      <a
                        @click="hapusRek(itemRek.id)"
                        class="btn btn-danger mt-1"
                        style="border-radius: 5px"
                      >
                        <i class="fas fa-trash-alt text-white"></i>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  :disabled="isLoading"
                  type="button"
                  class="btn btn-secondary font-weight-bold"
                  data-dismiss="modal"
                  style="background: #fdb738; border-color: #fdb738"
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-lg-none" >
      <div style="margin-top: -177px" class="container-m  p-3">
        <div class="row">
          <div class="col-md-12">
            <div class="card-product shadow-custom br-card-jaja">
              <div class="card-header br-card-header-jaja">
                <div class="d-flex justify-content-between bd-highlight">
                  <div class="bd-highlight">
                    <h5 class="d-flex font-weight-bold text-dark">Rekening</h5>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="col-center row">
                  <div :class="limitRek > 1 ? `d-none` : ``">
                    <router-link to="/tambahrekening">
                      <div
                        class="add-credit-card m-2"
                        style="width: 350px; height: 190px"
                        id="show1"
                      >
                        <div
                          class="child-add-credit-card text-dark font-weight-bold"
                        >
                          <p class="mt-3">Tambah Rekening</p>
                          <i class="fas fa-plus"></i>
                        </div>
                      </div>
                    </router-link>
                  </div>

                  <div
                    v-for="(item, index) in dataRek"
                    :key="index"
                    class="list-card"
                  >
                    <div
                      class="credit-card m-2"
                      @click="showInfo(index)"
                      style="cursor: pointer; width: 350px; height: 190px"
                    >
                      <div class="flip">
                        <div class="front">
                          <div>
                            <div class="investor text-bold">
                              {{
                                item.bankName.length > 30
                                  ? item.bankName.slice(0, 30).concat("...")
                                  : item.bankName
                              }}
                            </div>
                            <div>
                              <div class="float-left">
                                <div class="chip">
                                  <div class="chip-line"></div>
                                  <div class="chip-line"></div>
                                  <div class="chip-line"></div>
                                  <div class="chip-line"></div>
                                  <div class="chip-main"></div>
                                </div>
                              </div>

                              <div
                                class="float-right"
                                style="
                                  position: absolute;
                                  top: 70px;
                                  right: 25px;
                                "
                              >
                                <span
                                  :class="
                                    item.verified
                                      ? 'badge badge-soft-success text-white bg-success'
                                      : 'badge badge-soft-warning text-white bg-danger'
                                  "
                                  >{{
                                    item.verified
                                      ? "Terverifikasi"
                                      : "Blm Terverifikasi"
                                  }}</span
                                >
                              </div>
                              <div class="clearfix"></div>
                            </div>
                            <div class="card-number">
                              <div class="section">{{ item.account }}</div>
                            </div>
                          </div>
                          <div class="card-holder">
                            <div onclick="show_modal()">
                              <div class="float-left">
                                {{ item.accountName }}
                                <input
                                  type="hidden"
                                  class="form-control"
                                  name="id_data"
                                  id="id_data"
                                  value="235"
                                />
                              </div>
                            </div>
                            <div class="float-right">
                              <span
                                :class="
                                  item.isPrimary
                                    ? 'badge badge-success'
                                    : 'd-none'
                                "
                              >
                                <div onclick="changePrimary(265)">
                                  {{ item.isPrimary ? "UTAMA" : "" }}
                                </div>
                              </span>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal" id="detailRek">
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title font-weight-bold">
                  Detail Rekening Bank
                </h5>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col text-left">
                    <span class="text-primary-jaja font-weight-bold"
                      >Atas Nama</span
                    >
                    <p style="color: #3d3d3d" id="name">
                      {{ itemRek.accountName }}
                    </p>
                  </div>
                  <div class="col text-left">
                    <span class="text-primary-jaja font-weight-bold">Bank</span>
                    <p style="color: #3d3d3d" id="bank_name">
                      {{ itemRek.bankName }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-left">
                    <span class="text-primary-jaja font-weight-bold"
                      >No. Rekening</span
                    >
                    <p style="color: #3d3d3d" id="account">
                      {{ itemRek.account }}
                    </p>
                  </div>
                  <div class="col text-left">
                    <span class="text-primary-jaja font-weight-bold"
                      >Kantor Cabang</span
                    >
                    <p style="color: #3d3d3d" id="branch_office">
                      {{ itemRek.branchOffice }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-left">
                    <span class="text-primary-jaja font-weight-bold"
                      >Kota/Kabupaten</span
                    >
                    <p style="color: #3d3d3d" id="city">
                      {{ itemRek.city }}
                    </p>
                  </div>
                  <div class="col text-left" v-if="itemRek.verified">
                    <span class="text-primary-jaja font-weight-bold"
                      >Status</span
                    >
                    <p style="color: #28a745; font-weight: bold" id="verified">
                      Terverifikasi
                    </p>
                  </div>
                  <div class="col text-left" v-else>
                    <span class="text-primary-jaja font-weight-bold"
                      >Status</span
                    >
                    <p style="color: #dc3545; font-weight: bold" id="verified">
                      Belum Terverifikasi :
                      <a
                        v-if="!itemRek.verified"
                        @click="kirimUlangVerif(itemRek.id)"
                        class="text-primary font-weight-bold"
                        style="cursor: pointer"
                      >
                        Kirim Ulang Verifikasi
                      </a>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-left">
                    <span class="text-primary-jaja font-weight-bold"
                      >Rekening Utama</span
                    >
                    <b-form-checkbox
                      switch
                      size="lg"
                      class="mt-1"
                      @change="changeRekUtama(itemRek.id)"
                      :checked="itemRek.isPrimary"
                      :disabled="itemRek.isPrimary"
                    >
                      <small v-if="itemRek.isPrimary"
                        >Telah Menjadi Rekening Utama</small
                      >
                      <small v-if="!itemRek.isPrimary"
                        >Jadikan Rekening Utama</small
                      >
                    </b-form-checkbox>
                  </div>
                  <div class="col text-left">
                    <span class="text-primary-jaja font-weight-bold"
                      >Hapus Rekening</span
                    >
                    <p style="color: #3d3d3d">
                      <a
                        @click="hapusRek(itemRek.id)"
                        class="btn btn-danger mt-1"
                        style="border-radius: 5px"
                      >
                        <i class="fas fa-trash-alt text-white"></i>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  :disabled="isLoading"
                  type="button"
                  class="btn btn-secondary font-weight-bold"
                  data-dismiss="modal"
                  style="background: #fdb738; border-color: #fdb738"
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <br />
        <NavBar />
      </div>
    </div>
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
<script>
import { mapGetters } from "vuex";
import sideBar from "../../components/sidebar/sideBar";
import mapGoggle from "../../components/mapcomponent/mapcomp.vue";
import NavBar from "../../components/master/navbarbuttom.vue";
export default {
  data() {
    return {
      dataRek: [],
      limitRek: 0,
      isLoading: false,
      itemRek: {},
    };
  },
  components: {
    sideBar,
    mapGoggle,
    NavBar,
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  mounted() {
    this.getBankAccount();
    window.scrollTo(0, 0);
  },
  methods: {
    getBankAccount() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Cookie", "ci_session=r3rqr2lumbostcrujs286hthp1jfngnt");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      // fetch(`${this.domainURL}user/bankAccount`, requestOptions)
      fetch(`https://jaja.id/backend/user/bankAccount `, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.dataRek = res.data.items;
            this.limitRek = res.data.length;
          }
        })
        .catch((error) => console.log("error", error));
    },
    showInfo(id) {
      const data = this.dataRek;
      this.itemRek = data[id];

      $("#detailRek").modal({ backdrop: "static", keyboard: false }, "show");
    },
    changeRekUtama(data) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        bankAccountId: data,
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/changePrimaryBankAccount`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.$swal.fire("Sukses", res.status.message, "success");
            this.getBankAccount();
          }
        })
        .catch((error) => console.log("error", error));
    },
    kirimUlangVerif(id) {
      this.$swal
        .fire({
          title: "Apakah anda yakin",
          text: "Akan mengirim ulang verifikasi rekening ini?",
          icon: "warning",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Ya",
          denyButtonText: `Tidak`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.runkirimUlangVerif(id);
          }
        });
    },
    runkirimUlangVerif(id) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        bankId: id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}mailing/bankAccount`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.$swal.fire("Sukses", `${res.status.message}`, "success");
          } else {
            this.$swal.fire("Gagal", `${res.status.message}`, "warning");
          }
        })
        .catch((error) => console.log("error", error));
    },
    hapusRek(id) {
      this.$swal
        .fire({
          title: "Apakah anda yakin",
          text: "Akan menghapus rekening ini?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Hapus",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.runDeleteRek(id);
          }
        });
    },
    runDeleteRek(id) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);

      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/bankAccount/${id}`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.$swal
              .fire({
                title: "Sukses",
                text: "Data rekening berhasil dihapus",
                icon: "success",
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: false,
              })
              .then(() => {
                location.reload();
              });
          } else {
            this.$swal.fire("Gagal", `${res.status.code}`, "warning");
          }
        })
        .catch((error) => console.log("error", error));
    },
  },
};
</script>
<style>
.flip {
  width: inherit;
  height: inherit;
}
.credit-card {
  width: 350px;
  height: 200px;
  text-align: left;
}

.front {
  transform: translateZ(0);
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: 15px;
  color: #fff;
  backface-visibility: hidden;
  background-image: linear-gradient(to bottom, #7d7d7d, #9c9c9c);
  overflow: hidden;
}
.card-holder {
  margin: 10px 25px;
}

.chip {
  position: relative;
  top: 40px;
  left: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 40px;
  border-radius: 5px;
  background-image: linear-gradient(to bottom left, #ffecc7, #d0b978);
  overflow: hidden;
}
.chip .chip-line:nth-child(1) {
  top: 13px;
}
.chip .chip-line:nth-child(2) {
  top: 20px;
}
.chip .chip-line:nth-child(3) {
  top: 28px;
}
.chip .chip-line:nth-child(4) {
  left: 25px;
  width: 1px;
  height: 50px;
}
.chip .chip-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #333;
}
.chip .chip-main {
  width: 20px;
  height: 25px;
  border: 1px solid #333;
  border-radius: 3px;
  background-image: linear-gradient(to bottom left, #efdbab, #e1cb94);
  z-index: 1;
}
.investor {
  position: relative;
  top: 20px;
  left: 25px;
}

.text-bold {
  font-weight: bold;
}
.card-number {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px 25px 15px;
  font-size: 18px;
  font-family: "cc font", monospace;
}
.col-center {
  justify-content: center;
  display: flex;
}
.add-credit-card {
  background: #fedeac;
  position: relative;
  border-radius: 15px;
  display: inline-block;
  width: 350px;
  height: 200px;
  border: rgb(100, 175, 200) dashed 4px;
}
.add-credit-card .child-add-credit-card {
  position: absolute;
  top: 30%;
  text-align: center;
  left: 0;
  right: 0;
}
.badge-credit-card {
  color: #fff;
  border: 1px solid #43d39e;
}
.container-m {
  padding: 10px;
}
</style>