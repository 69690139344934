import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import Flickity from 'vue-flickity';
import GoogleLogin from 'vue-google-login';
import DatePicker from 'vue2-datepicker';
import VueBase64FileUpload from 'vue-base64-file-upload';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueContentPlaceholders from 'vue-content-placeholders';

import LetItSnow from 'vue-let-it-snow';



import vSelect from 'vue-select'

import Pagination from 'vue-pagination-2';


import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { FormRatingPlugin } from 'bootstrap-vue'

import VueSocialSharing from 'vue-social-sharing'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'vue-select/dist/vue-select.css';

import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";

let envConfig = {}

const firebaseConfig = {
  apiKey: "AIzaSyAyK_jliLynwi05uVHLdImhfENldcg3jrM",
  authDomain: "jajachat-e052d.firebaseapp.com",
  databaseURL: "https://jajachat-e052d-default-rtdb.firebaseio.com",
  projectId: "jajachat-e052d",
  storageBucket: "jajachat-e052d.appspot.com",
  messagingSenderId: "284366139562",
  appId: "1:284366139562:web:9eed2959651b3e93e1a0d3",
  measurementId: "G-6T2NFH8YXW"
};
firebase.initializeApp(firebaseConfig);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC_O0-LKyAboQn0O5_clZnePHSpQQ5slQU',
    libraries: 'places',
    callback: 'initAutocomplete'
  }
});

Vue.use(VueSweetalert2);
Vue.use(Flickity);
Vue.use(GoogleLogin);
Vue.use(DatePicker);
Vue.use(VueBase64FileUpload);
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(VueContentPlaceholders)

Vue.use(LetItSnow);

Vue.component('pagination', Pagination);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(FormRatingPlugin)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueSocialSharing);
Vue.component('v-select', vSelect)

Vue.config.productionTip = false
// Vue.prototype.$baseUrlCuk = false

// console.log('process.env.VUE_APP_SERVICE_URL', process.env.VUE_APP_SERVICE_URL)

// if (process.env.VUE_APP_SERVICE_URL != "http://localhost:8080/") {
//   Vue.prototype.domainURL = process.env.VUE_APP_SERVICE_URL_PRODUCTION
//   axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL_PRODUCTION
// } else {
//   Vue.prototype.domainURL = process.env.VUE_APP_SERVICE_URL
//   axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL
// }
Vue.prototype.domainURL = process.env.VUE_APP_SERVICE_URL_PRODUCTION
axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL_PRODUCTION



const token = localStorage.getItem("token");

import { setHeaderToken } from "../utils/auth";



if (token) {
  setHeaderToken(token);
}

store.dispatch("get_user", token)
  .then(() => {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })


