<template>
  <div>
    <div class="d-none d-lg-block">
      <div class="scrool">
        <div
          style="
            transition: top 0.3s;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 99;
            padding: 15px 0px;
          "
          id="wrapper"
          class="wrapper-fluid banners-effect-10 BlurNavbarJaja shadow-custom bg-white"
        >
          <header id="header" class="typeheader-4">
            <div class="header-middle">
              <div class="container">
                <table align="center" border="0" width="100%">
                  <tbody>
                    <tr>
                      <td width="17%">
                        <div class="">
                          <router-link :to="'/'">
                            <img
                              src="https://jsonx.jaja.id/asset/home/logo/jaja-logo.png"
                              title="Jaja ID Toko Online Terlengkap di Indonesia"
                              alt="Jaja ID Toko Online Terlengkap di Indonesia"
                              height="85px"
                            />
                          </router-link>
                        </div>
                      </td>
                      <td>
                        <div
                          @mouseover="mouseMasuk()"
                          @mouseleave="mouseKeluar()"
                          style="
                            border-radius: 4px;
                            background: var(--Primary-10, #f3fcff);
                            display: flex;
                            padding: 8px;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                          "
                        >
                          <span
                            style="
                              color: var(--Primary-60, #275463);
                              font-family: Poppins;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 500;
                              line-height: 20px; /* 142.857% */
                              letter-spacing: -0.14px;
                            "
                            >Kategori
                            <i
                              class="fas fa-chevron-down"
                              style="margin-left: 4px"
                            ></i
                          ></span>
                        </div>
                        <div
                          class="row"
                          :class="
                            cekMouseKategori
                              ? 'megamenu list-unstyled data-list-kategory'
                              : 'd-none'
                          "
                          @mouseover="mouseMasuk()"
                          @mouseleave="mouseKeluar()"
                        >
                          <div class="col-md-6">
                            <ul
                              style="
                                overflow-y: scroll;
                                max-height: 450px;
                                position: absolute;
                                z-index: 999;
                                /* width: 250%; */
                                width: 300px;
                                left: -25px;
                              "
                            >
                              <ul style="width: 450px" class="list-group">
                                <div
                                  v-for="(item, index) in masterKategori"
                                  :key="index"
                                >
                                  <div
                                    v-if="
                                      item.name !== 'Gift' &&
                                      item.name !== 'Lainnya'
                                    "
                                  >
                                    <li
                                      @click="gotoDetailProdukNavbar(item.slug)"
                                      @mouseover="mouselistover(index)"
                                      :id="'active' + index"
                                      :class="
                                        index == indexParentActive
                                          ? 'list-group-item active text-dark'
                                          : 'list-group-item text-dark'
                                      "
                                      style="
                                        cursor: pointer;
                                        font-size: 90%;
                                        text-align: left;
                                        font-weight: bold;
                                      "
                                    >
                                      {{ item.name }}
                                    </li>
                                  </div>
                                </div>
                              </ul>
                            </ul>
                          </div>
                          <div
                            class="col-md-6"
                            v-if="Object.keys(childCategori.data).length > 0"
                          >
                            <div
                              style="
                                position: absolute;
                                width: 430px;
                                height: 450px;
                                background: white;
                                z-index: 999;
                                left: 360px;
                              "
                            >
                              <ul class="list-group">
                                <div
                                  v-for="(item, index) in childCategori.data"
                                  :key="index"
                                >
                                  <li
                                    @click="gotoDetailProdukNavbar(item.slug)"
                                    @mouseover="mouselistchildover(index)"
                                    @mouseleave="mouselistchildleave(index)"
                                    class="list-group-item child-megamenu text-dark"
                                    :id="'childActive' + index"
                                    style="
                                      cursor: pointer;
                                      font-size: 90%;
                                      text-align: left;
                                      font-weight: bold;
                                    "
                                  >
                                    {{ item.name }}
                                  </li>
                                </div>
                              </ul>
                            </div>
                          </div>
                          <div v-else></div>
                        </div>
                      </td>
                      <td>
                        <div
                          class="justify-content-center input-group mb-3 mt-3"
                          style="align-items: center"
                        >
                          <div
                            type="button"
                            @click="pencarianEnter()"
                            style="background-color: white; height: 38px"
                            class="input-group-prepend"
                          >
                            <span
                              class="input-group-text"
                              id="inputGroup-sizing-default"
                            >
                              <i
                                style="color: #65b0c9"
                                class="fas fa-search"
                              ></i>
                            </span>
                          </div>

                          <input
                            style="
                              display: flex;
                              padding: 10px;
                              align-items: center;
                              gap: 10px;
                              flex: 1 0 0;
                              border-top-right-radius: 12px;
                              border-bottom-right-radius: 12px;
                              border: 1px solid var(--Secondary-30, #fdb739);
                              background: var(--Neutral-10, #fff);
                              max-width: 550px !important;
                              width: 500px;
                            "
                            type="text"
                            class="form-control"
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            placeholder="Cari produk dan toko di jaja"
                            @click="pencarianData"
                            @keypress="pencarianData"
                            @keyup="pencarianData"
                            @keyup.enter="pencarianEnter()"
                         
                            autocomplete="off"
                          />
                        </div>

                        <div class="triggerMiddleware" v-if="isOpen">
                          <!-- {{hiddenCardPencarian}} -->
                          <ul
                            class="triggerMiddleware list-group ul-pencarian shadow-custom"
                            style="
                              height: 400px;
                              max-width: 600px !important;
                              overflow-y: auto !important;
                              margin-left: 2%;
                            "
                          >
                            <div
                              v-for="(item, index) in dataCombine"
                              :key="index"
                            >
                              <label
                                class="triggerMiddleware d-flex container p-2 text-white"
                                style="
                                  background-color: #65b0c9 !important;
                                  margin-bottom: 0px;
                                  border-radius: 2px;
                                "
                                for=""
                                >{{
                                  index == 0
                                    ? `Produk`
                                    : index == 1
                                    ? `Toko`
                                    : index == 2
                                    ? `Kategori`
                                    : ``
                                }}</label
                              >
                              <div
                                :class="
                                  index
                                    ? index == 1
                                      ? `triggerMiddleware cardtoko `
                                      : index == 2
                                      ? `triggerMiddleware cardkategori  `
                                      : ``
                                    : `triggerMiddleware cardproduk `
                                "
                                style="margin-bottom: 15px"
                              >
                                <div
                                  v-if="item.length > 0"
                                  class="triggerMiddleware"
                                  style="width: 100%; text-align: left"
                                >
                                  <div v-for="(a, b) in item" :key="b">
                                    <!-- PRODUK -->
                                    <template v-if="index == 0">
                                      <li
                                        @click="setPage(index, a.slug, a.name)"
                                        class="triggerMiddleware tobepointer list-group-item d-flex"
                                        style="cursor: pointer"
                                      >
                                        <span class="mr-3"> </span>
                                        {{ a.name }}
                                      </li>
                                    </template>

                                    <!-- KATEGORI -->
                                    <template v-else-if="index == 1">
                                      <li
                                        @click="setPage(index, a.slug, a.name)"
                                        class="triggerMiddleware tobepointer list-group-item d-flex"
                                        style="cursor: pointer"
                                      >
                                        <span class="mr-3"
                                          ><img
                                            style="object-fit: contain"
                                            :src="a.image"
                                            width="30"
                                            height="30"
                                            alt=""
                                          />
                                        </span>
                                        {{ a.name }}
                                      </li>
                                    </template>
                                    <!-- TOKO -->
                                    <template v-else-if="index == 2">
                                      <li
                                        @click="setPage(index, a.slug, a.name)"
                                        class="triggerMiddleware tobepointer list-group-item d-flex"
                                        style="cursor: pointer"
                                      >
                                        <span class="mr-3"
                                          ><img
                                            style="object-fit: contain"
                                            :src="a.icon"
                                            width="30"
                                            height="30"
                                            alt=""
                                          />
                                        </span>
                                        {{ a.name }}
                                      </li>
                                    </template>
                                  </div>
                                </div>

                                <div v-else>
                                  <li
                                    class="triggerMiddleware list-group-item d-flex"
                                  >
                                    Data tidak ditemukan
                                  </li>
                                </div>
                              </div>
                            </div>
                          </ul>
                        </div>
                      </td>
                      <template v-if="!isLoggedIn">
                        <td width="90" align="right">
                          <div
                            class="d-flex justify-content-start bd-highlight"
                          >
                            <router-link :to="'/user/login'">
                              <div class="bd-highlight">
                                <a
                                  class="warnabirujaja btn btn-sm text-white mr-2"
                                >
                                  <span><b>Login</b></span>
                                </a>
                              </div>
                            </router-link>
                            <router-link :to="'/user/register'">
                              <div class="bd-highlight">
                                <a
                                  class="warnaorangejaja btn btn-sm text-white"
                                >
                                  <span><b>Daftar</b></span>
                                </a>
                              </div>
                            </router-link>
                          </div>
                        </td>
                      </template>
                      <template v-else>
                        <td>
                          <div class="">
                            <div
                              class="d-flex justify-content-end align-items-center bd-highlight mb-1 ml-4"
                            >
                              <div class="bd-highlight">
                                <router-link
                                  :to="'/cart/cart'"
                                  class="nav-link avatar-with-name"
                                >
                                  <span class="icon-top-menu">
                                    <img
                                      class="uk-icon"
                                      src="../../assets/navbar-asset/cart.png"
                                    />
                                    <small
                                      class="badge badge-red rounded-circle"
                                      >{{ cartNotifications }}</small
                                    >
                                  </span>
                                </router-link>
                              </div>
                              <div class="bd-highlight">
                                <router-link :to="'/chat'">
                                  <a class="nav-link avatar-with-name">
                                    <span class="icon-top-menu">
                                      <img
                                        style="
                                          width: 27px;
                                          height: 20px;
                                          flex-shrink: 0;
                                        "
                                        src="../../assets/navbar-asset/chat.png"
                                      />
                                      <small
                                        class="badge badge-red rounded-circle"
                                        >{{ chatNotifications }}</small
                                      >
                                    </span>
                                  </a>
                                </router-link>
                              </div>
                              <div class="bd-highlight">
                                <router-link
                                  :to="'/pemberitahuan'"
                                  class="nav-link avatar-with-name"
                                >
                                  <span class="icon-top-menu">
                                    <img
                                      class="uk-icon"
                                      src="../../assets/navbar-asset/notif.png"
                                    />
                                    <small
                                      class="badge badge-red rounded-circle"
                                      >{{ notificationNotifications }}</small
                                    >
                                  </span>
                                </router-link>
                              </div>
                              <!-- <div class="bd-highlight">
                          <router-link :to="'/tasya'" class="nav-link avatar-with-name" id="navbarDropdownMenuLink">
                            <span class="icon-top-menu">
                              <i class="fas fa-bell"></i>
                              <small class="badge badge-warning rounded-circle"><small id="notifh" class=""></small></small>
                            </span>
                          </router-link>
                        </div> -->

                              <div class="p-2 bd-highlight">
                                <div
                                  @click="showDropDownLogout"
                                  class="dropdown"
                                >
                                  <button
                                    class="ml-2 btn btn-light dropdown-toggle"
                                  >
                                    <img
                                      style="width: 40px; height: 40px"
                                      src="../../assets/navbar-asset/user.png"
                                    />

                                    <span
                                      style="
                                        color: var(--Neutral-60, #1a1a1a);

                                        /* Mobile/H2 */
                                        font-family: Poppins;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 16px; /* 133.333% */
                                        letter-spacing: 0.12px;
                                      "
                                      >&nbsp;{{ user.name }}</span
                                    >
                                  </button>
                                  <div
                                    :class="
                                      showOrHiddenLogout
                                        ? 'dropdown-content shadow-custom'
                                        : 'dropdown-content d-none'
                                    "
                                    style="
                                      margin-left: 5%;
                                      position: absolute;
                                      z-index: 5;
                                      background-color: #f8f9fa;
                                      border-radius: 10px;
                                    "
                                  >
                                    <div
                                      class="bd-highlight mb-1"
                                      style="text-align: left"
                                    >
                                      <div class="bd-highlight">
                                        <router-link :to="'/dashboard'">
                                          <a
                                            class="dropdown-item"
                                            style="border-radius: 5px"
                                            >Akun Saya</a
                                          >
                                        </router-link>
                                      </div>
                                    </div>
                                    <div
                                      class="bd-highlight mb-1"
                                      style="text-align: left"
                                    >
                                      <div class="bd-highlight">
                                        <router-link :to="'/listorder'">
                                          <a
                                            class="dropdown-item"
                                            style="border-radius: 5px"
                                            >Pesanan Saya</a
                                          >
                                        </router-link>
                                      </div>
                                    </div>
                                    <div
                                      class="bd-highlight mb-1"
                                      style="text-align: left"
                                    >
                                      <div class="bd-highlight">
                                        <a
                                          @click="logout"
                                          class="dropdown-item"
                                          style="
                                            cursor: pointer;
                                            border-radius: 5px;
                                          "
                                          >Logout</a
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </header>
        </div>
      </div>
    </div>
    <div class="d-lg-none"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import vClickOutside from "v-click-outside";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      showSearchDropdown: false,
      showMenu: false,
      chatNotifications: 0,
      notificationNotifications: 0,
      cartNotifications: 0,
      isScrolled: false,
      // isLoggedIn: false,
      // showOrHiddenLogout: false,
      srcData: "",
      typingTimer: "",
      doneTypingInterval: 700,
      dataCombine: [],
      hiddenCardPencarian: false,
      dataToko: {},
      dataCategory: {},
      dataProduct: {},
      dataDitemukan: {},
      masterKategori: {},
      masterInfo: {},
      cekMouseKategori: false,
      cekMouseJaja: false,
      cekMouseEBH: false,
      cekMouseMasdis: false,
      showOrHiddenLogout: false,
      childCategori: {
        length: 0,
        data: "",
      },
      indexParentActive: 0,

      vcoConfig: {
        handler: this.handler,
        middleware: this.middleware,
        events: ["dblclick", "click"],
        // Note: The default value is true, but in case you want to activate / deactivate
        //       this directive dynamically use this attribute.
        isActive: true,
        // Note: The default value is true. See "Detecting Iframe Clicks" section
        //       to understand why this behaviour is behind a flag.
        detectIFrame: true,
      },
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({ isLoggedIn: "isLoggedIn" }),
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    // ...mapGetters({ totalKeranjang: "infoGetters" }),
    ...mapGetters({ user: "user" }),
    ...mapGetters({
      dataUrl: "urlGetters",
    }),
  },
  created() {
    this.srcData = this.srcData === "" ? this.$route.params.id : "";
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeMount() {
    this.getMasteKategori();
    // this.escapeFunction()
  },
  filters: {
    limitToOneWord: function (value) {
      return value.split(" ")[0];
    },
  },
  mounted() {
    this.getNotif();
    this.getInfo();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    toggleSearchDropdown() {
      this.showSearchDropdown = !this.showSearchDropdown;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    handleScroll() {
      // Mengubah nilai isScrolled berdasarkan perilaku scroll
      if (window.scrollY > 0) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },

    onClickOutside(event) {},
    handler(event) {
      this.isOpen = false;
    },
    middleware(event) {
      return event.target.className.split(" ")[0] !== "triggerMiddleware";
    },
    escapeFunction() {
      // this.hiddenCardPencarian=true
      // alert('dsadsa')
      // let that = this;
      // document.addEventListener('keyup', function (evt) {
      //     if (evt.keyCode === 27) {
      //       console.log(this.hiddenCardPencarian)
      //       this.hiddenCardPencarian=true
      //     }
      // });
    },
    onEnter(e) {
      this.$router.push("/product-search/" + e);
    },
    pencarianEnter() {
      this.$router.push("/product-search/" + this.srcData);
      this.isOpen = false; // Hide the .triggerMiddleware element
    },
    pencarianData(event) {
      this.srcData = event.target.value;
      clearTimeout(this.typingTimer);

      if (event.type === "keyup" && event.key === "Enter") {
        this.pencarianEnter();
      } else {
        if (this.srcData.trim() === "") {
          this.isOpen = false;
        } else {
          this.isOpen = true;
          this.typingTimer = setTimeout(
            () => this.doneTyping(this.srcData),
            500
          );
        }
      }
    },
    doneTyping(string) {
      let myHeaders = new Headers();
      myHeaders.append("Cookie", "ci_session=vah7ivbaoqeus4qfh89d7c8o2q55216c");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}product/search?limit=5&keyword=${string}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          this.dataCombine = [];
          this.hiddenCardPencarian = false;
          const res = JSON.parse(result);
          this.dataToko = res.data.store;
          this.dataCategory = res.data.category;
          this.dataProduct = res.data.product;
          this.dataDitemukan = true;
          this.dataCombine.push(res.data.product);
          this.dataCombine.push(res.data.store);
          this.dataCombine.push(res.data.category);

          console.log("dataCategory", res.data.category);
          console.log("dataProduct", res.data.product);
        });
    },
    getInfo() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/info`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.cartNotifications = res.data.totalProductInCart || 0;
            this.notificationNotifications = res.data.totalNotifUnread || 0;
            // Set data lain jika perlu
          }
        })
        .catch((error) => console.log("error", error));
    },
    getNotif() {
      const database = firebase.database();
      const uid = this.user.uid;

      database.ref(`/friend/${uid}`).on("value", (snapshot) => {
        let countChat = 0;
        snapshot.forEach((snap) => {
          const item = snap.val();
          if (item && item.id !== uid && item.id !== "null" && item.amount) {
            countChat += item.amount;
          }
        });
        this.chatNotifications = countChat;
      });
    },
    getMasteKategori() {
      let myHeaders = new Headers();
      myHeaders.append("Cookie", "ci_session=mgqcv1a854uuigbeae07b3hue1hcdgo8");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}master/category`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.masterKategori = res.data;
          }
        })
        .catch((error) => console.log("error", error));
    },
    mouseMasuk() {
      this.cekMouseKategori = true;
    },
    mouseKeluar() {
      this.cekMouseKategori = false;
    },
    mouseMasukJaja() {
      this.cekMouseJaja = true;
    },
    mouseKeluarJaja() {
      this.cekMouseJaja = false;
    },
    mouseMasukEBH() {
      this.cekMouseEBH = true;
    },
    mouseKeluarEBH() {
      this.cekMouseEBH = false;
    },
    mouseMasukMasdis() {
      this.cekMouseMasdis = true;
    },
    mouseKeluarMasdis() {
      this.cekMouseMasdis = false;
    },
    mouselistover(index) {
      this.indexParentActive = index;
      this.childCategori.length = this.masterKategori[index].children.length;
      this.childCategori.data = this.masterKategori[index].children;
    },
    gotoDetailProdukNavbar(slug) {
      this.$router.push("/kategori/" + slug);
      this.cekMouseKategori = false;
    },
    mouselistchildover(index) {
      var element = document.getElementById("childActive" + index);
      element.classList.add("active");
      this.cekMouseKategori = true;
    },
    mouselistchildleave(index) {
      var element = document.getElementById("childActive" + index);
      element.classList.remove("active");
    },
    setPage(index, slug, name) {
      if (index == 0) {
        this.isOpen = false;
        this.srcData = name;
        this.$router.push(`/home/DetailProduk/${slug}`);
      } else if (index == 1) {
        this.isOpen = false;
        this.srcData = name;
        this.$router.push(`/home/DetailToko/${slug}`);
      } else {
        this.isOpen = false;
        this.srcData = name;
        this.$router.push(`/kategori/${slug}`);
      }
      this.dataCombine = [];
    },
    logout() {
      this.$swal
        .fire({
          icon: "info",
          title: "Apakah anda akan keluar?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Keluar",
          denyButtonText: `Batalkan`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.runLogout();
          }
          // else if (result.isDenied) {
          //   this.$swal.fire("Cancel", "Anda tidak Logout", "warning");
          // }
        });
      // localStorage.clear();
      // window.location.href = "${this.domainURL}"
    },
    runLogout() {
      // this.$swal.fire('sukses', 'anda berhasil logout', 'success')
      localStorage.clear();
      setTimeout(() => this.runRedirect(), 500);
    },
    runRedirect() {
      let url = new URL(this.dataUrl.url);
      window.location.href = url.origin;
    },
    showDropDownLogout() {
      this.showOrHiddenLogout
        ? (this.showOrHiddenLogout = false)
        : (this.showOrHiddenLogout = true);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav_pos {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  // background: white;
}
.box-penambal {
  width: 100vh;
  /* height:23vh; */
  background: yellow;
  /* margin-bottom:1%; */
}
/* .ul-pencarian {
      position: absolute;
      z-index: 999;
      background: white;
      width: 55%;
  } */
.ul-pencarian {
  // margin-left:3.4%;
  margin-left: 0;
  position: absolute;
  z-index: 999;
  background: white;
  width: 38%;
  box-shadow: 5px 10px #888888;
  padding: 30px;
}
.card-body {
  background-color: white;
}
.tobepointer:hover {
  cursor: pointer;
}
.cardtoko {
  max-height: 400px;
  overflow: hidden;
}
.cardkategori {
  max-height: 400px;
  overflow: hidden;
}
.cardproduk {
  max-height: 400px;
  overflow: hidden;
}
.warnabirujaja {
  background: #65b0c9 !important;
}
.warnatextbirujaja {
  color: #65b0c9 !important;
}

.warnaorangejaja {
  background: #fdb738 !important;
}
.logo img {
  height: 100px;
}
.searchPencarian {
  // margin-left:8%;
  margin-left: 0;
  width: 600px;
}
/* PHONE */
@media (max-width: 600px) {
  .logo img {
    height: 65px;
  }
  .btn-border {
    border-radius: 0rem 0.1rem 0.1rem 0rem !important;
    border: 0.5px solid #ffc107;
    /* border-top: 1px solid #495057; 
      border-bottom: 1px solid #495057; 
      border-right: 1px solid #495057; */
  }
  .searchPencarian {
    // margin-left:8%;
    margin-left: 0;
    width: 200px;
  }
  .tab-kategori {
    width: 100px;
    padding: 1px;
    border-radius: 5px 5px 0 0;
  }
  .malltab-list {
    position: relative;
    height: 40px;
    float: left;
    margin-top: 6px;
    box-shadow: 5px 0 10px rgb(0 0 0 / 20%);
    font-size: 14px;
  }
  .malltab-list,
  .malltab-list a {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}
/* PHONE AND TABLET */
@media (min-width: 600px) {
  .logo img {
    height: 65px;
  }
  .btn-border {
    border-radius: 0rem 0.1rem 0.1rem 0rem !important;
    border: 0.5px solid #ffc107;
    /* border-top: 1px solid #495057; 
      border-bottom: 1px solid #495057; 
      border-right: 1px solid #495057; */
  }
  .searchPencarian {
    // margin-left:8%;
    margin-left: 0;
    width: 250px;
  }
  .tab-kategori {
    width: 100px;
    padding: 1px;
    border-radius: 5px 5px 0 0;
  }
  .malltab-list {
    position: relative;
    height: 40px;
    float: left;
    margin-top: 6px;
    box-shadow: 5px 0 10px rgb(0 0 0 / 20%);
    font-size: 14px;
  }
  .malltab-list,
  .malltab-list a {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}

/* TABLET */
@media (min-width: 768px) {
  .logo img {
    height: 100px;
    .btn-border {
      border-radius: 0rem 0.1rem 0.1rem 0rem !important;
      border: 0.5px solid #ffc107;
      /* border-top: 1px solid #495057; 
        border-bottom: 1px solid #495057; 
        border-right: 1px solid #495057; */
    }
    .searchPencarian {
      // margin-left:8%;
      margin-left: 0;
      width: 300px;
    }
    .tab-kategori {
      width: 150px;
      padding: 1px;
      border-radius: 10px 10px 0 0;
    }
    .malltab-list {
      position: relative;
      height: 40px;
      float: left;
      margin-top: 6px;
      box-shadow: 5px 0 10px rgb(0 0 0 / 20%);
      font-size: 14px;
    }
    .malltab-list,
    .malltab-list a {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
  }
}
/* LAPTOP */
@media (min-width: 992px) {
  .logo img {
    height: 100px;
  }
  .btn-border {
    border-radius: 0rem 0.25rem 0.25rem 0rem !important;
    border: 1px solid #ffc107;
    /* border-top: 1px solid #495057; 
      border-bottom: 1px solid #495057; 
      border-right: 1px solid #495057; */
  }
  .searchPencarian {
    // margin-left:8%;
    margin-left: 0;
    width: 400px;
  }
  .tab-kategori {
    width: 150px;
    padding: 1px;
    border-radius: 10px 10px 0 0;
  }
  .malltab-list {
    position: relative;
    height: 40px;
    float: left;
    margin-top: 6px;
    box-shadow: 5px 0 10px rgb(0 0 0 / 20%);
    font-size: 14px;
  }
  .malltab-list,
  .malltab-list a {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}
/* DEKSTOP */
@media (min-width: 992px) {
  .logo img {
    height: 100px;
  }
  .btn-border {
    border-radius: 0rem 0.25rem 0.25rem 0rem !important;
    border: 1px solid #ffc107;
    /* border-top: 1px solid #495057; 
        border-bottom: 1px solid #495057; 
        border-right: 1px solid #495057; */
  }
  .searchPencarian {
    // margin-left:8%;
    margin-left: 0;
    width: 600px;
  }
  .tab-kategori {
    width: 150px;
    padding: 1px;
    border-radius: 10px 10px 0 0;
  }
  .malltab-list {
    position: relative;
    height: 40px;
    float: left;
    margin-top: 6px;
    box-shadow: 5px 0 10px rgb(0 0 0 / 20%);
    font-size: 14px;
  }
  .malltab-list,
  .malltab-list a {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}

.searchPencarian input {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
}
.tab-kategori {
  width: 150px;
  padding: 1px;
  border-radius: 10px 10px 0 0;
}

/* CUSTOM BY MALA */
.btn-border {
  border-radius: 0rem 0.25rem 0.25rem 0rem !important;
  border: 1px solid #ffc107;
  /* border-top: 1px solid #495057; 
    border-bottom: 1px solid #495057; 
    border-right: 1px solid #495057; */
}

/* width */
::-webkit-scrollbar {
  // height: 1px;
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.list-group-item.active {
  z-index: 2;
  color: #fff !important;
  background-color: #fcb536 !important;
  border-color: #fcb536 !important;
}

.avatar-with-name {
  color: #65b0c9 !important;
}

.dropdown-item:hover {
  background-color: #65b0c9 !important;
  color: white;
}

.utils {
  position: relative;
  padding: 0;
  background: #ffffff;
}
.utils_innr {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}
.malltab {
  height: 46px;
  position: relative;
  color: #fff;
  /* overflow: hidden; */
}
.malltab-list.ilotte {
  z-index: 1;
  background-color: white;
}
.malltab-list.hover,
.malltab-list:hover {
  z-index: 10;
  margin-top: 0;
  height: 46px;
}
.malltab-list {
  position: relative;
  height: 40px;
  float: left;
  margin-top: 6px;
  box-shadow: 5px 0 10px rgb(0 0 0 / 20%);
  font-size: 14px;
}
.malltab-list,
.malltab-list a {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  /* transition: all .2s; */
}

.bg-kategori-kuning {
  background-color: #fcb536 !important;
}

.fa-bell:hover {
  color: #378098;
}

.fa-envelope:hover {
  color: #378098;
}
.fa-shopping-bag:hover {
  color: #378098;
}
.font-bold-baru {
  font-weight: 400;
}
.container-nav-m {
  padding: 20px;
}
.menu-list {
  list-style-type: none; /* Menghilangkan bullet points */
  padding: 0;
}

.menu-list li {
  margin-bottom: 5px; /* Jarak antar menu */
  border-bottom: 1px solid #ccc; /* Garis pembatas */
}

.menu-list li a {
  color: white; /* Warna teks putih */
  text-decoration: none; /* Menghapus garis bawah dari tautan */
}
.pd-list {
  padding: 8px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: white;
  border: 1px solid #fdb739;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.uk-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.badge-red {
  color: white;
  background-color: #e41010;
  margin-top: -7px;
  margin-left: -5px;
  position: absolute;
}
</style>
