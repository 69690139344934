<template>
  <div>
    <div class="row" v-if="isFinish">
      <div style="margin-top: -2%" class="container p-3">
        <div class="row">
          <div class="col-md-3 desktop-only">
            <sideBar />
          </div>
          <div class="col-md-9">
            <div v-if="!loadingOrder && !loadingTracking && orderData">
              <!-- Konten sebelumnya -->
              <div class="card shadow-custom br-card-jaja">
                <div class="card-header br-card-header-jaja">
                  <div class="d-flex justify-content-start bd-highlight">
                    <div class="bd-highlight text-white text-bold mr-auto">
                      Tracking Pesanan
                    </div>
                    <div class="bd-highlight text-white text-bold">
                      <router-link :to="'/order/' + this.$route.params.id">
                        <button
                          class="btn btn-sm btn-warning font-weight-bold text-white"
                        >
                          Kembali
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Bagian baru untuk menampilkan riwayat pergerakan paket -->
              <div class="card shadow-custom br-card-jaja">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 text-left">
                      <ul class="timeline list-track">
                        <li
                          v-for="(item, index) in orderData.data.rajaongkir.result.manifest"
                          :key="index"
                        >
                          <a class="float-right text-danger"
                            >{{ item.manifest_date }} {{ item.manifest_time }}</a
                          >
                          <p>{{ item.manifest_description }}</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Placeholder content while loading -->
            <div v-else>
              <div class="card shadow-custom br-card-jaja">
                <div class="card-header br-card-header-jaja">
                  <div class="d-flex justify-content-start bd-highlight">
                    <div class="bd-highlight text-white text-bold mr-auto">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="1"
                          style="width: 200px"
                        />
                      </content-placeholders>
                    </div>
                    <div class="bd-highlight text-white text-bold">
                      <content-placeholders :rounded="true">
                        <content-placeholders-img
                          style="width: 100px; height: 30px"
                        />
                      </content-placeholders>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 text-left">
                      <ul class="timeline list-track">
                        <li v-for="index in 6" :key="index">
                          <a class="float-right text-danger">
                            <content-placeholders :rounded="true">
                              <content-placeholders-text
                                :lines="1"
                                style="width: 200px"
                              />
                            </content-placeholders>
                          </a>
                          <p>
                            <content-placeholders :rounded="true">
                              <content-placeholders-heading
                                style="width: 200px"
                              />
                            </content-placeholders>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
import sideBar from "../../components/sidebar/sideBar";
export default {
  data() {
    return {
      isFinish: false,
      loadingOrder: true,
      loadingTracking: true,
      orderData: null,
      dataTracking: {},
      dataTrackingBaru: [],
    };
  },
  components: {
    sideBar,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  beforeMount() {
    this.getDataOrder();
  },
  methods: {
    getDataOrder() {
      this.loadingOrder = true;
      var myHeaders = new Headers();
      myHeaders.append('Authorization', this.globalToken);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      fetch(`${this.domainURL}order/${this.$route.params.id}`, requestOptions)
        .then(response => response.json())
        .then(res => {
          this.isFinish = true;
          if (res.status.code === 200) {
            this.dataTracking = res.data;
            this.getTracking(
              res.data.items[0].shippingSelected.code,
              this.dataTracking.trackingId
            );
          }
          this.loadingOrder = false;
        })
        .catch(error => {
          console.log('error', error);
          this.loadingOrder = false;
        });
    },
    getTracking(courier, waybill) {
      this.loadingTracking = true;
      const url = `https://jaja.id/backend/order/orderTracking?courier=${courier}&waybill=${waybill}`;

      // Buat objek Headers
      const myHeaders = new Headers();
      myHeaders.append('Authorization', this.globalToken);

      // Buat objek untuk konfigurasi fetch
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      fetch(url, requestOptions)
        .then(response => response.json())
        .then(result => {
          this.orderData = result;
          this.loadingTracking = false;
          console.log('ini tracking', this.orderData);
        })
        .catch(error => {
          console.error('Error fetching order data:', error);
          this.loadingTracking = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 98%;
  z-index: 9;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 9;
}
</style>