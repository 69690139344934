<template>
<div>
    <flickity ref="flickity" class="flickity" :options="flickityOptions">
    <div v-for='index in 4' :key='index'  class="carousel-cell mb-4">
        <div class="p-2 bd-highlight">
            <div class="card card-tranding bg-white p-3 shadow-custom mr-1" style="left: 10px !important;">
                <content-placeholders :rounded="true">
                <div class="kotak-img" style="height: auto !important">
                    <content-placeholders-img class="card-img-top"/>
                </div>
                <div class="kotak-body-rekom ">
                    <content-placeholders-heading />
                    <content-placeholders-text :lines="1"/>
                </div>
                </content-placeholders>
            </div>
        </div>
    </div>
    </flickity>
</div>
</template>

<script>
import Flickity from 'vue-flickity'
    export default {

        data() {
            return {
               slides: 7,
               flickityOptions: {
                  initialIndex: 0,
                  prevNextButtons: false,
                  pageDots: false,
                  cellAlign:'left',
                  wrapAround: false,
                  freeScroll: false
               },
            }
        },
        components: {
             Flickity,
        }
        
    }
</script>


<style scoped>

   /* .box-header-slider {
      margin-top:15%;
   } */

   .box-slider {
      height: 350px;
      /* margin-top: 20px;
      margin-bottom: 20px; */
   }

   .body-home {
   /* jaja */
   /* background: #eff6f8 */
   /* ebh */
   background: #f2f2f2;
   }
   .image-banner {
      height:240%;
      width: 100%;
      object-fit: contain;
   }

      .card-category-banner{
         margin-left:20px;
      }

      .card-category-testing {
         margin-right:20px;
         width:260px;
         height: 90px;
         border: 3px solid #a0cadb;
         border-radius: 10px;
      }

      .card-category-testing div p {
         color:#65b0c9;
      }

      .card-body-category {
         border-radius: 10px;
      }

      .card-category {
         /* width:250px; */
         margin-left:20px;
      }
      .body-keunggulan {
         position:relative;
      }
      .body-keunggulan .bungkus-icon {
         position:absolute;
         top:-12px;
      }
      .bungkus-icon span {
         border: 4px #ffc107 solid !important;
      }
      .body-keunggulan .bungkus-text {
         /* position:absolute; */
         margin-left:30px;
         /* width:200px; */
         border-radius:10px;
         padding: 10px 20px 10px 20px;
      }

      .banner-gift {
            width: 105%;
            position:relative;
            left:-20px;
      }
      .kotak-img  {
         height:260px;
         width:195px;
      }

      .kotak-img img{
         border-radius:20px 20px 0 0 ;
         height:100%;
         width:100%;
         object-fit: cover;
      }
      .card-rekom {
         box-shadow: 10px 10px 10px rgba(0,0,0,0.2);
         border:0.1px;
         width:215px;
         height:325px;
         border-radius:20px;
         display: flex;
         justify-content: center;
      }

      .kotak-img-rekom {
         /* background:yellow; */
         height:60%;
         width:90%;
         align-self: center;
         padding: 10px 0;
      }
      .kotak-img-rekom img {
         border-radius:20px 20px 0 0 ;
         height:100%;
         width:100%;
         object-fit: contain;
         /* margin-left:20px; */
      }
      .kotak-body-rekom{
         display: flex;
         flex-direction: column;
         padding-bottom: 0px;
         padding-top: 12px;
         padding-left: 12px;
         padding-right: 12px;
         height:40%;
         border-radius:0 0 20px 20px ;
      }
      .title-card{
         width: 90%; 
      }

      .judul-tranding {
         font-size:15px;
         color:#65b0c9;
         font-weight:bold;
      }

      .card-tranding {
         /* border-radius: 5% 5% 0 0; */
         border-radius: 20px;
      }

      .totalcari-tranding {
         color: #fdb738 ;
      }

      .totalcari-rekom {
         color:#fdb738 !important
      }

      a:hover {
         text-decoration: none;
      }

      .flex-kotak-populer {
         /* border:1px solid rgb(221, 213, 213); */
         border-radius: 20px;
      }

      .kotak-img-populer {
         width:150px;
         height:150px;
         background: white;
      }
      
      .image-populer {
         width:150px;
         height:160px;
         object-fit: cover;
         border-radius: 15% 15% 0 0;
      }

      .kotak-text-populer {
         width:150px;
         height:150px;
         background: white;
      }
      .judul-populer {
         text-align:left;
         color:#65b0c9;
         font-size:17px;
         font-weight: bold;
      }
      .harga-dan-loc-populer {
         position: absolute;;
         top:55%;
      }
      .harga-dan-loc-populer p:nth-child(1) {
         text-align: right;
         color: #e0a800;
         font-weight: bold;
         font-size: 22px;
      }
      .harga-dan-loc-populer p:nth-child(2) {
         text-align: left;
         position: relative;
         top:-10px;
      }
</style>


<style lang="scss" scoped>

.birujaja-keunggulan {
   background: #a0cadb;
}

.border-warning {
   border: 3px solid #ffc107!important;
}

.carousel-3d-slide.current {
  opacity: 1 !important;
  visibility: visible !important;
  transform: none !important;
  z-index: 999;
  position: relative;
  background-repeat:no-repeat;
  
}

.carousel-3d-slide {
    opacity: 0.5 !important;
  }

.carousel-3d-container {
  .carousel-3d-slide {
    margin-left: -65%;
  }
}

</style>