<template>
  <div>
    <div class="d-none d-lg-block">
      <div style="margin-top: -2%" class="container p-3">
        <div class="row">
          <div class="col-md-3">
            <sideBar />
          </div>
          <div class="col-md-9">
            <div class="card-product shadow-custom br-card-jaja">
              <div class="card-header br-card-header-jaja">
                <div class="d-flex justify-content-between bd-highlight">
                  <div class="pt-2 bd-highlight mr-auto">
                    <h5 class="d-flex font-weight-bold text-dark">
                      Tambah Rekening
                    </h5>
                  </div>
                  <div class="p-1 bd-highlight">
                    <h6 class="d-flex font-weight-bold">
                      <router-link to="/rekening">
                        <button
                          type="button"
                          class="btn btn-warning btn-sm text-white font-weight-bold"
                        >
                          <i class="fas fa-angle-left ver-middle"></i
                          >&nbsp;<span class="ver-middle">Kembali</span>
                        </button>
                      </router-link>
                    </h6>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="">
                  <div class="row">
                    <div class="col">
                      <div class="form-group row">
                        <label class="col-lg-3 col-form-label text-left"
                          >Bank <span class="text-danger">*</span>
                        </label>
                        <div class="col-lg-9">
                          <v-select
                            v-model="selected"
                            :options="options"
                            @input="getCodeBank"
                            label="name"
                            :placeholder="options[0].name"
                          ></v-select>
                          <!-- <select
                        @change="getCodeBank"
                        class="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option
                          v-for="(item, index) in dataBank"
                          :key="index"
                          :value="index"
                        >
                          {{ item.name }}
                        </option>
                      </select> -->
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-lg-3 col-form-label text-left"
                          >Nomor Rekening <span class="text-danger">*</span>
                        </label>
                        <div class="col-lg-9">
                          <input
                            type="number"
                            @keyup="inputData"
                            name="account"
                            class="form-control add"
                            placeholder="Masukkan No. Rekening"
                            required
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-lg-3 col-form-label text-left"
                          >Kantor Cabang <span class="text-danger">*</span>
                        </label>
                        <div class="col-lg-9">
                          <input
                            type="text"
                            name="branchOffice"
                            @keyup="inputData"
                            class="form-control add"
                            placeholder="Masukkan Kantor Cabang"
                            required=""
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-lg-3 col-form-label text-left"
                          >Kota/Kabupaten <span class="text-danger">*</span>
                        </label>
                        <div class="col-lg-9">
                          <input
                            type="text"
                            name="city"
                            @keyup="inputData"
                            class="form-control add"
                            placeholder="Masukkan Kota/Kabupaten"
                            required=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger font-weight-bold mr-2"
                >
                  Reset
                </button>
                <button
                  type="button"
                  @click="submitAddRek()"
                  name="add"
                  class="btn btn-primary bg-primary-jaja br-color-primary-jaja text-white font-weight-bold"
                >
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-lg-none" >
      <div style="margin-top: -177px" class="container-m p-3">
        <div class="row">
          <div class="col-md-12">
            <div class="card-product shadow-custom br-card-jaja">
              <div class="card-header br-card-header-jaja">
                <div class="d-flex justify-content-between bd-highlight">
                  <div class="pt-2 bd-highlight mr-auto">
                    <h5 class="d-flex font-weight-bold text-dark">
                      Tambah Rekening
                    </h5>
                  </div>
                  <div class="p-1 bd-highlight">
                    <h6 class="d-flex font-weight-bold">
                      <router-link to="/rekening">
                        <button
                          type="button"
                          class="btn btn-warning btn-sm text-white font-weight-bold"
                        >
                          <i class="fas fa-angle-left ver-middle"></i
                          >&nbsp;<span class="ver-middle">Kembali</span>
                        </button>
                      </router-link>
                    </h6>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="">
                  <div class="row">
                    <div class="col">
                      <div class="form-group row">
                        <label class="col-lg-3 col-form-label text-left"
                          >Bank <span class="text-danger">*</span>
                        </label>
                        <div class="col-lg-9">
                          <v-select
                            v-model="selected"
                            :options="options"
                            @input="getCodeBank"
                            label="name"
                            :placeholder="options[0].name"
                          ></v-select>
                          <!-- <select
                        @change="getCodeBank"
                        class="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option
                          v-for="(item, index) in dataBank"
                          :key="index"
                          :value="index"
                        >
                          {{ item.name }}
                        </option>
                      </select> -->
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-lg-3 col-form-label text-left"
                          >Nomor Rekening <span class="text-danger">*</span>
                        </label>
                        <div class="col-lg-9">
                          <input
                            type="number"
                            @keyup="inputData"
                            name="account"
                            class="form-control add"
                            placeholder="Masukkan No. Rekening"
                            required
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-lg-3 col-form-label text-left"
                          >Kantor Cabang <span class="text-danger">*</span>
                        </label>
                        <div class="col-lg-9">
                          <input
                            type="text"
                            name="branchOffice"
                            @keyup="inputData"
                            class="form-control add"
                            placeholder="Masukkan Kantor Cabang"
                            required=""
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-lg-3 col-form-label text-left"
                          >Kota/Kabupaten <span class="text-danger">*</span>
                        </label>
                        <div class="col-lg-9">
                          <input
                            type="text"
                            name="city"
                            @keyup="inputData"
                            class="form-control add"
                            placeholder="Masukkan Kota/Kabupaten"
                            required=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger font-weight-bold mr-2"
                >
                  Reset
                </button>
                <button
                  type="button"
                  @click="submitAddRek()"
                  name="add"
                  class="btn btn-primary bg-primary-jaja br-color-primary-jaja text-white font-weight-bold"
                >
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <br />
        <NavBar />
      </div>
    </div>
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
<script>
import { mapGetters } from "vuex";
import sideBar from "../../components/sidebar/sideBar";
import NavBar from "../../components/master/navbarbuttom.vue";
export default {
  data() {
    return {
      dataBank: {},
      req: {
        bankCode: "",
        bankName: "",
        account: "",
        branchOffice: "",
        city: "",
      },
      options: [],
      selected: "",
    };
  },
  components: {
    sideBar,
    NavBar,
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  mounted() {
    this.getListBank(), window.scrollTo(0, 0);
  },
  methods: {
    getListBank() {
      var myHeaders = new Headers();
      myHeaders.append("Cookie", "ci_session=nh86ml06ab0ov233dfmdum1aodes5870");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      // fetch(`${this.domainURL}master/bank`, requestOptions)
      fetch("https://jaja.id/backend/master/bank", requestOptions)
        .then((response) => response.json())
        .then((res) => {
          res.data.unshift({
            code: "",
            name: "Silahkan Pilih Rekening Milik Anda",
          });
          if (res.status.code === 200) {
            this.options = res.data;
          }
        })
        .catch((error) => console.log("error", error));
    },
    getCodeBank() {
      const bankCode = this.selected.code;
      const bankName = this.selected.name;
      this.req.bankCode = bankCode;
      this.req.bankName = bankName;
    },
    inputData() {
      const obj = event.target.name;
      if (obj == "account") {
        this.req.account = event.target.value;
      } else if (obj == "branchOffice") {
        this.req.branchOffice = event.target.value;
      } else if (obj == "city") {
        this.req.city = event.target.value;
      }
    },
    submitAddRek() {
      const objArrKeys = Object.keys(this.req);
      const objArrVals = Object.values(this.req);
      const params = [];
      objArrKeys.map((item, index) => {
        if (index !== 3 && index !== 4) {
          params.push({
            index: index,
            value: item,
          });
        }
      });

      if (objArrVals[1] === "" || objArrVals[2] === "") {
        if (objArrVals[1] === "") {
          this.$swal.fire("", "Mohon pilih bank anda", "warning");
        }

        if (objArrVals[2] === "") {
          this.$swal.fire("", "Mohon input nomer rekening anda", "warning");
        }
      } else {
        this.runAddReq(this.req);
      }
    },
    runAddReq(req) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=nh86ml06ab0ov233dfmdum1aodes5870");

      var raw = JSON.stringify(req);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/bankAccount`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.$swal.fire("Sukses", res.status.message, "success");
            this.$router.push("/rekening");
          } else {
            this.$swal.fire("Gagal", res.status.message, "warning");
          }
        })
        .catch((error) => console.log("error", error));
    },
  },
};
</script>
<style>
.container-m {
  padding: 10px;
}
</style>