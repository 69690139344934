<template>
  <div>
    <div class="d-none d-lg-block">
      <div style="margin-top: -2%" class="container p-3">
        <div class="row">
          <div class="col-md-3">
            <sideBar />
          </div>
          <div class="col-md-9" v-if="isFinish">
            <div class="card-product shadow-custom br-card-jaja">
              <div class="card-body">
                <ul class="list-group">
                  <li class="list-group-item border-0">
                    <div class="row">
                      <div class="col-md-6 btn">
                        <div
                          id="history_koin"
                          @click="tabHistory('history_koin')"
                          class="p-2 rounded text-dark font-weight-bold h6"
                          style="
                            font-weight: bold;
                            border: 2px solid #65b0c9 !important;
                            border-radius: 8px !important;
                          "
                        >
                          History Koin
                        </div>
                      </div>
                      <div class="col-md-6 btn">
                        <div
                          id="history_penarikan_koin"
                          @click="tabHistory('history_penarikan_koin')"
                          class="p-2 rounded h6 text-white bg-primary-jaja font-weight-bold"
                          style="
                            font-weight: bold;
                            border: 2px solid #65b0c9 !important;
                            border-radius: 8px !important;
                          "
                        >
                          History Penarikan Koin
                        </div>
                      </div>
                    </div>
                    <hr
                      style="color: black !important: padding: 0px; margin: 0px;"
                    />
                  </li>

                  <li class="list-group-item border-0 history_koin d-none">
                    <div class="slide-history-koin">
                      <div class="row">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Jumlah</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in dataKoin" :key="index">
                              <th scope="row">{{ index + 1 }}</th>
                              <td
                                :class="
                                  item.tipe_koin !== 'minus'
                                    ? `text-success`
                                    : `text-danger`
                                "
                              >
                                {{ item.tipe_koin ? `+` : `-` }} {{ item.koin }}
                              </td>
                              <td>{{ item.note }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <hr
                      style="color: black !important: padding: 0px; margin: 0px;"
                    />
                  </li>
                  <li class="list-group-item border-0 history_penarikan_koin">
                    <div class="slide-history-penarikan-koin">
                      <div class="d-flex flex-row">
                        <div class="font-weight-bold mr-auto">
                          Koin Tersedia
                        </div>
                        <div
                          class="pt-0 pl-2 pr-2 pb-2 font-weight-bold mb-2"
                          style="margin-top: -5px"
                        >
                          <img
                            src="https://jsonx.jaja.id/asset/front/images/koin.png"
                            alt="coin-gif"
                            width="32"
                          />
                          <span class="nilaiKoin font-weight-bold">{{
                            user.coinFormat
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <hr
                      style="color: black !important: padding: 0px; margin: 0px;"
                    />
                  </li>
                  <li class="list-group-item border-0 history_penarikan_koin">
                    <button
                      @click="showModalTS"
                      class="btn bg-orange-jaja btn-block text-white font-weight-bold br-color-button-jaja"
                    >
                      Ajukan Tarik Saldo
                    </button>
                  </li>
                  <li class="list-group-item border-0 history_penarikan_koin">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Tanggal</th>
                          <th scope="col">Kode Referensi</th>
                          <th scope="col">Jumlah</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody class="listDataPenarikan">
                        <tr
                          v-for="(item, index) in listDataPenarikan"
                          :key="index"
                        >
                          <td>{{ item.created_date }}</td>
                          <td>JJPO-0{{ item.id_customer }}{{ item.id_pay }}</td>
                          <td>
                            {{
                              item.amount.toLocaleString("id-ID", {
                                style: "currency",
                                currency: "IDR",
                              })
                            }}
                          </td>
                          <td v-if="item.status == 'queued'">
                            <button class="btn btn-xs btn-warning">
                              Menunggu
                            </button>
                          </td>
                          <td v-else-if="item.status == 'processed'">
                            <button class="btn btn-xs btn-info">
                              Diproses
                            </button>
                          </td>
                          <td v-else-if="item.status == 'completed'">
                            <button class="btn btn-xs btn-success">
                              Selesai
                            </button>
                          </td>
                          <td v-else>
                            <button class="btn btn-xs btn-danger">Gagal</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-9" v-if="!isFinish">
            <div class="card-product shadow-custom br-card-jaja">
              <div class="card-body">
                <ul class="list-group">
                  <li class="list-group-item border-0">
                    <div class="row">
                      <div class="col-md-6 btn">
                        <div
                          id="history_koin"
                          class="p-2 rounded text-dark font-weight-bold h6"
                          style="
                            font-weight: bold;
                            border: 2px solid #65b0c9 !important;
                            border-radius: 8px !important;
                          "
                        >
                          <content-placeholders
                            :rounded="true"
                            :centered="true"
                          >
                            <content-placeholders-img
                              style="width: 100%; height: 20px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                      <div class="col-md-6 btn">
                        <div
                          id="history_penarikan_koin"
                          class="p-2 rounded h6 text-white bg-primary-jaja font-weight-bold"
                          style="
                            font-weight: bold;
                            border: 2px solid #65b0c9 !important;
                            border-radius: 8px !important;
                          "
                        >
                          <content-placeholders
                            :rounded="true"
                            :centered="true"
                          >
                            <content-placeholders-img
                              style="width: 100%; height: 20px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                    </div>
                    <hr
                      style="color: black !important: padding: 0px; margin: 0px;"
                    />
                  </li>

                  <li class="list-group-item border-0 history_penarikan_koin">
                    <div class="slide-history-penarikan-koin">
                      <div class="d-flex flex-row">
                        <div class="font-weight-bold mr-auto">
                          <content-placeholders
                            :rounded="true"
                            :centered="true"
                          >
                            <content-placeholders-img
                              style="width: 100px; height: 20px"
                            />
                          </content-placeholders>
                        </div>
                        <div
                          class="pt-0 pl-2 pr-2 pb-2 font-weight-bold mb-2"
                          style="margin-top: -5px"
                        >
                          <content-placeholders
                            :rounded="true"
                            :centered="true"
                          >
                            <content-placeholders-img
                              style="width: 120px; height: 20px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                    </div>
                    <hr
                      style="color: black !important: padding: 0px; margin: 0px;"
                    />
                  </li>
                  <li class="list-group-item border-0 history_penarikan_koin">
                    <content-placeholders :rounded="true" :centered="true">
                      <content-placeholders-img
                        style="width: 100%; height: 20px"
                      />
                    </content-placeholders>
                  </li>
                  <li class="list-group-item border-0 history_penarikan_koin">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">
                            <content-placeholders
                              :rounded="true"
                              :centered="true"
                            >
                              <content-placeholders-text
                                :lines="1"
                                style="width: 100%; height: 20px"
                              />
                            </content-placeholders>
                          </th>
                          <th scope="col">
                            <content-placeholders
                              :rounded="true"
                              :centered="true"
                            >
                              <content-placeholders-text
                                :lines="1"
                                style="width: 100%; height: 20px"
                              />
                            </content-placeholders>
                          </th>
                          <th scope="col">
                            <content-placeholders
                              :rounded="true"
                              :centered="true"
                            >
                              <content-placeholders-text
                                :lines="1"
                                style="width: 100%; height: 20px"
                              />
                            </content-placeholders>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="listDataPenarikan">
                        <tr></tr>
                      </tbody>
                    </table>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="modalTarikSaldo"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title font-weight-bold">Tarik Saldo</h5>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div
                v-if="Object.keys(dataWithdraw).length > 0"
                class="modal-body"
              >
                <div
                  class="row"
                  style="
                    border-left: 6px solid #1aaac8 !important;
                    background-color: #ddffff !important;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    margin: auto;
                  "
                >
                  <div class="col d-flex">Sisa Saldo</div>
                  <div class="col text-right text-bold coinRemaining">
                    Rp. {{ dataWithdraw.coinRemainingCurrencyFormat }}
                  </div>
                </div>
                <br />
                <div
                  class="row"
                  style="
                    border-left: 6px solid #1aaac8 !important;
                    background-color: #ddffff !important;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    margin: auto;
                  "
                >
                  <div class="col d-flex">Penarikan Diproses</div>
                  <div class="col text-right text-bold payoutsQueued">Rp0</div>
                </div>
                <div class="row mt-3">
                  <div class="col">
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label d-flex"
                        >Rekening Tujuan
                        <span class="text-danger">*</span></label
                      >
                      <div class="col-lg-9 d-flex">
                        <span class=""
                          >{{ dataWithdraw.bank.bankName }}
                          <div class="d-flex">
                            {{ dataWithdraw.bank.account }}
                          </div>
                        </span>
                        <br />
                        <span class="rekBank"></span>
                        <span class="namaUserBank"></span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label d-flex"
                        >Nominal <span class="text-danger">*</span>
                        <i
                          class="fas fa-question-circle ml-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title=""
                          data-original-title="Setiap penarikan saldo dikenakan biaya admin sebesar Rp0"
                        ></i
                      ></label>
                      <div class="col-lg-9">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">Rp.</div>
                          </div>
                          <input
                            type="number"
                            @keyup="inputTarikKoin"
                            name="amount"
                            class="form-control tarik_saldo"
                            placeholder="Masukkan Nominal Penarikan"
                            required=""
                          />
                        </div>
                        <small class="help-block clsa float-left mt-1 ml-1">
                          <b
                            >Minimum penarikan
                            <span class="minimalPenarikan text-danger">{{
                              dataWithdraw.minPayoutCurrencyFormat
                            }}</span></b
                          >
                        </small>
                        <small
                          class="help-block float-right mt-1 ml-1 text-danger"
                          id="error_amount"
                        ></small>
                        <div class="clearfix"></div>
                        <div class="d-flex flex-row-reverse">
                          <div class="p-2">
                            Biaya Admin
                            <span class="biayaAdmin text-danger">{{
                              dataWithdraw.feePayoutCurrencyFormat
                            }}</span>
                          </div>
                        </div>
                        <div class="d-flex flex-row-reverse">
                          <div class="p-2">
                            Total yang di terima
                            <span class="totalTerimaUang text-danger"
                              >Rp{{ totalTerimaUang }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label d-flex"
                        >Catatan</label
                      >
                      <div class="col-lg-9">
                        <textarea
                          @keyup="inputKetTarikKoin"
                          name="notes"
                          class="form-control tarik_saldo"
                          placeholder="Masukkan Catatan"
                          cols="20"
                          rows="5"
                          maxlength="100"
                        ></textarea>
                        <span
                          class="help-block float-right"
                          id="maxlength_notes"
                          >0/100</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  :disabled="isLoading"
                  type="button"
                  class="btn btn-secondary font-weight-bold"
                  data-dismiss="modal"
                  style="background: #fdb738; border-color: #fdb738"
                >
                  Close
                </button>
                <button
                  :disabled="isLoading"
                  type="button"
                  @click="runTarikSaldo"
                  class="btn btn-primary font-weight-bold"
                  style="background: #64b0c9; border-color: #64b0c9"
                >
                  Tarik Saldo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-lg-none">
      <div style="margin-top: -177px" class="container-m p-3">
        <div class="row">
          <div class="col-md-12" v-if="isFinish">
            <div class="card-product shadow-custom br-card-jaja">
              <div class="card-body">
                <ul class="list-group">
                  <li class="list-group-item border-0">
                    <div class="row">
                      <div class="col-md-6 btn">
                        <div
                          id="history_koin"
                          @click="tabHistory('history_koin')"
                          class="p-2 rounded text-dark font-weight-bold h6"
                          style="
                            font-weight: bold;
                            border: 2px solid #65b0c9 !important;
                            border-radius: 8px !important;
                          "
                        >
                          History Koin
                        </div>
                      </div>
                      <div class="col-md-6 btn">
                        <div
                          id="history_penarikan_koin"
                          @click="tabHistory('history_penarikan_koin')"
                          class="p-2 rounded h6 text-white bg-primary-jaja font-weight-bold"
                          style="
                            font-weight: bold;
                            border: 2px solid #65b0c9 !important;
                            border-radius: 8px !important;
                          "
                        >
                          History Penarikan Koin
                        </div>
                      </div>
                    </div>
                    <hr
                      style="color: black !important: padding: 0px; margin: 0px;"
                    />
                  </li>

                  <li class="list-group-item border-0 history_koin d-none">
                    <div class="slide-history-koin">
                      <div class="row">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Jumlah</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in dataKoin" :key="index">
                              <th scope="row">{{ index + 1 }}</th>
                              <td
                                :class="
                                  item.tipe_koin !== 'minus'
                                    ? `text-success`
                                    : `text-danger`
                                "
                              >
                                {{ item.tipe_koin ? `+` : `-` }} {{ item.koin }}
                              </td>
                              <td>{{ item.note }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <hr
                      style="color: black !important: padding: 0px; margin: 0px;"
                    />
                  </li>
                  <li class="list-group-item border-0 history_penarikan_koin">
                    <div class="slide-history-penarikan-koin">
                      <div class="d-flex flex-row">
                        <div class="font-weight-bold mr-auto">
                          Koin Tersedia
                        </div>
                        <div
                          class="pt-0 pl-2 pr-2 pb-2 font-weight-bold mb-2"
                          style="margin-top: -5px"
                        >
                          <img
                            src="https://jsonx.jaja.id/asset/front/images/koin.png"
                            alt="coin-gif"
                            width="32"
                          />
                          <span class="nilaiKoin font-weight-bold">{{
                            user.coinFormat
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <hr
                      style="color: black !important: padding: 0px; margin: 0px;"
                    />
                  </li>
                  <li class="list-group-item border-0 history_penarikan_koin">
                    <button
                      @click="showModalTS"
                      class="btn bg-orange-jaja btn-block text-white font-weight-bold br-color-button-jaja"
                    >
                      Ajukan Tarik Saldo
                    </button>
                  </li>
                  <li class="list-group-item border-0 history_penarikan_koin">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Tanggal</th>
                          <th scope="col">Referensi</th>
                          <th scope="col">Jumlah</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody class="listDataPenarikan">
                        <tr
                          v-for="(item, index) in listDataPenarikan"
                          :key="index"
                        >
                          <td>{{ item.created_date }}</td>
                          <td>JJPO-0{{ item.id_customer }}{{ item.id_pay }}</td>
                          <td>
                            {{
                              item.amount.toLocaleString("id-ID", {
                                style: "currency",
                                currency: "IDR",
                              })
                            }}
                          </td>
                          <td v-if="item.status == 'queued'">
                            <button class="btn btn-xs btn-warning">
                              Menunggu
                            </button>
                          </td>
                          <td v-else-if="item.status == 'processed'">
                            <button class="btn btn-xs btn-info">
                              Diproses
                            </button>
                          </td>
                          <td v-else-if="item.status == 'completed'">
                            <button class="btn btn-xs btn-success">
                              Selesai
                            </button>
                          </td>
                          <td v-else>
                            <button class="btn btn-xs btn-danger">Gagal</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-9" v-if="!isFinish">
            <div class="card-product shadow-custom br-card-jaja">
              <div class="card-body">
                <ul class="list-group">
                  <li class="list-group-item border-0">
                    <div class="row">
                      <div class="col-md-6 btn">
                        <div
                          id="history_koin"
                          class="p-2 rounded text-dark font-weight-bold h6"
                          style="
                            font-weight: bold;
                            border: 2px solid #65b0c9 !important;
                            border-radius: 8px !important;
                          "
                        >
                          <content-placeholders
                            :rounded="true"
                            :centered="true"
                          >
                            <content-placeholders-img
                              style="width: 100%; height: 20px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                      <div class="col-md-6 btn">
                        <div
                          id="history_penarikan_koin"
                          class="p-2 rounded h6 text-white bg-primary-jaja font-weight-bold"
                          style="
                            font-weight: bold;
                            border: 2px solid #65b0c9 !important;
                            border-radius: 8px !important;
                          "
                        >
                          <content-placeholders
                            :rounded="true"
                            :centered="true"
                          >
                            <content-placeholders-img
                              style="width: 100%; height: 20px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                    </div>
                    <hr
                      style="color: black !important: padding: 0px; margin: 0px;"
                    />
                  </li>

                  <li class="list-group-item border-0 history_penarikan_koin">
                    <div class="slide-history-penarikan-koin">
                      <div class="d-flex flex-row">
                        <div class="font-weight-bold mr-auto">
                          <content-placeholders
                            :rounded="true"
                            :centered="true"
                          >
                            <content-placeholders-img
                              style="width: 100px; height: 20px"
                            />
                          </content-placeholders>
                        </div>
                        <div
                          class="pt-0 pl-2 pr-2 pb-2 font-weight-bold mb-2"
                          style="margin-top: -5px"
                        >
                          <content-placeholders
                            :rounded="true"
                            :centered="true"
                          >
                            <content-placeholders-img
                              style="width: 120px; height: 20px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                    </div>
                    <hr
                      style="color: black !important: padding: 0px; margin: 0px;"
                    />
                  </li>
                  <li class="list-group-item border-0 history_penarikan_koin">
                    <content-placeholders :rounded="true" :centered="true">
                      <content-placeholders-img
                        style="width: 100%; height: 20px"
                      />
                    </content-placeholders>
                  </li>
                  <li class="list-group-item border-0 history_penarikan_koin">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">
                            <content-placeholders
                              :rounded="true"
                              :centered="true"
                            >
                              <content-placeholders-text
                                :lines="1"
                                style="width: 100%; height: 20px"
                              />
                            </content-placeholders>
                          </th>
                          <th scope="col">
                            <content-placeholders
                              :rounded="true"
                              :centered="true"
                            >
                              <content-placeholders-text
                                :lines="1"
                                style="width: 100%; height: 20px"
                              />
                            </content-placeholders>
                          </th>
                          <th scope="col">
                            <content-placeholders
                              :rounded="true"
                              :centered="true"
                            >
                              <content-placeholders-text
                                :lines="1"
                                style="width: 100%; height: 20px"
                              />
                            </content-placeholders>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="listDataPenarikan">
                        <tr></tr>
                      </tbody>
                    </table>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="modalTarikSaldo"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title font-weight-bold">Tarik Saldo</h5>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div
                v-if="Object.keys(dataWithdraw).length > 0"
                class="modal-body"
              >
                <div
                  class="row"
                  style="
                    border-left: 6px solid #1aaac8 !important;
                    background-color: #ddffff !important;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    margin: auto;
                  "
                >
                  <div class="col d-flex">Sisa Saldo</div>
                  <div class="col text-right text-bold coinRemaining">
                    Rp. {{ dataWithdraw.coinRemainingCurrencyFormat }}
                  </div>
                </div>
                <br />
                <div
                  class="row"
                  style="
                    border-left: 6px solid #1aaac8 !important;
                    background-color: #ddffff !important;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    margin: auto;
                  "
                >
                  <div class="col d-flex">Penarikan Diproses</div>
                  <div class="col text-right text-bold payoutsQueued">Rp0</div>
                </div>
                <div class="row mt-3">
                  <div class="col">
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label d-flex"
                        >Rekening Tujuan
                        <span class="text-danger">*</span></label
                      >
                      <div class="col-lg-9 d-flex">
                        <span class=""
                          >{{ dataWithdraw.bank.bankName }}
                          <div class="d-flex">
                            {{ dataWithdraw.bank.account }}
                          </div>
                        </span>
                        <br />
                        <span class="rekBank"></span>
                        <span class="namaUserBank"></span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label d-flex"
                        >Nominal <span class="text-danger">*</span>
                        <i
                          class="fas fa-question-circle ml-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title=""
                          data-original-title="Setiap penarikan saldo dikenakan biaya admin sebesar Rp0"
                        ></i
                      ></label>
                      <div class="col-lg-9">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">Rp.</div>
                          </div>
                          <input
                            type="number"
                            @keyup="inputTarikKoin"
                            name="amount"
                            class="form-control tarik_saldo"
                            placeholder="Masukkan Nominal Penarikan"
                            required=""
                          />
                        </div>
                        <small class="help-block clsa float-left mt-1 ml-1">
                          <b
                            >Minimum penarikan
                            <span class="minimalPenarikan text-danger">{{
                              dataWithdraw.minPayoutCurrencyFormat
                            }}</span></b
                          >
                        </small>
                        <small
                          class="help-block float-right mt-1 ml-1 text-danger"
                          id="error_amount"
                        ></small>
                        <div class="clearfix"></div>
                        <div class="d-flex flex-row-reverse">
                          <div class="p-2">
                            Biaya Admin
                            <span class="biayaAdmin text-danger">{{
                              dataWithdraw.feePayoutCurrencyFormat
                            }}</span>
                          </div>
                        </div>
                        <div class="d-flex flex-row-reverse">
                          <div class="p-2">
                            Total yang di terima
                            <span class="totalTerimaUang text-danger"
                              >Rp{{ totalTerimaUang }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label d-flex"
                        >Catatan</label
                      >
                      <div class="col-lg-9">
                        <textarea
                          @keyup="inputKetTarikKoin"
                          name="notes"
                          class="form-control tarik_saldo"
                          placeholder="Masukkan Catatan"
                          cols="20"
                          rows="5"
                          maxlength="100"
                        ></textarea>
                        <span
                          class="help-block float-right"
                          id="maxlength_notes"
                          >0/100</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  :disabled="isLoading"
                  type="button"
                  class="btn btn-secondary font-weight-bold"
                  data-dismiss="modal"
                  style="background: #fdb738; border-color: #fdb738"
                >
                  Close
                </button>
                <button
                  :disabled="isLoading"
                  type="button"
                  @click="runTarikSaldo"
                  class="btn btn-primary font-weight-bold"
                  style="background: #64b0c9; border-color: #64b0c9"
                >
                  Tarik Saldo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <br />
        <NavBar />
      </div>
    </div>
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
<script>
import { mapGetters } from "vuex";
import sideBar from "../../components/sidebar/sideBar";
import mapGoggle from "../../components/mapcomponent/mapcomp.vue";
import NavBar from "../../components/master/navbarbuttom.vue";
export default {
  data() {
    return {
      dataPayOut: {},
      dataKoin: {},
      dataWithdraw: {},
      submitTarikSaldos: {
        bankId: "",
        amount: "",
        note: "",
      },
      totalTerimaUang: 0,
      isLoading: false,
      listDataPenarikan: [],
      isFinish: false,
    };
  },
  components: {
    sideBar,
    NavBar,
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  mounted() {
    this.getFirstFunction();
    window.scrollTo(0, 0);
  },
  methods: {
    getFirstFunction() {
      this.getDataPayOut(), this.getHistoryKoin(), this.listCustomerPayOut();
    },
    getDataPayOut() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Cookie", "ci_session=r3rqr2lumbostcrujs286hthp1jfngnt");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/ListCustomerPayouts`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          this.isFinish = true;
          if (res.status.code === 200) {
            this.dataPayOut = res.data;
          }
        })
        .catch((error) => console.log("error", error));
    },

    getHistoryKoin() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Cookie", "ci_session=r3rqr2lumbostcrujs286hthp1jfngnt");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/historyKoin`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.dataKoin = res.data.history;
            if (res.status.code == 200) {
              if (res.status.message == "get data success") {
                const data = [];
                if (res.data.total_record > 0) {
                  res.data.history.forEach(function (a, b) {
                    const html = `
                                        <tr>
                                            <td>${b + 1}</td>
                                            <td class="${
                                              a.tipe_koin == "minus"
                                                ? `text-danger`
                                                : `text-success`
                                            }">${
                      a.tipe_koin == "minus" ? `-${a.koin}` : `+${a.koin}`
                    }</td>
                                            <td>${a.note}</td>
                                        <tr>
                                        `;
                    data.push(html);
                  });
                  $(".listDataPakaiKoin").html(
                    data.toString().replaceAll(",", "")
                  );
                }
              }
            } else if (res.status.code == 204) {
            }
          }
        })
        .catch((error) => console.log("error", error));
    },
    tabHistory(slug) {
      if (slug == "history_koin") {
        $(`#history_koin`).addClass(
          "text-white bg-primary-jaja font-weight-bold"
        );
        $(`#history_koin`).removeClass("text-dark");
        $(`#history_penarikan_koin`).addClass("text-dark border");
        $(`#history_penarikan_koin`).removeClass(
          "text-white bg-primary-jaja font-weight-bold"
        );
        $(`.history_koin`).removeClass("d-none");
        $(`.history_penarikan_koin`).addClass("d-none");
      } else {
        $(`#history_penarikan_koin`).addClass(
          "text-white bg-primary-jaja font-weight-bold"
        );
        $(`#history_penarikan_koin`).removeClass("text-dark");
        $(`#history_koin`).addClass("text-dark");
        $(`#history_koin`).removeClass(
          "text-white bg-primary-jaja font-weight-bold"
        );
        $(`.history_penarikan_koin`).removeClass("d-none");
        $(`.history_koin`).addClass("d-none");
      }
    },
    showModalTS() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/withdraw`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.dataWithdraw = res.data;
            this.submitTarikSaldos.bankId = this.dataWithdraw.bank.id;
            $("#modalTarikSaldo").modal("show");
            $("#form-saldo")[0].reset();
          } else {
            this.$swal.fire("Warning", res.status.message, "warning");
          }
        })
        .catch((error) => console.log("error", error));
    },
    runTarikSaldo() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify(this.submitTarikSaldos);

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/payoutCoin`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.isLoading = false;
            this.$swal
              .fire("Sukses", res.status.message, "success")
              .then(() => {
                location.reload();
              });
            this.getFirstFunction;
          } else {
            this.$swal.fire("Warning", res.status.message, "warning");
            $("#tarik_saldo").modal("hide");
          }
        })
        .catch((error) => console.log("error", error));
    },
    inputTarikKoin() {
      this.submitTarikSaldos.amount = event.target.value;
      const totalTerima = Number(
        parseInt(event.target.value.replaceAll(".", "")) -
          parseInt(this.dataWithdraw.feePayout)
      );
      if (totalTerima > 0) {
        this.totalTerimaUang = totalTerima;
      }
    },
    inputKetTarikKoin() {
      this.submitTarikSaldos.note = event.target.value;
    },
    listCustomerPayOut() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/ListCustomerPayouts`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code == 200) {
            if (res.status.message == "get data success") {
              const data = [];
              if (res.data.total_record > 0) {
                this.listDataPenarikan = res.data.items;

                // console.log(this.listDataPenarikan);

                // res.data.items.forEach(function(a,b){
                //     const html = `
                //     <tr>
                //         <td>${b + 1}</td>
                //         <td>${a.amount}</td>
                //         <td>${a.status}</td>
                //     <tr>
                //     `
                //     data.push(html);
                // });
                // $(".listDataPenarikan").html(data);
              }
            }
          } else if (res.status.code == 204) {
            $(".listDataPenarikan").html(`
                            <tr>
                                <td colspan="12">Data masih kosong</td>
                            </tr>
                        `);
          }
        })
        .catch((error) => console.log("error", error));
    },
  },
};
</script>

<style>
.bg-orange-jaja {
  background: #fdb738 !important;
}
.container-m {
  padding: 10px;
}
</style>