<template>
  <div @keydown.esc="escapeFunction" id="app">
 
    <div v-if="paramUrl === 'Login'">
      <div :class="paramUrl === 'Login' ? 'box-login' : ''">
        <router-view :key="$route.path" />
      </div>
    </div>
    <div v-else-if="paramUrl === 'LupaPassword'">
      <div :class="paramUrl === 'LupaPassword' ? 'box-login' : ''">
        <router-view :key="$route.path" />
      </div>
    </div>
    <div v-else-if="paramUrl === 'LupaPasswordVerification'">
      <div :class="paramUrl === 'LupaPasswordVerification' ? 'box-login' : ''">
        <router-view :key="$route.path" />
      </div>
    </div>
    <div v-else-if="paramUrl === 'LupaPasswordNewPassword'">
      <div :class="paramUrl === 'LupaPasswordNewPassword' ? 'box-login' : ''">
        <router-view :key="$route.path" />
      </div>
    </div>
    <div v-else-if="paramUrl === 'chat'">
      <div :class="paramUrl === 'chat' ? '' : ''">
        <router-view :key="$route.path" />
      </div>
    </div>
    <div v-else-if="paramUrl === 'seller'">
      <div :class="paramUrl === 'seller' ? '' : ''">
        <router-view :key="$route.path" />
      </div>
    </div>
    <div v-else-if="paramUrl === 'RegisterVerification'">
      <div :class="paramUrl === 'RegisterVerification' ? 'box-login' : ''">
        <router-view :key="$route.path" />
      </div>
    </div>
    <div v-else>
      <div v-if="paramUrl === 'register'">
        <div :class="paramUrl === 'register' ? 'box-login' : ''">
          <router-view :key="$route.path" />
        </div>
      </div>
      
      <div v-else>
        <!-- <let-it-snow v-bind="snowConf" :show="show"></let-it-snow>
        <let-it-snow v-bind="snowConf2" :show="show2"></let-it-snow>
        <let-it-snow v-bind="snowConf3" :show="show3"></let-it-snow> -->
        <navbar />
        <div class="bg-dasar">
          <router-view :key="$route.path" />
        </div>
        <div v-if="paramUrl !== 'chat'">
          <footerbottom />
        </div>
      </div>
    </div>
  </div>
</template>

<script
  async
  src="https://www.googletagmanager.com/gtag/js?id=G-BGPP8PTCCV"
></script>

<script>
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());

gtag("config", "G-BGPP8PTCCV");
</script>

<script>
import navbar from "./components/master/navbar";
import footerbottom from "./components/master/footer";
// import footer from './components/master/footer'

import { mapGetters } from "vuex";

export default {
  components: {
    navbar,
    footerbottom,
  },
  computed: {
    paramUrl() {
      // let url_string = window.location.href;
      // let url = new URL(url_string);
      // console.log(url)
      return this.$route.name;
    },
    ...mapGetters({
      dataUrl: "urlGetters",
    }),
  },
  data() {
    return {
      // snowConf: {
      //   windPower: 0.5,
      //   speed: 1.5,
      //   count: 5,
      //   size: 15,
      //   opacity: 1,
      //   images: [
      //     "https://cdn1.iconfinder.com/data/icons/ramadan-191/512/Drum-64.png",
      //   ],
      // },
      // show: false,
      // snowConf2: {
      //   windPower: 0.5,
      //   speed: 1.5,
      //   count: 5,
      //   size: 15,
      //   opacity: 0.5,
      //   images: [
      //     "https://cdn2.iconfinder.com/data/icons/ramadan-filled-1/64/ramadan-08-512.png",
      //   ],
      // },
      // show2: false,
      snowConf3: {
        windPower: 0.5,
        speed: 0.5,
        count: 10,
        size: 25,
        opacity: 0.5,
        images: { src: require("@/assets/ketupat1.png") },
      },

      // TEMA NATAL VERSI KAMALA
      // snowConf: {
      //   windPower: 0.5,
      //   speed: 1.5,
      //   count: 10,
      //   size: 15,
      //   opacity: 1,
      //   images: [
      //     "https://raw.githubusercontent.com/bob-chen/let_it_snow/master/demo/snow.png",
      //   ],
      // },
      // show: false,
      // snowConf2: {
      //   windPower: 0.5,
      //   speed: 1.5,
      //   count: 5,
      //   size: 9,
      //   opacity: 0.5,
      //   images: [
      //     "https://raw.githubusercontent.com/bob-chen/let_it_snow/master/demo/sock.png",
      //   ],
      // },
      // show2: false,
      // snowConf3: {
      //   windPower: 0.5,
      //   speed: 1.5,
      //   count: 8,
      //   size: 13,
      //   opacity: 0.5,
      //   images: [
      //     "https://raw.githubusercontent.com/bob-chen/let_it_snow/master/demo/tree.png",
      //   ],
      // },
      show3: false,
    };
  },
  created() {
    this.home();
    this.recommended();
  },
  mounted() {
    (this.show = true), (this.show2 = true), (this.show3 = true), this.getUrl();
  },
  methods: {
    escapeFunction() {},
    home() {
      this.$store.dispatch("getHome");
    },
    recommended() {
      this.$store.dispatch("getRecomendation");
    },
    getUrl() {
      this.$store.dispatch("getUrlDinamic");
      // let url_string = window.location.href;
      // let url = new URL(url_string);
      // this.paramUrl = url.pathname.split("/").slice(-1)[0];
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Asap&family=Poppins:wght@700&family=Signika+Negative:wght@300&display=swap"); */
body {
  font-family: "Poppins", sans-serif;
}
.swal2-popup {
  font-family: "Poppins", sans-serif;
}
.swal2-title {
  font-family: "Poppins", sans-serif;
}

.swal2-text {
  font-family: "Poppins", sans-serif;
}

.swal2-footer {
  font-family: "Poppins", sans-serif;
}

.swal2-button {
  font-family: "Poppins";
}

.swal2-styled.swal2-confirm {
  font-family: "Poppins";
  font-weight: bold;
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #65b0c9 !important;
  color: #fff;
  font-size: 1em;
}

.swal2-styled.swal2-deny {
  font-family: "Poppins";
  font-weight: bold;
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #d6242abf !important;
  color: #fff;
  font-size: 1em;
}

.swal2-html-container {
  font-family: "Poppins", sans-serif;
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em 0.3em;
  padding: 0;
  overflow: auto;
  color: inherit;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
}

.swal2-html {
  font-family: "Poppins", sans-serif;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.container {
  max-width: 80vw !important;
}

.box-login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("./assets/bg/banner-login3.png");
  background-size: cover;
  background-position: center;
}

@media only screen and (min-width: 450px) {
  .bg-dasar {
    /* jaja */
    /* background:#eff6f8; */
    /* ebh */
    background: #f2f2f2;
    /* padding-top: 180px; */
    /* padding: 200px 20px 20px 20px; */
    padding: 200px 0px 0px 0px;
    /* opacity: 0.5; */
  }
}
@media only screen and (max-width: 449px) {
  .bg-dasar {
    /* jaja */
    /* background:#eff6f8; */
    /* ebh */
    background: #f2f2f2;
    margin-top: 180px;
    /* padding: 20px; */
    /* opacity: 0.5; */
  }
}

/* CUSTOM BY MALA */
.shadow-custom {
  /* box-shadow: 0 .5rem 1rem rgba(0,0,0,.30)!important; */
  box-shadow: rgba(0, 0, 0, 0.5) 8px 8px 10px -8px !important;
}
a:hover {
  text-decoration: none !important;
}

/* CUSTOM RADIO BUTTON DI VARIANT PRODUCT DETAIL*/
.btn-outline-primary {
  color: #65b0c9 !important;
  background-color: #fff !important;
  border-color: #65b0c9 !important;
  margin-left: 10px;
  margin-right: 10px;
  width: 80px;
  border-radius: 5px !important;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.open > .dropdown-toggle.btn-outline-primary {
  color: #fff !important;
  background-color: #65b0c9 !important;
  border-color: #65b0c9 !important;
}
.card-header {
  background-color: #65b0c9 !important;
}
/* CUSTOM RADIO BUTTON DI VARIANT PRODUCT DETAIL*/
.btn-outline-danger {
  color: #dc3545 !important;
  background-color: #fff !important;
  border-color: #dc3545 !important;
  margin-left: 10px;
  margin-right: 10px;
  width: 80px;
  border-radius: 5px !important;
}
.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger.active,
.open > .dropdown-toggle.btn-outline-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
.card-header {
  background-color: white !important;
}
/* CUSTOM RADIO BUTTON RATING PRODUCT DETAIL*/
.btn-outline-warning-custom {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #fdb738 !important;
  margin-left: 10px;
  margin-right: 10px;
  width: auto;
  border-radius: 5px !important;
}
.btn-outline-warning-custom:hover,
.btn-outline-warning-custom:focus,
.btn-outline-warning-custom:active,
.btn-outline-warning-custom.active,
.open > .dropdown-toggle.btn-outline-warning-custom {
  color: #fff !important;
  background-color: #fdb738 !important;
  border-color: #fdb738 !important;
}

.btn-outline-warning-custom-checkbox {
  color: #fff !important;
  background-color: #65b0c9 !important;
  border-color: #65b0c9 !important;
  width: auto;
  border-radius: 5px !important;
  font-size: 10px !important;
  font-weight: bold !important;
}
.btn-outline-warning-custom-checkbox:hover,
.btn-outline-warning-custom-checkbox:focus,
.btn-outline-warning-custom-checkbox:active,
.btn-outline-warning-custom-checkbox.active,
.open > .dropdown-toggle.btn-outline-warning-custom-checkbox {
  color: #fff !important;
  background-color: #212529 !important;
  border-color: #212529 !important;
  font-size: 10px !important;
  font-weight: bold !important;
}

.text-primary-jaja {
  color: #65b0c9 !important;
}
.text-secondary-jaja {
  color: #fdb738 !important;
}
.btn-warning-custom {
  color: #fff !important;
  background-color: #fdb738 !important;
  border-color: #fdb738 !important;
  border-radius: 5px !important;
}
.bg-primary-jaja {
  background-color: #65b0c9 !important;
}
.bg-secondary-jaja {
  background-color: #f7ca78 !important;
}
.bg-danger-jaja {
  background-color: #dc3545 !important;
}
.br-color-primary-jaja {
  border-color: #65b0c9 !important;
}
.br-color-secondary-jaja {
  border-color: #f7ca78 !important;
}

.br-color-button-jaja {
  border-radius: 6px !important;
}
.br-card-jaja {
  border-radius: 10px !important;
}
.br-card-header-jaja {
  border-radius: 10px 10px 0 0 !important;
}
.br-card-footer-jaja {
  border-radius: 0 0 10px 10px !important;
}
.border-0 {
  border: 0px !important;
}
.text-size-jaja {
  font-size: 17px !important;
}
.page-item.active .page-link {
  color: #fff !important;
  background-color: #65b0c9 !important;
  border-color: #65b0c9 !important;
}
.page-link {
  color: #65b0c9 !important;
}
.center-middle-custom {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ver-middle {
  vertical-align: middle;
}
.border-primary-jaja {
  border-color: #65b0c9 !important;
}
.bg-info-jaja {
  background-color: #65b0c9 !important;
}
.close {
  font-size: 2rem;
  color: #fff;
  text-shadow: 0 1px 0 #fff;
}

.modal-header {
  background: #64b0c9 !important;
  color: white;
  font-weight: bold;
  border-radius: 1rem 1rem 0 0;
}

.modal-content {
  border-radius: 1rem;
}

.bg-light-blue {
  background-color: #eefafd !important;
}

.text-danger-jaja {
  color: #fb7181 !important;
}

.bg-danger-jaja {
  background-color: #fb7181 !important;
}

.br-color-danger-jaja {
  border-color: #fb7181 !important;
}

.bg-success-jaja {
  background-color: rgb(66, 183, 143) !important;
}

.br-color-success-jaja {
  border-color: rgb(66, 183, 143) !important;
}

.bg-light-grey-jaja {
  background-color: #e9eaef !important;
}

.bg-warning-jaja {
  background-color: #fdb739 !important;
}

/* VOUCHER */

.coupon .kanan {
  border-left: 1px dashed #ddd;
  width: 40% !important;
  position: relative;
}

.coupon .kanan .info::after,
.coupon .kanan .info::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: #ffffff;
  border-radius: 100%;
}

.coupon .kanan .info::before {
  top: -10px;
  left: -10px;
}

.coupon .kanan .info::after {
  bottom: -10px;
  left: -10px;
}

.coupon .time {
  font-size: 1.6rem;
}

.bg-soft-primary-jaja {
  background-color: #cde8ef !important;
}

.flickity-button {
  position: absolute;
  background: #65b0c9 !important;
  border: none;
  color: #333;
}

/* big buttons, no circle */
.flickity-prev-next-button.previous {
  background: #65b0c9 !important;
}
/* arrow color */
.flickity-prev-next-button .arrow {
  fill: white !important;
}
.flickity-prev-next-button.no-svg {
  color: white !important;
}
.flickity-prev-next-button:hover {
  background: transparent;
}
/* hide disabled button */
.flickity-prev-next-button:disabled {
  display: none;
}
</style>
