var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-none d-lg-block custom-background-footer"},[_c('div',{staticClass:"container",staticStyle:{"margin-top":"40px"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_vm._m(0),_c('div',[_c('div',{staticClass:"title-f"},[_vm._v("Layanan Pelanggan")]),_c('div',{staticStyle:{"margin-top":"20px"}},[_vm._m(1),_vm._m(2),_vm._m(3),_c('p',{staticClass:"text-left"},[_c('router-link',{staticClass:"text-footer",attrs:{"to":'/seller/info'}},[_vm._v(" Mulai Jualan ")])],1)]),_c('div',{staticClass:"title-f",staticStyle:{"padding-top":"40px"}},[_vm._v("Pembayaran")]),_vm._m(4),_vm._m(5)]),_vm._m(6)]),_c('br'),_c('br')])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"title-f"},[_vm._v("Jaja.id")]),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"text-left"},[_c('a',{staticClass:"text-footer",attrs:{"href":"https://jsonx.jaja.id/tentang-jaja-id"}},[_vm._v(" Tentang Jaja ")])]),_c('p',{staticClass:"text-left"},[_c('a',{staticClass:"text-footer",attrs:{"href":"https://jsonx.jaja.id/blogs"}},[_vm._v("Blogs")])]),_c('p',{staticClass:"text-left"},[_c('a',{staticClass:"text-footer",attrs:{"href":"https://jsonx.jaja.id/kebijakan-privasi"}},[_vm._v(" Kebijakan Privasi ")])]),_c('p',{staticClass:"text-left"},[_c('a',{staticClass:"text-footer",attrs:{"href":"https://jsonx.jaja.id/syarat-ketentuan"}},[_vm._v(" Syarat Layanan ")])])]),_c('div',{staticClass:"title-f",staticStyle:{"padding-top":"40px"}},[_vm._v("Pengiriman")]),_c('div',{staticClass:"row",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"col-3"},[_c('img',{staticClass:"img-pengiriman",attrs:{"src":require("../../assets/footer-asset/p2.png")}})]),_c('div',{staticClass:"col-3"},[_c('img',{staticClass:"ml-3 img-pengiriman",attrs:{"src":require("../../assets/footer-asset/p4.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-left"},[_c('a',{staticClass:"text-footer",attrs:{"href":"https://jsonx.jaja.id/ketentuan-transaksi"}},[_vm._v(" Cara Menanggapi Komplain ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-left"},[_c('a',{staticClass:"text-footer",attrs:{"href":"https://jsonx.jaja.id/pencairan-dana"}},[_vm._v(" Pengembalian Dana dan Barang ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-left"},[_c('a',{staticClass:"text-footer",attrs:{"href":"https://jsonx.jaja.id/help"}},[_vm._v(" Bantuan ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"col-3"},[_c('img',{staticClass:"img-pengiriman",attrs:{"src":require("../../assets/footer-asset/payment1.png")}})]),_c('div',{staticClass:"col-3"},[_c('img',{staticClass:"img-pengiriman",attrs:{"src":require("../../assets/footer-asset/payment2.png")}})]),_c('div',{staticClass:"col-3"},[_c('img',{staticClass:"img-pengiriman",attrs:{"src":require("../../assets/footer-asset/payment3.png")}})]),_c('div',{staticClass:"col-3"},[_c('img',{staticClass:"img-pengiriman",attrs:{"src":require("../../assets/footer-asset/payment4.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"col-3"},[_c('img',{staticClass:"img-pengiriman",attrs:{"src":require("../../assets/footer-asset/payment5.png")}})]),_c('div',{staticClass:"col-3"},[_c('img',{staticClass:"img-pengiriman",attrs:{"src":require("../../assets/footer-asset/payment6.png")}})]),_c('div',{staticClass:"col-3"},[_c('img',{staticClass:"img-pengiriman",attrs:{"src":require("../../assets/footer-asset/payment7.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"title-f"},[_vm._v("Sosial Media")]),_c('div',{staticClass:"d-flex",staticStyle:{"margin-top":"20px"}},[_c('div',[_c('a',{attrs:{"href":"https://api.whatsapp.com/send?phone=6287888337555","target":"_blank"}},[_c('img',{staticClass:"img-sosmed",attrs:{"src":require("../../assets/footer-asset/whatsapp.png")}})])]),_c('div',{staticClass:"ml-2"},[_c('a',{attrs:{"href":"https://www.facebook.com/Jajaid-Marketplace-101914401806448/","target":"_blank"}},[_c('img',{staticClass:"img-sosmed",attrs:{"src":require("../../assets/footer-asset/fb.png")}})])]),_c('div',{staticClass:"ml-2"},[_c('a',{attrs:{"href":"https://www.instagram.com/jajaid.official/","target":"_blank"}},[_c('img',{staticClass:"img-sosmed",attrs:{"src":require("../../assets/footer-asset/IG.png")}})])]),_c('div',{staticClass:"ml-2"},[_c('img',{staticClass:"img-sosmed",attrs:{"src":require("../../assets/footer-asset/tiktok.png")}})])]),_c('div',{staticClass:"title-f",staticStyle:{"padding-top":"40px"}},[_vm._v("Download Apps")]),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('img',{staticClass:"g-play",attrs:{"src":require("../../assets/footer-asset/app-store.png")}})]),_c('div',[_c('img',{staticClass:"g-play",attrs:{"src":require("../../assets/footer-asset/g-play.png")}})])])
}]

export { render, staticRenderFns }