<template>
  <div>
    <div v-if="isLoading" class="overlay">
      <img
        src="https://jsonx.jaja.id/asset/home/logo/Loading_2.gif"
        alt="Loading"
        class="loading-gif"
      />
    </div>
    <div  class="d-none d-lg-block">
      <div class="container p-3" style="margin-top: -4.5%" v-if="isFinish">
        <div>
          <!-- add to cart DLL -->
          <div
            class="container bg-white rounded p-2 mb-4 shadow-custom card-detail-product"
            style="border-radius: 12px !important"
          >
            <div class="row style-text pb-2">
              <div class="col-md-4 col-12">
                <div class="rounded card-pdx ml-2 mr-2 mt-3">
                  <div class="row">
                    <div class="col-12">
                      <div class="img[0]-pdx img-cover-product">
                        <img
                          id="gambarUtama"
                          :src="
                            String(detailProdukImageFirst).includes('/https:')
                              ? detailProdukImageFirst.slice(
                                  45,
                                  detailProdukImageFirst.length
                                )
                              : detailProdukImageFirst
                          "
                          alt=""
                          class="img-fluid center-img"
                          style=""
                        />
                        <!-- <img
                      id="gambarUtama"
                      :src="detailProdukImageFirst
                      "
                      alt=""
                      class="img-fluid center-img"
                      style=""
                    /> -->
                      </div>
                    </div>
                    <div class="col-12">
                      <flickity
                        v-if="Object.keys(detailProdukImage).length > 0"
                        ref="flickity"
                        class="flickity"
                        :options="flickityOptions"
                      >
                        <div
                          class="d-flex justify-content-start bd-highlight mb-3"
                        >
                          <div
                            v-for="(item, index) in detailProdukImage"
                            :key="index"
                            class="p-2 bd-highlight"
                          >
                            <div
                              class="card text-center img-cover-child shadow-custom card-category mb-4 mt-4"
                            >
                              <img
                                @click="getSrc(item)"
                                :src="
                                  String(item).includes('/https:')
                                    ? item.slice(45, item.length)
                                    : item
                                "
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </flickity>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-7 col-12">
                <div class="col-md-10 ml-2 mt-3">
                  <div>
                    <span
                      style="
                        color: var(--Primary-40, #65b0c9);

                        align-self: stretch;
                        font-family: Poppins;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 26px; /* 130% */
                        letter-spacing: 0.2px;
                      "
                    >
                      {{ dataDetailProduk.name }}
                    </span>
                  </div>
                  <div class="d-flex">
                    <div>
                      <b-form-rating
                        v-model="rating"
                        readonly
                        variant="warning"
                        size="lg"
                        style="
                          border: 0px !important;
                          padding-left: 0px !important;
                        "
                      ></b-form-rating>
                    </div>
                    <div
                      :class="
                        dataDetailProduk.amountSold
                          ? `d-flex justify-content-start mb-3 `
                          : `d-none`
                      "
                    >
                      <span
                        style="
                          color: #000;
                          font-family: Poppins;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: 20px; /* 142.857% */
                          letter-spacing: -0.14px;
                        "
                        class="mt-3 ml-1"
                      >
                        | &nbsp; {{ dataDetailProduk.amountSold }} Terjual
                      </span>
                      <!-- <small>{{dataDetailProduk.amountSold}} Terjual</small> -->
                    </div>
                  </div>

                  <strike
                    style="color: #ffb3b3"
                    :class="dataDetailProduk.isDiscount ? `` : `d-none`"
                  >
                    <small> {{ dataDetailProduk.price }}</small>
                    <span
                      :class="
                        dataDetailProduk.isDiscount
                          ? `ml-3 badge badge-danger`
                          : `d-none`
                      "
                    >
                      {{ dataDetailProduk.discount }} %</span
                    >
                  </strike>
                  <!-- diskon -->
                  <div class="mt-3 d-flex justify-content-start mb-5">
                    <span>
                      <span
                        style="
                          color: #000;
                          font-family: Poppins;
                          font-size: 32px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: 36px; /* 112.5% */
                          letter-spacing: 0.64px;
                        "
                        >{{
                          dataDetailProduk.isDiscount
                            ? dataDetailProduk.priceDiscount
                            : dataDetailProduk.price
                        }}</span
                      >
                    </span>
                  </div>
                  <div
                    v-if="variasiHarga.length != 0"
                    class="d-flex justify-content-start mb-5"
                  >
                    <span class="h3 text-warning font-weight-bold mr-1"
                      >{{ variasiHarga.price }}
                    </span>
                    <span class="h3 text-info font-weight-bold mr-1"
                      >({{ variasiHarga.name }})</span
                    >
                  </div>
                  <!-- preorder -->
                  <div class="row" v-if="dataDetailProduk.preOrder">
                    <div class="col-md-3">
                      <div class="d-flex flex-row">
                        <div class="p-2 font-weight-bold">Pre Order</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="d-flex flex-row">
                        <div class="p-2 font-weight-bold">
                          {{ dataDetailProduk.masaPengemasan }} Hari
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- kondisi -->
                  <div class="row">
                    <div class="col-md-3">
                      <div class="d-flex flex-row">
                        <div class="font-weight-bold">Kondisi</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="d-flex flex-row">
                        <div class="font-weight-bold">
                          {{ dataDetailProduk.condition }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- variant -->
                  <div class="row" v-if="dataDetailProduk.variant.length > 0">
                    <div class="col-md-3">
                      <div
                        class="d-flex flex-row mb-1"
                        v-if="dataDetailProduk.variant"
                        :variant="dataDetailProduk.variant"
                      >
                        <div class="p-2 font-weight-bold">Variant</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div
                        class="d-flex flex-col"
                        v-if="dataDetailProduk.variant"
                        :variant="dataDetailProduk.variant"
                      >
                        <div class="">
                          <div style="">
                            <b-form-group>
                              <b-form-radio-group
                                style="
                                  display: inline-flex;
                                  vertical-align: middle;
                                  flex-wrap: wrap;
                                "
                                id="btn-radios-2"
                                v-model="selectedVariant"
                                @change="changeVar()"
                                :options="options"
                                button-variant="outline-primary"
                                size="sm"
                                name="radio-btn-outline"
                                buttons
                              ></b-form-radio-group>
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="d-flex">
                      <div class="mr-4">
                        <a class="heart-small" @click="tambahWislist()">
                          <i
                            style="color: #65b0c9"
                            class="fas fa-heart"
                            v-if="isWishlist == false"
                          ></i>
                          <i
                            style="color: red; width: "
                            class="fas fa-heart"
                            v-if="isWishlist == true"
                          ></i>
                        </a>
                      </div>
                      <span class="dropdown">
                        <a
                          type=""
                          data-toggle="dropdown"
                          class="mr-3"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="mt-2 fas fa-share-alt share-icon"></i>
                        </a>
                        <span
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          &emsp;
                          <ShareNetwork
                            network="facebook"
                            :url="
                              'https://sandbox.jaja.id/home/DetailProduk/' +
                              dataDetailProduk.slug
                            "
                            title="Jaja.id 1st Marketplace for Your Hobbies"
                            description="Yuk beli barang-barang kebutuhan hobi di Jaja.id"
                          >
                            <i class="fab fa-lg fa-facebook"></i>
                          </ShareNetwork>
                          &emsp;
                          <ShareNetwork
                            network="whatsapp"
                            :url="
                              'https://jaja.id/home/DetailProduk/' +
                              dataDetailProduk.slug
                            "
                            title="Jaja.id 1st Marketplace for Your Hobbies"
                            description="Yuk beli barang-barang kebutuhan hobi di Jaja.id"
                          >
                            <i
                              class="fab fa-lg fa-whatsapp"
                              style="color: green"
                            ></i>
                          </ShareNetwork>
                          &emsp;
                          <ShareNetwork
                            network="twitter"
                            :url="
                              'https://jaja.id/home/DetailProduk/' +
                              dataDetailProduk.slug
                            "
                            title="Jaja.id 1st Marketplace for Your Hobbies"
                            description="Yuk beli barang-barang kebutuhan hobi di Jaja.id"
                          >
                            <i class="fab fa-lg fa-twitter"></i>
                          </ShareNetwork>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        color: var(--Primary-40, #65b0c9);
                        margin-top: 20px;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px; /* 142.857% */
                        letter-spacing: -0.14px;
                      "
                      class="text-left"
                    >
                      Kuantitas
                    </div>
                  </div>
                  <div class="d-flex flex-row">
                    <div style="margin-top: 10px">
                      <b-form-spinbutton
                        class="custom-spinbutton"
                        v-model="kuantitas"
                        inline
                        min="1"
                        :max="dataDetailProduk.stock"
                        v-if="!dataDetailProduk.isNonPhysical"
                        style="margin-right: 10px"
                      ></b-form-spinbutton>

                      <b-form-spinbutton
                        class="custom-spinbutton"
                        v-model="kuantitas"
                        inline
                        min="1"
                        :max="1"
                        v-if="dataDetailProduk.isNonPhysical"
                        style="margin-left: 10px"
                      ></b-form-spinbutton>

                      &nbsp;&nbsp; Tersisa
                      <b>{{ dataDetailProduk.stock }}</b> buah

                      <!-- <button class="mr-4 border border-dark pl-3 pr-3 py-1 rounded" type="button">-</button> 
										<span class="mr-4 font-weight-bold">1</span>
										<button class="border border-dark pl-3 pr-3 py-1 rounded" type="button">+</button> -->
                    </div>
                  </div>

                  <div class="mt-4 mb-4 d-flex">
                    <button
                      @click="beliSekarang()"
                      style="
                        display: flex;
                        background-color: white;
                        padding: 12px 8px;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;
                        flex: 1 0 0;
                        border-radius: 4px;
                        border: 1px solid var(--Secondary-30, #fdb739);
                      "
                    >
                      <span
                        style="
                          color: var(--Secondary-30, #fdb739);
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 600;
                          line-height: 20px; /* 142.857% */
                        "
                        >Beli Langsung</span
                      >
                    </button>
                    <button
                      @click="cekLSToken()"
                      class="ml-2"
                      style="
                        display: flex;
                        padding: 12px 8px;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;
                        flex: 1 0 0;
                        border-radius: 4px;
                        background: var(--Primary-40, #65b0c9);
                        border: none;
                      "
                    >
                      <span
                        style="
                          color: var(--Neutral-10, #fff);

                          /* Web/Button */
                          font-family: Inter;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 600;
                          line-height: 20px; /* 142.857% */
                        "
                        >+ Keranjang</span
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- detail toko -->
        <div
          class="container shadow-custom rounded bg-white"
          style="border-radius: 12px !important"
        >
          <div class="d-flex justify-content-start bd-highlight pt-3 mb-3 mr-4">
            <div
              class="font-weight-bold text-white"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <router-link :to="'/home/DetailToko/' + dataDetailStore.slug">
                <img
                  class="rounded-circle square-80 imguseravatar"
                  :src="dataDetailStore.image"
                  alt="avatar"
                />
              </router-link>
            </div>
            <div class="mr-auto bd-highlight mt-4 ml-4">
              <div class="d-flex justify-content-start bd-highlight">
                <router-link :to="'/home/DetailToko/' + dataDetailStore.slug">
                  <div
                    style="
                      color: var(--Secondary-30, #fdb739);
                      font-family: Poppins;
                      font-size: 20px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 26px; /* 130% */
                      letter-spacing: 0.2px;
                    "
                  >
                    {{ dataDetailStore ? dataDetailStore.name : `` }}
                  </div>
                </router-link>
              </div>
              <div class="d-flex justify-content-start bd-highlight">
                <div class="bd-highlight">
                  <small
                    ><img
                      src="https://jsonx.jaja.id/asset/home/logo/loc.png"
                      class=""
                      width="12px"
                      alt=""
                    />
                    {{ dataDetailStore ? dataDetailStore.location : `` }}</small
                  >
                </div>
              </div>
              <div
                class="d-flex justify-content-start bd-highlight"
                v-if="dataDetailStore.freeOngkir == 'Y'"
              >
                <div class="bd-highlight">
                  <small
                    ><img
                      src="https://nimda.jaja.id/asset/icon-jaja/free-delivery.png"
                      class=""
                      width="15px"
                      alt=""
                    />&nbsp;Gratis Ongkir</small
                  >
                </div>
              </div>
              <div
                class="d-flex justify-content-start bd-highlight"
                v-if="dataDetailStore.closed_store"
              >
                <div class="bd-highlight">
                  <small class="font-weight-bold" style="color: red"
                    >&nbsp;Toko Sedang
                  </small>
                </div>
              </div>
            </div>
            <div class="bd-highlight">
              <div
                class="d-flex align-items-center bd-highlight mb-3"
                style="height: 100px"
              >
                <div class="bd-highlight">
                  <button
                    type="button"
                    style="
                      border-radius: 12px;
                      background: var(--Primary-40, #65b0c9);
                      display: flex;
                      width: 140px;
                      height: 48px;
                      padding: 10px;
                      justify-content: center;
                      align-items: center;
                      gap: 10px;
                      border: none;
                    "
                    @click="chatStore()"
                    id="chat-toko"
                  >
                    <span
                      style="
                        color: var(--Neutral-10, #fff);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px; /* 142.857% */
                      "
                    >
                      <img
                        style="width: 30px; height: 30px; flex-shrink: 0"
                        src="../../assets/icon-s/chat.png"
                      />
                      &nbsp; Chat Toko</span
                    >
                  </button>
                </div>
                <div class="p-2 bd-highlight">
                  <router-link
                    :to="'/home/DetailToko/' + dataDetailStore.slug"
                    style="
                      display: flex;
                      width: 140px;
                      height: 48px;
                      padding: 10px;
                      justify-content: center;
                      align-items: center;
                      gap: 10px;
                      border-radius: 12px;
                      border: 1px solid var(--Secondary-30, #fdb739);
                      background-color: white;
                    "
                  >
                    <span
                      style="
                        color: var(--Secondary-30, #fdb739);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px; /* 142.857% */
                      "
                    >
                      <img
                        style="width: 30px; height: 30px; flex-shrink: 0"
                        src="../../assets/icon-s/shop.png"
                      />
                      &nbsp; Kunjungi</span
                    >
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- deksripsi produk -->
        <div
          class="container shadow-custom rounded bg-white py-2 pl-4 pr-4 mb-3"
          style="border-radius: 12px !important"
        >
          <div class="mt-3 d-flex align-items-center bd-highlight">
            <div class="title-spes">Spesifikasi Produk</div>
          </div>
          <div class="mt-4 d-flex justify-content-start bd-highlight mb-3">
            <div class="bd-highlight">
              <div class="spes-text text-left">
                <div class="mt-3">Kategori</div>
                <div class="mt-3">Brand</div>
                <div class="mt-3">Stok</div>
                <div class="mt-3">Berat</div>
                <div class="mt-3">Dikirim dari</div>
              </div>
            </div>
            <div class="ml-4 bd-highlight">
              <div class="isi-spes text-left">
                <div class="mt-3">{{ dataDetailCat.name }}</div>
                <div class="mt-3">{{ dataDetailStore.name }}</div>
                <div class="mt-3">{{ dataDetailProduk.stock }}</div>
                <div class="mt-3">{{ dataDetailProduk.weight }} Gram</div>
                <div class="mt-3">{{ dataDetailStore.location }}</div>
              </div>
            </div>
          </div>
          <div class="mt-5 d-flex align-items-center bd-highlight">
            <div class="title-spes">Deskripsi Produk</div>
          </div>
          <div class="mt-4 text-justify mb-2">
            {{ dataDetailProduk.description }}
          </div>
        </div>

        <!-- penilaian -->
        <div
          class="container shadow-custom rounded bg-white py-2 pl-4 pr-4 mb-3"
          style="border-radius: 12px !important"
        >
          <div
            class="mt-3"
            style="
              display: flex;
              padding: 10px;
              flex-direction: column;
              align-items: flex-start;
              gap: 10px;
              align-self: stretch;
              border-radius: 10px;
              background: var(--Primary-20, #c1efff);
            "
          >
            <div class="d-flex align-items-center bd-highlight">
              <div class="title-spes">Penilaian Produk</div>
            </div>

            <div class="mt-4 d-flex justify-content-start bd-highlight mb-3">
              <div
                class="d-flex"
                style="
                  border-radius: 4px;
                  background: var(--Secondary-10, #fff8eb);
                  display: flex;
                  padding: 20px;
                  align-items: center;
                  gap: 10px;
                "
              >
                <div class="text-left">
                  <span
                    style="
                      color: var(--Secondary-40, #ca8c1e);
                      font-family: Poppins;
                      font-size: 32px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 36px; /* 112.5% */
                      letter-spacing: 0.64px;
                    "
                  >
                    {{ rating }} / 5
                  </span>
                </div>
                <div class="text-left">
                  <b-form-rating
                    v-model="rating"
                    readonly
                    variant="warning"
                    size="lg"
                    style="
                      border: 0px !important;
                      padding-left: 0px !important;
                      background-color: transparent !important;
                    "
                  ></b-form-rating>
                </div>
              </div>
              <div class="bd-highlight mr-2 mt-4 text-left ml-2">
                <span
                  style="
                    color: var(--Neutral-60, #1a1a1a);

                    /* Web/H2 */
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 22px; /* 137.5% */
                    letter-spacing: -0.16px;
                  "
                  >Ulasan Pembeli</span
                >
                <!-- <p
                  style="
                    color: var(--Neutral-30, #bebebe);

                    /* Web/Body */
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                    letter-spacing: -0.14px;
                  "
                >
                  {{ filterTotal.total }} Ulasan
                </p> -->
              </div>
              <div class="bd-highlight mr-2 mt-4">
                <b-form-group>
                  <b-form-radio-group
                    v-model="selectedReview"
                    :options="filterReview"
                    button-variant="py-1 btn-outline-warning-custom pl-3 pr-3 rounded ml-3"
                    size="md"
                    name="radio-btn-outline"
                    buttons
                    class=""
                  ></b-form-radio-group>
                </b-form-group>
              </div>
              <div class="bd-highlight ml-auto"></div>
            </div>
          </div>
          <div
            class="border border rounded py-4 mt-4 mb-4"
            v-if="totalDataReview == 0"
          >
            <h4 class="py-4 mt-4 mb-4">Belum Ada Ulasan</h4>
          </div>
          <div
            class="border border rounded py-4 mt-4 mb-4"
            style="
              border-right: 0px !important;
              border-left: 0px !important;
              border-bottom: 0px !important;
            "
            v-else
          >
            <div v-for="(item, index) in itemReview" :key="index">
              <div class="d-flex bd-highlight mb-3">
                <div class="p-2 bd-highlight">
                  <div>
                    <img
                      style="width: 52px; height: 52px"
                      :src="item.customerImage"
                      alt="avatar"
                    />
                  </div>
                </div>
                <div class="p-2 bd-highlight">
                  <span
                    style="
                      color: #000;
                      font-family: Poppins;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 20px; /* 142.857% */
                      letter-spacing: -0.14px;
                      text-align: left;
                    "
                    class="mb-4"
                  >
                    {{ item.customerName }}
                    <b-form-rating
                      v-model="item.rate"
                      readonly
                      variant="warning"
                      style="
                        border: 0px !important;
                        padding-left: 0px !important;
                        width: 100px;
                      "
                    ></b-form-rating>
                  </span>
                </div>
                <div class="ml-auto p-2 bd-highlight">
                  <div>
                    <p style="text-align: right">
                      {{ item.createdAt }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="ml-2">
                <p
                  style="
                    color: #000;

                    /* Web/Body */
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                    letter-spacing: -0.14px;
                  "
                  class="text-left"
                >
                  Komentar :
                </p>
                <p
                  style="
                    color: #000;

                    /* Web/Info */
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px; /* 160% */
                    letter-spacing: -0.1px;
                  "
                  class="text-left"
                >
                  {{ item.comment }}
                </p>
                <p
                  v-if="Object.keys(item.image).length > 0"
                  style="text-align: left"
                >
                  <img
                    class=""
                    :src="item.image"
                    alt="image review"
                    style="
                      width: 100%;
                      height: 100%;
                      max-width: 80px;
                      max-height: 80px;
                    "
                  />
                </p>
                <hr />
              </div>
            </div>
          </div>
        </div>

        <!-- produk lain -->
        <div
          class="container rounded bg-default py-2 pl-4 mb-4"
          style="margin-top: 20px"
        >
          <div class="mt-4 mb-4" style="margin-left: -15px !important">
            <h3 class="text-left mb-4">Produk Lain di toko ini</h3>
            <cardSlider
              :data="dataDetailProduk.otherProduct"
              style="margin-left: -15px !important"
            />
          </div>
        </div>
      </div>
      <div class="container p-3" style="margin-top: -4.5%" v-if="!isFinish">
        <div class="container shadow-custom header-info rounded">
          <div
            class="d-flex justify-content-start bd-highlight py-2 mb-3 text-white"
          >
            <div class="mr-1 bd-highlight">
              <content-placeholders :rounded="true">
                <content-placeholders-text
                  class=""
                  :lines="1"
                  style="width: 15vw; height: 20px"
                />
              </content-placeholders>
            </div>
          </div>
        </div>

        <!-- add to cart DLL -->
        <div
          class="container bg-white rounded p-2 mb-4 shadow-custom card-detail-product"
          style="border-radius: 10px !important"
        >
          <div class="row style-text pb-2">
            <div class="col-md-4 col-12">
              <div class="rounded card-pdx ml-2 mr-2 mt-3">
                <div class="row">
                  <div class="col-12">
                    <div class="img[0]-pdx img-cover-product">
                      <content-placeholders :rounded="true">
                        <content-placeholders-img
                          class="img-fluid center-img"
                          style="width: 15vw; height: 40vh"
                        />
                      </content-placeholders>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="d-flex justify-content-start bd-highlight mb-3">
                      <div
                        v-for="index in 3"
                        :key="index"
                        class="p-2 bd-highlight"
                      >
                        <div
                          class="text-center img-cover-child card-category mb-4 mt-4"
                        >
                          <content-placeholders :rounded="true">
                            <content-placeholders-img
                              class="img-fluid center-img"
                              style="width: 15vw; height: 8vh"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-7 col-12">
              <div class="col-md-10 ml-2 mt-3">
                <div>
                  <h3 class="d-flex justify-content-start text-info">
                    <b>
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="1"
                          style="width: 200px; height: 20px"
                        />
                      </content-placeholders>
                    </b>
                  </h3>
                </div>
                <div
                  class="d-flex justify-content-start mb-2"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <span class="ml-1 font-weight-bold">
                    <content-placeholders :rounded="true">
                      <content-placeholders-text
                        :lines="1"
                        style="width: 150px; height: 20px"
                      />
                    </content-placeholders>
                  </span>
                </div>
                <div class="d-flex justify-content-start mb-5">
                  <span class="ml-1">
                    <content-placeholders :rounded="true">
                      <content-placeholders-text
                        :lines="1"
                        style="width: 100px; height: 20px"
                      />
                    </content-placeholders>
                  </span>
                </div>
                <div class="d-flex justify-content-start mb-5">
                  <span>
                    <span class="h3 text-warning font-weight-bold mr-1">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="1"
                          style="width: 200px; height: 20px"
                        />
                      </content-placeholders>
                    </span>
                  </span>
                </div>
                <!-- kondisi -->
                <div class="row">
                  <div class="col-md-3">
                    <div class="d-flex flex-row">
                      <div class="p-2 font-weight-bold">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 80px; height: 20px"
                          />
                        </content-placeholders>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="d-flex flex-row">
                      <div class="p-2 font-weight-bold">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 80px; height: 20px"
                          />
                        </content-placeholders>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- kuantitas -->
                <div class="row">
                  <div class="col-md-3">
                    <div class="d-flex flex-row mb-1">
                      <div class="p-2 font-weight-bold">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 80px; height: 20px"
                          />
                        </content-placeholders>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="d-flex flex-row">
                      <div class="p-2">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 100px; height: 20px"
                          />
                        </content-placeholders>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- variant -->
                <div class="row">
                  <div class="col-md-3">
                    <div class="d-flex flex-row mb-1">
                      <div class="p-2 font-weight-bold">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 80px; height: 20px"
                          />
                        </content-placeholders>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="d-flex flex-row">
                      <div class="p-2">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 80px; height: 20px"
                          />
                        </content-placeholders>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-add-cart">
                  <div class="child-cart">
                    <content-placeholders :rounded="true">
                      <content-placeholders-text
                        :lines="1"
                        style="width: 250px; height: 20px"
                      />
                    </content-placeholders>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- detail toko -->
        <div
          class="container shadow-custom rounded bg-white"
          style="border-radius: 10px !important"
        >
          <div class="d-flex justify-content-start bd-highlight pt-3 mb-3 mr-4">
            <div
              class="font-weight-bold text-white"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <content-placeholders :rounded="true">
                <content-placeholders-img
                  style="width: 80px; height: 80px"
                  class="rounded-circle square-80 imguseravatar"
                />
              </content-placeholders>
            </div>
            <div class="mr-auto bd-highlight mt-4 ml-4">
              <div class="d-flex justify-content-start bd-highlight">
                <div class="bd-highlight text-product">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 200px; height: 20px"
                    />
                  </content-placeholders>
                </div>
              </div>
              <div class="d-flex justify-content-start bd-highlight">
                <div class="bd-highlight">
                  <small>
                    <content-placeholders :rounded="true">
                      <content-placeholders-text
                        :lines="1"
                        style="width: 100px; height: 20px"
                      />
                    </content-placeholders>
                  </small>
                </div>
              </div>
            </div>
            <div class="bd-highlight">
              <div
                class="d-flex align-items-center bd-highlight mb-3"
                style="height: 100px"
              >
                <div class="bd-highlight">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 100px; height: 20px"
                    />
                  </content-placeholders>
                </div>
                <div class="p-2 bd-highlight">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 100px; height: 20px"
                    />
                  </content-placeholders>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- deksripsi produk -->
        <div
          class="container shadow-custom rounded bg-white py-2 pl-4 pr-4 mb-3"
          style="border-radius: 10px !important"
        >
          <div class="d-flex align-items-center bd-highlight">
            <div
              class="bd-highlight py-1 pl-2 pr-2 mt-2 mb-2 rounded text-white font-weight-bold"
            >
              <content-placeholders :rounded="true">
                <content-placeholders-text
                  :lines="1"
                  style="width: 200px; height: 20px"
                />
              </content-placeholders>
            </div>
          </div>
          <div class="d-flex justify-content-start bd-highlight mb-3">
            <div class="bd-highlight">
              <div class="satu text-left">
                <div>
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 100px; height: 20px"
                    />
                  </content-placeholders>
                </div>
                <div>
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 100px; height: 20px"
                    />
                  </content-placeholders>
                </div>
                <div>
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 100px; height: 20px"
                    />
                  </content-placeholders>
                </div>
                <div>
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 100px; height: 20px"
                    />
                  </content-placeholders>
                </div>
                <div>
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 100px; height: 20px"
                    />
                  </content-placeholders>
                </div>
              </div>
            </div>
            <div class="ml-4 bd-highlight">
              <div class="dua text-left">
                <div>
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 100px; height: 20px"
                    />
                  </content-placeholders>
                </div>
                <div>
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 150px; height: 20px"
                    />
                  </content-placeholders>
                </div>
                <div>
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 50px; height: 20px"
                    />
                  </content-placeholders>
                </div>
                <div>
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 80px; height: 20px"
                    />
                  </content-placeholders>
                </div>
                <div>
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 100px; height: 20px"
                    />
                  </content-placeholders>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center bd-highlight">
            <div
              class="bd-highlight py-1 pl-2 pr-2 mt-2 mb-2 rounded text-white font-weight-bold"
            >
              <content-placeholders :rounded="true">
                <content-placeholders-text
                  :lines="1"
                  style="width: 200px; height: 20px"
                />
              </content-placeholders>
            </div>
          </div>
          <div class="text-justify mb-2">
            <content-placeholders :rounded="true">
              <content-placeholders-heading />
            </content-placeholders>
          </div>
        </div>

        <!-- penilaian -->
        <div
          class="container shadow-custom rounded bg-white py-2 pl-4 pr-4 mb-3"
          style="border-radius: 10px !important"
        >
          <div class="d-flex align-items-center bd-highlight">
            <div
              class="bd-highlight py-1 pl-2 pr-2 mt-2 mb-3 rounded text-white font-weight-bold"
            >
              <content-placeholders :rounded="true">
                <content-placeholders-text
                  :lines="1"
                  style="width: 200px; height: 20px"
                />
              </content-placeholders>
            </div>
          </div>

          <div class="d-flex justify-content-start bd-highlight mb-3">
            <div class="bd-highlight mr-auto">
              <div class="text-left">
                <h3 class="text-danger font-weight-bold">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 200px; height: 20px"
                    />
                  </content-placeholders>
                </h3>
              </div>
              <div class="text-left">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    style="width: 150px; height: 20px"
                  />
                </content-placeholders>
              </div>
            </div>
            <div class="bd-highlight mr-2">
              <b-form-group>
                <b-form-radio-group
                  :options="filterReviewPlaceholder"
                  button-variant="py-1 btn-outline-warning-custom pl-3 pr-3 rounded ml-3"
                  size="sm"
                  name="radio-btn-outline"
                  buttons
                  class=""
                ></b-form-radio-group>
              </b-form-group>
            </div>
            <div class="bd-highlight ml-auto"></div>
          </div>

          <div
            class="border border rounded py-4 mt-4 mb-4"
            style="
              border-right: 0px !important;
              border-left: 0px !important;
              border-bottom: 0px !important;
            "
          >
            <div v-for="(item, index) in itemReview" :key="index">
              <div
                class="row p-3 mr-2 ml-2"
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.09)"
              >
                <div class="col-md-2">
                  <div
                    class="font-weight-bold text-white"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <content-placeholders :rounded="true">
                      <content-placeholders-img
                        class="rounded-circle square-80 imguseravatar shadow"
                        style="width: 80px; height: 80px"
                      />
                    </content-placeholders>
                  </div>
                </div>
                <div
                  class="col-md-8"
                  style="display: flex; justify-content: left"
                >
                  <h6 class="text-left mb-4">
                    <b style="color: #65b0c9 !important">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="1"
                          style="width: 200px; height: 20px"
                        />
                      </content-placeholders>
                    </b>
                    <content-placeholders :rounded="true">
                      <content-placeholders-text
                        :lines="1"
                        style="width: 100px; height: 20px"
                      />
                    </content-placeholders>
                    <p style="text-align: left">
                      <content-placeholders :rounded="true">
                        <content-placeholders-heading />
                      </content-placeholders>
                    </p>
                    <p style="text-align: left">
                      <content-placeholders :rounded="true">
                        <content-placeholders-img
                          style="
                            width: 100%;
                            height: 80px;
                            max-width: 200px;
                            max-height: 200px;
                          "
                        />
                      </content-placeholders>
                    </p>
                  </h6>
                </div>
                <div class="col-md-2">
                  <p style="text-align: right">
                    <content-placeholders :rounded="true">
                      <content-placeholders-text
                        :lines="1"
                        style="width: 200px; height: 20px"
                      />
                    </content-placeholders>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- produk lain -->
        <div
          class="container rounded bg-default py-2 pl-4 mb-4"
          style="margin-top: 20px"
        >
          <div class="mt-4 mb-4" style="margin-left: -15px !important">
            <h3 class="text-left mb-4">Produk Lain di toko ini</h3>
            <cardSlider
              :data="dataDetailProduk.otherProduct"
              style="margin-left: -15px !important"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-lg-none"
      style="background-color: #f0f0f0; margin-top: -180px"
    >
      <div class="product-card">
        <div class="image-container" style="position: relative">
          <button class="absolute-button">
            <router-link :to="'/'">
              <i class="fas fa-arrow-left"></i>
            </router-link>
          </button>
          <img
            style="object-fit: cover"
            :src="
              String(detailProdukImageFirst).includes('/https:')
                ? detailProdukImageFirst.slice(
                    45,
                    detailProdukImageFirst.length
                  )
                : detailProdukImageFirst
            "
            alt="Product Image"
          />
          <div class="text-banner">
            Tidak perlu khawatir, Produk ini sudah Jaminan Termurah di Jaja.iD
          </div>
        </div>
        <div class="product-details">
          <div
            style="
              background: var(--Neutral-10, #fff);
              border-radius: 8px;
              padding: 5px 20px;
            "
          >
            <div class="d-flex bd-highlight mb-3">
              <div class="mr-auto mt-3 bd-highlight">
                <span
                  style="
                    color: var(--Neutral-60, #1a1a1a);
                    font-family: Poppins;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px; /* 133.333% */
                    letter-spacing: 0.36px;
                  "
                  >Rp 3.890.000</span
                >
                <div class="mt-2" style="display: flex; gap: 3px">
                  <p
                    style="
                      color: #fa1919;
                      text-align: left;
                      font-family: Poppins;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 16px; /* 133.333% */
                      letter-spacing: 0.12px;
                    "
                    :class="dataDetailProduk.isDiscount ? `` : `d-none`"
                  >
                    <s>{{ dataDetailProduk.price }}</s>
                    <span
                      class="ml-1 badge badge-danger"
                      :class="
                        dataDetailProduk.isDiscount
                          ? `badge badge-danger`
                          : `d-none`
                      "
                    >
                      {{ dataDetailProduk.discount }} %</span
                    >
                  </p>
                </div>
              </div>
              <div class="p-3 bd-highlight">
                <i class="fas fa-heart"></i>
                <!-- Ikon Favorit -->
              </div>
              <div class="p-3 bd-highlight">
                <i class="fas fa-share-alt"></i>
                <!-- Ikon Share -->
              </div>
            </div>
            <p
              style="
                text-align: left;
                color: var(--Neutral-60, #1a1a1a);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 19px; /* 135.714% */
                letter-spacing: 0.28px;
              "
            >
              {{ dataDetailProduk.name }}
            </p>
          </div>

          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              background: var(--Neutral-10, #fff);
              border-radius: 8px;
              padding: 5px 20px;
            "
            class="mt-3 d-flex bd-highlight mb-3"
          >
            <div class="mt-2 p-2 bd-highlight">
              <router-link :to="'/home/DetailToko/' + dataDetailStore.slug">
                <img
                  style="width: 40px; height: 40px"
                  :src="dataDetailStore.image"
                  alt="Product Image"
                />
              </router-link>
            </div>
            <div class="mt-2 p-2 bd-highlight">
              <span
                style="
                  color: var(--Secondary-30, #fdb739);
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 19px; /* 135.714% */
                  letter-spacing: 0.28px;
                  text-align: left;
                "
              >
                {{ dataDetailStore ? dataDetailStore.name : `` }}</span
              >
              <p
                style="
                  text-align: left;
                  color: var(--Neutral-30, #bebebe);
                  font-size: 8px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 14px; /* 175% */
                  letter-spacing: 0.08px;
                "
              >
                {{ dataDetailStore ? dataDetailStore.location : `` }}
              </p>
            </div>
            <div class="mt-2 ml-auto p-2 bd-highlight">
              <span style="width: 28.466px; height: 22px; flex-shrink: 0"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="22"
                  viewBox="0 0 30 22"
                  fill="none"
                >
                  <path
                    d="M8.53405 0V7.11645C8.53405 7.97567 8.19272 8.79969 7.58517 9.40725C6.97761 10.0148 6.15358 10.3561 5.29437 10.3561C4.43515 10.3561 3.61113 10.0148 3.00357 9.40725C2.39601 8.79969 2.05469 7.97567 2.05469 7.11645L4.64372 0H8.53405Z"
                    fill="#CA8C1E"
                  />
                  <path
                    d="M14.9999 0V7.11645C15.0286 7.55861 14.9662 8.00194 14.8167 8.41904C14.6672 8.83614 14.4337 9.21813 14.1307 9.54141C13.8277 9.86469 13.4616 10.1224 13.055 10.2985C12.6485 10.4747 12.2101 10.5656 11.767 10.5656C11.3239 10.5656 10.8856 10.4747 10.479 10.2985C10.0724 10.1224 9.70634 9.86469 9.40333 9.54141C9.10032 9.21813 8.86685 8.83614 8.71734 8.41904C8.56783 8.00194 8.50548 7.55861 8.53412 7.11645V0H14.9999Z"
                    fill="#CA8C1E"
                  />
                  <path
                    d="M21.4657 0V7.11645C21.4944 7.55861 21.432 8.00194 21.2825 8.41904C21.133 8.83614 20.8995 9.21813 20.5965 9.54141C20.2935 9.86469 19.9274 10.1224 19.5209 10.2985C19.1143 10.4747 18.6759 10.5656 18.2328 10.5656C17.7898 10.5656 17.3514 10.4747 16.9448 10.2985C16.5383 10.1224 16.1722 9.86469 15.8692 9.54141C15.5661 9.21813 15.3327 8.83614 15.1832 8.41904C15.0337 8.00194 14.9713 7.55861 14.9999 7.11645V0H21.4657Z"
                    fill="#CA8C1E"
                  />
                  <path
                    d="M27.9452 7.11645C27.9452 7.97567 27.6039 8.79969 26.9963 9.40725C26.3887 10.0148 25.5647 10.3561 24.7055 10.3561C23.8463 10.3561 23.0223 10.0148 22.4147 9.40725C21.8071 8.79969 21.4658 7.97567 21.4658 7.11645V0H25.3561L27.9452 7.11645Z"
                    fill="#CA8C1E"
                  />
                  <path
                    d="M25.3561 10.3561V22H4.6438V10.3561H5.29445C5.71989 10.3561 6.14116 10.2723 6.53422 10.1095C6.92727 9.94672 7.28441 9.70809 7.58524 9.40725C7.88608 9.10642 8.12471 8.74928 8.28752 8.35623C8.45033 7.96317 8.53412 7.5419 8.53412 7.11646C8.50548 7.55861 8.56784 8.00194 8.71734 8.41904C8.86685 8.83614 9.10033 9.21814 9.40334 9.54141C9.70635 9.86469 10.0724 10.1224 10.479 10.2985C10.8856 10.4747 11.3239 10.5656 11.767 10.5656C12.2101 10.5656 12.6485 10.4747 13.055 10.2985C13.4616 10.1224 13.8277 9.86469 14.1307 9.54141C14.4337 9.21814 14.6672 8.83614 14.8167 8.41904C14.9662 8.00194 15.0286 7.55861 14.9999 7.11646C14.9713 7.55861 15.0336 8.00194 15.1831 8.41904C15.3327 8.83614 15.5661 9.21814 15.8691 9.54141C16.1721 9.86469 16.5383 10.1224 16.9448 10.2985C17.3514 10.4747 17.7897 10.5656 18.2328 10.5656C18.6759 10.5656 19.1143 10.4747 19.5208 10.2985C19.9274 10.1224 20.2935 9.86469 20.5965 9.54141C20.8995 9.21814 21.133 8.83614 21.2825 8.41904C21.432 8.00194 21.4944 7.55861 21.4657 7.11646C21.4657 7.5419 21.5495 7.96317 21.7123 8.35623C21.8751 8.74928 22.1138 9.10642 22.4146 9.40725C22.7154 9.70809 23.0726 9.94672 23.4656 10.1095C23.8587 10.2723 24.28 10.3561 24.7054 10.3561H25.3561Z"
                    fill="#FDB739"
                  />
                  <path
                    d="M20.1782 15.5343H9.82202V22.0001H20.1782V15.5343Z"
                    fill="#FFDFA5"
                  /></svg
              ></span>
              <p
                style="
                  color: var(--Secondary-30, #fdb739);
                  font-family: Poppins;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 16px; /* 160% */
                  letter-spacing: -0.1px;
                "
              >
                Kunjungi
              </p>
            </div>
          </div>
          <div
            style="
              display: flex;
              padding: 16px 20px;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex-shrink: 0;
              border-radius: 8px;
              background: var(--Neutral-10, #fff);
            "
          >
            <span class="title-des">Spesifikasi Produk</span>
            <div class="d-flex justify-content-start bd-highlight mb-3">
              <div class="bd-highlight">
                <div class="text-des">
                  <div>Kategori</div>
                  <div>Brand</div>
                  <div>Stok</div>
                  <div>Berat</div>
                  <div>Dikirim dari</div>
                </div>
              </div>
              <div class="ml-4 bd-highlight">
                <div class="text-des">
                  <div>{{ dataDetailCat.name }}</div>
                  <div>{{ dataDetailStore.name }}</div>
                  <div>{{ dataDetailProduk.stock }}</div>
                  <div>{{ dataDetailProduk.weight }} Gram</div>
                  <div>{{ dataDetailStore.location }}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="mt-3"
            style="
              display: flex;
              padding: 16px 20px;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex-shrink: 0;
              border-radius: 8px;
              background: var(--Neutral-10, #fff);
            "
          >
            <span class="title-des">Deskripsi Produk</span>
            <p class="text-des">
              {{ dataDetailProduk.description }}
            </p>
          </div>
          <div
            class="mt-3 mb-5"
            style="
              display: flex;
              padding: 16px 20px;
              flex-direction: column;
              gap: 8px;
              flex-shrink: 0;
              border-radius: 8px;
              background: var(--Neutral-10, #fff);
            "
          >
            <span class="title-des">Penilaian Produk</span>
            <div class="d-flex justify-content-between">
              <div>
                <div class="text-left">
                  <span
                    style="
                      color: var(--Secondary-40, #ca8c1e);
                      font-family: Poppins;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 36px;
                      letter-spacing: 0.64px;
                    "
                  >
                    <i class="fas fa-star"></i> &nbsp;{{ rating }} / 5
                  </span>
                </div>
              </div>
              <div style="color: white"></div>
              <div class="pr-3">
                <b-form-group>
                  <b-form-select
                    v-model="selectedReview"
                    :options="filterReview"
                    class="py-1 pl-3 pr-3 rounded ml-3"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </div>
            </div>
            <div
              class="border border rounded py-4 mt-4 mb-4"
              v-if="totalDataReview == 0"
            >
              <h4
                class="py-4 mt-4 mb-4"
                style="
                  color: var(--Secondary-40, #ca8c1e);
                  font-family: Poppins;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 36px;
                  letter-spacing: 0.64px;
                "
              >
                Belum Ada Ulasan
              </h4>
            </div>
            <div
              class="border border rounded py-4 mt-4 mb-4"
              style="
                border-right: 0px !important;
                border-left: 0px !important;
                border-bottom: 0px !important;
              "
              v-else
            >
              <div v-for="(item, index) in itemReview" :key="index">
                <div class="d-flex bd-highlight mb-3">
                  <div class="p-2 bd-highlight">
                    <div>
                      <img
                        style="width: 52px; height: 52px"
                        :src="item.customerImage"
                        alt="avatar"
                      />
                    </div>
                  </div>
                  <div class="p-2 bd-highlight">
                    <span
                      style="
                        color: #000;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px; /* 142.857% */
                        letter-spacing: -0.14px;
                        text-align: left;
                      "
                      class="mb-4"
                    >
                      {{ item.customerName }}
                      <b-form-rating
                        v-model="item.rate"
                        readonly
                        variant="warning"
                        style="
                          border: 0px !important;
                          padding-left: 0px !important;
                          width: 100px;
                        "
                      ></b-form-rating>
                    </span>
                  </div>
                  <div class="ml-auto p-2 bd-highlight">
                    <div>
                      <p style="text-align: right">
                        {{ item.createdAt }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ml-2">
                  <p
                    style="
                      color: #000;

                      /* Web/Body */
                      font-family: Poppins;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 20px; /* 142.857% */
                      letter-spacing: -0.14px;
                    "
                    class="text-left"
                  >
                    Komentar :
                  </p>
                  <p
                    style="
                      color: #000;

                      /* Web/Info */
                      font-family: Poppins;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 16px; /* 160% */
                      letter-spacing: -0.1px;
                    "
                    class="text-left"
                  >
                    {{ item.comment }}
                  </p>
                  <p
                    v-if="Object.keys(item.image).length > 0"
                    style="text-align: left"
                  >
                    <img
                      class=""
                      :src="item.image"
                      alt="image review"
                      style="
                        width: 100%;
                        height: 100%;
                        max-width: 80px;
                        max-height: 80px;
                      "
                    />
                  </p>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style="
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 1000;
            display: flex;
            padding: 12px 10px 8px 10px;
            align-items: flex-start;
            gap: 8px;
            border-radius: 16px 16px 0px 0px;
            background: var(--Neutral-10, #fff);
            box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.04);
          "
        >
          <button
            style="
              border-radius: 4px;
              border: 1px solid var(--Neutral-10, #fff);
              background: var(--Secondary-30, #fdb739);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              padding: 5px 8px;
            "
            @click="chatStore()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
            >
              <path
                d="M10 18C15.5228 18 20 13.9705 20 9C20 4.02943 15.5228 0 10 0C4.47715 0 0 4.02943 0 9C0 10.4397 0.37562 11.8004 1.04346 13.0073C1.22094 13.3279 1.28001 13.6945 1.17712 14.0405L0.58151 16.044C0.32295 16.9137 1.20701 17.7093 2.17335 17.4767L4.39939 16.9406C4.78393 16.848 5.19121 16.9012 5.54753 17.0609C6.88837 17.662 8.4003 18 10 18Z"
                fill="white"
              />
              <path
                d="M13 9C13 9.49707 13.4477 9.9 14 9.9C14.5523 9.9 15 9.49707 15 9C15 8.50293 14.5523 8.1 14 8.1C13.4477 8.1 13 8.50293 13 9Z"
                fill="#FDB739"
              />
              <path
                d="M9 9C9 9.49707 9.4477 9.9 10 9.9C10.5523 9.9 11 9.49707 11 9C11 8.50293 10.5523 8.1 10 8.1C9.4477 8.1 9 8.50293 9 9Z"
                fill="#FDB739"
              />
              <path
                d="M5 9C5 9.49707 5.44772 9.9 6 9.9C6.55228 9.9 7 9.49707 7 9C7 8.50293 6.55228 8.1 6 8.1C5.44772 8.1 5 8.50293 5 9Z"
                fill="#FDB739"
              />
            </svg>
            <span
              style="
                color: var(--Neutral-10, #fff);

                /* Mobile/Info */
                font-family: Inter;
                font-size: 8px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px; /* 175% */
                letter-spacing: 0.08px;
              "
              >Tanya
            </span>
          </button>
          <button
            style="
              border-radius: 4px;
              border: 1px solid var(--Secondary-30, #fdb739);
              display: flex;
              padding: 12px 8px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              background-color: white;
            "
            @click="beliSekarang()"
          >
            <span
              style="
                color: var(--Secondary-30, #fdb739);

                font-family: Poppins;
                font-size: 11px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px; /* 163.636% */
                letter-spacing: 0.11px;
              "
            >
              Beli Langsung</span
            >
          </button>
          <button
            style="
              border: none;
              display: flex;
              padding: 12px 8px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              border-radius: 4px;
              background: var(--Primary-40, #65b0c9);
            "
            @click="cekLSToken()"
          >
            <span
              style="
                color: var(--Neutral-10, #fff);

                /* Mobile/Button */
                font-family: Poppins;
                font-size: 11px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px; /* 163.636% */
                letter-spacing: 0.11px;
              "
            >
              + Keranjang</span
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script
  src="https://code.jquery.com/jquery-3.6.0.js"
  integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk="
  crossorigin="anonymous"
></script>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Flickity from "vue-flickity";
import cardSlider from "../../components/card/cardslider";
import firebase from "firebase/app";

export default {
  data() {
    return {
      isLoading: false,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "left",
        wrapAround: false,
        freeScroll: false,
      },
      selectedReview: "",
      filterReview: [],
      filterReviewPlaceholder: [
        { text: "Semua" },
        { text: "5 Bintang" },
        { text: "4 Bintang" },
        { text: "3 Bintang" },
        { text: "2 Bintang" },
        { text: "1 Bintang" },
      ],
      itemReview: [],
      totalDataReview: 0,
      rating: 0,
      kuantitas: 1,
      options: [],
      selectedVariant: "",
      isWishlist: "",
      dataDetailProduk: {},
      dataDetailStore: {},
      dataDetailCat: {},
      detailProdukImage: [],
      detailProdukImageFirst: "",
      dataHtmlImage: ""[0],
      paramAdd: {
        productId: "",
        flashSaleId: "",
        lelangId: "",
        variantId: "",
        qty: 1,
      },
      isFinish: false,
      variasi: [],
      variasiHarga: [],
    };
  },
  components: {
    Flickity,
    cardSlider,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  created() {
    this.getDetailProduct();
    this.getRating();
  },
  watch: {
    selectedReview() {
      this.getRating();
    },
  },
  methods: {
    getDetailProduct() {
      axios
        .get(`product/${this.$route.params.id}?variant_group=0`)
        .then((res) => {
          this.isFinish = true;
          this.dataDetailProduk = res.data.data;
          this.dataDetailStore = res.data.data.store;
          this.dataDetailCat = res.data.data.category;
          this.options = res.data.data.variant.map((item) => {
            return {
              value: item.id,
              text: item.name,
            };
          });
          this.isWishlist = res.data.data.isWishlist;
          this.rating = res.data.data.rating;
          this.detailProdukImage = res.data.data.image;
          this.detailProdukImageFirst = res.data.data.image[0];
          this.paramAdd.productId = res.data.data.id;
          this.paramAdd.variantId = res.data.data.variant.length
            ? res.data.data.variant
            : 0;
          const isi = res.data.data.image.map((item) => {
            return `<div class="p-2 bd-highlight">
							<img onclick="" src="${item}" style="width: 100px; height: 100px;" alt="">
						</div>`;
          });
          this.dataHtmlImage = isi.toString().replaceAll(",", "");

          this.variasi = res.data.data.variant;
        });
    },
    getSrc(res) {
      document.getElementById(
        "gambarUtama"
      ).src = require("@/assets/loading.gif");
      setTimeout(() => {
        this.detailProdukImageFirst = res;
      }, 200);
    },
    changeVar() {
      console.log("this.variasiHarga", this.variasi);

      const selectedId = [this.selectedVariant];

      const data = this.variasi;

      const res = selectedId.map((id) => data.find((o) => o.id === id));

      this.variasiHarga = res[0];
    },
    beliSekarang() {
      if (this.dataDetailStore.closed_store) {
        this.$swal.fire("Info", "Saat ini toko sedang libur", "info");
      } else {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", this.globalToken);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          productId: this.dataDetailProduk.id,
          flashSaleId: "",
          lelangId: "",
          variantId: this.selectedVariant,
          qty: this.kuantitas,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(`${this.domainURL}cart`, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            const res = JSON.parse(result);
            if (res.status.code === 200) {
              // Tampilkan overlay loading
              this.isLoading = true;
              setTimeout(() => {
                // Sembunyikan overlay setelah 2 detik
                this.isLoading = false;
                // Navigasi ke halaman keranjang
                this.$router.push("/cart/cart").then(() => {
                  // Reload halaman setelah navigasi berhasil
                  this.isLoading = true;
                  this.$router.go(0);
                  setTimeout(() => {
                    this.isLoading = false;
                  }, 2000);
                });
              }, 2000);
            } else if (res.status.code === 401) {
              // Check for unauthorized status code
              this.$swal
                .fire("Warning", "Anda belum login", "warning")
                .then(() => {
                  this.$router.push("/user/login");
                });
            } else {
              this.$swal.fire("Warning", res.status.message, "warning");
            }
          })
          .catch((error) => {
            console.log("error", error);
            this.$swal.fire("Error", "Terjadi kesalahan", "error");
          });
      }
    },
    async cekLSToken() {
      if (this.dataDetailStore.closed_store) {
        this.$swal.fire("Info", "Saat ini toko sedang libur", "info");
      } else {
        try {
          const myHeaders = new Headers({
            Authorization: this.globalToken,
            "Content-Type": "application/json",
          });

          const raw = JSON.stringify({
            productId: this.dataDetailProduk.id,
            flashSaleId: "",
            lelangId: "",
            variantId: this.selectedVariant,
            qty: this.kuantitas,
          });

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          const response = await fetch(`${this.domainURL}cart`, requestOptions);
          const result = await response.text();
          const res = JSON.parse(result);

          if (res.status.code === 200) {
            // Show success message and manage loading overlay
            await this.$swal.fire(
              "Sukses",
              "Berhasil menambahkan data",
              "success"
            );
            this.isLoading = true; // Show loading overlay
            this.$router.go(0);
            setTimeout(() => {
              this.isLoading = false;
            }, 2000);
          } else if (res.status.code === 401) {
            // Check for unauthorized status code
            this.$swal
              .fire("Warning", "Anda belum login", "warning")
              .then(() => {
                this.$router.push("/user/login");
              });
          } else {
            this.$swal.fire("Warning", res.status.message, "warning");
          }
        } catch (error) {
          console.log("error", error);
          this.$swal.fire("Error", "Terjadi kesalahan", "error");
        }
      }
    },
    beliSekarangNonPhysic() {
      if (this.dataDetailStore.closed_store) {
        this.$swal.fire("Info", "Saat ini toko sedang libur", "info");
      } else {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", this.globalToken);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          productId: this.dataDetailProduk.id,
          flashSaleId: "",
          lelangId: "",
          variantId: this.selectedVariant,
          qty: this.kuantitas,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(`${this.domainURL}cart`, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            const res = JSON.parse(result);
            if (res.status.code === 200) {
              localStorage.setItem("is_non_physical", true);
              setTimeout(() => {
                this.$router.push("/payment");
                // window.location.href = `https://jaja.id/payment`;
              }, 500);
            } else {
              this.$swal.fire("Warning", res.status.message, "warning");
            }
          })
          .catch((error) => console.log("error", error));
      }
    },
    tambahWislist() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id_produk: this.dataDetailProduk.id,
      });
      // var raw = JSON.stringify(this.paramAdd);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/addWishlist`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.$swal
              .fire("Sukses", res.status.message, "success")
              .then(() => {
                location.reload();
              });
            this.$store.dispatch("getInfo");
          }
        })
        .catch((error) => console.log("error", error));
    },
    getRating() {
      axios
        .get(
          `product/review/${this.$route.params.id}?page=1&limit=10&status=${this.selectedReview}`
        )
        .then((res) => {
          this.filterReview = res.data.data.filters.map((item) => {
            return {
              value: item.value,
              text: item.title + "   " + "(" + item.total + ") ",
            };
          });
          this.filterTotal = res.data.data.filters[0];
          this.itemReview = res.data.data.items;
          this.totalDataReview = res.data.data.totalData;
        });
    },
    chatStore() {
      const dataProfile = this.user;
      const dataDetailStore = this.dataDetailProduk.store;
      const dataDetailProduct = this.dataDetailProduk;

      $("#chat-toko").attr("disabled", "true");
      $("#chat-toko").html('<i class="fas fa-circle-notch fa-spin"></i>');

      let getFirebaseTimestamp = firebase.database.ServerValue.TIMESTAMP;
      const database = firebase.database();
      let chatRoom = `${dataProfile.uid}${dataDetailStore.uid}`;

      database
        .ref(`friend/${dataDetailStore.uid}/${dataProfile.uid}`)
        .set({
          chat: chatRoom,
          name: dataProfile.name,
          message: {
            text: "",
            time: getFirebaseTimestamp,
          },
          amount: 0,
        })
        .then(() => {
          database
            .ref(`friend/${dataProfile.uid}/${dataDetailStore.uid}`)
            .set({
              chat: chatRoom,
              name: dataDetailStore.name,
              message: {
                text: "",
                time: getFirebaseTimestamp,
              },
              amount: 0,
            })
            .then(() => {
              const paramObjChat = {
                name: dataDetailStore.name,
                chat: chatRoom,
                id: dataDetailStore.uid,
                product: {
                  name: dataDetailProduct.name,
                  slug: dataDetailProduct.slug,
                  image: dataDetailProduct.image.length
                    ? dataDetailProduct.image[0]
                    : "https://seller.jaja.id/asset/images/img-thumbnail.png",
                  priceDiscount: dataDetailProduct.discount,
                  priceFirst: dataDetailProduct.price,
                  priceLast: dataDetailProduct.isDiscount
                    ? dataDetailProduct.priceDiscount
                    : dataDetailProduct.price,
                },
              };
              localStorage.setItem("paramToChat", JSON.stringify(paramObjChat));
              this.$router.push("/chat/");
            });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.heart-small {
  font-size: 32px;
}

.heart-large {
  font-size: 52px;
}

.share-icon {
  font-size: 32px;
  color: #65b0c9;
}
.title-spes {
  color: var(--Primary-40, #65b0c9);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 130% */
  letter-spacing: 0.2px;
}
.spes-text {
  width: 100px;
  color: var(--Neutral-50, #757575);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
}
.isi-spes {
  color: var(--Neutral-60, #1a1a1a);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
}
.bg-warning {
  background-color: #fdb738 !important;
}

.btn-warning {
  color: #212529;
  background-color: #fdb738;
  border-color: #fdb738;
}
.header-info {
  background-image: linear-gradient(
    90deg,
    rgb(40, 93, 166) 0%,
    rgb(0, 118, 193) 0%,
    rgb(0, 174, 239) 97%
  );
}

.img-cover-product img {
  max-height: 300px;
  height: 100%;
}

.img-cover-child {
  width: 50px;
  height: 50px;
}
.img-cover-child img {
  object-fit: contain;
}

.card-detail-product {
  box-shadow: 10px 10px 5px #8382826c;
}

.mt-price {
  margin-bottom: 8% !important;
}

.mb-price {
  margin-bottom: 9% !important;
}
.card-add-cart {
  /* padding-top: 5%; */
  flex: 1; /* height: 10%; */
  display: flex;
  flex-direction: column;
}

.child-cart {
  display: flex;
  flex-direction: row;
  margin-top: auto;
}

.satu {
  width: 170px;
}

.imguseravatar {
  object-fit: contain;
  width: 100px;
  height: 100px;
  border: 4px solid #fff;
}

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// CSS Mobile
.container-m {
  padding: 10px;
}

.imguseravatar-m {
  object-fit: contain;
  width: 50px;
  height: 50px;
  border: 4px solid #fff;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-gif {
  width: 300px; /* Atur ukuran gif loading sesuai kebutuhan */
  height: auto;
}
.custom-spinbutton {
  display: inline-flex;
  align-items: center;
  border: 2px solid #ffa726; /* Warna oranye untuk border */
  border-radius: 8px; /* Border radius untuk membulatkan sudut */
  background-color: #fff3e0; /* Warna latar belakang */
  padding: 5px;
}

.custom-spinbutton input {
  border: none;
  background: none;
  width: 30px; /* Atur lebar input */
  text-align: center;
  font-size: 16px;
  color: #000;
}

.custom-spinbutton button {
  background-color: #ffa726; /* Warna oranye untuk tombol */
  border: none;
  border-radius: 50%; /* Membuat tombol menjadi bulat */
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-spinbutton button:hover {
  background-color: #ff9800; /* Warna saat hover */
}

.custom-spinbutton button:disabled {
  background-color: #ffe0b2; /* Warna tombol saat disabled */
}

// TAMPILAN MOBILE
.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  font-family: Arial, sans-serif;
}

.image-container img {
  width: 100%;
  height: 375px;
}
.image-container img {
  width: 100%;
  height: 375px;
}

.absolute-button {
  position: absolute;
  top: 30px;
  left: 30px;
  background-color: #ffffff; /* White background for the button */
  border: none;
  padding: 10px;
  border-radius: 32px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /* Optional: add some shadow */
  z-index: 1;
}

.absolute-button i {
  color: black; /* Match the icon color with your theme */
}
.text-banner {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 16px 0px 0px 0px;
  background: linear-gradient(
    270deg,
    #ffbd47 -1.73%,
    #ffd995 3.81%,
    #ffcb6c 11.09%,
    #d18c10 55.01%,
    #cd5803 100%
  );
  color: white; /* Ensure the text is readable */
  font-family: Poppins, sans-serif;

  font-size: 12px; /* Adjust the font size if needed */
  z-index: 1; /* Ensure the banner is above the image */
  box-sizing: border-box;
}

.product-details {
  padding: 16px;
}

.price-section {
  display: flex;
  align-items: center;
}

.current-price {
  color: #e74c3c;
  font-size: 20px;
  margin-right: 8px;
}

.original-price {
  text-decoration: line-through;
  color: #888;
  margin-right: 8px;
}

.discount {
  color: #27ae60;
}

.product-title {
  font-size: 18px;
  font-weight: bold;
  margin: 8px 0;
}

.seller-info {
  color: #555;
}

.product-description {
  margin: 16px 0;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background-color: #f9f9f9;
}

.action-buttons button {
  padding: 10px 20px;
  border: none;
  background-color: #3498db;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.action-buttons button:hover {
  background-color: #2980b9;
}
.title-des {
  color: var(--Secondary-30, #fdb739);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 135.714% */
  letter-spacing: 0.28px;
  text-align: left;
}
.text-des {
  color: var(--Neutral-60, #1a1a1a);
  text-align: left;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 160% */
  letter-spacing: -0.1px;
}
</style>
