<template>
  <div>
    <div style="margin-top: -3%" class="container p-3 d-none d-lg-block">
      <div class="row">
        <!-- <div class="col-md-3 desktop-only">
          <sideBar />
        </div> -->

        <!-- CONTENT -->

        <div class="col-md-12" v-if="isFinish">
          <div v-if="statusInfo === 'Belum Dibayar'">
            <div v-if="timeRemaining > 0" class="countdown">
              <span style="margin-top: 5px">Batas Pembayaran</span>
              <div class="timer">
                <div class="time-box">{{ hours }} h</div>
                <div class="time-box">{{ minutes }} m</div>
                <div class="time-box">{{ seconds }} s</div>
              </div>
            </div>
            <div v-else class="countdown">
              <span class="mt-1">Batas Pembayaran</span>
              <div class="timer">
                <div class="time-box">Waktu Pembayaran Habis</div>
              </div>
            </div>
          </div>

          <div
            class="mt-3 card-product shadow-custom p-3"
            style="margin-left: 0px; margin-right: 0px"
          >
            <div class="d-flex justify-content-between bd-highlight">
              <div class="p-2 bd-highlight">
                <div>
                  <h4
                    style="
                      margin-left: 10px;
                      text-align: left;
                      margin-top: 10px;
                      color: #000;
                      font-family: Poppins;
                      font-size: 20px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 26px; /* 130% */
                      letter-spacing: 0.2px;
                    "
                  >
                    <router-link style="color: black" :to="'/listorder'">
                      <i class="fas fa-arrow-left"></i> </router-link
                    >&nbsp; &nbsp; #{{ jjdOrInv }}
                  </h4>
                  <p class="ml-5 tgl-pesan text-left">
                    Tanggal Pemesanan<span style="color: red"
                      >&nbsp;{{ dataOrder.createdAt }}</span
                    >
                  </p>

                  <p
                    v-if="statusInfo === 'Dikirim'"
                    class="ml-5 tgl-pesan text-left"
                  >
                    Pesanan otomatis diterima jika melewati batas waktu
                    <span style="color: red"
                      >&nbsp;{{ dataOrder.receiveExpired }}
                    </span>
                  </p>
                </div>
              </div>

              <div class="p-2 bd-highlight"></div>
              <div class="mt-3 p-2 bd-highlight">
                <div class="d-flex">
                  <div
                    :class="statusClass"
                    class="font-weight-bold"
                    style="
                      border-radius: 4px;
                      padding: 8px;
                      gap: 8px;
                      color: white;
                      font-family: Poppins;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 20px; /* 142.857% */
                      letter-spacing: -0.14px;
                      margin-top: 8px;
                      height: 40px;
                    "
                  >
                    {{ statusInfo }}
                  </div>
                  <div v-if="statusOrder == 'notPaid'"></div>
                  <div class="ml-2 center-middle-custom" v-else>
                    <a
                      :href="dataOrder.downloadOrderPdf"
                      class="p-2 bd-highlight bg-primary-jaja mt-2 mb-2 statusorder text-center text-white rounded font-weight-bold"
                      target="_blank"
                    >
                      <i class="fa fa-download"></i><span> Invoice</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4 row">
            <div class="col-md-7">
              <div
                v-if="statusInfo === 'Dikirim' || statusInfo === 'Selesai'"
                class="card shadow-custom br-card-jaja"
              >
                <div class="card-header br-card-header-jaja">
                  <div class="d-flex justify-content-start bd-highlight">
                    <div class="bd-highlight text-bold mr-auto">
                      Lacak Pesanan
                    </div>
                    <div class="bd-highlight text-white text-bold">
                      <!-- <router-link :to="'/tracking/' + this.$route.params.id">
                          <button
                            class="btn btn-sm btn-warning font-weight-bold text-white"
                          >
                            Detail
                          </button>
                        </router-link> -->

                      <button
                        @click="toggleDetail"
                        class="btn btn-sm btn-warning font-weight-bold text-white"
                      >
                        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                        <i v-else-if="showDetail" class="fas fa-arrow-up"></i>
                        <i v-else class="fas fa-arrow-down"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="orderData">
                <!-- Bagian baru untuk menampilkan riwayat pergerakan paket -->
                <div v-if="showDetail" class="card shadow-custom">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 text-left">
                        <ul class="timeline list-track">
                          <li
                            v-for="(item, index) in orderData.data.rajaongkir
                              .result.manifest"
                            :key="index"
                          >
                            <a class="float-right text-danger"
                              >{{ item.manifest_date }}
                              {{ item.manifest_time }}</a
                            >
                            <p class="ml-5">{{ item.manifest_description }}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="mt-3 alamat"
                v-for="(item, index) in dataItemProduk"
                :key="index"
              >
                <div class="infoalamat">
                  <div class="card shadow-custom br-card-jaja mb-3">
                    <div class="card-header br-card-header-jaja">
                      <span class="mb-0 float-left judul-sec">
                        <img
                          src="../../assets/icon-s/shipping1.png"
                          alt="icon"
                          class="mr-2 mb-1"
                          style="width: 35.556px; height: 16px; color: black"
                        />
                        &nbsp; Pilihan Jasa Pengiriman</span
                      >
                      <br />
                      <div class="mt-4 d-flex justify-content-start">
                        <div>
                          <p class="dharga text-left">
                            {{ item.shippingSelected.description }}
                          </p>
                          <p class="des-hrga text-left">
                            {{ item.shippingSelected.name }}
                          </p>
                        </div>
                        <div class="ml-5">
                          <p class="dharga text-left">
                            <!-- {{
                                item.shippingSelected.sendTime
                              }} -->
                            Biaya Pengiriman
                          </p>
                          <p class="des-hrga text-left">
                            {{ item.shippingSelected.priceCurrencyFormat }}
                          </p>
                        </div>
                        <div
                          class="ml-5"
                          v-if="
                            statusInfo === 'Dikirim' || statusInfo === 'Selesai'
                          "
                        >
                          <p class="dharga text-left">Nomor Resi Pengiriman</p>
                          <p class="des-hrga text-left">
                            {{ dataTracking.trackingId }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="card-header br-card-header-jaja">
                      <span class="mb-0 float-left judul-sec">
                        <img
                          src="../../assets/icon-s/addres.png"
                          alt="icon"
                          class="mr-2 mb-1"
                          style="width: 21.772px; height: 20px; color: black"
                        />
                        &nbsp; Alamat Pengiriman</span
                      >
                      <div>
                        <br />
                        <div class="mt-4">
                          <div class="text-left">
                            <span
                              ><b
                                >{{ item.address.receiverName }} &nbsp; ({{
                                  item.address.phoneNumber
                                }})</b
                              ></span
                            >
                          </div>
                          <div class="dharga text-left">
                            <span class="">{{ item.address.address }}</span>
                          </div>
                        </div>
                        <div class="mt-3 row">
                          <div class="col-md-5 text-left">
                            <span><b>Catatan</b> </span>
                          </div>
                          <div class="col-md-7 dharga text-left">
                            <span class="">{{ item.note }}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card br-card-jaja">
                      <div class="mt-2 card-header" style="border-bottom: none">
                        <div class="d-flex justify-content-between">
                          <div class="mt-2">
                            <router-link
                              :to="'/home/DetailToko/' + item.store.slug"
                              class="mb-0 float-left judul-sec text-bold"
                            >
                              <img
                                src="../../assets/icon-s/shop.png"
                                alt="icon"
                                class="mr-2 mb-1"
                                style="
                                  width: 23.29px;
                                  height: 18px;
                                  color: black;
                                "
                              />
                              {{ item.store.name }}
                            </router-link>
                          </div>
                          <div></div>
                          <div>
                            <button
                              type="button"
                              class="btn btn-sm bg-primary float-left text-white br-button-jaja"
                              style="
                                background: #42b78f !important;
                                font-weight: bold;
                              "
                              @click="chatStore(index)"
                              id="chat-toko"
                            >
                              <i class="fas fa-comment-dots"></i>
                              &nbsp; Chat Penjual
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="card-body">
                            <div
                              class="pb-2"
                              v-for="(a, b) in item.products"
                              :key="b"
                            >
                              <div class="d-flex justify-content-between">
                                <div>
                                  <div class="media">
                                    <router-link
                                      :to="'/home/DetailProduk/' + a.slug"
                                    >
                                      <p class="judul-des">Gambar</p>
                                      <p class="des-hrga">
                                        <img
                                          :src="a.image"
                                          style="
                                            width: 60px;
                                            height: 60px;
                                            flex-shrink: 0;
                                            margin-top: -10px;
                                          "
                                          alt=""
                                        />
                                      </p>
                                    </router-link>
                                  </div>
                                </div>
                                <div>
                                  <p class="judul-des">Nama Produk</p>
                                  <p class="des-hrga mt-4">{{ a.name }}</p>
                                  <p v-if="a.variant" class="des-hrga mt-4">
                                    {{ a.variant }}
                                  </p>
                                </div>
                                <div>
                                  <div>
                                    <p class="judul-des">Harga</p>
                                    <p class="des-hrga mt-4">
                                      {{
                                        a.isDiscount
                                          ? a.priceDiscountCurrencyFormat
                                          : a.priceCurrencyFormat
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <p class="judul-des">Kuantitas</p>
                                    <p class="des-hrga mt-4">{{ a.qty }}</p>
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <p class="judul-des">Jumlah</p>
                                    <pre class="des-hrga mt-4"
                                      >{{ a.subTotalCurrencyFormat }} </pre
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="dataVoucher.length" class="mt-4">
                          <div class="judul-sec-container">
                            <span class="judul-sec">
                              <img
                                src="../../assets/icon-s/Voucher.png"
                                alt="icon"
                                class="icon-voucher"
                              />
                              Voucher toko yang Digunakan
                            </span>
                          </div>

                          <hr />
                          <ul>
                            <li
                              v-for="voucher in dataVoucher"
                              :key="voucher.id"
                              class="voucher-claim"
                            >
                              <div class="d-flex justify-content-between">
                                <div>
                                  <span class="fsx"
                                    >{{ voucher.name }} ({{
                                      voucher.kode_voucher
                                    }})</span
                                  >
                                </div>
                                <div></div>
                                <div>
                                  <span
                                    class="fsx text-danger-jaja float-right"
                                  >
                                    (- {{ voucher.discountText }} )
                                  </span>
                                </div>
                              </div>
                              <!-- <p>Mulai: {{ voucher.startDate }}</p>
      <p>Berakhir: {{ voucher.endDate }}</p> -->
                            </li>
                          </ul>
                        </div>
                      </div>

                      <hr class="m-0" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3 col-md-5">
              <div class="ringkasanBelanja">
                <div class="infoalamat">
                  <div class="card shadow-custom br-card-jaja">
                    <div class="card-header br-card-header-jaja">
                      <div class="d-flex justify-content-between">
                        <div>
                          <span class="mb-0 float-left judul-sec"
                            >Ringkasan Belanja</span
                          >
                        </div>
                        <div></div>
                        <div>
                          <div
                            v-for="(item, index) in dataItemProduk"
                            :key="index"
                          >
                            <div v-if="statusOrder === 'done' && item.isRating">
                              <button
                                type="button"
                                class="btn btn-sm btn-primary bg-warning text-white font-weight-bold br-button-jaja"
                                data-dismiss="modal"
                                style="
                                  background: #fdb738;
                                  border-color: #fdb738;
                                  font-size: 13px;
                                "
                                @click="beriRatingmModal(b)"
                              >
                                Beri Rating
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- {{dataOrder}} -->
                    </div>
                    <div class="card-body">
                      <ul class="list-group">
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              <span class="text-primary-jaja text-bold"
                                >Rincian Harga</span
                              >
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            ></div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">
                              <span
                                >Total Harga Produk (
                                <span
                                  >{{ dataOrder.totalAllProduct }} Produk</span
                                >
                                )</span
                              >
                            </div>

                            <div class="bd-highlight dharga">
                              {{ dataOrder.subTotalCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">
                              <!-- <span>
                                Voucher Toko (<b
                                  v-text="
                                    dataOrder.items[0].voucherStoreSelected.code.toUpperCase()
                                  "
                                ></b
                                >)
                              </span> -->
                              <span> Voucher Toko </span>
                            </div>
                            <div class="bd-highlight text-danger-jaja dharga">
                              <!-- {{
                                dataOrder.items[0].voucherDiscountCurrencyFormat
                              }} -->
                              -
                              {{
                                formatCurrency(dataOrder.diskon_voucher_toko)
                              }}
                            </div>
                          </div>
                        </li>

                        <!-- <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">
                              Subtotal (
                              <span
                                >{{ dataOrder.totalAllProduct }} Produk</span
                              >
                              )
                            </div>

                            <div class="bd-highlight dharga">
                              {{ dataOrder.subTotalCurrencyFormat }}
                              {{ formatCurrency(dataOrder.total) }}
                            </div>
                          </div>
                        </li> -->
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">Ongkos</div>
                            <div class="bd-highlight dharga">
                              {{ dataOrder.shippingCostCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">Biaya Layanan</div>
                            <div class="bd-highlight dharga">
                              {{ formatRupiah(dataOrder.fee) }}
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">
                              Pajak ({{ dataOrder.taxFormat }})
                            </div>
                            <div class="bd-highlight dharga">
                              {{ formatRupiah(dataOrder.tax_amount) }}
                            </div>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 p-0 mb-3"
                          v-if="dataOrder.voucherDiscountJaja > 0"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">
                              {{ dataOrder.voucherDiscountJajaDesc }}
                            </div>
                            <div class="bd-highlight text-danger-jaja dharga">
                              {{ dataOrder.voucherDiscountJajaCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 p-0 mb-3"
                          v-if="dataOrder.voucherDiscountJaja == 0"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">
                              Potongan Jaja.id
                            </div>
                            <div class="bd-highlight dharga">0</div>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 p-0 mb-3"
                          v-if="dataOrder.coinCurrencyFormat != 0"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">
                              Penggunaan Koin Jaja.id
                            </div>
                            <div class="bd-highlight text-danger-jaja dharga">
                              {{ dataOrder.coinCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 p-0 mb-3"
                          v-if="dataOrder.coinCurrencyFormat == 0"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">Koin Jaja.id</div>
                            <div class="bd-highlight dharga">
                              {{ dataOrder.coinCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                        <!-- <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">
                              Biaya Penanganan
                            </div>
                            <div class="bd-highlight dharga">0</div>
                          </div>
                        </li> -->
                        <li class="list-group-item border-0 pl-0 pr-0">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">Total</div>
                            <div class="bd-highlight tharga">
                              {{ dataOrder.totalCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div
                      class="card-footer bg-white border-0 br-card-footer-jaja"
                    >
                      <div>
                        <div
                          :class="dataTokenMidtrans ? `d-none` : ``"
                          v-if="statusOrder === 'notPaid'"
                        >
                          <!-- <div class="card card-metodePembayaran bg-primary text-center p-2 mb-2 mt-2" @click="showPaymentMethod('first')">
                                                        <span id="metodePembayaran" class="mb-0 fsx text-center text-white font-weight-bold">Pilih Metode Pembayaran</span>
                                                    </div> -->
                          <button
                            type="button"
                            @click="showPaymentMethod('first')"
                            class="btn-pembayaran"
                            style="
                              color: var(--Neutral-10, #fff);
                              font-family: Poppins;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 600;
                              line-height: 18px;
                              letter-spacing: 0.11px;
                            "
                          >
                            Pilih Metode Pembayaran
                          </button>
                        </div>
                        <div
                          :class="dataTokenMidtrans ? `row` : `d-none row`"
                          v-if="statusOrder === 'notPaid'"
                        >
                          <div class="col-md-6 pr-2">
                            <button
                              type="button"
                              @click="buyWithMidtrans()"
                              class="btn-pembayaran font-weight-bold"
                              style="width: 100%; height: 40px; color: white"
                            >
                              Bayar Sekarang
                            </button>
                          </div>
                          <div class="col-md-6 pl-0">
                            <button
                              type="button"
                              @click="showPaymentMethod('second')"
                              class="btn btn-secondary font-weight-bold"
                              data-dismiss="modal"
                              style="
                                background: #fdb738;
                                border-color: #fdb738;
                                width: 100%;
                                height: 40px;
                              "
                            >
                              Ganti Pembayaran
                            </button>
                          </div>
                        </div>
                        <div v-if="statusOrder === 'notPaid'">
                          <button
                            type="button"
                            class="p-2 mb-2 mt-2 btn-cancel"
                            @click="cancelOrderNotPaid()"
                          >
                            Batalkan Pesanan
                          </button>
                        </div>
                        <div v-if="statusOrder === 'waitConfirm'">
                          <button
                            type="button"
                            class="p-2 mb-2 mt-2 btn btn-md btn-primary btn-block bg-danger-jaja br-color-danger-jaja text-white font-weight-bold br-button-jaja"
                            @click="cancelOrderWaitConfirm()"
                          >
                            Batalkan Pesanan
                          </button>
                        </div>

                        <div
                          v-if="
                            statusOrder === 'sent' && dataOrder.complain != true
                          "
                        >
                          <button
                            type="button"
                            class="p-2 mb-2 mt-2 btn btn-md btn-primary btn-block text-white bg-success-jaja br-color-success-jaja font-weight-bold br-button-jaja"
                            @click="terimaPesananConfirm()"
                          >
                            Terima Pesanan
                          </button>
                        </div>
                        <div
                          v-if="
                            statusOrder === 'sent' && dataOrder.complain != true
                          "
                        >
                          <button
                            type="button"
                            class="p-2 mb-2 mt-2 btn btn-md btn-primary btn-block bg-danger-jaja br-color-danger-jaja text-white font-weight-bold br-button-jaja"
                            @click="komplainPesananModal()"
                          >
                            Komplain Pesanan
                          </button>
                        </div>
                        <div
                          v-if="
                            statusOrder === 'sent' && dataOrder.complain == true
                          "
                        >
                          <router-link
                            :to="'/order/detail-komplain/' + jjdOrInv"
                            class="p-2 mb-2 mt-2 btn btn-md btn-primary btn-block bg-danger-jaja br-color-danger-jaja text-white font-weight-bold br-button-jaja"
                          >
                            Menunggu Solusi Komplain
                          </router-link>
                        </div>
                        <!-- <div v-for="(item, index) in dataItemProduk" :key="index">
                                                <div v-if="statusOrder === 'done' && item.isRating == false">
                                                    <button type="button" class="p-2 mb-2 mt-2 btn btn-md btn-primary btn-block bg-warning text-white font-weight-bold br-button-jaja" data-dismiss="modal" style="background: #fdb738; border-color: #fdb738" @click="beriRatingmModal()" >Beri Rating 
                                                    </button>
                                                </div>
                                                </div> -->
                        <div v-if="statusOrder === 'canceled'" class="mb-3">
                          <button
                            type="button"
                            class="p-2 mb-2 mt-2 btn btn-md btn-primary btn-block bg-danger-jaja br-color-danger-jaja text-white font-weight-bold br-button-jaja"
                          >
                            Pesanan Telah Dibatalkan
                          </button>
                          <div
                            class="d-flex justify-content-between bd-highlight"
                            v-if="dataOrder.cancelBy"
                          >
                            <div class="bd-highlight">Dibatalkan Oleh</div>
                            <div
                              class="bd-highlight text-danger-jaja text-bold"
                            >
                              {{ dataOrder.cancelBy }}
                            </div>
                          </div>
                          <div
                            class="d-flex justify-content-between bd-highlight"
                            v-if="dataOrder.cancelReason"
                          >
                            <div class="bd-highlight">Alasan Dibatalkan</div>
                            <div
                              class="bd-highlight text-danger-jaja text-bold"
                            >
                              {{ dataOrder.cancelReason }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3 ringkasanBelanja">
                <div class="infoalamat">
                  <div class="card shadow-custom br-card-jaja">
                    <div class="card-header br-card-header-jaja">
                      <div class="mt-2 d-flex justify-content-between">
                        <div>
                          <span class="mb-0 float-left judul-sec"
                            >No. Pesanan</span
                          >
                        </div>
                        <div></div>
                        <div>
                          <span class="mb-0 judul-sec"
                            >{{ jjdOrInv }}
                            <button
                              @click="copyToClipboard(jjdOrInv)"
                              style="color: red"
                              class="btn btn-link"
                            >
                              SALIN
                            </button></span
                          >
                        </div>
                      </div>
                      <!-- {{dataOrder}} -->
                    </div>
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <div>
                          <span class="mb-0 float-left dharga"
                            >Waktu Pemesanan</span
                          >
                        </div>
                        <div></div>
                        <div>
                          <span class="mb-0 dharga">{{
                            dataOrder.createdAt
                          }}</span>
                        </div>
                      </div>
                      <div class="mt-3 d-flex justify-content-between">
                        <div>
                          <span class="mb-0 float-left dharga"
                            >Waktu Pembayaran</span
                          >
                        </div>
                        <div></div>
                        <div>
                          <span class="mb-0 dharga">{{
                            dataOrder.paidAt
                          }}</span>
                        </div>
                      </div>
                      <div class="mt-3 d-flex justify-content-between">
                        <div>
                          <span class="mb-0 float-left dharga"
                            >Waktu Pengiriman</span
                          >
                        </div>
                        <div></div>
                        <div>
                          <span class="mb-0 dharga">{{
                            dataOrder.shippedAt
                          }}</span>
                        </div>
                      </div>
                      <div class="mt-3 d-flex justify-content-between">
                        <div>
                          <span class="mb-0 float-left dharga"
                            >Waktu Pesanan Selesai</span
                          >
                        </div>
                        <div></div>
                        <div>
                          <span class="mb-0 dharga">{{
                            dataOrder.receivedAt
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="metodePembayaran"> -->
            </div>
          </div>
        </div>

        <div class="col-md-12" v-if="!isFinish">
          <div class="card-product shadow custom mb-3 p-3">
            <div class="d-flex justify-content-between bd-highlight">
              <div class="p-2 bd-highlight">
                <span class="font-weight-bold h5">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      :centered="true"
                      style="width: 120px"
                    />
                  </content-placeholders>
                </span>
              </div>
              <div class="p-2 bd-highlight"></div>
              <div
                class="p-2 bd-highlight statusorder text-center text-white rounded font-weight-bold"
              >
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    :centered="true"
                    style="width: 100px"
                  />
                </content-placeholders>
              </div>
            </div>

            <div class="d-flex justify-content-between bd-highlight">
              <div class="p-2 bd-highlight mt-2 mb-2 text-left">
                <span class="font-weight-bold h5">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      :centered="true"
                      style="width: 100px"
                    />
                  </content-placeholders>
                </span>
              </div>
              <div class="center-middle-custom p-2">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    :centered="true"
                    style="width: 120px"
                  />
                </content-placeholders>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-7">
              <div class="alamat">
                <div class="infoalamat">
                  <div class="card shadow-custom br-card-jaja mb-3">
                    <div class="card-header br-card-header-jaja">
                      <span class="mb-0 float-left fsx text-white text-bold">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            :centered="true"
                            style="width: 120px"
                          />
                        </content-placeholders>
                      </span>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-5 text-left">
                          <span>
                            <content-placeholders :rounded="true">
                              <content-placeholders-text
                                :lines="1"
                                :centered="true"
                                style="width: 120px"
                              />
                            </content-placeholders>
                          </span>
                        </div>
                        <div class="col-md-7 text-left">
                          <span class="">
                            <content-placeholders :rounded="true">
                              <content-placeholders-text
                                :lines="3"
                                :centered="true"
                                style="width: 150px"
                              />
                            </content-placeholders>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5 text-left">
                          <span
                            ><b>
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </b>
                          </span>
                        </div>
                        <div class="col-md-7 text-left">
                          <span class="">
                            <content-placeholders :rounded="true">
                              <content-placeholders-text
                                :lines="1"
                                :centered="true"
                                style="width: 120px"
                              />
                            </content-placeholders>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="card br-card-jaja">
                      <div class="card-header bg-secondary-jaja">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            :centered="true"
                            style="width: 120px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="card-body">
                            <div class="row pb-2">
                              <div class="col-md-2">
                                <div class="media">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-img
                                      style="width: 45px; height: 40px"
                                    />
                                  </content-placeholders>
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="row">
                                  <div class="col-md-5">
                                    <span
                                      ><a
                                        class="d-flex text-dark text-bold text-left"
                                      >
                                        <content-placeholders :rounded="true">
                                          <content-placeholders-text
                                            :lines="1"
                                            style="width: 100px"
                                          />
                                        </content-placeholders> </a
                                    ></span>
                                    <div class="row">
                                      <div class="col-md-12">
                                        <span class="float-left text-dark">
                                          <content-placeholders :rounded="true">
                                            <content-placeholders-text
                                              :lines="1"
                                              style="width: 80px"
                                            />
                                          </content-placeholders>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 text-primary-jaja text-bold align-self-end"
                                  >
                                    <span class="text-primary-jaja"
                                      ><b>
                                        <content-placeholders :rounded="true">
                                          <content-placeholders-text
                                            :lines="1"
                                            style="width: 100px"
                                          />
                                        </content-placeholders> </b
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr class="m-0" />

                      <div
                        class="card-footer text-muted bg-white br-card-footer-jaja"
                      >
                        <div class="row">
                          <div class="col-md-12 mb-2 mt-2">
                            <div class="row">
                              <div class="col-md-6 col-6">
                                <content-placeholders :rounded="true">
                                  <content-placeholders-img
                                    style="width: 200px; height: 30px"
                                  />
                                </content-placeholders>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </a>
                            <span class="float-right">
                              <b
                                ><span class="float-right text-danger-jaja">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text
                                      :lines="1"
                                      style="width: 100px"
                                    />
                                  </content-placeholders>
                                </span>
                              </b>
                            </span>
                          </div>
                        </div>
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </a>
                            <span class="float-right">
                              <b
                                ><span class="float-right text-danger-jaja">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text
                                      :lines="1"
                                      style="width: 100px"
                                    />
                                  </content-placeholders>
                                </span>
                              </b>
                            </span>
                          </div>
                        </div>
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </a>
                            <span class="float-right">
                              <b
                                ><span class="float-right text-danger-jaja">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text
                                      :lines="1"
                                      style="width: 100px"
                                    />
                                  </content-placeholders>
                                </span>
                              </b>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="card-footer text-muted bg-white br-card-footer-jaja"
                      >
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </a>
                            <span class="float-right">
                              <b>
                                <content-placeholders :rounded="true">
                                  <content-placeholders-text
                                    :lines="1"
                                    style="width: 120px"
                                  />
                                </content-placeholders>
                              </b>
                            </span>
                          </div>
                        </div>
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </a>
                            <span class="float-right">
                              <b>
                                <content-placeholders :rounded="true">
                                  <content-placeholders-text
                                    :lines="1"
                                    style="width: 100px"
                                  />
                                </content-placeholders>
                              </b>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5" v-if="!isFinish">
              <div class="ringkasanBelanja">
                <div class="infoalamat">
                  <div class="card shadow-custom br-card-jaja">
                    <div class="card-header br-card-header-jaja">
                      <span class="mb-0 float-left fsx text-white text-bold">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            :centered="true"
                            style="width: 120px"
                          />
                        </content-placeholders>
                      </span>
                      <!-- {{dataOrder}} -->
                    </div>
                    <div class="card-body">
                      <ul class="list-group">
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 70px"
                                />
                              </content-placeholders>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 70px"
                                />
                              </content-placeholders>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 70px"
                                />
                              </content-placeholders>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 70px"
                                />
                              </content-placeholders>
                            </div>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 pl-0 pr-0"
                          style="border-top: 1px solid grey !important"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div
                      class="card-footer bg-white border-0 br-card-footer-jaja mb-2"
                    >
                      <div>
                        <content-placeholders :rounded="true" :centered="true">
                          <content-placeholders-img
                            style="width: 200px; height: 40px"
                          />
                        </content-placeholders>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="metodePembayaran"> -->
            </div>
          </div>
        </div>

        <!-- END CONTENT -->
      </div>
    </div>
    <div style="margin-top: -40%" class="container-fluid p-3 d-lg-none">
      <div class="row">
        <!-- <div class="col-md-3 desktop-only">
          <sideBar />
        </div> -->

        <!-- CONTENT -->

        <div class="col-md-12" v-if="isFinish">
          <div
            class="card-product shadow-custom p-3"
            style="margin-left: 0px; margin-right: 0px"
          >
            <div class="d-flex justify-content-between bd-highlight">
              <div class="p-2 bd-highlight">
                <div>
                  <h4
                    style="
                      margin-left: 10px;
                      text-align: left;
                      margin-top: 10px;
                      color: #000;
                      font-family: Poppins;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 26px; /* 130% */
                      letter-spacing: 0.2px;
                    "
                  >
                    <router-link style="color: black" :to="'/listorder'">
                      <i class="fas fa-arrow-left"></i> </router-link
                    >&nbsp; &nbsp; #{{ jjdOrInv }}
                  </h4>
                  <p class="ml-2 tgl-pesan text-left">Tanggal Pemesanank</p>
                  <span class="mr-4 tgl-pesan text-left" style="color: red">
                    &nbsp;{{ dataOrder.createdAt }}
                  </span>
                </div>
              </div>

              <div class="p-2 bd-highlight"></div>
              <div class="mt-3 p-2 bd-highlight">
                <div
                  style="
                    border-radius: 4px;
                    background: var(--Alerts-Red-100, #e41010);
                    padding: 5px;
                    gap: 8px;
                    color: white;
                    color: var(--Neutral-10, #fff);
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                    letter-spacing: -0.14px;
                  "
                >
                  {{ statusInfo }}
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4 row">
            <div class="col-md-7">
              <div
                v-if="statusInfo === 'Dikirim' || statusInfo === 'Selesai'"
                class="card shadow-custom br-card-jaja"
              >
                <div class="card-header br-card-header-jaja">
                  <div class="d-flex justify-content-start bd-highlight">
                    <div class="bd-highlight text-bold mr-auto">
                      Lacak Pesanan
                    </div>
                    <div class="bd-highlight text-white text-bold">
                      <!-- <router-link :to="'/tracking/' + this.$route.params.id">
                          <button
                            class="btn btn-sm btn-warning font-weight-bold text-white"
                          >
                            Detail
                          </button>
                        </router-link> -->

                      <button
                        @click="toggleDetail"
                        class="btn btn-sm btn-warning font-weight-bold text-white"
                      >
                        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                        <i v-else-if="showDetail" class="fas fa-arrow-up"></i>
                        <i v-else class="fas fa-arrow-down"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="orderData">
                <!-- Bagian baru untuk menampilkan riwayat pergerakan paket -->
                <div v-if="showDetail" class="card shadow-custom br-card-jaja">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 text-left">
                        <ul class="timeline list-track">
                          <li
                            v-for="(item, index) in orderData.data.rajaongkir
                              .result.manifest"
                            :key="index"
                          >
                            <a class="float-right text-danger"
                              >{{ item.manifest_date }}
                              {{ item.manifest_time }}</a
                            >
                            <p>{{ item.manifest_description }}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="mt-3 alamat"
                v-for="(item, index) in dataItemProduk"
                :key="index"
              >
                <div class="infoalamat">
                  <div class="card shadow-custom br-card-jaja mb-3">
                    <div class="card-header br-card-header-jaja">
                      <span class="mb-0 float-left judul-sec">
                        <img
                          src="../../assets/icon-s/shipping1.png"
                          alt="icon"
                          class="mr-2 mb-1"
                          style="width: 35.556px; height: 16px; color: black"
                        />
                        &nbsp; Pilihan Jasa Pengiriman</span
                      >
                      <br />
                      <div class="mt-4 d-flex justify-content-start">
                        <div>
                          <p class="dharga text-left">
                            {{ item.shippingSelected.description }}
                          </p>
                          <p class="des-hrga text-left">
                            {{ item.shippingSelected.name }}
                          </p>
                        </div>
                        <div class="ml-5">
                          <p class="dharga text-left">
                            <!-- {{
                                item.shippingSelected.sendTime
                              }} -->
                            Biaya Pengiriman
                          </p>
                          <p class="des-hrga text-left">
                            {{ item.shippingSelected.priceCurrencyFormat }}
                          </p>
                        </div>
                        <div
                          class="ml-5"
                          v-if="
                            statusInfo === 'Dikirim' || statusInfo === 'Selesai'
                          "
                        >
                          <p class="dharga text-left">Nomor Resi Pengiriman</p>
                          <p class="des-hrga text-left">
                            {{ dataTracking.trackingId }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="card-header br-card-header-jaja">
                      <span class="mb-0 float-left judul-sec">
                        <img
                          src="../../assets/icon-s/addres.png"
                          alt="icon"
                          class="mr-2 mb-1"
                          style="width: 21.772px; height: 20px; color: black"
                        />
                        &nbsp; Alamat Pengiriman</span
                      >
                      <div>
                        <br />
                        <div class="mt-4">
                          <div class="text-left">
                            <span
                              ><b
                                >{{ item.address.receiverName }} &nbsp; ({{
                                  item.address.phoneNumber
                                }})</b
                              ></span
                            >
                          </div>
                          <div class="dharga text-left">
                            <span class="">{{ item.address.address }}</span>
                          </div>
                        </div>
                        <div class="mt-3 row">
                          <div class="col-md-5 text-left">
                            <span><b>Catatan</b> </span>
                          </div>
                          <div class="col-md-7 dharga text-left">
                            <span class="">{{ item.note }}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card br-card-jaja">
                      <div class="mt-2 card-header" style="border-bottom: none">
                        <div class="d-flex justify-content-between">
                          <div class="mt-2">
                            <router-link
                              :to="'/home/DetailToko/' + item.store.slug"
                              class="mb-0 float-left judul-sec text-bold"
                            >
                              <img
                                src="../../assets/icon-s/shop.png"
                                alt="icon"
                                class="mr-2 mb-1"
                                style="
                                  width: 23.29px;
                                  height: 18px;
                                  color: black;
                                "
                              />
                              {{ item.store.name }}
                            </router-link>
                          </div>
                          <div></div>
                          <div>
                            <button
                              type="button"
                              class="btn btn-sm bg-primary float-left text-white br-button-jaja"
                              style="
                                background: #42b78f !important;
                                font-weight: bold;
                              "
                              @click="chatStore(index)"
                              id="chat-toko"
                            >
                              <i class="fas fa-comment-dots"></i>
                              &nbsp; Chat Penjual
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="card-body">
                            <div
                              class="pb-2"
                              v-for="(a, b) in item.products"
                              :key="b"
                            >
                              <div class="d-flex justify-content-between">
                                <div>
                                  <div class="media">
                                    <img
                                      :src="a.image"
                                      style="
                                        width: 60px;
                                        height: 60px;
                                        flex-shrink: 0;
                                      "
                                      alt=""
                                    />
                                    <router-link
                                      :to="'/home/DetailProduk/' + a.slug"
                                    >
                                      <span
                                        ><a
                                          class="mt-3 ml-3 d-flex product-text text-left"
                                          >{{ a.name }}
                                        </a></span
                                      >
                                    </router-link>
                                  </div>
                                </div>
                                <div>
                                  <p class="judul-des">Variant:</p>
                                  <p class="des-hrga">{{ a.variant || "-" }}</p>
                                </div>
                                <div>
                                  <div>
                                    <p class="judul-des">Harga</p>
                                    <p class="des-hrga">
                                      {{
                                        a.isDiscount
                                          ? a.priceDiscountCurrencyFormat
                                          : a.priceCurrencyFormat
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <p class="judul-des">Kuantitas</p>
                                    <p class="des-hrga">{{ a.qty }} items</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr class="m-0" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3 mb-5 col-md-5">
              <div class="ringkasanBelanja mb-5">
                <div class="infoalamat">
                  <div class="card shadow-custom br-card-jaja">
                    <div class="card-header br-card-header-jaja">
                      <span class="mb-0 float-left judul-sec"
                        >Ringkasan Belanja</span
                      >
                      <!-- {{dataOrder}} -->
                    </div>
                    <div class="card-body">
                      <ul class="list-group">
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              <span class="text-primary-jaja text-bold"
                                >Rincian Harga</span
                              >
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            ></div>
                          </div>
                        </li>
                        <li
                          v-if="
                            dataOrder.items[0].voucherDiscountCurrencyFormat !==
                            'Rp0'
                          "
                          class="list-group-item border-0 p-0 mb-3"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">
                              <span>Total Harga Produk</span>
                            </div>

                            <div class="bd-highlight dharga">
                              {{ dataOrder.items[0].totalCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                        <li
                          v-if="
                            dataOrder.items[0].voucherDiscountCurrencyFormat !==
                            'Rp0'
                          "
                          class="list-group-item border-0 p-0 mb-3"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">
                              <!-- <span>
                                Voucher Toko (<b
                                  v-text="
                                    dataOrder.items[0].voucherStoreSelected.code.toUpperCase()
                                  "
                                ></b
                                >)
                              </span> -->
                              <span> Voucher Toko </span>
                            </div>
                            <div class="bd-highlight text-danger-jaja dharga">
                              <!-- - {{
                                dataOrder.items[0].voucherDiscountCurrencyFormat
                              }} -->
                              -
                              {{
                                formatCurrency(dataOrder.diskon_voucher_toko)
                              }}
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">
                              Subtotal (
                              <span
                                >{{ dataOrder.totalAllProduct }} Produk</span
                              >
                              )
                            </div>
                            <div class="bd-highlight dharga">
                              {{ dataOrder.subTotalCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">Ongkos</div>
                            <div class="bd-highlight dharga">
                              {{ dataOrder.shippingCostCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">
                              Biaya Penanganan
                            </div>
                            <div class="bd-highlight dharga">0</div>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 p-0 mb-3"
                          v-if="dataOrder.voucherDiscountJaja > 0"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">
                              {{ dataOrder.voucherDiscountJajaDesc }}
                            </div>
                            <div class="bd-highlight text-danger-jaja dharga">
                              {{ dataOrder.voucherDiscountJajaCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 p-0 mb-3"
                          v-if="dataOrder.voucherDiscountJaja == 0"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">
                              Potongan Jaja.id
                            </div>
                            <div class="bd-highlight dharga">0</div>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 p-0 mb-3"
                          v-if="dataOrder.coinCurrencyFormat != 0"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">Koin Jaja.id</div>
                            <div class="bd-highlight text-danger-jaja dharga">
                              {{ dataOrder.coinCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 p-0 mb-3"
                          v-if="dataOrder.coinCurrencyFormat == 0"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">Koin Jaja.id</div>
                            <div class="bd-highlight dharga">
                              {{ dataOrder.coinCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 pl-0 pr-0">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight dharga">Total</div>
                            <div class="bd-highlight tharga">
                              {{ dataOrder.totalCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="metodePembayaran"> -->
            </div>
          </div>
        </div>

        <div class="col-md-12" v-if="!isFinish">
          <div class="card-product shadow custom mb-3 p-3">
            <div class="d-flex justify-content-between bd-highlight">
              <div class="p-2 bd-highlight">
                <span class="font-weight-bold h5">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      :centered="true"
                      style="width: 120px"
                    />
                  </content-placeholders>
                </span>
              </div>
              <div class="p-2 bd-highlight"></div>
              <div
                class="p-2 bd-highlight statusorder text-center text-white rounded font-weight-bold"
              >
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    :centered="true"
                    style="width: 100px"
                  />
                </content-placeholders>
              </div>
            </div>

            <div class="d-flex justify-content-between bd-highlight">
              <div class="p-2 bd-highlight mt-2 mb-2 text-left">
                <span class="font-weight-bold h5">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      :centered="true"
                      style="width: 100px"
                    />
                  </content-placeholders>
                </span>
              </div>
              <div class="center-middle-custom p-2">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    :centered="true"
                    style="width: 120px"
                  />
                </content-placeholders>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-7">
              <div class="alamat">
                <div class="infoalamat">
                  <div class="card shadow-custom br-card-jaja mb-3">
                    <div class="card-header br-card-header-jaja">
                      <span class="mb-0 float-left fsx text-white text-bold">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            :centered="true"
                            style="width: 120px"
                          />
                        </content-placeholders>
                      </span>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-5 text-left">
                          <span>
                            <content-placeholders :rounded="true">
                              <content-placeholders-text
                                :lines="1"
                                :centered="true"
                                style="width: 120px"
                              />
                            </content-placeholders>
                          </span>
                        </div>
                        <div class="col-md-7 text-left">
                          <span class="">
                            <content-placeholders :rounded="true">
                              <content-placeholders-text
                                :lines="3"
                                :centered="true"
                                style="width: 150px"
                              />
                            </content-placeholders>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5 text-left">
                          <span
                            ><b>
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </b>
                          </span>
                        </div>
                        <div class="col-md-7 text-left">
                          <span class="">
                            <content-placeholders :rounded="true">
                              <content-placeholders-text
                                :lines="1"
                                :centered="true"
                                style="width: 120px"
                              />
                            </content-placeholders>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="card br-card-jaja">
                      <div class="card-header bg-secondary-jaja">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            :centered="true"
                            style="width: 120px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="card-body">
                            <div class="row pb-2">
                              <div class="col-md-2">
                                <div class="media">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-img
                                      style="width: 45px; height: 40px"
                                    />
                                  </content-placeholders>
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="row">
                                  <div class="col-md-5">
                                    <span
                                      ><a
                                        class="d-flex text-dark text-bold text-left"
                                      >
                                        <content-placeholders :rounded="true">
                                          <content-placeholders-text
                                            :lines="1"
                                            style="width: 100px"
                                          />
                                        </content-placeholders> </a
                                    ></span>
                                    <div class="row">
                                      <div class="col-md-12">
                                        <span class="float-left text-dark">
                                          <content-placeholders :rounded="true">
                                            <content-placeholders-text
                                              :lines="1"
                                              style="width: 80px"
                                            />
                                          </content-placeholders>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 text-primary-jaja text-bold align-self-end"
                                  >
                                    <span class="text-primary-jaja"
                                      ><b>
                                        <content-placeholders :rounded="true">
                                          <content-placeholders-text
                                            :lines="1"
                                            style="width: 100px"
                                          />
                                        </content-placeholders> </b
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr class="m-0" />

                      <div
                        class="card-footer text-muted bg-white br-card-footer-jaja"
                      >
                        <div class="row">
                          <div class="col-md-12 mb-2 mt-2">
                            <div class="row">
                              <div class="col-md-6 col-6">
                                <content-placeholders :rounded="true">
                                  <content-placeholders-img
                                    style="width: 200px; height: 30px"
                                  />
                                </content-placeholders>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </a>
                            <span class="float-right">
                              <b
                                ><span class="float-right text-danger-jaja">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text
                                      :lines="1"
                                      style="width: 100px"
                                    />
                                  </content-placeholders>
                                </span>
                              </b>
                            </span>
                          </div>
                        </div>
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </a>
                            <span class="float-right">
                              <b
                                ><span class="float-right text-danger-jaja">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text
                                      :lines="1"
                                      style="width: 100px"
                                    />
                                  </content-placeholders>
                                </span>
                              </b>
                            </span>
                          </div>
                        </div>
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </a>
                            <span class="float-right">
                              <b
                                ><span class="float-right text-danger-jaja">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text
                                      :lines="1"
                                      style="width: 100px"
                                    />
                                  </content-placeholders>
                                </span>
                              </b>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="card-footer text-muted bg-white br-card-footer-jaja"
                      >
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </a>
                            <span class="float-right">
                              <b>
                                <content-placeholders :rounded="true">
                                  <content-placeholders-text
                                    :lines="1"
                                    style="width: 120px"
                                  />
                                </content-placeholders>
                              </b>
                            </span>
                          </div>
                        </div>
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </a>
                            <span class="float-right">
                              <b>
                                <content-placeholders :rounded="true">
                                  <content-placeholders-text
                                    :lines="1"
                                    style="width: 100px"
                                  />
                                </content-placeholders>
                              </b>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5" v-if="!isFinish">
              <div class="ringkasanBelanja">
                <div class="infoalamat">
                  <div class="card shadow-custom br-card-jaja">
                    <div class="card-header br-card-header-jaja">
                      <span class="mb-0 float-left fsx text-white text-bold">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            :centered="true"
                            style="width: 120px"
                          />
                        </content-placeholders>
                      </span>
                      <!-- {{dataOrder}} -->
                    </div>
                    <div class="card-body">
                      <ul class="list-group">
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 70px"
                                />
                              </content-placeholders>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 70px"
                                />
                              </content-placeholders>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 70px"
                                />
                              </content-placeholders>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 70px"
                                />
                              </content-placeholders>
                            </div>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 pl-0 pr-0"
                          style="border-top: 1px solid grey !important"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div
                      class="card-footer bg-white border-0 br-card-footer-jaja mb-2"
                    >
                      <div>
                        <content-placeholders :rounded="true" :centered="true">
                          <content-placeholders-img
                            style="width: 200px; height: 40px"
                          />
                        </content-placeholders>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="metodePembayaran"> -->
            </div>
          </div>
        </div>

        <!-- END CONTENT -->
      </div>
      <div
        style="
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          z-index: 1000;
          padding: 12px 10px 8px 10px;
          gap: 8px;
          border-radius: 16px 16px 0px 0px;
          background: var(--Neutral-10, #fff);
          box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.04);
        "
      >
        <div class="container-m d-flex justify-content-between">
          <div>
            <p
              class="text-left"
              style="
                color: var(--Neutral-50, #757575);
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px; /* 160% */
                letter-spacing: -0.1px;
              "
            >
              Total
            </p>
            <p
              class="text-left"
              style="
                color: var(--Neutral-60, #1a1a1a);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 19px;
                letter-spacing: 0.28px;
              "
            >
              {{ dataOrder.totalCurrencyFormat }}
            </p>
          </div>
          <div>
            <div
              :class="dataTokenMidtrans ? `d-none` : ``"
              v-if="statusOrder === 'notPaid'"
            >
              <!-- <div class="card card-metodePembayaran bg-primary text-center p-2 mb-2 mt-2" @click="showPaymentMethod('first')">
                                                        <span id="metodePembayaran" class="mb-0 fsx text-center text-white font-weight-bold">Pilih Metode Pembayaran</span>
                                                    </div> -->
              <button
                type="button"
                @click="showPaymentMethod('first')"
                class="mt-3 checkout-button-mobile"
                style="
                  color: var(--Neutral-10, #fff);
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 18px;
                  letter-spacing: 0.11px;
                "
              >
                Pembayaran
              </button>
            </div>
            <div v-if="statusOrder === 'sent' && dataOrder.complain != true">
              <button
                type="button"
                class="p-2 mb-2 mt-2 btn btn-md btn-primary btn-block text-white bg-success-jaja br-color-success-jaja font-weight-bold br-button-jaja"
                @click="terimaPesananConfirm()"
              >
                Terima Pesanan
              </button>
            </div>
          </div>

          <div>
            <div
              :class="dataTokenMidtrans ? `row` : `d-none row`"
              v-if="statusOrder === 'notPaid'"
            >
              <div class="col-md-6 pr-2">
                <button
                  type="button"
                  @click="buyWithMidtrans()"
                  class="mt-3 checkout-button-mobile"
                >
                  Bayar Sekarang
                </button>
              </div>
              <div class="col-md-6 pl-0">
                <button
                  type="button"
                  @click="showPaymentMethod('second')"
                  class="mt-3 checkout-button-mobile"
                  data-dismiss="modal"
                  style="background: #fdb738; border-color: #fdb738"
                >
                  Ganti Pembayaran
                </button>
              </div>
            </div>
            <div v-if="statusOrder === 'notPaid'">
              <button
                type="button"
                class="p-2 mb-2 mt-3 btn-cancel"
                @click="cancelOrderNotPaid()"
              >
                Batalkan Pesanan
              </button>
            </div>
            <div v-if="statusOrder === 'waitConfirm'">
              <button
                type="button"
                class="p-2 mb-2 mt-3 btn btn-md btn-primary btn-block bg-danger-jaja br-color-danger-jaja text-white font-weight-bold br-button-jaja"
                @click="cancelOrderWaitConfirm()"
              >
                Batalkan Pesanan
              </button>
            </div>

            <div v-if="statusOrder === 'sent' && dataOrder.complain != true">
              <button
                type="button"
                class="p-2 mb-2 mt-2 btn btn-md btn-primary btn-block bg-danger-jaja br-color-danger-jaja text-white font-weight-bold br-button-jaja"
                @click="komplainPesananModal()"
              >
                Komplain Pesanan
              </button>
            </div>
            <div v-if="statusOrder === 'sent' && dataOrder.complain == true">
              <router-link
                :to="'/order/detail-komplain/' + jjdOrInv"
                class="p-2 mb-2 mt-2 btn btn-md btn-primary btn-block bg-danger-jaja br-color-danger-jaja text-white font-weight-bold br-button-jaja"
              >
                Menunggu Solusi Komplain
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" id="myModalLoading">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body" id="list-pengiriman">
            <div v-if="isLoading" class="text-center">
              <img
                src="https://jsonx.jaja.id/asset/home/logo/Loading_2.gif"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- pilih metode pembayaran -->
    <div
      id="myModalCommon"
      class="modal fade"
      role="dialog"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold">
              Pilih Metode Pembayaran
            </h5>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              :disabled="isLoading"
            >
              &times;
            </button>
          </div>
          <div class="modal-body">
            <div v-if="isLoading" class="text-center">
              <img
                src="https://jsonx.jaja.id/asset/home/logo/Loading_2.gif"
                alt=""
              />
            </div>
            <div v-else>
              <ul
                class="nav nav-tabs nav-justified mb-2"
                id="myTab"
                role="tablist"
              >
                <li
                  v-for="(item, index) in dataCommon"
                  :key="index"
                  class="nav-item"
                >
                  <a
                    :class="[
                      index === 0
                        ? 'nav-link tab-payment-active active link_0 py-2 px-0'
                        : 'nav-link tab-payment-active  link_0 py-2 px-0',
                    ]"
                    id="0-tab"
                    @click="setActiveLabel(index)"
                    data-toggle="tab"
                    href="#kolom0"
                    role="tab"
                    aria-controls=""
                    aria-selected="true"
                  >
                    {{ item.payment_type_label }}
                  </a>
                </li>
              </ul>
              <div
                v-for="(item, index) in dataCommonPerIndex"
                :key="index"
                class="mb-2"
              >
                <div v-if="item.payment_sub_label != 'BNI'">
                  <div
                    class="p-2"
                    style="
                      border-radius: #64b0c9 !important;
                      border: 2px solid #64b0c9;
                      border-radius: 10px;
                    "
                  >
                    <div class="row">
                      <div
                        class="col-md-2"
                        style="
                          width: 100px;
                          display: flex;
                          align-items: center;
                          justify-content: right;
                        "
                      >
                        <input
                          :id="`radio${index}`"
                          type="radio"
                          name="payment_type"
                          value=""
                          :checked="index == 0"
                          @change="setParam(index)"
                        />
                      </div>
                      <div
                        class="col-md-4"
                        style="
                          width: 100px;
                          display: flex;
                          align-items: center;
                          justify-content: left;
                        "
                      >
                        <img
                          v-if="item.payment_sub_label == 'BCA'"
                          class="iconPayment"
                          :src="`https://jsonx.jaja.id/asset/payment/transfer/BNI.png`"
                          alt=""
                        />
                        <img
                          v-else
                          class="iconPayment"
                          :src="`https://jsonx.jaja.id/asset/payment/transfer/${item.payment_sub_label}.png`"
                          alt=""
                        />
                      </div>
                      <div class="col-md-6 text-left">
                        <label
                          v-if="item.payment_sub_label == 'BCA'"
                          for="0"
                          class="w-100 rounded p-2 mb-0 pointer font-weight-bold"
                          >BNI
                        </label>
                        <label
                          v-else
                          for="0"
                          class="w-100 rounded p-2 mb-0 pointer font-weight-bold"
                          >{{ item.payment_sub_label }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              :disabled="isLoading"
              type="button"
              class="btn btn-secondary font-weight-bold"
              data-dismiss="modal"
              style="background: #fdb738; border-color: #fdb738"
            >
              Close
            </button>
            <button
              :disabled="isLoading"
              @click="getParamsMidtrans()"
              type="button"
              class="btn btn-primary font-weight-bold"
              style="background: #64b0c9; border-color: #64b0c9"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" id="myModalRating">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold">Beri Rating</h5>
            <button
              :disabled="isLoading"
              type="button"
              class="close"
              data-dismiss="modal"
            >
              &times;
            </button>
          </div>
          <div class="modal-body" id="list-pengiriman">
            <div v-if="isLoading" class="text-center">
              <img
                src="https://jsonx.jaja.id/asset/home/logo/Loading_2.gif"
                alt=""
              />
            </div>
            <form enctype="multipart/form-data" v-else>
              <div class="card-body">
                <div class="">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="media">
                        <img
                          :src="itemProduct.image"
                          class="img-thumbnail img-responsive"
                          width="45px"
                          height="40px"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="row">
                        <div class="col-md-12">
                          <router-link
                            :to="'/home/DetailProduk/' + itemProduct.slug"
                          >
                            <span
                              ><a
                                class="d-flex text-dark text-bold text-left"
                                >{{ itemProduct.name }}</a
                              ></span
                            >
                          </router-link>
                          <div class="row">
                            <div class="col-md-12">
                              <span class="float-left text-dark">
                                <input
                                  type="hidden"
                                  :value="itemProduct.productId"
                                  id="productId"
                                />
                                <b-form-rating
                                  v-model="rating"
                                  variant="warning"
                                  style="
                                    border: 0px !important;
                                    padding-left: 0px !important;
                                    width: 100px;
                                  "
                                >
                                </b-form-rating>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <span class="d-flex text-dark text-bold text-left mb-1"
                        >Review</span
                      >
                      <ul
                        class="list-group mb-2"
                        style="
                          border-radius: 10px;
                          border: 2px solid rgb(100, 176, 201);
                        "
                      >
                        <li
                          class="list-group-item"
                          style="border: 0px !important"
                        >
                          <div class="form-group">
                            <b-form-group
                              v-slot="{ ariaDescribedby }"
                              style="text-align: left !important"
                            >
                              <b-form-checkbox-group
                                @change="selectCommentCustom"
                                v-model="selected"
                                :options="options"
                                :aria-describedby="ariaDescribedby"
                                button-variant="py-1 btn-outline-warning-custom-checkbox rounded ml-1"
                                size="sm"
                                name="radio-btn-outline"
                                buttons
                              >
                              </b-form-checkbox-group>
                            </b-form-group>
                            <textarea
                              maxlength="300"
                              class="form-control"
                              rows="3"
                              v-model="review"
                              style="margin-bottom: 0rem !important"
                            >
                            </textarea>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <span class="d-flex text-dark text-bold text-left mb-1"
                        >Upload Photo</span
                      >
                      <ul
                        class="list-group mb-2"
                        style="
                          border-radius: 10px;
                          border: 2px solid rgb(100, 176, 201);
                        "
                      >
                        <li
                          class="list-group-item"
                          style="border: 0px !important"
                        >
                          <div class="form-group">
                            <b-form-group>
                              <b-form-file
                                @change="onFileImageChange"
                                id="file-image"
                                style="margin-bottom: 0rem !important"
                              ></b-form-file>
                              <div id="preview">
                                <img
                                  class="mt-3"
                                  v-if="urlImage"
                                  :src="urlImage"
                                  alt=""
                                />
                              </div>
                            </b-form-group>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-md-12">
                      <span class="d-flex text-dark text-bold text-left mb-1"
                        >Upload Video</span
                      >
                      <ul
                        class="list-group mb-2"
                        style="
                          border-radius: 10px;
                          border: 2px solid rgb(100, 176, 201);
                        "
                      >
                        <li
                          class="list-group-item"
                          style="border: 0px !important"
                        >
                          <div
                            class="form-group"
                            style="margin-bottom: 0rem !important"
                          >
                            <b-form-group>
                              <b-form-file
                                accept="video/*"
                                @change="onFileVideoChange"
                                id="file-video"
                                style="margin-bottom: 0rem !important"
                              >
                              </b-form-file>
                              <div id="preview">
                                <video
                                  class="mt-3"
                                  :src="urlVideo"
                                  width="320"
                                  height="240"
                                  controls
                                  v-show="urlVideo"
                                ></video>
                              </div>
                            </b-form-group>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div> -->
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              :disabled="isLoading"
              type="button"
              class="btn btn-secondary font-weight-bold"
              data-dismiss="modal"
              style="background: #fdb738; border-color: #fdb738"
            >
              Close
            </button>
            <button
              :disabled="isLoading"
              type="button"
              @click="runBeriRating"
              class="btn btn-primary font-weight-bold"
              style="background: #64b0c9; border-color: #64b0c9"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="myModalComplain" class="modal fade" role="dialog">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold">Pilih Product Komplain</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div class="modal-body body-complain">
            <div class="card">
              <div class="card-body">
                <table
                  class="table"
                  v-for="(item, index) in dataItemProduk"
                  :key="index"
                >
                  <thead>
                    <tr>
                      <th scope="col">Nomer</th>
                      <th scope="col" class="text-left">Gambar</th>
                      <th scope="col" class="text-left">Product</th>
                      <th scope="col">Variant</th>
                      <th scope="col">Pilih</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(a, b) in item.products" :key="b">
                      <td>{{ b + 1 }}</td>
                      <td class="text-left">
                        <img
                          class="img-fluid"
                          style="width: 100px; height: auto"
                          :src="a.image"
                          alt=""
                        />
                      </td>
                      <td class="text-left">{{ a.name }}</td>
                      <td v-if="a.variant">{{ a.variant }}</td>
                      <td v-if="!a.variant">-</td>
                      <td>
                        <div class="custom-control custom-checkbox d-inline">
                          <input
                            @click="pilihProductComplain(a.productId, b)"
                            type="checkbox"
                            :id="'pilihProduct' + b"
                            class="custom-control-input"
                            :value="a.productId"
                          />
                          <label
                            class="custom-control-label"
                            :for="'pilihProduct' + b"
                          ></label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              :disabled="isLoading"
              type="button"
              class="btn btn-secondary font-weight-bold"
              data-dismiss="modal"
              style="background: #fdb738; border-color: #fdb738"
            >
              Close
            </button>
            <button
              :disabled="isLoading || !isProductSelected"
              type="button"
              @click="komplainPesananLanjutanModal"
              class="btn btn-primary font-weight-bold"
              style="background: #64b0c9; border-color: #64b0c9"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="myModalLanjutComplain" class="modal fade" role="dialog">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold">Komplain Poduct</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div
            class="modal-body body-lanjut-complain"
            style="max-height: calc(100vh - 210px); overflow-y: auto"
          >
            <div v-if="isLoading" class="text-center">
              <img
                src="https://jsonx.jaja.id/asset/home/logo/Loading_2.gif"
                alt=""
              />
            </div>
            <div v-else class="row text-left">
              <div class="col-md-5">
                <div class="mb-2 mt-2">
                  <h5 class="font-weight-bold">
                    Pilih Salah Satu Komplain atau Lainnya
                  </h5>
                  <ul class="list-group">
                    <li class="list-group-item">
                      <div class="form-check">
                        <label
                          class="form-check-label text-primary-jaja font-weight-bold"
                        >
                          <input
                            @change="pilihInputComplain('tidakSesuai')"
                            type="radio"
                            class="form-check-input"
                            name="optradio"
                          />Barang Tidak Sesuai
                        </label>
                      </div>
                      <div id="tidakSesuai" class="mt-3 ml-3 d-none">
                        <div>
                          <div class="md-form">
                            <label for="form10" class="font-weight-bold"
                              >1. Pilih Judul Komplain</label
                            >
                          </div>
                          <ul
                            class="list-group list-complain-pilih ml-3"
                            style="
                              height: 150px;
                              overflow: auto;
                              border: 1px solid rgba(0, 0, 0, 0.125);
                            "
                          >
                            <li
                              class="list-group-item pl-4"
                              v-for="(item, index) in listJudulComplain"
                              :key="index"
                            >
                              <input
                                @change="getJudulComplain(item)"
                                id="ceklis_judul"
                                class="form-check-input me-1"
                                type="radio"
                                name="pilih_judul"
                                aria-label="..."
                                :value="item"
                              />
                              {{ item }}
                            </li>
                          </ul>
                        </div>
                        <div class="mt-3">
                          <div class="md-form">
                            <label for="form10" class="font-weight-bold"
                              >2. Masukan Alasan Komplain</label
                            >
                            <textarea
                              id="form10"
                              class="md-textarea form-control ml-3"
                              rows="3"
                              cols="50"
                              placeholder="Alasan Komplain"
                              v-model="arrReqComplain.komplain"
                              style="width: 95%"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="form-check">
                        <label
                          class="form-check-label text-primary-jaja font-weight-bold"
                        >
                          <input
                            @change="pilihInputComplain('lainnya')"
                            type="radio"
                            class="form-check-input"
                            name="optradio"
                          />Lainnya
                        </label>
                      </div>
                      <div id="lainnya" class="mt-3 d-none ml-3">
                        <div class="md-form">
                          <label for="form10" class="font-weight-bold"
                            >1. Masukan Judul Komplain</label
                          >
                          <input
                            type="text"
                            class="form-control ml-3"
                            placeholder="Judul Komplain"
                            aria-label="Username"
                            aria-describedby="material-addon1"
                            v-model="arrReqComplain.judul_komplain"
                            style="width: 95%"
                          />
                        </div>
                        <div class="md-form mt-3">
                          <label for="form10" class="font-weight-bold"
                            >2. Masukan Alasan Komplain</label
                          >
                          <textarea
                            id="form10"
                            class="md-textarea form-control ml-3"
                            rows="3"
                            placeholder="Alasan Komplain"
                            v-model="arrReqComplain.komplain"
                            style="width: 95%"
                          ></textarea>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-7">
                <div class="mb-2 mt-2">
                  <h5 class="font-weight-bold">Lengkapi Bukti Complain :</h5>
                  <div class="mt-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Unggah Foto</label>
                      <b-form-group>
                        <b-form-file
                          accept="image/*"
                          @change="onFileImageChangeComplain"
                          id="file-image-compain"
                          style="margin-bottom: 0rem !important"
                        >
                        </b-form-file>
                        <div id="preview">
                          <img
                            class="mt-3"
                            v-if="urlImageComplain"
                            :src="urlImageComplain"
                            alt=""
                          />
                        </div>
                      </b-form-group>
                      <!-- <input id="fotoComplain" @change="uploadFotoComplain(event)" type="file" class="form-control" aria-describedby="emailHelp">
                                        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                    </div>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Unggah Video</label>
                      <b-form-group>
                        <b-form-file
                          accept="video/*"
                          @change="onFileVideoChangeComplain"
                          id="file-video-compain"
                          style="margin-bottom: 0rem !important"
                        >
                        </b-form-file>
                        <div id="preview">
                          <video
                            class="mt-3"
                            :src="urlVideoComplain"
                            width="320"
                            height="240"
                            controls
                            v-show="urlVideoComplain"
                          ></video>
                        </div>
                      </b-form-group>
                      <!-- <input type="file" id="videoComplain" @change="uploadVideoComplain(event)"  class="form-control" aria-describedby="emailHelp">
                                        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              :disabled="isLoading"
              type="button"
              class="btn btn-secondary font-weight-bold"
              data-dismiss="modal"
              style="background: #fdb738; border-color: #fdb738"
            >
              Close
            </button>
            <button
              :disabled="isLoading"
              type="button"
              @click="runningComplain()"
              class="btn btn-primary font-weight-bold"
              style="background: #64b0c9; border-color: #64b0c9"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
<!-- <script type="text/javascript" src="https://app.midtrans.com/snap/snap.js" data-client-key="Mid-client-S9f6KNmuVrQre0GQ"></script>
<script src="https://app.sandbox.midtrans.com/snap/snap.js" data-client-key="Mid-client-S9f6KNmuVrQre0GQ"></script> -->
<script>
import { mapGetters } from "vuex";
import sideBar from "../../components/sidebar/sideBar";
import firebase from "firebase/app";

import Swal from "sweetalert2";

export default {
  data() {
    return {
      timer: null,
      timeRemaining: 0,
      loading: false,
      showDetail: false,
      orderData: null,
      isFinish: false,
      selected: [], // Must be an array reference!
      options: [
        { text: "Barang Sesuai Pesanan", value: "Barang Sesuai Pesanan" },
        { text: "Respon Penjual Cepat", value: "Respon Penjual Cepat" },
        { text: "Pengiriman Cepat", value: "Pengiriman Cepat" },
      ],
      review: "",
      urlImage: null,
      urlVideo: null,
      statusOrder: {},
      statusInfo: {},
      goToMidtransPage: {},
      dataTokenMidtrans: {},
      dataOrder: {},
      jjdOrInv: {},
      dataAddress: {},
      dataItemProduk: {},
      dataCommon: {},
      dataCommonPerIndex: {},
      totalOrder: 0,
      paramSubmitPayment: {
        payment_type: "",
        payment_type_label: "",
        payment_sub: "",
        payment_sub_label: "",
        payment_fee: "",
        payment_form: "",
      },
      dataParamsMidtrans: {},
      dataUser: {},
      dataTracking: [],
      dataParamsGetSnapToken: {
        transaction_details: {
          gross_amount: "",
          order_id: "",
        },
        customer_details: {
          email: "",
          first_name: "",
          last_name: "",
          phone: null,
        },
        enabled_payments: [],
        credit_card: "",
      },
      dataToken: {},
      paramsSnapTokenUpdate: {
        total_pembayaran: "",
        fee: "",
        id_invoice: "",
        dataPayment: {
          payment_type: "",
          payment_type_label: "",
          payment_sub: "",
          payment_sub_label: "",
          payment_fee: "",
          payment_form: "",
        },
        token: "",
        order_code: "",
        order_id: "",
        va_or_code_or_link: "",
      },
      dataUpdateSnapToken: {},
      firstMethodOrNot: 0,
      rating: 5,
      isLoading: false,
      productId: [],
      arrReqComplain: {
        invoice: "",
        jenis_komplain: "",
        judul_komplain: "",
        komplain: "",
        video: "",
        images: [],
        productsComplain: [],
      },
      listJudulComplain: [
        "Barang rusak",
        "Barang tidak berfungsi",
        "Ukuran barang tidak sesuai",
        "Warna barang tidak sesuai",
        "Barang tidak sesuai deskripsi",
        "Barang tidak original",
        "Barang tidak lengkap",
        "Barang tidak dikirim",
      ],
      urlImageComplain: null,
      urlVideoComplain: null,
      dataProduct: [],
      itemProduct: {},
      resultAllPayment: {},
      isFinish: false,
      inv: "",
      dataTrackingBaru: [],
      // rates: [
      //     {
      //         "productId": "",
      //         "rate": "",
      //         "comment": "",
      //         "images": [],
      //         "video": ""
      //     }
      // ]
    };
  },
  components: {
    sideBar,
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
    statusClass() {
      switch (this.statusInfo) {
        case "Belum Dibayar":
          return "status-belum-dibayar";
        case "Menunggu Konfirmasi":
          return "status-menunggu-konfirmasi";
        case "Disiapkan":
          return "status-disiapkan";
        case "Dikirim":
          return "status-dikirim";
        case "Selesai":
          return "status-selesai";
        case "Dibatalkan":
          return "status-dibatalkan";
        case "Pengembalian":
          return "status-pengembalian";
        default:
          return "status-default";
      }
    },
    expiredAt() {
      return this.dataOrder.expiredAt;
    },
    hours() {
      return Math.floor(this.timeRemaining / 3600);
    },
    minutes() {
      return Math.floor((this.timeRemaining % 3600) / 60);
    },
    seconds() {
      return this.timeRemaining % 60;
    },
    isProductSelected() {
      return this.arrReqComplain.productsComplain.length > 0;
    },
  },
  beforeMount() {
    this.getDataOrder();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    let midtransScript = document.createElement("script");
    midtransScript.setAttribute("src", "https://app.midtrans.com/snap/snap.js");
    midtransScript.setAttribute(
      "data-client-key",
      "Mid-client-S9f6KNmuVrQre0GQ"
    );
    document.head.appendChild(midtransScript);
    window.scrollTo(0, 0);
    this.updateTimer();
    this.timer = setInterval(this.updateTimer, 1000);
  },
  methods: {
    formatRupiah(amount) {
      if (amount === null || amount === undefined) return "";
      return "Rp " + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    updateTimer() {
      const now = new Date().getTime();
      const expiry = new Date(this.expiredAt).getTime();
      const difference = expiry - now;

      this.timeRemaining = Math.max(Math.floor(difference / 1000), 0);

      if (this.timeRemaining === 0) {
        clearInterval(this.timer);
      }
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("Copied to clipboard");
        })
        .catch((err) => {
          console.error("Could not copy text: ", err);
        });
    },
    formatCurrency(value) {
      if (!value) return "Rp 0";
      value = parseFloat(value);
      return "Rp" + value.toLocaleString("id-ID");
    },
    toggleDetail() {
      if (!this.orderData) {
        // Jika data masih dimuat, set status loading menjadi true
        this.loading = true;
        // Lakukan logika untuk memuat data dari API
        // Setelah selesai memuat, set loading kembali ke false
        setTimeout(() => {
          // Simulasi logika memuat data dari API
          // Setelah data dimuat, set loading kembali ke false
          this.loading = false;
          // Set showDetail menjadi true untuk menampilkan detail
          this.showDetail = !this.showDetail;
        }, 2000); // Contoh timeout 2 detik, ganti dengan logika memuat data sesungguhnya
      } else {
        // Jika data sudah dimuat, toggle showDetail
        this.showDetail = !this.showDetail;
      }
    },
    getDataOrder() {
      this.inv = this.$route.params.id;
      const myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}order/${this.inv}`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          this.isFinish = true;
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.dataTracking = res.data;
            this.isFinish = true;
            this.getTracking(
              res.data.items[0].shippingSelected.code,
              this.dataTracking.trackingId
            );
            this.goToMidtransPage =
              res.data.orderPaymentRecent.payment_va_or_code_or_link;
            this.statusOrder = res.data.status;
            this.statusInfo = res.data.statusTitle;
            this.dataTokenMidtrans = res.data.orderPaymentRecent.token;
            this.dataAddress = res.data.address;
            this.dataOrder = res.data;
            this.dataVoucher = res.data.items[0].voucherStoreSelected;
            console.log("ini data voucher ", this.dataVoucher);
            this.jjdOrInv = this.$route.params.id;
            this.dataItemProduk = res.data.items;
            this.dataProduct = res.data.items[0].products.map(
              (products) => products
            );
            this.totalOrder = res.data.total;
            this.getDataCommon();
            this.cekNotPaid();
          }
        })
        .catch((error) => console.log("error", error));
    },
    getTracking(courier, waybill) {
      const url = `https://jaja.id/backend/order/orderTracking?courier=${courier}&waybill=${waybill}`;
      const myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          const waybillNull =
            result.data?.rajaongkir?.query?.waybill === "null";
          const status = result.data?.rajaongkir?.status?.code;
          const manifest = result.data?.rajaongkir?.result?.manifest;

          if (status === 400 && !waybillNull) {
            Swal.fire({
              icon: "warning",
              title: "Data Tidak Ditemukan",
              text: "Data tidak ditemukan. Mohon gunakan website resmi ekspedisi.",
              // text: result.data.rajaongkir.status.description,
            });
          } else if (!waybillNull && (!manifest || manifest.length === 0)) {
            Swal.fire({
              icon: "warning",
              title: "Data Tidak Ditemukan",
              text: "Data tidak ditemukan. Mohon gunakan website resmi ekspedisi.",
            });
          } else {
            this.orderData = result;
            console.log("ini tracking", this.orderData);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Fetch Error",
            text: "Error fetching order data: " + error.message,
          });
          console.error("Error fetching order data:", error);
        });
    },
    getDataCommon() {
      var myHeaders = new Headers();
      myHeaders.append("Cookie", "ci_session=7huq0r7m8qr8msgk411sjl5khur7d7hv");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `https://jsonx.jaja.id/front/api/common/methodPayment/${this.totalOrder}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          const newData = res.filter((item, index) => {
            if (item.payment_type !== "card") {
              return item;
            }
          });

          this.dataCommon = newData;
        })
        .catch((error) => console.log("error", error));
    },
    showPaymentMethod(cek) {
      if (cek === "first") {
        this.firstMethodOrNot = 1;
      } else {
        this.firstMethodOrNot = 0;
      }
      $("#myModalCommon").modal(
        { backdrop: "static", keyboard: false },
        "show"
      );
      this.dataCommonPerIndex = this.dataCommon[0].subPayment;
      this.paramSubmitPayment.payment_type = this.dataCommon[0].payment_type;
      this.paramSubmitPayment.payment_type_label =
        this.dataCommon[0].payment_type_label;
      this.paramSubmitPayment.payment_sub =
        this.dataCommonPerIndex[0].payment_sub;
      this.paramSubmitPayment.payment_sub_label =
        this.dataCommonPerIndex[0].payment_sub_label;
      this.paramSubmitPayment.payment_fee = this.dataCommonPerIndex[0].fee;
      this.paramSubmitPayment.payment_form =
        this.dataCommonPerIndex[0].payment_form;
    },
    setActiveLabel(index) {
      document.getElementById("radio0").checked = true;
      this.dataCommonPerIndex = this.dataCommon[index].subPayment;
      this.paramSubmitPayment.payment_type =
        this.dataCommon[index].payment_type;
      this.paramSubmitPayment.payment_type_label =
        this.dataCommon[index].payment_type_label;
      this.paramSubmitPayment.payment_sub =
        this.dataCommonPerIndex[0].payment_sub;
      this.paramSubmitPayment.payment_sub_label =
        this.dataCommonPerIndex[0].payment_sub_label;
      this.paramSubmitPayment.payment_fee = this.dataCommonPerIndex[0].fee;
      this.paramSubmitPayment.payment_form =
        this.dataCommonPerIndex[0].payment_form;
    },
    setParam(index) {
      this.paramSubmitPayment.payment_sub =
        this.dataCommonPerIndex[index].payment_sub;
      this.paramSubmitPayment.payment_sub_label =
        this.dataCommonPerIndex[index].payment_sub_label;
      this.paramSubmitPayment.payment_fee = this.dataCommonPerIndex[index].fee;
      this.paramSubmitPayment.payment_form =
        this.dataCommonPerIndex[index].payment_form;
    },
    getParamsMidtrans() {
      this.isLoading = true;
      var myHeaders = new Headers();
      myHeaders.append("Cookie", "ci_session=1s6f2fevft22dk5gln0bh2hf8h5qa21v");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      // const url = `https://jaja.id/backend/payment/getPayment/${this.$route.params.id}`;
      const url = ` ${this.domainURL}payment/getPayment/${this.$route.params.id}`;

      fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          this.dataParamsMidtrans = res;
          this.dataUser = this.user;
          this.setParamsSnap();
        })
        .catch((error) => console.log("error", error));
    },
    setParamsSnap() {
      this.dataParamsGetSnapToken.transaction_details.gross_amount =
        this.dataParamsMidtrans.orderPaymentRecent.grand_total;
      this.dataParamsGetSnapToken.transaction_details.order_id =
        this.dataParamsMidtrans.orderPaymentRecent.payment_id;
      this.dataParamsGetSnapToken.customer_details.email = this.dataUser.email;
      this.dataParamsGetSnapToken.customer_details.first_name =
        this.dataUser.name;
      this.dataParamsGetSnapToken.customer_details.last_name = "";
      // this.dataParamsGetSnapToken.customer_details.phone = this.dataUser.phoneNumber
      this.dataParamsGetSnapToken.customer_details.phone = null;
      this.dataParamsGetSnapToken.enabled_payments.push(
        this.paramSubmitPayment.payment_sub
      );

      this.getSnapToken();
    },
    getSnapToken() {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=69bd52hul0vqar30rmn5p65naskaco6h");

      let raw = JSON.stringify(this.dataParamsGetSnapToken);

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const urlgetSnapToken = `${this.domainURL}payment/getSnapToken/${this.$route.params.id}?isFirstMethod=${this.firstMethodOrNot}`;

      fetch(urlgetSnapToken, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.error_messages?.length) {
            this.$swal.fire({
              icon: "warning",
              title: "Warning",
              text: `Sudah tidak dapat mengganti metode pembayaran`,
              showConfirmButton: true,
            });
          } else {
            this.dataToken = res;
            this.updateSnapToken();
          }
        })
        .catch((error) => console.log("error", error));
    },
    updateSnapToken() {
      this.paramsSnapTokenUpdate.dataPayment.payment_type =
        this.paramSubmitPayment.payment_type;
      this.paramsSnapTokenUpdate.dataPayment.payment_type_label =
        this.paramSubmitPayment.payment_type_label;
      this.paramsSnapTokenUpdate.dataPayment.payment_sub =
        this.paramSubmitPayment.payment_sub;
      this.paramsSnapTokenUpdate.dataPayment.payment_sub_label =
        this.paramSubmitPayment.payment_sub_label;
      this.paramsSnapTokenUpdate.dataPayment.payment_fee =
        this.paramSubmitPayment.payment_fee;
      this.paramsSnapTokenUpdate.dataPayment.payment_form =
        this.paramSubmitPayment.payment_form;
      this.paramsSnapTokenUpdate.total_pembayaran =
        this.dataParamsMidtrans.orderPaymentRecent.grand_total;
      this.paramsSnapTokenUpdate.fee =
        this.dataParamsMidtrans.orderPaymentRecent.fee;
      this.paramsSnapTokenUpdate.id_invoice = "";
      this.paramsSnapTokenUpdate.token = this.dataToken.token;
      this.paramsSnapTokenUpdate.order_code = "";
      this.paramsSnapTokenUpdate.order_id =
        this.dataParamsMidtrans.orderPaymentRecent.order_id;
      this.paramsSnapTokenUpdate.va_or_code_or_link =
        this.dataToken.redirect_url;

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=69bd52hul0vqar30rmn5p65naskaco6h");

      let raw = JSON.stringify(this.paramsSnapTokenUpdate);

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}payment/snap_token_update`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          this.dataUpdateSnapToken = res;
          this.getDataOrder();
          setTimeout(() => {
            this.isLoading = false;
            $("#myModalCommon").modal("hide");
            snap.pay(res.token);
          }, 3000);
        })
        .catch((error) => console.log("error", error));
    },
    cekNotPaid() {
      if (this.statusOrder == "notPaid") {
        this.inv = this.$route.params.id;
      } else {
        this.inv = this.dataOrder.items[0].invoice;
        this.$router.push({ path: `/order/${this.inv}` });
      }
    },
    buyWithMidtrans() {
      //   window.open(this.goToMidtransPage);
      var myHeaders = new Headers();
      myHeaders.append("Cookie", "ci_session=nvha2jep6gogidt9rmcle1par0j8ul4f");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };
      // var url =
      //   "https://jaja.id/backend/payment/getPayment/" + this.$route.params.id;
      var url =
        "https://api.jaja.id/payment/getPayment/" + this.$route.params.id;
      // console.log('getPaymentUrl', url);
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          snap.pay(result.orderPaymentRecent.token).then(() => {
            this.cekNotPaid();
          });
          // console.log('payment_va_or_code_or_link', result.orderPaymentRecent.payment_va_or_code_or_link);
          // if (String(result.orderPaymentRecent.payment_va_or_code_or_link).includes('qr-code')) {
          //     setqrcode(result.orderPaymentRecent.payment_va_or_code_or_link)
          // }
        })
        .catch((error) => console.log("error", error));
    },
    //   setPayment(orderId){
    //     const gthis = this
    //     const tokenMidtrans = this.orderData.tokenMidtrans
    //     const invoice_no = this.orderData.invoiceNumber.split('-')[1]
    //     snap.pay(tokenMidtrans, {
    //         onSuccess: function(result){
    //             console.log('success');console.log('success',result);
    //             gthis.$axios.put('https://elibx.jaja.id/checkout/set-proccess-payment', {
    //                 invoice:String(invoice_no)
    //             }).then(() => {
    //                 gthis.$axios.post('checkout/set-payment', {
    //                     "orderId": orderId,
    //                     }).then((res)=>{
    //                     });
    //             })
    //         },
    //         onPending: function(result){console.log('pending');console.log(result);},
    //         onError: function(result){console.log('error');console.log(result);},
    //         onClose: function(){console.log('customer closed the popup without finishing the payment');}
    //     });
    // },

    gantiPembayaran() {
      var paramPayMD = {
        order_id: this.dataOrder.orderPaymentRecent.order_id,
        id_token: this.dataOrder.orderPaymentRecent.id_token,
      };

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=7glbt8ufb9pgaiv13v6fm7bbbr33k91d");

      var raw = JSON.stringify(paramPayMD);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}payment/new_invoice`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setTimeout(() => {
            this.getParamsMidtrans();
          }, 50);
        })
        .catch((error) => console.log("error", error));
    },
    cancelOrderNotPaid() {
      this.$swal
        .fire({
          icon: "info",
          title: "Apakah anda ingin membatalkan pesanan ini?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Ya",
          denyButtonText: `Tidak`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.runCancelOrderNotPaid();
          }
        });
    },
    cancelOrderWaitConfirm() {
      this.$swal
        .fire({
          icon: "info",
          title: "Apakah anda ingin membatalkan pesanan ini?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Ya",
          denyButtonText: `Tidak`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.runCancelOrderWaitConfirm();
          }
        });
    },
    runCancelOrderNotPaid() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}order/batalBelumbayar?order_id=${this.$route.params.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (
            result &&
            Object.keys(result).length &&
            result.status.code == 200
          ) {
            this.$swal.fire("Sukses", "Pesanan berhasil dibatalkan", "success");
            setTimeout(
              () =>
                // this.getDataOrder(),
                location.reload(),
              200
            );
          } else {
            this.$swal.fire("Gagal", "Pesanan gagal dibatalkan", "danger");
          }
        });
    },
    runCancelOrderWaitConfirm() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}/order/batalMenungguKonfirmasi?invoice=${this.$route.params.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (
            result &&
            Object.keys(result).length &&
            result.status.code == 200
          ) {
            this.$swal.fire("Sukses", "Pesanan berhasil dibatalkan", "success");
            setTimeout(
              () =>
                // this.getDataOrder(),
                location.reload(),
              200
            );
          } else {
            this.$swal.fire("Gagal", "Pesanan gagal dibatalkan", "warning");
          }
        });
    },
    terimaPesananConfirm() {
      this.$swal
        .fire({
          title: "Apakah anda ingin menerima pesanan ini?",
          icon: "info",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Ya",
          denyButtonText: `Tidak`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.runTerimaPesanan();
          }
        });
    },
    runTerimaPesanan() {
      this.isLoading = true;
      $("#myModalLoading").modal(
        { backdrop: "static", keyboard: false },
        "show"
      );
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Cookie", "ci_session=7vgloal55kn733tsqch0v7lh1tfrcilq");

      var formdata = new FormData();
      formdata.append("invoice", this.$route.params.id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${this.domainURL}order/pesananDiterima`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.isLoading = false;
            $("#myModalLoading").modal("hide");
            this.$swal
              .fire("Sukses", res.status.message, "success")
              .then(() => {
                this.getDataOrder();
              });
          } else {
            this.$swal
              .fire("Warning", res.status.message, "warning")
              .then(() => {
                this.getDataOrder();
              });
          }
        })
        .catch((error) => console.log("error", error));
    },
    beriRatingmModal(id) {
      const data = this.dataProduct;
      this.itemProduct = data[id];
      $("#myModalRating").modal(
        { backdrop: "static", keyboard: false },
        "show"
      );
    },

    selectCommentCustom() {
      this.review = this.selected;
    },
    onFileImageChange(e) {
      const file = e.target.files[0];
      this.createBase64Image(file);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.urlImage = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    onFileVideoChange(e) {
      const file = e.target.files[0];
      this.createBase64Video(file);
    },
    createBase64Video(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.urlVideo = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    runBeriRating() {
      this.isLoading = true;

      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        invoice: this.$route.params.id,
        rates: [
          {
            productId: this.dataOrder.items[0].products[0].productId,
            rate: this.rating,
            comment: this.review + " ",
            images: [this.urlImage],
            video: this.urlVideo,
          },
        ],
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}order/rate`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.isLoading = false;
            $("#myModalRating").modal("hide");
            this.$swal
              .fire("Sukses", res.status.message, "success")
              .then(() => {
                location.reload();
              });
          } else {
            this.$swal
              .fire("Warning", res.status.message, "warning")
              .then(() => {
                this.getDataOrder();
              });
          }
        })
        .catch((error) => console.log("error", error));
    },

    showSnapPay() {
      alert("ok");
      // this.getDataOrder()
      snap.pay(this.dataUpdateSnapToken.token, {
        onSuccess: function (result) {
          // console.log("success");
          // console.log(result);
        },
        onPending: function (result) {
          // console.log("pending");
          // console.log(result);
        },
        onError: function (result) {
          // console.log("error");
          // console.log(result);
        },
        onClose: function () {},
      });
    },
    komplainPesananModal() {
      $("#myModalComplain").modal(
        { backdrop: "static", keyboard: false },
        "show"
      );
    },
    pilihProductComplain(e, b) {
      const productId = document.getElementById("pilihProduct" + b).value;
      if (document.getElementById("pilihProduct" + b).checked) {
        this.arrReqComplain.productsComplain.push(productId);
      } else {
        const index = this.arrReqComplain.productsComplain.indexOf(productId);
        if (index > -1) {
          this.arrReqComplain.productsComplain.splice(index, 1);
        }
      }
    },
    komplainPesananLanjutanModal() {
      if (this.isProductSelected) {
        $("#myModalComplain").modal("hide");
        setTimeout(() => {
          $("#myModalLanjutComplain").modal({
            backdrop: "static",
            keyboard: false,
          });
        }, 100);
      }
    },
    pilihInputComplain(slug) {
      if (slug == "lainnya") {
        $(`#lainnya`).removeClass("d-none");
        $(`#tidakSesuai`).addClass("d-none");
        this.arrReqComplain.jenis_komplain = "lainnya";
      } else {
        $(`#tidakSesuai`).removeClass("d-none");
        $(`#lainnya`).addClass("d-none");
        this.arrReqComplain.jenis_komplain = "barang";
      }
    },
    lanjutComplain() {
      const arr = [
        "Barang rusak",
        "Barang tidak berfungsi",
        "Ukuran barang tidak sesuai",
        "Warna barang tidak sesuai",
        "Barang tidak sesuai deskripsi",
        "Barang tidak original",
        "Barang tidak lengkap",
        "Barang tidak dikirim",
      ];

      // arr.forEach(function(a,b) {
      // 	console.log(a,b)
      // })
      const html = arr.map((item) => {
        return `<li @click="ambilTextLi('${item.replaceAll(
          " ",
          "_"
        )}')" class="list-group-item">
                        <input class="ceklis_judul" id="${item.replaceAll(
                          " ",
                          "_"
                        )}" class="form-check-input me-1" type="radio" name="pilih_judul" aria-label="...">
                        ${item}
                    </li>`;
      });
      $(".list-complain-pilih").html(html.toString().replaceAll(",", ""));

      this.arrReqComplain.productsComplain.length
        ? komplainPesananLanjutanModal()
        : this.$swal.fire(
            "",
            "Silahkan pilih produk yang akan dikomplain",
            "warning"
          );
    },
    ambilTextLi(slug) {
      this.arrReqComplain.judul_komplain = slug.replaceAll("_", " ");
      document.getElementById(slug).checked = true;
    },
    getJudulComplain(item) {
      this.arrReqComplain.judul_komplain = item;
      // console.log(this.arrReqComplain.judul_komplain);
    },
    uploadFotoComplain(event) {
      var reader = new FileReader();
      this.arrReqComplain.imagesShow = event.target.files[0];
      reader.onload = function () {
        var output = document.getElementById("fotoComplain");
        output.src = reader.result;
        this.arrReqComplain.images.push(reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      // console.log('arrReqComplain', arrReqComplain)
    },
    uploadVideoComplain(event) {
      var reader = new FileReader();
      reader.onload = function () {
        var output = document.getElementById("videoComplain");
        output.src = reader.result;
        this.arrReqComplain.video = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    cekValuComplain() {
      const arrValParams = Object.values(this.arrReqComplain);
      const arrKeyParams = Object.keys(this.arrReqComplain);
      arrKeyParams.map((item, index) => {
        if (typeof arrValParams[index] !== "object") {
          if (item !== "video") {
            arrValParams[index]
              ? ``
              : this.$swal.fire(
                  ``,
                  `Mohon isi ${item.replaceAll("_", "")}`,
                  "warning"
                );
            return;
          }
        }
      });
      cekValueObject();
    },
    cekValueObject() {
      const arrValParams = Object.values(this.arrReqComplain);
      const arrKeyParams = Object.keys(this.arrReqComplain);
      const cekIsiKosong = [];
      // console.log(arrKeyParams);
      arrKeyParams.map((item, index) => {
        if (typeof arrValParams[index] !== "string") {
          if (item == "images") {
            if (arrValParams[index].length < 1) {
              this.$swal.fire("", `Mohon upload ${item}`, "warning");
              return;
            } else {
              runningComplain();
            }
          }
        }
      });
    },
    onFileImageChangeComplain(e) {
      const file = e.target.files[0];
      this.createBase64ImageComplain(file);
    },
    createBase64ImageComplain(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.urlImageComplain = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    onFileVideoChangeComplain(e) {
      const file = e.target.files[0];
      this.createBase64VideoComplain(file);
    },
    createBase64VideoComplain(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.urlVideoComplain = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    runningComplain() {
      this.isLoading = true;

      this.arrReqComplain.invoice = this.$route.params.id;
      // this.arrReqComplain.jenis_komplain = 'barang'
      // this.arrReqComplain.judul_komplain = document.getElementById("ceklis_judul").value
      this.arrReqComplain.video = this.urlVideoComplain;
      this.arrReqComplain.images = [this.urlImageComplain];

      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        complain: [this.arrReqComplain],
      });

      // console.log('raw', raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      // console.log(raw);

      fetch(`${this.domainURL}order/complain`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status.code === 200) {
            this.isLoading = false;

            $("#myModalComplain").modal("hide");
            $("#myModalLanjutComplain").modal("hide");
            this.$swal.fire("Sukses", result.status.message, "success");
            this.getDataOrder();
          } else {
            this.isLoading = false;
            this.$swal.fire("", result.status.message, "danger");
          }
        })
        .catch((error) => {});
    },

    chatStore(index) {
      const dataProfile = this.user;
      const dataDetailStore = this.dataItemProduk[0].store;
      const invoice = this.dataItemProduk[0].invoice;
      const dataDetailProduct = this.dataItemProduk[0].products[0];
      $("#chat-toko").attr("disabled", "true");
      $("#chat-toko").html('<i class="fas fa-circle-notch fa-spin"></i>');

      let getFirebaseTimestamp = firebase.database.ServerValue.TIMESTAMP;
      const database = firebase.database();
      let chatRoom = `${dataProfile.uid}${dataDetailStore.uid}`;
      database
        .ref(`friend/${dataDetailStore.uid}/${dataProfile.uid}`)
        .set({
          chat: chatRoom,
          name: dataProfile.name,
          message: {
            text: "",
            time: getFirebaseTimestamp,
          },
          amount: 0,
        })
        .then(() => {
          database
            .ref(`friend/${dataProfile.uid}/${dataDetailStore.uid}`)
            .set({
              chat: chatRoom,
              name: dataDetailStore.name,
              message: {
                text: "",
                time: getFirebaseTimestamp,
              },
              amount: 0,
            })
            .then(() => {
              const paramObjChat = {
                name: dataDetailStore.name,
                chat: chatRoom,
                id: dataDetailStore.uid,
                order: {
                  imageOrder: dataDetailProduct.image,
                  invoice: invoice,
                  status: this.statusInfo,
                },
              };
              localStorage.setItem("paramToChat", JSON.stringify(paramObjChat));
              this.$router.push("/chat/");
            });
        });

      // console.log('ini', getFirebaseTimestamp)
      // console.log('index', index)
    },
  },
};
</script>


<style scoped>
.product-text {
  color: var(--Neutral-60, #1a1a1a);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
}
.judul-des {
  color: var(--Primary-40, #65b0c9);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
}
.des-hrga {
  color: var(--Neutral-60, #1a1a1a);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.16px;
}
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.iconPayment {
  width: 50%;
  margin-right: 5%;
}

.card {
  border: 0px !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #fff;
  background-color: #65b0c9;
  border-color: #dee2e6 #dee2e6 #fff;
  font-weight: bold;
  border-radius: 20px 20px 0 0;
  border: 2px solid #65b0c9;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #65b0c9;
  font-weight: bold;
  border-radius: 20px 20px 0 0;
  border: 2px solid #65b0c9;
}

.nav-link:hover {
  color: #fff;
  background-color: #65b0c9;
  border-color: #dee2e6 #dee2e6 #fff;
  font-weight: bold;
  border-radius: 20px 20px 0 0;
  border: 2px solid #65b0c9;
}
</style>
<style lang="scss" scoped>
.close {
  font-size: 2rem;
  color: #fff;
  text-shadow: 0 1px 0 #fff;
}

.modal-header {
  background: #64b0c9 !important;
  color: white;
  font-weight: bold;
  border-radius: 1rem 1rem 0 0;
}

.modal-content {
  border-radius: 1rem;
}
ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 98%;
  z-index: 9;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 9;
}
.tgl-pesan {
  color: var(--Neutral-50, #757575);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
  text-align: left;
}
.judul-sec-container {
  text-align: left;
}

.judul-sec {
  color: var(--Neutral-60, #1a1a1a);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.16px;
  display: inline-block;
}

.icon-voucher {
  width: 33.29px;
  height: 25px;
  color: black;
  vertical-align: middle;
  margin-right: 8px; /* Adjust as needed */
}

.btn-pembayaran {
  display: flex;
  padding: 12px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--Primary-40, #65b0c9);
  border: none;
  width: 100%;
}
.btn-cancel {
  border-radius: 4px;
  border: 1px solid var(--Alerts-Red-100, #e41010);
  display: flex;
  padding: 12px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--Alerts-Red-100, #e41010);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 163.636% */
  letter-spacing: 0.11px;
  width: 100%;
  background: white;
}
.text-btn-p {
  color: var(--Neutral-10, #fff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 163.636% */
  letter-spacing: 0.11px;
}
.rincian-harga {
  color: var(--Primary-40, #65b0c9);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.tharga {
  color: var(--Neutral-60, #1a1a1a);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 130% */
  letter-spacing: 0.2px;
}
.dharga {
  color: var(--Neutral-50, #757575);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
}
.text-harga-baru {
  color: var(--Neutral-60, #1a1a1a);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.16px;
}
.bg-voucher {
  background: linear-gradient(
    269deg,
    #0058db -8.55%,
    #52acff 48.45%,
    #6b1eb8 100%
  );
}
.voucher-claim {
  border: 1px solid #ffa500; /* Orange border to highlight the promotion */
  margin: 10px 0;
  padding: 15px;
  background-color: #fff8e1; /* Light yellow background */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  // transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 10px;
  font-size: 14px;
}

// .voucher-item:hover {
//   transform: scale(1.05); /* Slight zoom on hover */
//   box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
// }

.voucher-claim h4 {
  margin: 0;
  font-size: 1.5em;
  color: #d35400; /* Darker orange for the title */
}

.voucher-claim span {
  margin: 5px 0;
  /* font-size: 1.2em; */
}

.voucher-claim span:first-of-type {
  font-weight: 500;
  color: #e67e22; /* Another shade of orange */
}
.status-default {
  background: var(--Alerts-Red-100, #e41010);
}
.status-belum-dibayar {
  // background: #ffcc00; /* Example color */
  background: #ff6699; /* Example color */
}
.status-menunggu-konfirmasi {
  background: #ff9900; /* Example color */
}
.status-disiapkan {
  background: #66cc66; /* Example color */
}
.status-dikirim {
  background: #3399ff; /* Example color */
}
.status-selesai {
  background: #33cc33; /* Example color */
}
.status-dibatalkan {
  background: #cc0000; /* Example color */
}
.status-pengembalian {
  background: #ff6699; /* Example color */
}
.countdown {
  display: flex;
  // align-items: center;
  justify-content: center;
  background-color: #fdb739;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.timer {
  display: flex;
  gap: 10px;
  margin-left: 10px;
}

.time-box {
  background-color: white;
  color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  min-width: 40px;
  text-align: center;
  font-size: 14px;
}
</style>