<template>
  <div>
    <div class="row" v-if="Object.keys(data).length > 0">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="col-md-3 col-6 carousel-cell is-selected"
      >
        <router-link :to="'/home/DetailToko/' + item.slug">
          <div
            class="CheckShowCheck card-product p-3 mb-3 shadow-custom"
            style="background: white !important"
          >
            <div class="">
              <div></div>
              <vue-load-image>
                <img
                  slot="image"
                  :src="
                    String(item.image).includes('/https:')
                      ? item.image.slice(45, item.image.length)
                      : item.image
                  "
                  class="ImgSizeProduct img-product"
                  style="
                    max-height: 100%;
                    max-width: 100%;
                    width: auto;
                    border-radius: 10px;
                  "
                  alt="produk"
                />
                <img
                  slot="preloader"
                  src="https://jsonx.jaja.id/asset/home/logo/Loading_2.gif"
                  class="ImgSizeProduct img-product"
                  alt="loading"
                />
                <div slot="error">
                  <img
                    src="../../assets/dashboard/no-product.png"
                    class="ImgSizeProduct img-product"
                    style="
                      max-height: 100%;
                      max-width: 100%;
                      width: auto;
                      border-radius: 10px;
                    "
                    alt="logo"
                  />
                </div>
              </vue-load-image>

              <div class="text-dark">
                <div class="keterangan ResetKet_JajaID">
                  <div class="text-toko one-line fsxx pt-1">
                    {{
                      item.name.length > 32
                        ? item.name.slice(0, 32).concat("...")
                        : item.name
                    }}
                  </div>

                  <div class="text-left one-line fsxx">
                    <small class="">{{ item.location }}</small>
                  </div>
                  <div class="d-flex justify-content-start mb-2">
                    <div class="text-left font-weight-bold">
                      <img
                        slot="image"
                        :src="
                          String(item.ranking).includes('/https:')
                            ? item.ranking.slice(45, item.image.length)
                            : item.ranking
                        "
                        class="ImgSizeProduct img-product"
                        style="height: 40%; width: auto; margin: 5px"
                        alt="produk"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="row" v-else-if="isFinish">
      <div class="col-md-12 col-12 carousel-cell is-selected">
        <div
          class="CheckShowCheck card-product p-3 mb-3 shadow-custom"
          style="background: white !important"
        >
          <div class="">
            <div></div>
            <img
              slot="image"
              src="https://nimda.jaja.id/asset/icon-jaja/notfound.png"
              class="img-fluid"
              style="
                max-height: 400px;
                max-width: 100%;
                width: auto;
                border-radius: 10px;
              "
              alt="not found"
            />

            <div class="text-dark">
              <div class="keterangan ResetKet_JajaID">
                <h4
                  class="text-toko one-line fsxx text-center"
                  style="margin-top: -20px"
                >
                  Toko belum tersedia.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div v-for="index in 12" :key="index" class="" style="padding: 10px">
        <content-placeholders :rounded="true">
          <content-placeholders-img style="width: 200px; height: 200px" />
          <content-placeholders-heading />
        </content-placeholders>
      </div>
    </div>
  </div>
</template>

<script>
import VueLoadImage from "vue-load-image";
export default {
  components: {
    "vue-load-image": VueLoadImage,
  },
  props: {
    data: {
      // type: Array,
      required: true,
    },
    isFinish: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      srcLoad: "http://via.placeholder.com/350x150",
      isLoaded: false,
    };
  },
  watch: {
    isLoaded: function (newValue, oldValue) {
      // console.log("New value is: " + newValue);
      // console.log("Old value is: " + oldValue);
    },
  },
  methods: {
    onImgLoad() {
      this.isLoaded = true;
    },
  },
};
</script>

<style scope>
.body-home {
  /* jaja */
  /* background: #eff6f8 */
  /* ebh */
  background: #f2f2f2;
}
.header-filter {
  border-radius: 15px 15px 0 0;
}
.kotak-filter {
  color: #65b0c9;
  font-size: 25px;
}
.kotak-tombol-reset a {
  background: rgb(224, 116, 116);
  color: white;
}
.ul-list-filter {
  list-style-type: none;
}
.kotak-kondisi {
  position: relative;
  top: -12px;
}
.kotak-lokasi {
  position: relative;
  top: -12px;
}
.kotak-kategori {
  position: relative;
  top: -12px;
}
.kotak-etalase {
  position: relative;
  top: -12px;
}
.kotak-stock {
  position: relative;
  top: -8.5px;
}
.short-product {
  padding: 15px 0 15px 0;
  position: relative;
  top: -28px;
  border-radius: 0 0 15px 15px;
}
.short-product div {
  margin-left: 17.5%;
}
.text-label {
  color: rgb(117, 116, 116);
}
.label-order {
  font-size: 16px;
}
.label-order-ready {
  font-size: 14px;
}
.bg-filter {
  background: #ffd68d;
}
.card-product {
  border-radius: 20px;
  background: white !important;
}
.ImgSizeProduct {
  height: 150px;
}
.ImgSizeProduct img {
  border-radius: 20px 20px 0 0;
  width: 165px;
  height: 150px;
  object-fit: cover;
}
.text-toko {
  color: #244786;
  font-weight: bold;
  text-align: left;
  max-height: 25px;
  height: 100%;
}
.text-price {
  color: #fdb739;
  font-weight: bold;
  text-align: left;
  font-size: 20px;
}
.kotak-location {
  position: relative;
  display: flex;
  top: -10px;
}
.kotak-location small {
  position: absolute;
  left: 15px;
}
.kotak-location img {
  width: 14px;
  height: 14px;
  position: absolute;
}

a:hover {
  text-decoration: none;
}

.card-product .btn-wishlist {
  position: absolute;
  right: 15px;
  top: 0;
}
.bgdiskons {
  color: #fff;
  background-color: #65b0c9;
  padding: 3px 3px 8px 3px;
  font-size: 15px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  /* font-weight: bold; */
}

.card-product .btn-wishlist2 {
  position: absolute;
  right: 15px;
  top: 130px;
}
.bgpreorder {
  color: #fff;
  background-color: #da8922bd;
  padding: 3px 3px 8px 3px;
  font-size: 12px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  /* font-weight: bold; */
}

.card-product .btn-wishlist3 {
  position: absolute;
  left: 15px;
  top: 130px;
}
.bgfreeongkir {
  color: #fff;
  background-color: #fb7181cc;
  padding: 3px 3px 8px 3px;
  font-size: 12px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  /* font-weight: bold; */
}
</style>