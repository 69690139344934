<template>
  <div class="">
    <div class="con-filter">
      <div class="d-flex justify-content-between">
        <div><span class="title-fil">Filter</span></div>
        <div><span class="title-fil"></span></div>
        <div>
          <button
            type="button"
            class="btn btn-sm font-weight-bold bg-danger text-white"
            @click="resetOptions"
          >
            Reset
          </button>
        </div>
      </div>
      <div  v-for="(item, index) in dataFilter" :key="index">
        <div class="mt-4" v-if="item.slug === 'location'">
          <label class="d-flex title-sub" for="">{{ item.name }}</label>
          <select
            class="browser-default custom-select categoryProduct"
            name="categoryProduct"
            @change="fungsiFilterLocation"
          >
            <option value="">- Pilih Lokasi -</option>
            <option
              v-for="(a, b) in dataFilterLokasi"
              :key="b"
              :value="a.value"
              class="categoryProduct"
            >
              {{ a.name }}
            </option>
          </select>
        </div>
      </div>
      <div style="margin-top: -30px">
        <label class="d-flex title-sub" for="">Kondisi</label>
        <div class="d-flex justify-content-between bd-highlight">
          <div class="bd-highlight">
            <div class="form-check">
              <input
                @change="fungsi('filterProduk:condition:baru')"
                class="form-check-input conditionProduct"
                type="radio"
                name="conditionProduct"
                id="condition-baru"
                value="baru"
              />
              <label
                class="form-check-label text-label text-size-jaja"
                for="condition-baru"
              >
                Baru
              </label>
            </div>
          </div>
          <div
            class="mr-5 bd-highlight"
            style="margin-right: 42px !important"
          >
            <div class="form-check">
              <input
                @change="fungsi('filterProduk:condition:bekas')"
                class="form-check-input conditionProduct"
                type="radio"
                name="conditionProduct"
                id="condition-bekas"
                value="bekas"
              />
              <label
                class="form-check-label text-label text-size-jaja"
                for="condition-bekas"
              >
                Bekas
              </label>
            </div>
          </div>
        </div>
      </div>
      <div>
        <label class="d-flex title-sub" for="">Stock</label>
        <div class="d-flex justify-content-between bd-highlight">
          <div class=" bd-highlight">
            <div class="form-check">
              <input
                @change="fungsi('filterProduk:preorder:Y')"
                class="form-check-input preorderProduct"
                type="radio"
                name="preorderProduct"
                id="preorder-Y"
                value="Y"
              />
              <label
                class="form-check-label text-label label-order"
                for="preorder-Y"
              >
                <label
                  class="form-check-label text-label text-size-jaja"
                  for="preorder-Y"
                >
                  Pre Order
                </label>
              </label>
            </div>
          </div>
          <div class=" bd-highlight">
            <div class="form-check">
              <input
                @change="fungsi('filterProduk:preorder:T')"
                class="form-check-input preorderProduct"
                type="radio"
                name="preorderProduct"
                id="preorder-T"
                value="T"
              />
              <label
                class="form-check-label text-label text-size-jaja"
                for="preorder-T"
              >
                Ready Stok
              </label>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <label class="d-flex title-sub" for="">Urutkan</label>
          <div
            v-for="(item, index) in dataSort"
            :key="index"
            class="form-check-block d-flex mb-1"
            style="margin-left: 7% !important"
          >
            <label class="form-check-label text-size-jaja">
              <input
                @change="fungsi('sortProduk:' + item.value)"
                type="radio"
                class="form-check-input sortProduct"
                :value="item.value"
                name="sortProduct"
              />{{ item.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="filter-product mb-4">
      <div
        class="d-flex justify-content-between bd-highlight bg-filter header-filter"
      >
        <div class="p-2 bd-highlight ml-3 mt-2 font-weight-bold kotak-filter">
          Filter
        </div>
        <div class="p-2 bd-highlight kotak-tombol-reset">
          <button
            type="button"
            class="btn btn-sm mr-3 mt-2 font-weight-bold bg-danger text-white"
            @click="resetOptions"
          >
            Reset
          </button>
        </div>
      </div>

      {{dataEtalase}}

      <div class="" v-for="(item, index) in dataFilter" :key="index">
        <div v-if="item.slug === 'location'">
          <div class="kotak-kategori bg-filter p-2 mt-3 mb-1">
            <div class="bd-highlight">
              <div class="form-check mr-3">
                <label class="d-flex font-weight-bold text-label" for="">{{
                  item.name
                }}</label>
                <select
                  class="browser-default custom-select categoryProduct"
                  name="categoryProduct"
                  @change="fungsiFilterLocation"
                >
                  <option value="">- Pilih Lokasi -</option>
                  <option
                    v-for="(a, b) in dataFilterLokasi"
                    :key="b"
                    :value="a.value"
                    class="categoryProduct"
                  >
                    {{ a.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.slug === 'category'">
          <div class="kotak-lokasi bg-filter p-2 mt-3">
            <div class="bd-highlight">
              <div class="form-check mr-3">
                <label class="d-flex font-weight-bold text-label" for="">{{
                  item.name
                }}</label>
                <select
                  class="browser-default custom-select locationProduct"
                  name="locationProduct"
                  @change="fungsiFilterCategory"
                >
                  <option value="">- Pilih Kategori -</option>
                  <option
                    v-for="(a, b) in dataFilterCategory"
                    :key="b"
                    :value="a.value"
                    class="locationProduct"
                  >
                    {{ a.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="kotak-etalase bg-filter p-2 mt-1"
        v-if="dataEtalaseCount > 0"
        style="margin-bottom: 3px"
      >
        <label class="d-flex ml-3 font-weight-bold text-label" for=""
          >Etalase</label
        >
        <div v-for="(item, index) in dataEtalase" :key="index">
          <div class="d-flex justify-content-between bd-highlight">
            <div class="pl-2 ml-2 bd-highlight">
              <div class="form-check">
                <input
                  @change="fungsi('filterEtalase:etalase:' + item.value)"
                  :id="'etalase' + index"
                  class="form-check-input etalaseProduct"
                  type="radio"
                  name="etalaseProduct"
                  :value="item.value"
                />
                <label
                  class="form-check-label text-label text-size-jaja"
                  :for="'etalase' + index"
                >
                  {{ item.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="kotak-kondisi bg-filter p-2 mt-1">
        <label class="d-flex ml-3 font-weight-bold text-label" for=""
          >Kondisi</label
        >
        <div class="d-flex justify-content-between bd-highlight">
          <div class="p-2 ml-2 bd-highlight">
            <div class="form-check">
              <input
                @change="fungsi('filterProduk:condition:baru')"
                class="form-check-input conditionProduct"
                type="radio"
                name="conditionProduct"
                id="condition-baru"
                value="baru"
              />
              <label
                class="form-check-label text-label text-size-jaja"
                for="condition-baru"
              >
                Baru
              </label>
            </div>
          </div>
          <div
            class="p-2 mr-5 bd-highlight"
            style="margin-right: 42px !important"
          >
            <div class="form-check">
              <input
                @change="fungsi('filterProduk:condition:bekas')"
                class="form-check-input conditionProduct"
                type="radio"
                name="conditionProduct"
                id="condition-bekas"
                value="bekas"
              />
              <label
                class="form-check-label text-label text-size-jaja"
                for="condition-bekas"
              >
                Bekas
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="kotak-stock bg-filter p-2">
        <label class="d-flex ml-3 font-weight-bold text-label" for=""
          >Stock</label
        >
        <div class="d-flex justify-content-between bd-highlight">
          <div class="p-2 ml-2 bd-highlight">
            <div class="form-check">
              <input
                @change="fungsi('filterProduk:preorder:Y')"
                class="form-check-input preorderProduct"
                type="radio"
                name="preorderProduct"
                id="preorder-Y"
                value="Y"
              />
              <label
                class="form-check-label text-label label-order"
                for="preorder-Y"
              >
                <label
                  class="form-check-label text-label text-size-jaja"
                  for="preorder-Y"
                >
                  Pre Order
                </label>
              </label>
            </div>
          </div>
          <div class="p-2 mr-2 bd-highlight">
            <div class="form-check">
              <input
                @change="fungsi('filterProduk:preorder:T')"
                class="form-check-input preorderProduct"
                type="radio"
                name="preorderProduct"
                id="preorder-T"
                value="T"
              />
              <label
                class="form-check-label text-label text-size-jaja"
                for="preorder-T"
              >
                Ready Stok
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="short-product bg-filter">
      <div
        v-for="(item, index) in dataSort"
        :key="index"
        class="form-check-block d-flex mb-1"
        style="margin-left: 15% !important"
      >
        <label class="form-check-label text-size-jaja">
          <input
            @change="fungsi('sortProduk:' + item.value)"
            type="radio"
            class="form-check-input sortProduct"
            :value="item.value"
            name="sortProduct"
          />{{ item.name }}
        </label>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      valueLocation: "",
    };
  },
  props: {
    dataFilter: {
      type: Array,
      required: true,
    },
    dataSort: {
      type: Array,
      required: true,
    },
    dataEtalase: {
      // type: Array,
      required: false,
    },
    dataEtalaseCount: {
      type: Number,
      required: false,
    },
    fungsi: {
      type: Function,
      required: false,
    },
    fungsiFilterLocation: {
      type: Function,
      required: false,
    },
    dataFilterLokasi: {
      type: Array,
      required: false,
    },
    fungsiFilterCategory: {
      type: Function,
      required: false,
    },
    dataFilterCategory: {
      type: Array,
      required: false,
    },
    resetOptions: {
      type: Function,
      required: false,
    },
  },
  method: {},
};
</script>
<style scoped>
.con-filter {
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;
  background: var(--Neutral-10, #fff);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.title-fil {
  color: var(--Neutral-60, #1a1a1a);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 130% */
  letter-spacing: 0.2px;
}
.title-sub {
  color: var(--Primary-40, #65b0c9);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
}
</style>