<template>
  <div>
    <div style="margin-top: -4.5%" class="container p-3" v-if="isFinish">
      <div
        class="d-flex justify-content-start bd-highlight mb-1 border custom-background-toko"
        style="border-radius: 15px 15px 0 0"
      >
        <div class="p-2 bd-highlight mr-4">
          <div class="d-flex flex-column">
            <div class="p-2 mr-auto">
              <img
                src="https://nimda.jaja.id/asset/icon-jaja/jaja-logo%20kotak.png"
                alt="avatar"
                class="rounded-circle square-100 imguseravatar bg-white"
                v-if="!dataStoreImage.profile"
              />
              <img
                :src="dataStoreImage.profile"
                alt="avatar"
                class="rounded-circle square-100 imguseravatar bg-white"
                v-if="dataStoreImage.profile"
              />
            </div>
            <div class="p-2 judul-toko">
              {{ dataStore.name }}
            </div>
            <div
              class="ket-singkat"
              style="color: #dc3545 !important"
              v-if="dataStore.closed_store"
            >
              Toko sedang libur
            </div>
          </div>
        </div>

        <div class="py-4 bd-highlight">
          <div
            class="d-flex ml-4 py-1 align-items-start flex-column text-white"
            style="height: 100%"
          >
            <div class="mb-auto p-2">
              <img
                src="../../assets/icon-s/paket.png"
                style="width: 20px"
                alt=""
              />
              &nbsp;&nbsp;
              <small class="ket-singkat"
                >Total Produk : {{ dataStore.totalProduct }}</small
              >
            </div>
            <div class="mb-auto p-2">
              <img
                src="../../assets/icon-s/star.png"
                style="width: 20px"
                alt=""
              />
              &nbsp;
              <small class="ket-singkat"
                >Penilaian : {{ dataStore.rating }}</small
              >
            </div>
            <div class="mb-auto p-2">
              <img
                src="../../assets/icon-s/kalender.png"
                style="width: 20px"
                alt=""
              />
              &nbsp;&nbsp;
              <small class="ket-singkat"
                >Bergabung Sejak : {{ dataStore.createdAt }}</small
              >
            </div>
            <div class="mb-auto p-2">
              <img
                src="../../assets/icon-s/loc.png"
                style="width: 20px"
                alt=""
              />
              &nbsp;&nbsp;
              <small class="ket-singkat"
                >Lokasi : {{ dataStoreLoc.city }}
              </small>
            </div>
            <div class="mb-auto p-2">
              <button
                type="button"
                class="btn-chat"
                @click="chatStore(index)"
                id="chat-toko"
              >
                <img
                  src="../../assets/icon-s/chat.png"
                  style="width: 20px"
                  alt=""
                />
                &nbsp; Hubungi Toko
              </button>
            </div>
          </div>
        </div>
      </div>

      <nav class="navbar navbar-expand-lg navbar-light mb-2 pl-0 pt-0">
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <li
              @click="halamanProduct('hal_utama')"
              id="hal_utama"
              class="hal_utama br-color-button-jaja mr-2 nav-item active bg-warning border border-warning font-weight-bold"
              style="
                border-top-left-radius: 0rem !important;
                border-top-right-radius: 0rem !important;
              "
            >
              <a
                id="a-hal_utama"
                class="btn nav-link text-white font-weight-bold"
                >Halaman Toko</a
              >
            </li>
            <li
              @click="halamanProduct('hal_all_produk')"
              id="hal_all_produk"
              class="hal_all_produk br-color-button-jaja text-white bg-white nav-item"
              style="
                border-top-left-radius: 0rem !important;
                border-top-right-radius: 0rem !important;
              "
            >
              <a
                id="a-hal_all_produk"
                class="btn nav-link text-warning font-weight-bold"
              >
                Produk Toko</a
              >
            </li>
          </ul>
        </div>
      </nav>

      <div
        :class="
          setHidden.hal_utama
            ? `container bg-white shadow-custom py-2 mb-4 br-card-jaja`
            : `d-none`
        "
        v-if="dataStore.voucher > 0"
      >
        <div class="mb-auto p-2 text-left">
          <h5 class="font-weight-bold text-primary-jaja">Voucher Toko</h5>
        </div>
        <flickity
          v-if="dataStore.voucher > 0"
          ref="flickity"
          class="flickity"
          :options="flickityOptions"
        >
          <div
            v-for="(item, index) in dataStore.voucher"
            :key="index"
            class="carousel-cell mb-4"
            style="padding-left: 10px; padding-top: 10px"
          >
            <div
              class="coupon rounded mb-3 d-flex justify-content-between mr-4 ml-4 bg-warning-jaja"
              style="box-shadow: 2px 2px 2px 2px #e9eaef"
            >
              <div class="kiri p-3">
                <div class="icon-container">
                  <div class="icon-container_box"></div>
                </div>
              </div>
              <div
                class="tengah py-3 d-flex w-100 justify-content-start text-white"
              >
                <div>
                  <span class="fsx">Diskon</span>
                  <span style="font-weight: bold" class="fsx">
                    {{ item.discount }}
                  </span>

                  <br />
                  <small class="mb-0"
                    ><small>*) S/d : {{ item.endDate }}</small></small
                  >
                </div>
              </div>
              <div
                class="kanan"
                :class="
                  item.isClaimed == false
                    ? 'bg-light-grey-jaja'
                    : ' bg-warning-jaja'
                "
              >
                <div class="info m-3 d-flex align-items-center">
                  <div class="w-100">
                    <div class="block">
                      <button
                        v-if="!item.isClaimed"
                        @click="useVoucherToko(item.id)"
                        class="tombol-gunakan-point btn btn-outline-primary btn-sm float-right bg-white"
                        style="margin-top: 12px"
                      >
                        <span class="info-gunakan-poin font-weight-bold">
                          Klaim
                        </span>
                        <i data-id="" data-checklist="none"></i><br />
                      </button>
                      <button
                        v-else
                        class="tombol-gunakan-point btn btn-outline-primary btn-sm float-right bg-white"
                        style="margin-top: 12px"
                      >
                        <span class="info-gunakan-poin font-weight-bold">
                          Pakai
                        </span>
                        <i data-id="" data-checklist="none"></i><br />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </flickity>
      </div>

      <div
        :class="
          setHidden.hal_utama
            ? `container bg-white shadow-custom py-2 mb-4 br-card-jaja`
            : `d-none`
        "
      >
        <div class="d-flex align-items-start flex-column" style="height: 100%">
          <div class="mb-auto p-2">
            <h5 class="font-weight-bold text-primary-jaja">Tentang Toko</h5>
          </div>
          <div class="mb-auto p-2">
            <p class="text-des">
              <i class="fas fa-calendar h5 mr-2"></i
              ><span
                >Jadwal Buka Toko :
                {{
                  dataStore.openSchedule ? dataStore.openSchedule.day : ``
                }}</span
              >
            </p>
          </div>
          <div class="p-2 text-des text-justify">
            {{ dataStore.description }}
          </div>
        </div>
        <div class="main-banner">
          <div :class="dataStoreImage.mainBanner ? 'mb-2 mt-2' : 'd-none'">
            <img
              :src="dataStoreImage.mainBanner"
              class="card-img-top rounded"
              alt="avatar"
            />
          </div>
          <div>
            <b-carousel
              id="carousel-1"
              v-model="slide"
              :interval="4000"
              controls
              indicators
              background="#ababab"
              style="text-shadow: 1px 1px 2px #333"
              @sliding-start="onSlideStart"
              @sliding-end="onSlideEnd"
            >
              <!-- Slide untuk mainBanner
              <b-carousel-slide v-if="dataStoreImage.mainBanner">
                <template #img>
                  <img
                    style="width: 100%; height: 410px"
                    :src="dataStoreImage.mainBanner"
                    alt="main banner"
                  />
                </template>
              </b-carousel-slide> -->

              <!-- Slides untuk promoBanner -->
              <b-carousel-slide
                v-for="(item, index) in dataStoreImage.promoBanner"
                :key="index"
              >
                <template #img>
                  <img
                    style="width: 100%; height: 410px"
                    :src="item"
                    alt="promo banner"
                  />
                </template>
              </b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </div>

      <div :class="setHidden.hal_utama ? `` : `d-none`">
        <!-- <div>
          <div class="mt-5 mb-4">
            <h3 class="d-flex mb-2">Produk Terbaru</h3>

            <cardSlider :data="dataHalUtama" />
          </div>
        </div> -->
        <div>
          <div class="semuahalaman mb-2 mt-2" style="">
            <div class="row">
              <div class="col-md-3">
                <!-- <cardFIlter :fungsi="fungsiFilter" :dataEtalase="dataEtalase" :dataFilter="dataFilter" :dataSort="dataSort"   /> -->
                <cardFIlter
                  :fungsiFilterCategory="fungsiFilterCategory"
                  :fungsi="fungsiFilter"
                  :dataEtalaseCount="dataEtalaseCount"
                  :dataEtalase="dataEtalase"
                  :dataFilter="dataFilter"
                  :dataSort="dataSort"
                  :dataFilterCategory="dataFilterCategory"
                  :resetOptions="resetOptions"
                />
              </div>
              <div class="col-md-9">
                <cardProduk :data="dataSemuaProduk" :isFinish="isFinish" />
                <br />
                <pagination
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                  :records="recordsLength"
                  v-model="page"
                  :per-page="perPage"
                  @paginate="getProdukByStore"
                >
                </pagination>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div :class="setHidden.hal_all_produk ? `` : `d-none`">
          <div class="semuahalaman mb-2 mt-2" style="">
            <div class="row">
              <div class="col-md-3">
                <!-- <cardFIlter :fungsi="fungsiFilter" :dataEtalase="dataEtalase" :dataFilter="dataFilter" :dataSort="dataSort"   /> -->
                <cardFIlter
                  :fungsiFilterCategory="fungsiFilterCategory"
                  :fungsi="fungsiFilter"
                  :dataEtalaseCount="dataEtalaseCount"
                  :dataEtalase="dataEtalase"
                  :dataFilter="dataFilter"
                  :dataSort="dataSort"
                  :dataFilterCategory="dataFilterCategory"
                  :resetOptions="resetOptions"
                />
              </div>
              <div class="col-md-9">
                <cardProduk :data="dataSemuaProduk" :isFinish="isFinish" />
                <br />
                <pagination
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                  :records="recordsLength"
                  v-model="page"
                  :per-page="perPage"
                  @paginate="getProdukByStore"
                >
                </pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container p-3" v-if="!isFinish">
      <div
        class="d-flex justify-content-start bd-highlight mb-1 border bg-info-gradient-jaja"
        style="border-radius: 15px 15px 0 0"
      >
        <div class="p-2 bd-highlight mr-4">
          <div class="d-flex flex-column">
            <div class="p-2 mr-auto">
              <content-placeholders :rounded="true" :centered="true">
                <content-placeholders-img
                  class="rounded-circle square-100 imguseravatar bg-white"
                  style="width: 100px; height: 100px"
                />
              </content-placeholders>
            </div>
            <div class="p-2 font-weight-bold text-white">
              <content-placeholders :rounded="true" :centered="true">
                <content-placeholders-text
                  :lines="1"
                  style="width: 100%; height: 20px"
                />
              </content-placeholders>
            </div>
            <div
              class="font-weight-bold text-white small"
              style="color: #dc3545 !important"
            >
              <content-placeholders :rounded="true" :centered="true">
                <content-placeholders-text
                  :lines="1"
                  style="width: 100%; height: 20px"
                />
              </content-placeholders>
            </div>
          </div>
        </div>
        <div class="py-4 bd-highlight">
          <div
            class="d-flex ml-4 py-1 align-items-start flex-column text-white"
            style="height: 100%"
          >
            <div class="mb-auto p-2">
              <content-placeholders :rounded="true">
                <content-placeholders-text
                  :lines="1"
                  style="width: 100px; height: 20px"
                />
              </content-placeholders>
            </div>
            <div class="mb-auto p-2">
              <content-placeholders :rounded="true">
                <content-placeholders-text
                  :lines="1"
                  style="width: 120px; height: 20px"
                />
              </content-placeholders>
            </div>
            <div class="mb-auto p-2">
              <content-placeholders :rounded="true">
                <content-placeholders-text
                  :lines="1"
                  style="width: 160px; height: 20px"
                />
              </content-placeholders>
            </div>
            <div class="mb-auto p-2">
              <content-placeholders :rounded="true">
                <content-placeholders-text
                  :lines="1"
                  style="width: 200px; height: 20px"
                />
              </content-placeholders>
            </div>
            <div class="mb-auto p-2">
              <content-placeholders :rounded="true">
                <content-placeholders-img style="width: 200px; height: 30px" />
              </content-placeholders>
            </div>
          </div>
        </div>
      </div>

      <nav class="navbar navbar-expand-lg navbar-light mb-2 pl-0 pt-0">
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <li
              @click="halamanProduct('hal_utama')"
              id="hal_utama"
              class="hal_utama br-color-button-jaja mr-2 nav-item active bg-info border border-info font-weight-bold"
              style="
                border-top-left-radius: 0rem !important;
                border-top-right-radius: 0rem !important;
              "
            >
              <a
                id="a-hal_utama"
                class="btn nav-link text-white font-weight-bold"
                >Halaman Utama</a
              >
            </li>
            <li
              @click="halamanProduct('hal_all_produk')"
              id="hal_all_produk"
              class="hal_all_produk border border-info br-color-button-jaja text-white bg-white nav-item"
              style="
                border-top-left-radius: 0rem !important;
                border-top-right-radius: 0rem !important;
              "
            >
              <a
                id="a-hal_all_produk"
                class="btn nav-link text-warning font-weight-bold"
                >Semua Produk</a
              >
            </li>
          </ul>
        </div>
      </nav>

      <div
        :class="
          setHidden.hal_utama
            ? `container bg-white shadow-custom py-2 mb-4 br-card-jaja`
            : `d-none`
        "
      >
        <div class="d-flex align-items-start flex-column" style="height: 100%">
          <div class="mb-auto p-2">
            <h5 class="font-weight-bold text-primary-jaja">
              <content-placeholders :rounded="true">
                <content-placeholders-text
                  :lines="1"
                  style="width: 200px; height: 20px"
                />
              </content-placeholders>
            </h5>
          </div>
          <div class="mb-auto p-2">
            <p>
              <content-placeholders :rounded="true">
                <content-placeholders-heading style="width: 80vw" />
              </content-placeholders>
            </p>
          </div>
        </div>
      </div>

      <div :class="setHidden.hal_utama ? `` : `d-none`">
        <div>
          <div class="mt-4 mb-4">
            <h3 class="d-flex mb-2">Produk Terbaru</h3>

            <cardPlaceholder />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
</script>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import Flickity from "vue-flickity";
import cardPlaceholder from "../../components/card/cardplaceholder";
import cardSlider from "../../components/card/cardslider";
import cardProduk from "../../components/card/cardproduct";
import cardFIlter from "../../components/filtercategory/filtercategory";
export default {
  components: {
    Flickity,
    cardSlider,
    cardProduk,
    cardFIlter,
    cardPlaceholder,
  },
  data() {
    return {
      dataStore: {},
      dataStoreImage: {},
      dataStoreLoc: {},
      dataHalUtama: {},
      dataSemuaProduk: {},
      dataEtalase: {},
      dataFilter: [],
      dataPage: {},
      dataSort: [],
      setHidden: {
        hal_utama: true,
        hal_all_produk: false,
      },
      reqData: {
        page: 1,
        limit: 20,
        keyword: "",
        filter_price: "",
        filter_category: "",
        filter_condition: "",
        filter_preorder: "",
        filter_brand: "",
        sort: "",
        filter_etalase: "",
      },
      flickityOptions: {
        initialIndex: 3,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false,
        freeScroll: false,
        // any options from Flickity can be used
      },
      page: 1,
      perPage: 12,
      recordsLength: 0,
      pageSizes: [12, 24, 32],
      isFinish: false,
      dataFilterCategory: [],
      dataEtalaseCount: 0,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ isLoggedIn: "isLoggedIn" }),
    ...mapGetters({ user: "user" }),
  },
  beforeMount() {
    this.getStoreDetail();
    this.getProdukByStore();
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
    resetOptions: function () {
      this.reqData.filter_price = "";
      this.reqData.filter_category = "";
      this.reqData.filter_condition = "";
      this.reqData.filter_preorder = "";
      this.reqData.filter_brand = "";
      this.reqData.sort = "";
      this.reqData.filter_etalase = "";

      var category_list = document.getElementsByClassName("categoryProduct");
      for (var i_cat = 0; i_cat < category_list.length; i_cat++) {
        category_list[i_cat].selected = false;
      }

      var etalase_list = document.getElementsByClassName("etalaseProduct");
      for (var i_eta = 0; i_eta < etalase_list.length; i_eta++) {
        etalase_list[i_eta].checked = false;
      }

      var condition_list = document.getElementsByClassName("conditionProduct");
      for (var i_con = 0; i_con < condition_list.length; i_con++) {
        condition_list[i_con].checked = false;
      }

      var preorder_list = document.getElementsByClassName("preorderProduct");
      for (var i_pre = 0; i_pre < preorder_list.length; i_pre++) {
        preorder_list[i_pre].checked = false;
      }

      var sort_list = document.getElementsByClassName("sortProduct");
      for (var i_sort = 0; i_sort < sort_list.length; i_sort++) {
        sort_list[i_sort].checked = false;
      }

      this.getProdukByStore();
    },
    fungsiFilter(slug) {
      const sortOrNot = slug.split(":")[0];
      if (sortOrNot == "filterProduk") {
        const filterName = slug.split(":")[1];
        const valueFilter = slug.split(":")[2];
        if (filterName == "condition") {
          this.reqData.filter_condition = valueFilter;
          this.getProdukByStore();
        } else {
          this.reqData.filter_preorder = valueFilter;
          this.getProdukByStore();
        }
      } else if (sortOrNot == "sortProduk") {
        this.reqData.sort = slug.split(":")[1];
        this.getProdukByStore();
      } else {
        this.reqData.filter_etalase = slug.split(":")[2];
        this.getProdukByStore();
      }
    },
    fungsiFilterCategory(slug) {
      this.reqData.filter_category = event.target.value;
      this.getProdukByStore();
    },
    getStoreDetail() {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        this.globalToken ? this.globalToken : ""
      );
      myHeaders.append("Cookie", "ci_session=2kdci4f3vuscde03fkaof4iabv7v2ovp");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}store/${this.$route.params.id}`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.dataStore = res.data;
            this.dataStoreImage = res.data.image;
            this.dataStoreLoc = res.data.location;
          }
        })
        .catch((error) => console.log("error", error));
    },
    getProdukByStore: function () {
      this.reqData.limit = 10;
      const token = this.globalToken;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", token ? token : "");
      myHeaders.append("Cookie", "ci_session=p20okeu9kbgvs9amf7e93ptqjkmcg48r");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}product/store/${this.$route.params.id}?page=${this.page}&limit=${this.perPage}&keyword=${this.reqData.keyword}&filter_price=${this.reqData.filter_price}&filter_category=${this.reqData.filter_category}&filter_condition=${this.reqData.filter_condition}&filter_preorder=${this.reqData.filter_preorder}&filter_brand=${this.reqData.filter_brand}&sort=${this.reqData.sort}&filter_etalase=${this.reqData.filter_etalase}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          this.getKategoriSelect();
          const res = JSON.parse(result);
          // console.log(res)
          if (res.status.code === 200) {
            window.scrollTo(0, 0);
            const arrReqData = Object.values(this.reqData);
            const data = arrReqData.filter((item, index) => {
              return item != "";
            });
            if (data.length == 2) {
              data[0] == 1
                ? (this.dataHalUtama = res.data.items)
                : (this.dataHalUtama = {});
            }
            this.dataSemuaProduk = res.data.items;
            this.dataEtalase = res.data.etalase;
            this.dataEtalaseCount = res.data.etalase
              ? res.data.etalase.length
              : "";
            this.dataFilter = res.data.filters;
            this.dataSort = res.data.sorts;
            this.dataPage = res.data.totalPage;
            this.recordsLength = res.data.totalData;
          }
          this.isFinish = true;
        })
        .catch((error) => console.log("error", error));
    },
    getKategoriSelect: function (page) {
      this.reqData.limit = 10;
      const token = this.globalToken;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", token ? token : "");
      myHeaders.append("Cookie", "ci_session=p20okeu9kbgvs9amf7e93ptqjkmcg48r");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}product/store/${this.$route.params.id}?page=${this.page}&limit=${this.perPage}&keyword=&filter_price=&filter_category=&filter_condition=&filter_preorder=&filter_brand=&sort=&filter_etalase=`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.dataFilterCategory = res.data.filters[0].items;
          }
          this.isFinish = true;
        })
        .catch((error) => console.log("error", error));
    },
    handlePageSizeChange(event) {
      this.perPage = event.target.value;
      this.page = 1;
      this.getProdukByStore(this.page);
    },
    halamanProduct(slug) {
      if (slug == "hal_all_produk") {
        $(`#hal_all_produk`).addClass("bg-warning border border-warning");
        $(`#hal_all_produk a`).addClass("text-white font-weight-bold");
        $(`#hal_all_produk`).removeClass("bg-white");
        $(`#hal_all_produk a`).removeClass("text-warning");

        $(`#hal_utama`).addClass("bg-white border border-warning");
        $(`#hal_utama a`).addClass("text-warning font-weight-bold");
        $(`#hal_utama`).removeClass("bg-warning");
        $(`#hal_utama a`).removeClass("text-white");
      } else {
        $(`#hal_utama`).addClass("bg-warning border border-warning");
        $(`#hal_utama a`).addClass("text-white font-weight-bold");
        $(`#hal_utama`).removeClass("bg-white");
        $(`#hal_utama a`).removeClass("text-warning");

        $(`#hal_all_produk`).addClass("bg-white border border-warning");
        $(`#hal_all_produk a`).addClass("text-warning font-weight-bold");
        $(`#hal_all_produk`).removeClass("bg-warning");
        $(`#hal_all_produk a`).removeClass("text-white");
      }
      // var element = document.getElementById(slug);
      // element.classList.remove('bg-warning')
      // element.classList.add("bg-white")
      // element.classList.add("font-weight-bold")
      // element.classList.add("border")
      // element.classList.add("border-warning")
      // element.classList.add("text-warning")
      // const el_text = 'a-'+slug
      // var _el_text = document.getElementById(el_text);
      // _el_text.classList.remove('text-white')
      // _el_text.classList.add("text-warning")

      // var element = document.getElementById(slug);
      // element.classList.remove('bg-white')
      // element.classList.add("bg-warning")
      // element.classList.add("font-weight-bold")
      // element.classList.add("border")
      // element.classList.add("border-warning")
      // const el_text = 'a-'+slug
      // var _el_text = document.getElementById(el_text);
      // _el_text.classList.remove('text-warning')
      // _el_text.classList.add("text-white")

      if (slug == "hal_utama") {
        this.setHidden.hal_utama = true;
        this.setHidden.hal_all_produk = false;

        if (slug == "hal_utama") {
          // var element = document.getElementById(slug);
          // element.classList.remove('bg-warning')
          // element.classList.add("bg-white")
          // element.classList.add("font-weight-bold")
          // element.classList.add("border")
          // element.classList.add("border-warning")
          // element.classList.add("text-warning")
          // const el_text = 'a-'+slug
          // var _el_text = document.getElementById(el_text);
          // _el_text.classList.remove('text-white')
          // _el_text.classList.add("text-warning")
        } else {
          slug = "hal_all_produk";
          // var element = document.getElementById(slug);
          // element.classList.remove('bg-white')
          // element.classList.add("bg-warning")
          // element.classList.add("font-weight-bold")
          // element.classList.add("border")
          // element.classList.add("border-warning")
          // const el_text = 'a-'+slug
          // var _el_text = document.getElementById(el_text);
          // _el_text.classList.remove('text-warning')
          // _el_text.classList.add("text-white")
        }
      } else {
        this.setHidden.hal_utama = false;
        this.setHidden.hal_all_produk = true;
        if (slug == "hal_all_produk") {
          // var element = document.getElementById(slug);
          // element.classList.remove('bg-warning')
          // element.classList.add("bg-white")
          // element.classList.add("font-weight-bold")
          // element.classList.add("border")
          // element.classList.add("border-warning")
          // element.classList.add("text-warning")
          // const el_text = 'a-'+slug
          // var _el_text = document.getElementById(el_text);
          // _el_text.classList.remove('text-white')
          // _el_text.classList.add("text-warning")
        } else {
          slug = "hal_utama";
          // var element = document.getElementById(slug);
          // element.classList.remove('bg-white')
          // element.classList.add("bg-warning")
          // element.classList.add("font-weight-bold")
          // element.classList.add("border")
          // element.classList.add("border-warning")
          // const el_text = 'a-'+slug
          // var _el_text = document.getElementById(el_text);
          // _el_text.classList.remove('text-warning')
          // _el_text.classList.add("text-white")
        }
      }
    },
    filterProduk(slug) {
      // console.log(slug, event.target.value, Object.keys(this.reqData))
      const stringS = "filter_";
      const dataConcate = stringS.concat(slug);
      if (dataConcate == "filter_category") {
        this.reqData.filter_category = event.target.value;
      } else if (dataConcate == "filter_condition") {
        this.reqData.filter_condition = event.target.value;
      } else if (dataConcate == "filter_preorder") {
        this.reqData.filter_preorder = event.target.value;
      }
      setTimeout(() => this.getProdukByStore(), 200);
    },
    sortProduk() {
      this.reqData.sort = event.target.value;
      setTimeout(() => this.getProdukByStore(), 200);
    },
    setPage(page) {
      this.reqData.page = page;
      setTimeout(() => this.getProdukByStore(), 200);
    },
    useVoucherToko(id) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=3jj2gelqr7k1pgt00mekej9msvt8evts");

      const raw = JSON.stringify({
        voucherId: id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}voucher/claimVoucherStore`, requestOptions)
        .then((response) => response.json())
        .then((newRes) => {
          if (newRes.status.code === 200) {
            this.$swal
              .fire({
                icon: "success",
                title: "Sukses",
                text: newRes.status.message,
              })
              .then(() => {
                this.getStoreDetail();
              });
          } else {
            this.$swal.fire({
              icon: "warning",
              title: "Warning",
              text: newRes.status.message,
            });
          }
        })
        .catch((error) => console.log("error", error));
    },
    chatStore(index) {
      $("#chat-toko").attr("disabled", "true");
      $("#chat-toko").html('<i class="fas fa-circle-notch fa-spin"></i>');

      const dataProfile = this.user;
      const dataDetailStore = this.dataStore;

      let getFirebaseTimestamp = firebase.database.ServerValue.TIMESTAMP;
      const database = firebase.database();
      let chatRoom = `${dataProfile.uid}${dataDetailStore.uid}`;
      database
        .ref(`friend/${dataDetailStore.uid}/${dataProfile.uid}`)
        .set({
          chat: chatRoom,
          name: dataProfile.name,
          message: {
            text: "",
            time: getFirebaseTimestamp,
          },
          amount: 0,
        })
        .then(() => {
          database
            .ref(`friend/${dataProfile.uid}/${dataDetailStore.uid}`)
            .set({
              chat: chatRoom,
              name: dataDetailStore.name,
              message: {
                text: "",
                time: getFirebaseTimestamp,
              },
              amount: 0,
            })
            .then(() => {
              localStorage.setItem(
                "paramToChat",
                JSON.stringify({
                  name: dataDetailStore.name,
                  chat: chatRoom,
                  id: dataDetailStore.uid,
                })
              );

              this.$router.push("/chat/");
            });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-info-gradient {
  background-image: linear-gradient(
    90deg,
    rgb(4, 33, 73) 0%,
    rgb(2, 70, 112) 0%,
    rgb(7, 172, 231) 97%
  );
}

.bg-info-gradient-jaja {
  background-image: linear-gradient(
    90deg,
    rgb(4, 33, 73) 0%,
    rgb(86, 189, 207) 0%,
    rgb(7, 172, 231) 97%
  );
}

.bg-info-gradient-eureka {
  background-image: linear-gradient(
    90deg,
    rgb(4, 33, 73) 0%,
    rgb(2, 70, 112) 0%,
    rgb(7, 172, 231) 97%
  );
}

.bg-info-gradient-masdis {
  background-image: linear-gradient(
    90deg,
    rgb(4, 33, 73) 0%,
    rgb(245, 125, 47) 0%,
    rgb(255, 216, 94) 97%
  );
}

.handleWithoutImg {
  width: 100%;
  height: 100%;
}

.imguseravatar {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.gambar-ulasan {
  width: 100px;
  height: 100px;
}

video {
  width: 100px;
  height: 100px;
  position: relative;
}

.tag-discount {
  position: absolute;
}

.img-product {
  height: 200px;
}

.img-product img {
  height: 100%;
  width: 100%;
}

.title-product {
  font-size: 13px;
}

.fa-map-marker {
  color: #ffb6c1;
}

.list-image {
  width: 70px;
  height: 100px;
}

#gambarUtama {
  width: 100%;
  object-fit: contain;
}

.div-imagefull img {
  height: auto;
}

.fa-angle-left {
  font-size: 50px;
}
.fa-angle-right {
  font-size: 50px;
}

.input-group-prepend {
  margin-right: -1px;
  height: 77%;
}

.icon-search-mobile {
  padding: 0.25rem 0.5rem;
  font-size: 0.695rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  height: 77%;
}

.isiProdukLainnya {
  width: 100%;
  height: 250px;
  margin-right: 200px;
  border-radius: 5px;
  bottom: 0;
}

.showratingstar:hover {
  cursor: pointer;
  transition: font-weight 1s;
  font-weight: bold;
}

.showratingstar {
  font-weight: normal;
}

.listProdukTerbaru {
  width: 200px;
}

.imgtokoavatar {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  .product-slider {
    width: 1000px;
    height: 250px;
    margin-right: 200px;
    border-radius: 5px;
    bottom: 0;
  }
}

/* PAGINATION CUSTOM BY MALA */

.page-link {
  width: 40px;
  height: 40px;
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-right: 5px;
  margin-left: 5px;
  color: #65b0c9;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 50%;
  font-weight: bold;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
  border-radius: 50%;
  font-weight: bold;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #65b0c9;
  border-color: #65b0c9;
  font-weight: bold;
}

// CSS V TASYA
.judul-toko {
  color: var(--Neutral-10, #fff);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.16px;
}
.ket-singkat {
  color: var(--Neutral-10, #fff);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.16px;
}
.btn-chat {
  border-radius: 12px;
  background: var(--Primary-40, #65b0c9);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 48px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Neutral-10, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  border: none;
}
.bg-warning {
  background: var(--Primary-40, #65b0c9) !important;
}
.text-warning {
  color: #65b0c9 !important;
}
.border-warning {
  border-color: #65b0c9 !important;
}
.text-des {
  color: var(--Neutral-60, #1a1a1a);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
}
.custom-background-toko {
  background-image: url("../../assets/bg/frame-toko.png");
  background-size: cover;
  background-position: center;
}
</style>