<template>
  <div>
    <Chat />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Chat from "../../components/chat/chat";
export default {
  data() {
    return {
      resFilter: {},
    };
  },
  components: {
    Chat,
  },
  computed: {
    ...mapGetters({ isLoggedIn: "isLoggedIn" }),
    ...mapGetters({ globalToken: "globalTokenGetters" }),
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  beforeMount() {},
  methods: {},
};
</script>