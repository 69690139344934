<template>
  <div>
    <div class="d-none d-lg-block">
      <div>
        <!-- Main Content -->
        <main>
          <div
            style="
              background-color: white;
              box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
            "
          >
            <div
              style="padding-left: 270px; padding-right: 270px"
              class="d-flex bd-highlight mb-3"
            >
              <div class="p-2 bd-highlight">
                <router-link :to="'/'">
                  <img
                    style="width: 87.75px; height: 48px"
                    src="../../assets/seller/jajaid-logo.png"
                  />
                </router-link>
              </div>
              <div class="mt-1 p-2 bd-highlight">
                <!-- <img src="../../assets/seller/seller.png" /> -->
                <span
                  style="
                    color: var(--Primary-50, #438196);
                    text-align: center;
                    font-family: Poppins;
                    font-size: 20px;
                    font-style: italic;
                    font-weight: 600;
                    line-height: 38px; /* 190% */
                    letter-spacing: 0.2px;
                  "
                  >Seller
                  <span
                    style="
                      color: var(--Primary-50, #438196);
                      font-family: Poppins;
                      font-size: 20px;
                      font-style: italic;
                      font-weight: 400;
                      line-height: 38px;
                      letter-spacing: 0.2px;
                    "
                    >Center</span
                  ></span
                >
              </div>
              <div class="ml-auto p-2 bd-highlight">
                <button
                  style="
                    display: flex;
                    height: 48px;
                    padding: 12px 20px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border-radius: 4px;
                    border: none;
                    background: var(--Primary-40, #65b0c9);
                  "
                  onclick="window.open('https://seller.jaja.id/home/buka_toko', '_blank')"
                >
                  <span
                    style="
                      color: var(--Neutral-10, #fff);
                      font-family: Poppins;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 22px; /* 137.5% */
                      letter-spacing: -0.16px;
                    "
                    >Registrasi Toko</span
                  >
                </button>
              </div>
            </div>
          </div>
          <!-- Banner Section -->
          <section style="margin-top: -0.9%" class="banner">
            <!-- <h2>Daftarkan Toko anda dan dapatkan Keuntungannya!</h2> -->
            <img
              src="../../assets/seller/seller-banner.png"
              alt="Banner Image"
            />
          </section>
          <section
            class="banner"
            style="padding-left: 270px; padding-right: 270px"
          >
            <p
              style="
                color: var(--Primary-50, #438196);
                text-align: center;
                font-family: Poppins;
                font-size: 48px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%; /* 57.6px */
              "
            >
              Ayo buka tokomu di Jaja ID!
            </p>
            <p
              style="
                color: var(--Neutral-40, #a4a4a4);
                text-align: center;
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px;
                letter-spacing: 0.2px;
              "
            >
              Kenapa harus buka toko di Jaja.Id?
            </p>

            <div class="row">
              <div class="col-4">
                <div>
                  <img src="../../assets/seller/a.png" alt="Icon 1" />
                  <h3 class="mt-3">Daftar Toko Seller Mudah</h3>
                </div>
              </div>
              <div class="col-4">
                <div>
                  <img src="../../assets/seller/b.png" alt="Icon 2" />
                  <h3 class="mt-3">Biaya Admin Rendah</h3>
                </div>
              </div>
              <div class="col-4">
                <div>
                  <img src="../../assets/seller/c.png" alt="Icon 3" />
                  <h3 class="mt-3">Banyak Bonus dan Promo</h3>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4">
                <div>
                  <img src="../../assets/seller/d.png" alt="Icon 4" />
                  <h3 class="mt-3">Admin Tersedia 24 Jam</h3>
                </div>
              </div>
              <div class="col-4">
                <div>
                  <img src="../../assets/seller/e.png" alt="Icon 5" />
                  <h3 class="mt-3">Validasi Toko Cepat</h3>
                </div>
              </div>
              <div class="col-4">
                <div>
                  <img src="../../assets/seller/f.png" alt="Icon 6" />
                  <h3 class="mt-3">Penggunaan Mudah dan Cepat</h3>
                </div>
              </div>
            </div>
          </section>
          <section class="how-to-open-store">
            <div class="custom-bg-untung">
              <div class="pd-gambar d-flex justify-content-between">
                <div>
                  <p
                    style="
                      color: #fff;
                      font-family: Poppins;
                      font-size: 36px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 120%; /* 43.2px */
                    "
                  >
                    Keuntungan Seller Jaja.Id
                  </p>
                  <br />
                  <img src="../../assets/seller/icon.png" alt="Step 6" />
                </div>
                <div>
                  <div style="margin-top: 150px" class="row">
                    <div class="col-6">
                      <div class="card-ha">
                        <p class="mt-3 title-card1">Produkmu Ter-Hightlight</p>
                        <p class="des-card">
                          Lorem ipsum dolor sit amet consectetur. Risus duis
                          imperdiet pellentesque massa in fermentum purus. Id id
                          porta suspendisse nulla sem amet mauris massa.
                        </p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="card-ha">
                        <p class="mt-3 title-card1">Produkmu Ter-Hightlight</p>
                        <p class="des-card">
                          Lorem ipsum dolor sit amet consectetur. Risus duis
                          imperdiet pellentesque massa in fermentum purus. Id id
                          porta suspendisse nulla sem amet mauris massa.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4 row">
                    <div class="col-6">
                      <div class="card-ha">
                        <p class="mt-3 title-card1">Produkmu Ter-Hightlight</p>
                        <p class="des-card">
                          Lorem ipsum dolor sit amet consectetur. Risus duis
                          imperdiet pellentesque massa in fermentum purus. Id id
                          porta suspendisse nulla sem amet mauris massa.
                        </p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="card-ha">
                        <p class="mt-3 title-card1">Produkmu Ter-Hightlight</p>
                        <p class="des-card">
                          Lorem ipsum dolor sit amet consectetur. Risus duis
                          imperdiet pellentesque massa in fermentum purus. Id id
                          porta suspendisse nulla sem amet mauris massa.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- How to Open Store Section -->
          <section class="how-to-open-store">
            <h2>Cara Buka Toko Jaja.id</h2>
            <p>Ikuti step-step dibawah ini yaa untuk bisa membuka toko mu</p>
            <div class="steps">
              <div class="row">
                <div class="col-6">
                  <div>
                    <img src="../../assets/seller/1.png" alt="Step 1" />
                    <p class="txt-tutorial mt-2 mb-5">
                      1. Buka website Jaja.id Seller (www.seller.jaja.id)
                    </p>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    <img src="../../assets/seller/2.png" alt="Step 2" />
                    <p class="txt-tutorial mt-2 mb-5">
                      2. Buat atau Login akun Jaja.id
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div>
                    <img src="../../assets/seller/3.png" alt="Step 3" />
                    <p class="txt-tutorial mt-2 mb-5">
                      3. Klik Tombol "Buka Toko"
                    </p>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    <img src="../../assets/seller/4.png" alt="Step 4" />
                    <p class="txt-tutorial mt-2 mb-5">
                      4. Lengkapi Profil Toko Anda
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div>
                    <img src="../../assets/seller/5.png" alt="Step 5" />
                    <p class="txt-tutorial mt-2 mb-5">
                      5. Buat dan Masukkan Produk Anda
                    </p>
                  </div>
                </div>
                <div class="col-6">
                  <div class="">
                    <img src="../../assets/seller/6.png" alt="Step 6" />
                    <p class="txt-tutorial mt-2 mb-5">
                      6. Lakukan Verifikasi Toko Anda
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="custom-bg-download">
            <div
              style="padding-left: 300px; padding-right: 300px"
              class="pd-gambar d-flex justify-content-between"
            >
              <div
                style="
                  color: var(--Neutral-10, #fff);
                  font-family: Poppins;
                  font-size: 36px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 120%; /* 43.2px */
                "
              >
                Download Aplikasi Jaja.id Sekarang!
              </div>
              <div class="d-flex">
                <img src="../../assets/seller/appstore.png" alt="Step 6" />
                <img
                  class="ml-3"
                  src="../../assets/seller/gplay.png"
                  alt="Step 6"
                />
              </div>
            </div>
          </div>
          <!-- FAQ Section with Accordion -->
          <section class="banner">
            <h2>Pertanyaan terkait toko seller</h2>
            <p
              style="
                color: var(--Neutral-40, #a4a4a4);
                text-align: center;
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px; /* 130% */
                letter-spacing: 0.2px;
              "
            >
              Berikut adalah Pertanyaan yang sering ditanyakan seller
            </p>
            <div
              style="width: 85%; padding-left: 280px"
              class="accordion"
              role="tablist"
            >
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    style="padding: 20px"
                    block
                    v-b-toggle.accordion-1
                    variant="white"
                    class="d-flex justify-content-between align-items-center"
                    @click="toggleAccordion"
                  >
                    <span>Ini adalah Pertanyaan 1 ?</span>
                    <b-icon
                      :icon="isOpen ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-1"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body style="padding: 20px">
                    <b-card-text>{{ text }}</b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    style="padding: 20px"
                    block
                    v-b-toggle.accordion-2
                    variant="white"
                    class="d-flex justify-content-between align-items-center"
                    @click="toggleAccordion"
                  >
                    <span>Ini adalah Pertanyaan 2 ?</span>
                    <b-icon
                      :icon="isOpen ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>{{ text }}</b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    style="padding: 20px"
                    block
                    v-b-toggle.accordion-3
                    variant="white"
                    class="d-flex justify-content-between align-items-center"
                    @click="toggleAccordion"
                  >
                    <span>Ini adalah Pertanyaan 3 ?</span>
                    <b-icon
                      :icon="isOpen ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-3"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>{{ text }}</b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </section>
          <div class="custom-bg-footer">
            <p
              style="
                color: var(--Primary-40, #65b0c9);
                text-align: center;
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px; /* 130% */
                letter-spacing: 0.2px;
              "
            >
              By Eureka Group @2024.
            </p>
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                align-self: stretch;
              "
            >
              <div>
                <img class="card-per" src="../../assets/seller/ebh.png" />
              </div>
              <div>
                <img class="card-per" src="../../assets/seller/masdis.png" />
              </div>
              <div>
                <img class="card-per" src="../../assets/seller/jajaid.png" />
              </div>
              <div>
                <img class="card-per" src="../../assets/seller/race.png" />
              </div>
              <div>
                <img class="card-per" src="../../assets/seller/elog.png" />
              </div>
              <div>
                <img class="card-per" src="../../assets/seller/siplah.png" />
              </div>
              <div>
                <img class="card-per" src="../../assets/seller/ebhid.png" />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <div class="d-lg-none">
      <div>
        <main>
          <div
            style="
              background-color: white;
              box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
            "
          >
            <div class="d-flex bd-highlight mb-3">
              <!-- <div class="p-2 bd-highlight">
                <img
                  style="width: 87.75px; height: 48px"
                  src="../../assets/seller/jajaid-logo.png"
                />
              </div> -->
              <div class="ml-5 mt-1 p-2 bd-highlight">
                <!-- <img src="../../assets/seller/seller.png" /> -->
                <router-link :to="'/'">
                  <span
                    style="
                      color: var(--Primary-50, #438196);
                      text-align: center;
                      font-family: Poppins;
                      font-size: 20px;
                      font-style: italic;
                      font-weight: 600;
                      line-height: 38px; /* 190% */
                      letter-spacing: 0.2px;
                    "
                    >Jaja Seller
                    <span
                      style="
                        color: var(--Primary-50, #438196);
                        font-family: Poppins;
                        font-size: 20px;
                        font-style: italic;
                        font-weight: 400;
                        line-height: 38px;
                        letter-spacing: 0.2px;
                      "
                      >Center</span
                    ></span
                  >
                </router-link>
              </div>
              <div class="ml-auto mr-5 mt-1 p-2 bd-highlight">
                <button
                  style="
                    display: flex;
                    height: 30px;
                    padding: 6px 10px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border-radius: 4px;
                    border: none;
                    background: var(--Primary-40, #65b0c9);
                  "
                  onclick="window.open('https://jsonx.jaja.id/user/login', '_blank')"
                >
                  <span
                    style="
                      color: var(--Neutral-10, #fff);
                      font-family: Poppins;
                      font-size: 16px;
                      font-style: normal;
                      letter-spacing: -0.16px;
                    "
                    >Registrasi</span
                  >
                </button>
              </div>
            </div>
          </div>
          <!-- Banner Section -->
          <section style="margin-top: -5%" class="banner">
            <img
              src="../../assets/seller/responsive/bnner1.png"
              alt="Banner Image"
            />
          </section>
          <section class="banner">
            <p
              style="
                color: var(--Primary-50, #438196);
                text-align: center;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%; /* 57.6px */
              "
            >
              Ayo buka tokomu di Jaja ID!
            </p>
            <p
              style="
                color: var(--Neutral-40, #a4a4a4);
                text-align: center;
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px;
                letter-spacing: 0.2px;
              "
            >
              Kenapa harus buka toko di Jaja.Id?
            </p>

            <div class="row">
              <div class="col-4">
                <div>
                  <img
                    src="../../assets/seller/responsive/1.png"
                    alt="Icon 1"
                  />
                  <span class="text-buka mt-3">Daftar Toko Seller Mudah</span>
                </div>
              </div>
              <div class="col-4">
                <div>
                  <img
                    src="../../assets/seller/responsive/2.png"
                    alt="Icon 2"
                  />
                  <span class="text-buka mt-3">Biaya Admin Rendah</span>
                </div>
              </div>
              <div class="col-4">
                <div>
                  <img
                    src="../../assets/seller/responsive/3.png"
                    alt="Icon 3"
                  />
                  <span class="text-buka mt-3">Banyak Bonus dan Promo</span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4">
                <div>
                  <img
                    src="../../assets/seller/responsive/5.png"
                    alt="Icon 4"
                  />
                  <span class="text-buka mt-3">Admin Tersedia 24 Jam</span>
                </div>
              </div>
              <div class="col-4">
                <div>
                  <img
                    src="../../assets/seller/responsive/6.png"
                    alt="Icon 5"
                  />
                  <span class="text-buka mt-3">Validasi Toko Cepat</span>
                </div>
              </div>
              <div class="col-4">
                <div>
                  <img
                    src="../../assets/seller/responsive/7.png"
                    alt="Icon 6"
                  />
                  <span class="text-buka mt-3">Penggunaan Mudah dan Cepat</span>
                </div>
              </div>
            </div>
          </section>
          <section style="text-align: center; background-color: #e0f7fa">
            <img src="../../assets/seller/responsive/4.png" />
            <!-- <div class="custom-bg-untung">
              <div class="pd-gambar d-flex justify-content-between">
                <div>
                  <p
                    style="
                      color: #fff;
                      font-family: Poppins;
                      font-size: 36px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 120%; /* 43.2px */
                    "
                  >
                    Keuntungan Seller Jaja.Id
                  </p>
                  <br />
                  <img src="../../assets/seller/icon.png" alt="Step 6" />
                </div>
                <div>
                  <div style="margin-top: 150px" class="row">
                    <div class="col-6">
                      <div class="card-ha">
                        <p class="mt-3 title-card1">Produkmu Ter-Hightlight</p>
                        <p class="des-card">
                          Lorem ipsum dolor sit amet consectetur. Risus duis
                          imperdiet pellentesque massa in fermentum purus. Id id
                          porta suspendisse nulla sem amet mauris massa.
                        </p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="card-ha">
                        <p class="mt-3 title-card1">Produkmu Ter-Hightlight</p>
                        <p class="des-card">
                          Lorem ipsum dolor sit amet consectetur. Risus duis
                          imperdiet pellentesque massa in fermentum purus. Id id
                          porta suspendisse nulla sem amet mauris massa.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4 row">
                    <div class="col-6">
                      <div class="card-ha">
                        <p class="mt-3 title-card1">Produkmu Ter-Hightlight</p>
                        <p class="des-card">
                          Lorem ipsum dolor sit amet consectetur. Risus duis
                          imperdiet pellentesque massa in fermentum purus. Id id
                          porta suspendisse nulla sem amet mauris massa.
                        </p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="card-ha">
                        <p class="mt-3 title-card1">Produkmu Ter-Hightlight</p>
                        <p class="des-card">
                          Lorem ipsum dolor sit amet consectetur. Risus duis
                          imperdiet pellentesque massa in fermentum purus. Id id
                          porta suspendisse nulla sem amet mauris massa.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </section>

          <!-- How to Open Store Section -->
          <section
            style="text-align: center; padding: 50px; background-color: #e0f7fa"
          >
            <span
              style="
                color: var(--Primary-50, #438196);
                text-align: center;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 125% */
                letter-spacing: 0.16px;
              "
              >Cara Buka Toko Jaja.id</span
            >
            <p
              style="
                color: var(--Neutral-40, #a4a4a4);
                text-align: center;
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px; /* 160% */
                letter-spacing: -0.1px;
              "
            >
              Ikuti step-step dibawah ini yaa untuk bisa membuka toko mu
            </p>
            <div class="steps">
              <div class="row">
                <div class="col-6">
                  <div>
                    <img
                      src="../../assets/seller/responsive/s1.png"
                      alt="Step 1"
                    />
                    <p class="txt-tut mt-2 mb-5">
                      1. Buka website Jaja.id Seller (www.seller.jaja.id)
                    </p>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    <img
                      src="../../assets/seller/responsive/s2.png"
                      alt="Step 2"
                    />
                    <p class="txt-tut mt-2 mb-5">
                      2. Buat atau Login akun Jaja.id
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div>
                    <img
                      src="../../assets/seller/responsive/s3.png"
                      alt="Step 3"
                    />
                    <p class="txt-tut mt-2 mb-5">3. Klik Tombol "Buka Toko"</p>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    <img
                      src="../../assets/seller/responsive/s4.png"
                      alt="Step 4"
                    />
                    <p class="txt-tut mt-2 mb-5">
                      4. Lengkapi Profil Toko Anda
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div>
                    <img
                      src="../../assets/seller/responsive/s5.png"
                      alt="Step 5"
                    />
                    <p class="txt-tut mt-2 mb-5">
                      5. Buat dan Masukkan Produk Anda
                    </p>
                  </div>
                </div>
                <div class="col-6">
                  <div class="">
                    <img
                      src="../../assets/seller/responsive/s6.png"
                      alt="Step 6"
                    />
                    <p class="txt-tut mt-2">6. Lakukan Verifikasi Toko Anda</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section style="text-align: center; background-color: #e0f7fa">
            <img
              style="width: 100%"
              src="../../assets/seller/responsive/dres.png"
            />
          </section>
          <!-- <div class="custom-bg-download">
            <div
              style="padding-left: 300px; padding-right: 300px"
              class="pd-gambar d-flex justify-content-between"
            >
              <div
                style="
                  color: var(--Neutral-10, #fff);
                  font-family: Poppins;
                  font-size: 36px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 120%; /* 43.2px */
                "
              >
                Download Aplikasi Jaja.id Sekarang!
              </div>
              <div class="d-flex">
                <img src="../../assets/seller/appstore.png" alt="Step 6" />
                <img
                  class="ml-3"
                  src="../../assets/seller/gplay.png"
                  alt="Step 6"
                />
              </div>
            </div>
          </div> -->
          <!-- FAQ Section with Accordion -->
          <section class="banner">
            <span
              style="
                color: var(--Primary-50, #438196);
                text-align: center;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 125% */
                letter-spacing: 0.16px;
              "
              >Pertanyaan terkait toko seller</span
            >
            <p
              style="
                color: var(--Neutral-40, #a4a4a4);
                text-align: center;
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px; /* 160% */
                letter-spacing: -0.1px;
              "
            >
              Berikut adalah Pertanyaan yang sering ditanyakan seller
            </p>
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    style="padding: 20px"
                    block
                    v-b-toggle.accordion-1
                    variant="white"
                    class="d-flex justify-content-between align-items-center"
                    @click="toggleAccordion"
                  >
                    <span>Ini adalah Pertanyaan 1 ?</span>
                    <b-icon
                      :icon="isOpen ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-1"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body style="padding: 20px">
                    <b-card-text>{{ text }}</b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    style="padding: 20px"
                    block
                    v-b-toggle.accordion-2
                    variant="white"
                    class="d-flex justify-content-between align-items-center"
                    @click="toggleAccordion"
                  >
                    <span>Ini adalah Pertanyaan 2 ?</span>
                    <b-icon
                      :icon="isOpen ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>{{ text }}</b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    style="padding: 20px"
                    block
                    v-b-toggle.accordion-3
                    variant="white"
                    class="d-flex justify-content-between align-items-center"
                    @click="toggleAccordion"
                  >
                    <span>Ini adalah Pertanyaan 3 ?</span>
                    <b-icon
                      :icon="isOpen ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-3"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>{{ text }}</b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </section>
          <div class="custom-bg-footer">
            <p
              style="
                color: var(--Primary-40, #65b0c9);
                text-align: center;
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px; /* 130% */
                letter-spacing: 0.2px;
              "
            >
              By Eureka Group @2024.
            </p>
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                align-self: stretch;
              "
            >
              <div>
                <img class="card-per-res" src="../../assets/seller/ebh.png" />
              </div>
              <div>
                <img
                  class="card-per-res"
                  src="../../assets/seller/masdis.png"
                />
              </div>
              <div>
                <img
                  class="card-per-res"
                  src="../../assets/seller/jajaid.png"
                />
              </div>
              <div>
                <img class="card-per-res" src="../../assets/seller/race.png" />
              </div>
              <div>
                <img class="card-per-res" src="../../assets/seller/elog.png" />
              </div>
              <div>
                <img
                  class="card-per-res"
                  src="../../assets/seller/siplah.png"
                />
              </div>
              <div>
                <img class="card-per-res" src="../../assets/seller/ebhid.png" />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      text: `
        Lorem ipsum dolor sit amet consectetur. Egestas erat bibendum pellentesque egestas eget molestie ornare id. Hac amet integer lacinia risus. Aliquam tincidunt nibh et id morbi proin feugiat nulla imperdiet. Odio ultricies morbi dictum odio elit in venenatis risus.
          `,
      isOpen: false,
    };
  },
  methods: {
    toggle(index) {
      this.faqItems[index].open = !this.faqItems[index].open;
    },
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
    
    <style scoped>
/* Add your CSS styling here */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f8f8f8;
}
.header-container h1 {
  margin: 0;
}
.header-container nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}
.header-container nav ul li {
  margin-left: 20px;
}
.header-container nav ul li a {
  text-decoration: none;
  color: #000;
}
.banner {
  text-align: center;
  padding: 50px;
  background-color: #e0f7fa;
}
.benefits {
  text-align: center;
  padding: 50px;
  background-color: #ffffff;
}
.benefit-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.benefit-card {
  width: 200px;
  margin: 10px;
  text-align: center;
}
.how-to-open-store {
  text-align: center;
  padding: 50px;
  padding-right: 320px;
  padding-left: 320px;
  background-color: #e0f7fa;
}
.steps {
  /* display: flex; */
  justify-content: center;
  flex-wrap: wrap;
}
.step {
  width: 200px;
  margin: 10px;
  text-align: center;
}
.faq {
  padding: 50px;
  background-color: #ffffff;
}
.faq-item {
  margin-bottom: 20px;
  cursor: pointer;
}
.faq-item h3 {
  margin: 0;
}
.faq-item p {
  display: none;
  margin: 10px 0 0 0;
}
.faq-item p[v-show="true"] {
  display: block;
}
.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f8f8f8;
}
.footer-column {
  width: 20%;
}
.footer-column h3 {
  margin-top: 0;
}
.footer-column ul {
  list-style: none;
  padding: 0;
}
.footer-column ul li {
  margin-bottom: 10px;
}
.container-gift {
  padding: 200px;
}
.custom-bg-untung {
  background-image: url("../../assets/seller/bnner-tung.png");
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}
.contain-faq {
  width: 100%;
}
.pd-gambar {
  padding: 30px;
}
.card-ha {
  border-radius: 10px;
  background: #fff;
  width: 320px;
  height: 129px;
  flex-shrink: 0;
}
.title-card1 {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 130% */
  letter-spacing: 0.2px;
  padding-top: 20px;
}
.des-card {
  color: #000;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 160% */
  letter-spacing: -0.1px;
}
.custom-bg-download {
  background-image: url("../../assets/seller/bnnr-download.png");
  background-size: cover;
  background-position: center;
  padding: 20px;
}
.txt-tutorial {
  color: var(--Neutral-60, #1a1a1a);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 130% */
  letter-spacing: 0.2px;
}
.card-per {
  display: flex;
  height: 63px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.card-per-res {
  display: flex;
  height: 23px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}
.custom-bg-footer {
  background-image: url("../../assets/seller/bg-footer.png");
  background-size: cover;
  background-position: center;
  padding: 20px;
}
.text-buka {
  color: var(--Primary-50, #438196);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 160% */
  letter-spacing: -0.1px;
}
.txt-tut {
  color: var(--Primary-50, #438196);
  text-align: center;
  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 175% */
  letter-spacing: 0.08px;
}
.card-text:last-child {
  margin-bottom: 0;
  text-align: left;
}
</style>
    